import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

import { RecipientAvatar } from '@/domains/Business/components'
import { PaymentRails } from '@/domains/Business/constants'
import { getRecipientAddress } from '@/lib/address'
import { CountryWithFlag } from '@/shared/components'
import { Details, Typography } from '@/shared/ui'

import { Recipient } from '../types'
import { getRecipientName } from '../utils'

type Props = {
  recipient?: Recipient
}

export const RecipientContactDetails = ({ recipient }: Props) => {
  const intl = useIntl()

  switch (recipient?.paymentRails) {
    case PaymentRails.MEX:
    case PaymentRails.USA:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="recipient.recipientCountry"
                defaultMessage="Recipient country"
              />
            </Details.Label>

            <CountryWithFlag country={recipient.country} />
          </Details>

          {recipient.email ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.email" defaultMessage="Email" />
              </Details.Label>
              <Details.Value>{recipient.email}</Details.Value>
            </Details>
          ) : null}

          {recipient.internationalPhonePrefix && recipient.localPhoneNumber ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.phoneNumber"
                  defaultMessage="Phone number"
                />
              </Details.Label>
              <Details.Value>
                {formatPhoneNumberIntl(
                  recipient.internationalPhonePrefix +
                    recipient.localPhoneNumber,
                )}
              </Details.Value>
            </Details>
          ) : null}

          {recipient.addressCity ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.address" defaultMessage="Address" />
              </Details.Label>
              <Details.Value className="whitespace-break-spaces text-right">
                {getRecipientAddress(recipient, intl)}
              </Details.Value>
            </Details>
          ) : null}
        </>
      )

    case PaymentRails.DOLAR_APP:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.recipientName"
                defaultMessage="Recipient name"
              />
            </Details.Label>

            <Details.Value>{getRecipientName(recipient)}</Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.type" defaultMessage="Type" />
            </Details.Label>

            <div className="flex items-center gap-1">
              <RecipientAvatar
                variant="dark-neutral"
                recipient={recipient}
                size="sm"
              />

              <Typography>
                <FormattedMessage
                  defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
                  id="recipients.entity"
                  values={{ entity: recipient.entityType }}
                />
              </Typography>
            </div>
          </Details>
        </>
      )

    default:
      return (
        <>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
        </>
      )
  }
}
