import { FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { Button, Card, Typography } from '@/shared/ui'

import { OnboardingStepName } from '../types'

type KeyValue = {
  key?: React.ReactNode
  value?: React.ReactNode
}

type StepDetailsProps = {
  step: OnboardingStepName
  title?: string
  details: KeyValue[]
}

export const StepDetails = ({ step, details, title }: StepDetailsProps) => {
  return (
    <div className="flex flex-col">
      {title ? (
        <>
          <div className="flex justify-between px-2">
            <Typography bold>{title}</Typography>

            <Button
              variant="ghost"
              size="inline"
              className="text-neutral-gray-600"
              asChild
            >
              <Link to={generatePath(BusinessRoute.OnboardingStep, { step })}>
                <Typography bold>
                  <FormattedMessage id="action.edit" defaultMessage="Edit" />
                </Typography>
              </Link>
            </Button>
          </div>
          <div className="p-1" />
        </>
      ) : null}

      <Card className="flex flex-col gap-4" size="medium">
        {details.map(({ key, value }, index) => {
          if (!key || !value) {
            return null
          }

          return (
            <div className="flex w-full justify-between" key={index}>
              <Typography className="text-neutral-gray-600">{key}</Typography>
              {value}
            </div>
          )
        })}
      </Card>
    </div>
  )
}
