import { FormattedMessage } from 'react-intl'

import { Role } from '@/domains/Business/types/roles'
import { getFullName } from '@/lib/typography'
import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { TeamMemberSchema } from './AddTeamMemberSteps/schemas'

type Props = {
  teamMemberDetails?: TeamMemberSchema
  role?: Role
}

export const TeamDetailsReviewSection = ({
  teamMemberDetails,
  role,
}: Props) => {
  return (
    <Widget
      title={
        <FormattedMessage
          id="teamMember.create.reviewStep.memberDetails"
          defaultMessage="Team member details"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Role" id="label.role" />
        </Details.Label>

        <Details.Value>
          <FormattedMessage
            id="team.role.label"
            defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
            values={{ role }}
          />
        </Details.Value>
      </Details>
      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Name" id="label.name" />
        </Details.Label>

        <Details.Value>{getFullName(teamMemberDetails)}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Title" id="label.title" />
        </Details.Label>

        <Details.Value>
          {teamMemberDetails?.title !== '' ? teamMemberDetails?.title : '-'}
        </Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Work email" id="label.workEmail" />
        </Details.Label>

        <Details.Value>{teamMemberDetails?.email}</Details.Value>
      </Details>
    </Widget>
  )
}
