import { formatAmount } from '@/lib/money'
import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { HistoricalPayment } from '../../../types'

type Props = {
  payment: HistoricalPayment
}

export const HistoricalAmountCell = ({ payment }: Props) => {
  return (
    <div className="flex flex-col justify-center">
      <Typography className={cn('whitespace-nowrap')}>
        {formatAmount({
          amount: payment.baseAmount,
          currency: payment.baseCurrency,
        })}
      </Typography>

      <Typography className={cn('whitespace-nowrap text-neutral-gray-400')}>
        {formatAmount({
          amount: payment.localAmount,
          currency: payment.localCurrency,
        })}
      </Typography>
    </div>
  )
}
