import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { BusinessRoute, NavigationLink, navMessages } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getBusinessIdentity, getIdentity } from '@/domains/Business/api'
import { ProfileDropdown } from '@/domains/Business/components'
import { useBusinessRole } from '@/domains/Business/hooks'
import {
  BusinessIdentity,
  BusinessState,
  Identity,
} from '@/domains/Business/types'
import { cn } from '@/lib/utils'
import { LogoIcon, SlideInSpan } from '@/shared/components'
import { MotionSpan, Typography } from '@/shared/ui'

import { useNavigationLinks } from '../hooks/useNavigationLinks'

type NavigationLinksProps = {
  links: NavigationLink[]
}

const NavigationLinks = ({ links }: NavigationLinksProps) => {
  const intl = useIntl()

  return (
    <ul className="flex w-full flex-col items-start gap-4 text-neutral-gray-100">
      {links.map(({ key, path, icon: Icon, enabled, count }) => {
        if (!enabled) {
          return null
        }

        return (
          <li className="w-full" key={key}>
            <NavLink
              end={path === BusinessRoute.Dashboard}
              to={path}
              className={cn(
                'relative z-10 flex w-full items-center justify-center gap-3 whitespace-nowrap rounded-xl py-2 outline-none transition-colors aria-[current=page]:bg-neutral-gray-900 aria-[current=page]:font-bold md:h-10 md:justify-start md:p-2 md:hover:bg-neutral-gray-900 md:focus:bg-neutral-gray-900',
              )}
            >
              <span className="relative size-6 shrink-0">
                <Icon
                  className={cn('size-6 shrink-0 justify-self-center')}
                  aria-label={intl.formatMessage(navMessages[key])}
                />
              </span>

              <AnimatePresence mode="wait">
                <SlideInSpan className="hidden md:inline">
                  <Typography>
                    {intl.formatMessage(navMessages[key])}
                  </Typography>
                </SlideInSpan>
              </AnimatePresence>
              {count && count > 0 ? (
                <MotionSpan className="absolute right-2.5 hidden size-5 items-center justify-center rounded-full bg-primary-dark md:flex">
                  <Typography bold variant="body-small">
                    {count}
                  </Typography>
                </MotionSpan>
              ) : null}
            </NavLink>
          </li>
        )
      })}
    </ul>
  )
}

export const Sidebar = () => {
  const { topLinks, bottomLinks } = useNavigationLinks()

  const { boot } = useIntercom()

  const [openPopover, setOpenPopover] = useState(false)
  const { role } = useBusinessRole()

  const [businessQuery, userQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getBusinessIdentity],
        queryFn: getBusinessIdentity,
        select: (data: AxiosResponse<BusinessIdentity>) => data.data,
      },
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
    ],
  })

  useEffect(() => {
    if (userQuery.data && businessQuery.data && role) {
      boot({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
        phone: userQuery.data?.phoneNumber
          ? `${userQuery.data.phoneNumber.internationalPhonePrefix}${userQuery.data.phoneNumber.localPhoneNumber}`
          : undefined,
        customAttributes: {
          user_id: userQuery.data.id,
          user_type: 'BUSINESS',
          user_state: BusinessState.ACTIVE,
          user_role: role,
        },
        company: {
          name: businessQuery.data.legalName,
          companyId: businessQuery.data.id,
          customAttributes: {
            business_country: businessQuery.data.countryOfIncorporation,
            business_tax_id: businessQuery.data.taxId,
            business_tax_type: businessQuery.data.taxIdType,
          },
        },
      })

      Sentry.setUser({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
        id: userQuery.data?.id,
      })
    }
  }, [boot, businessQuery.data, role, userQuery.data])

  return (
    <nav
      className={cn(
        'fixed z-50 min-h-screen w-12 bg-neutral-gray-1000 px-1 py-6 transition-all duration-300 md:w-56 md:px-3',
      )}
    >
      <div className="hide-scrollbar flex h-screen flex-col overflow-y-auto overflow-x-hidden">
        <ProfileDropdown
          type="active"
          open={openPopover}
          onOpenChange={setOpenPopover}
        />

        <div className="p-6" />

        <NavigationLinks links={topLinks} />

        <div className="p-2" />

        <div className="mb-16 mt-auto flex w-full flex-col gap-8">
          <NavigationLinks links={bottomLinks} />

          <div className="md:pl-2.5">
            <LogoIcon aria-label="DolarApp Business logo" className="size-8" />
          </div>
        </div>
      </div>
    </nav>
  )
}
