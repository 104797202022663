import { forwardRef, Ref, type SVGProps } from 'react'

const SvgUnlink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 14 14"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M6.685 2.824a.75.75 0 1 0 1.06 1.06zm2.077-1.017-.523-.537-.007.007zm3.073.02.53-.53zm.02 3.074.53.53.008-.007zM9.778 5.917a.75.75 0 0 0 1.061 1.06zM8.78 9.042a.75.75 0 1 0-1.06-1.061zM6.7 10.058l-.53-.53zm-3.094 0 .53-.53zm0-3.094.53.53zm2.077-1.017a.75.75 0 1 0-1.06-1.06zm6.629 7.425a.75.75 0 1 0 1.044-1.077zM7.264 6.39A.75.75 0 1 0 6.22 7.468zM6.203 7.45a.75.75 0 1 0 1.078-1.042zM1.706.645A.75.75 0 0 0 .628 1.688zm5.562 6.819a.75.75 0 0 0-1.052-1.07zm-1.61-.52A.75.75 0 1 0 6.71 8.012zm4.15-2.029a.75.75 0 0 0-1.06-1.06zm-2.183.062a.75.75 0 1 0 1.06 1.06zm.12-1.093 1.547-1.546-1.06-1.061-1.547 1.547zm1.54-1.54a1.437 1.437 0 0 1 2.02.014l1.06-1.061A2.94 2.94 0 0 0 8.24 1.27zm2.02.014c.556.556.562 1.456.013 2.02l1.075 1.046a2.937 2.937 0 0 0-.027-4.127zm.02 2.012L9.778 5.917l1.061 1.06 1.547-1.546zM7.721 7.98 6.17 9.528l1.06 1.062 1.55-1.547zM6.17 9.529a1.44 1.44 0 0 1-2.034 0l-1.06 1.06a2.94 2.94 0 0 0 4.155 0zm-2.034 0a1.44 1.44 0 0 1 0-2.034l-1.06-1.06a2.94 2.94 0 0 0 0 4.155zm0-2.034 1.547-1.547-1.06-1.06-1.547 1.547zm9.22 4.8L7.264 6.39 6.22 7.468l6.092 5.904zM7.28 6.409 1.706.645.628 1.688 6.203 7.45zm-1.065-.014-.558.55L6.71 8.012l.558-.55zm2.532-2.54L7.625 4.978l1.06 1.06 1.123-1.122z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgUnlink)
export default ForwardRef
