import { useCallback, useMemo } from 'react'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { format } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getRatesWithFees, RatesWithFeesResponse } from '@/domains/Business/api'
import {
  ACH_FEE,
  PaymentMethod,
  SPEI,
  WIRE_FEE,
} from '@/domains/Business/constants'
import { getSingleRecipient } from '@/domains/Business/features/Recipients/api'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import { DisplayableType } from '@/domains/Business/features/Transactions/types'
import { useCheckUserPaymentLimit } from '@/domains/Business/hooks'
import { useErrorToast } from '@/hooks/useErrorToast'
import {
  formatAmount,
  formatCurrency,
  formatMoney,
  formatRate,
} from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton, Widget } from '@/shared/components'
import {
  Button,
  Details,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { sendMoney } from '../api'

import { SendAmountSchema } from './AmountScreen'
import { ReviewPaymentDetails } from './ReviewPaymentDetails'

type Props = {
  recipientId?: string | null
  onBack: () => void
  sendData?: SendAmountSchema
}

function isUSDc(currency?: Currency) {
  return currency === Currency.USDC
}

export const ReviewScreen = ({ sendData, onBack, recipientId }: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const isOverLimit = useCheckUserPaymentLimit()

  const [recipientQuery, ratesQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getSingleRecipient, recipientId],
        queryFn: () => getSingleRecipient({ id: recipientId ?? '' }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!recipientId,
      },
      {
        queryKey: [
          queryKeys.getFXRates,
          sendData?.currencyOut,
          sendData?.currencyIn,
        ],
        queryFn: () => {
          if (!sendData?.currencyIn) {
            return Promise.reject(new Error('Currency is required'))
          }

          return getRatesWithFees({
            from: Currency.USDC,
            to: isUSDc(sendData?.currencyIn)
              ? sendData?.currencyOut
              : sendData?.currencyIn,
          })
        },
        select: (data: AxiosResponse<RatesWithFeesResponse>) => data.data,
        enabled: !!sendData?.currencyIn,
        refetchInterval: 10 * 1000,
      },
    ],
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendMoney,
  })

  const isUSRecipient = recipientQuery.data?.country === CountryCode.US

  const onSubmit = useCallback(async () => {
    if (!sendData || !recipientId) {
      return
    }

    removeEmptyFormFields(sendData)

    try {
      const response = await mutateAsync({
        amount: sendData.amountIn,
        requestId: sendData.requestId,
        walletId: sendData.walletId,
        currency: sendData.currencyIn,
        beneficiaryId: recipientId,
        paymentMessage: sendData.paymentMessage,
      })

      const { amountIn, currencyIn } = sendData

      const payAmount = isUSDc(currencyIn)
        ? amountIn
        : Big(amountIn)
            .div(ratesQuery.data?.fxRate ?? 0)
            .toFixed(2)

      if (isOverLimit(payAmount)) {
        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getUserTasks],
        })

        toast.success(
          intl.formatMessage({
            id: 'transfer.task.submitted',
            defaultMessage: 'Send request submitted and pending approval',
          }),
        )

        navigate(BusinessRoute.Tasks)

        return
      }

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTransactions],
      })

      toast.success(
        intl.formatMessage({
          id: 'send.paymentSent',
          defaultMessage: 'Payment sent successfully',
        }),
      )

      const queryParams = new URLSearchParams()

      if (response.data.transactionId) {
        queryParams.set('tx', response.data.transactionId)
        queryParams.set('type', DisplayableType.SINGLE)

        navigate(`${BusinessRoute.Transactions}?${queryParams.toString()}`)
        return
      }

      navigate(BusinessRoute.Transactions)
    } catch (error) {
      notifyError(error)
    }
  }, [
    sendData,
    recipientId,
    mutateAsync,
    ratesQuery.data?.fxRate,
    isOverLimit,
    intl,
    navigate,
    notifyError,
  ])

  const payValue = useMemo(() => {
    if (!ratesQuery.data?.fxRate || !sendData) {
      return 0
    }

    return isUSDc(sendData.currencyIn)
      ? sendData.amountIn
      : Big(sendData?.amountIn).div(ratesQuery.data.fxRate).toFixed(2)
  }, [ratesQuery.data?.fxRate, sendData])

  const sendValue = useMemo(() => {
    if (!ratesQuery.data?.fxRate || !sendData) {
      return 0
    }

    return isUSDc(sendData.currencyIn) && !isUSRecipient
      ? Big(sendData.amountIn).times(ratesQuery.data.fxRate).toFixed(2)
      : sendData.amountIn
  }, [ratesQuery.data?.fxRate, sendData, isUSRecipient])

  const sendCurrency = useMemo(() => {
    return isUSDc(sendData?.currencyIn) && !isUSRecipient
      ? formatCurrency(sendData?.currencyOut)
      : formatCurrency(sendData?.currencyIn)
  }, [sendData, isUSRecipient])

  const exchangeRate = useMemo(() => {
    if (isUSRecipient) {
      return `1 ${formatCurrency(Currency.USDC)} = 1 ${formatCurrency(Currency.USD)}`
    }

    const sourceCurrency = formatCurrency(Currency.USDC)
    const targetCurrency =
      isUSDc(sendData?.currencyIn) && !isUSRecipient
        ? formatCurrency(sendData?.currencyOut)
        : formatCurrency(sendData?.currencyIn)
    const rate = formatRate(ratesQuery.data?.fxRate)

    return `1 ${sourceCurrency} = ${rate} ${targetCurrency}`
  }, [sendData, isUSRecipient, ratesQuery.data?.fxRate])

  const recipientCurrency = useMemo(() => {
    if (isUSRecipient) {
      return Currency.USD
    }

    return isUSDc(sendData?.currencyIn)
      ? formatCurrency(sendData?.currencyOut)
      : formatCurrency(sendData?.currencyIn)
  }, [sendData, isUSRecipient])

  const paymentType = useMemo(() => {
    if (!recipientQuery.data) {
      return undefined
    }

    switch (recipientQuery.data.country) {
      case CountryCode.US:
        return recipientQuery.data.localInformation.paymentMethod

      case CountryCode.MX:
        return SPEI

      default:
        return undefined
    }
  }, [recipientQuery.data])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Confirm payment"
            id="send.reviewScreen.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="send.reviewScreen.subtitle"
            defaultMessage="Review your payment carefully before sending"
          />
        </Typography>

        <div className="p-6" />

        <Widget
          title={
            <FormattedMessage
              id="label.recipientSummary"
              defaultMessage="Recipient summary"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="Name" id="label.name" />
            </Details.Label>
            {recipientQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>{recipientQuery.data?.nickname}</Details.Value>
            )}
          </Details>
          {recipientQuery.data?.email && (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Contact email"
                  id="label.contactEmail"
                />
              </Details.Label>
              {recipientQuery.isPending ? (
                <Details.Skeleton />
              ) : (
                <Details.Value>{recipientQuery.data?.email}</Details.Value>
              )}
            </Details>
          )}

          <ReviewPaymentDetails recipient={recipientQuery.data} />

          {recipientQuery.data?.bankName ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Recipient bank"
                  id="label.recipientBank"
                />
              </Details.Label>
              {recipientQuery.isPending ? (
                <Details.Skeleton />
              ) : (
                <Details.Value>{recipientQuery.data?.bankName}</Details.Value>
              )}
            </Details>
          ) : null}
        </Widget>

        <div className="p-3" />

        <Widget
          title={
            <FormattedMessage
              id="label.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="You pay"
                id="send.reviewScreen.youPay"
              />
            </Details.Label>
            {ratesQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatAmount({
                  amount: payValue,
                  currency: Currency.USDC,
                })}
              </Details.Value>
            )}
          </Details>

          {isUSRecipient ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Fee" id="label.fee" />
              </Details.Label>
              {ratesQuery.isPending ? (
                <Details.Skeleton />
              ) : (
                <div className="flex gap-1 text-right">
                  <Typography className="line-through">
                    {formatAmount({
                      amount:
                        paymentType === PaymentMethod.WIRE ? WIRE_FEE : ACH_FEE,
                      currency: Currency.USDC,
                    })}
                  </Typography>
                  <Typography bold className="uppercase text-primary">
                    <FormattedMessage defaultMessage="Free" id="label.free" />
                  </Typography>
                </div>
              )}
            </Details>
          ) : null}

          {!recipientQuery.isPending ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Exchange rate"
                  id="label.exchangeRate"
                />
              </Details.Label>
              {ratesQuery.isPending ? (
                <Details.Skeleton />
              ) : (
                <Details.Value>{exchangeRate}</Details.Value>
              )}
            </Details>
          ) : null}

          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Recipient gets"
                id="send.reviewScreen.recipientGets"
              />
            </Details.Label>
            {ratesQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatAmount({
                  amount: sendValue,
                  currency: recipientCurrency,
                })}
              </Details.Value>
            )}
          </Details>
        </Widget>

        <div className="p-3" />

        <Widget
          title={
            <FormattedMessage
              id="label.additionalInformation"
              defaultMessage="Additional information"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="Date" id="label.date" />
            </Details.Label>
            <Details.Value>
              {format(new Date(), 'd MMM. yyyy, HH:mm')}
            </Details.Value>
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="Status" id="label.status" />
            </Details.Label>
            <Details.Value>
              <FormattedMessage defaultMessage="Preview" id="label.preview" />
            </Details.Value>
          </Details>
          {sendData?.paymentMessage ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.paymentReferenceConcepto"
                  defaultMessage="Payment reference / Concepto"
                />
              </Details.Label>
              <Details.Value>{sendData?.paymentMessage}</Details.Value>
            </Details>
          ) : null}
        </Widget>

        <StickyContainer>
          <Button
            width="full"
            disabled={isPending}
            loading={isPending}
            onClick={onSubmit}
          >
            {isOverLimit(payValue) ? (
              <FormattedMessage
                defaultMessage="Send request for approval"
                id="action.sendRequestForApproval"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Send {value} {currency}"
                id="send.reviewScreen.sendAction"
                values={{
                  value: formatMoney(sendValue),
                  currency: formatCurrency(sendCurrency),
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
