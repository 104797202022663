import { Currency } from '@/constants/currency'

import {
  ContractorPaymentFrequency,
  HistoricalPayment,
  HistoricalPaymentState,
} from '../types'

type HistoricalPaymentParams = {
  id: string
}

export const getHistoricalPayment = ({ id }: HistoricalPaymentParams) => {
  console.log(id)

  return new Promise<HistoricalPayment>((resolve) => {
    resolve({
      id: 'asvr-2das-xcvt-asdv',
      localAmount: 1250.5,
      localCurrency: Currency.USDC,
      baseAmount: 1250.5,
      baseCurrency: Currency.USD,
      paymentDate: '2025-01-19',
      state: HistoricalPaymentState.COMPLETED,
      startDate: '2025-03-01',
      endDate: '2025-03-15',
      paidDate: '2025-01-20',
      totalContractors: 3,
      frequency: ContractorPaymentFrequency.MONTHLY,
    })
  })
}
