import { forwardRef, Ref, type SVGProps } from 'react'

const SvgEllipsisVertical = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#949494"
      d="M10.5 18a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0M12 13.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3M12 7.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgEllipsisVertical)
export default ForwardRef
