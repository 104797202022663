import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { formatDate, isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { formatLastFour } from '@/lib/card'
import { formatAmount, formatCurrency, formatMoney } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { parseAdditionalDetails } from '@/lib/utils'
import { Card, Details, Typography } from '@/shared/ui'

import { getSingleCard } from '../../features/Cards/api'
import { Card as CardType } from '../../features/Cards/types'
import { getTransaction } from '../../features/Transactions/api'
import {
  SingleTransaction,
  TransactionType,
} from '../../features/Transactions/types'

function formatCashback({
  totalAmount,
  cashbackPercentage,
  cashbackAmount,
}: {
  totalAmount: string
  cashbackPercentage: number
  cashbackAmount: string
}) {
  return `${totalAmount} x ${Big(cashbackPercentage).times(100).toNumber() + '%'} = ${cashbackAmount}`
}
type Props = {
  transaction?: SingleTransaction
}

export const TransactionInfo = ({ transaction }: Props) => {
  const cardId = parseAdditionalDetails<{ cardId?: string }>(
    transaction?.additionalDetails,
  )?.cardId

  const [relatedTransactionQuery, singleCardQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransaction, transaction?.relatedTransactionId],
        select: (data: AxiosResponse<SingleTransaction>) => data.data,
        queryFn: () =>
          getTransaction({ id: transaction?.relatedTransactionId ?? '' }),
        enabled: !!transaction?.relatedTransactionId,
        retry: 1,
      },

      {
        queryKey: [queryKeys.getSingleCard, cardId],
        queryFn: () => getSingleCard({ id: cardId ?? '' }),
        select: (data: AxiosResponse<CardType>) => data.data,
        enabled: !!cardId,
      },
    ],
  })

  switch (transaction?.transactionType) {
    case TransactionType.CASHBACK: {
      const parsedAdditionalDetails = parseAdditionalDetails<{
        cashbackStartDate?: string
        cashbackEndDate?: string
        eligibleCardSpendAmount?: string
        eligibleCardSpendCurrency?: Currency
        cashbackRate?: number
      }>(transaction?.additionalDetails)

      return (
        <>
          <Card className="flex flex-col gap-4" size="medium">
            <Details>
              <Details.Label>
                <FormattedMessage id="label.period" defaultMessage="Period" />
              </Details.Label>

              <Details.Value>
                {intersperse(
                  [
                    formatDate(
                      parsedAdditionalDetails?.cashbackStartDate ?? '',
                      isSameYear(
                        parsedAdditionalDetails?.cashbackStartDate ?? '',
                        parsedAdditionalDetails?.cashbackEndDate ?? '',
                      )
                        ? 'dd MMM.'
                        : 'dd MMM. yyyy',
                    ),
                    formatDate(
                      parsedAdditionalDetails?.cashbackEndDate ?? '',
                      'dd MMM. yyyy',
                    ),
                  ],
                  ' - ',
                )}
              </Details.Value>
            </Details>
            {parsedAdditionalDetails?.eligibleCardSpendCurrency ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.eligibleCardSpend"
                    defaultMessage="Eligible card spend"
                  />
                </Details.Label>

                <Details.Value className="max-w-72 whitespace-break-spaces text-right">
                  {formatAmount({
                    amount:
                      parsedAdditionalDetails?.eligibleCardSpendAmount ?? 0,
                    currency: parsedAdditionalDetails.eligibleCardSpendCurrency,
                  })}
                </Details.Value>
              </Details>
            ) : null}
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.cashback"
                  defaultMessage="Cashback"
                />
              </Details.Label>

              <Details.Value className="max-w-72 whitespace-break-spaces text-right">
                {formatCashback({
                  totalAmount: formatMoney(
                    parsedAdditionalDetails?.eligibleCardSpendAmount ?? 0,
                  ),
                  cashbackPercentage:
                    parsedAdditionalDetails?.cashbackRate ?? 0,
                  cashbackAmount: formatAmount({
                    amount: transaction.baseAmount,
                    currency: transaction.baseCurrency,
                  }),
                })}
              </Details.Value>
            </Details>
          </Card>
        </>
      )
    }
    case TransactionType.REWARD:
    case TransactionType.WITHDRAW_REFUND:
      return (
        <>
          <Card className="flex flex-col gap-4" size="medium">
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.refundAmount"
                  defaultMessage="Refund amount"
                />
              </Details.Label>

              <Details.Value>
                {formatMoney(transaction.baseAmount)}{' '}
                {formatCurrency(transaction.baseCurrency)}
              </Details.Value>
            </Details>
          </Card>

          {transaction.relatedTransactionId || transaction.description ? (
            <Card className="flex flex-col gap-4" size="medium">
              {transaction.description ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="label.description"
                      defaultMessage="Description"
                    />
                  </Details.Label>

                  <Details.Value>{transaction.description}</Details.Value>
                </Details>
              ) : null}
              {transaction.relatedTransactionId &&
              relatedTransactionQuery.data ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="label.originalPayment"
                      defaultMessage="Original payment"
                    />
                  </Details.Label>

                  <Link
                    to={`${BusinessRoute.Transactions}?tx=${transaction.relatedTransactionId}&type=${transaction.displayableType}`}
                  >
                    <Typography className="underline underline-offset-2">
                      <FormattedMessage
                        id="transaction.typeLabel"
                        defaultMessage="{type, select, DEPOSIT {From:} WITHDRAW {To:} INTERNAL_SWAP_DEPOSIT {From:} INTERNAL_SWAP_WITHDRAW {To:} other {}}"
                        values={{
                          type: relatedTransactionQuery.data.transactionType,
                        }}
                      />{' '}
                      {relatedTransactionQuery.data.name}
                    </Typography>
                  </Link>
                </Details>
              ) : null}
            </Card>
          ) : null}
        </>
      )

    case TransactionType.CARD_PAYMENT:
    case TransactionType.CARD_PAYMENT_CHARGEBACK:
    case TransactionType.CARD_PAYMENT_REFUND: {
      const parsedAdditionalDetails = parseAdditionalDetails<{
        cardId?: string
        cardType?: string
        cardLastFourDigits?: string
        merchantCategory?: string
      }>(transaction.additionalDetails)

      return (
        <>
          <Card className="flex flex-col gap-4" size="medium">
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.paymentMethod"
                  defaultMessage="Payment method"
                />
              </Details.Label>

              <Details.Value>
                <Link
                  to={`${BusinessRoute.Cards}?id=${parsedAdditionalDetails?.cardId}`}
                  className="underline underline-offset-2"
                >
                  <FormattedMessage
                    id="cards.type.selection"
                    defaultMessage="{type, select, VIRTUAL {Virtual} PHYSICAL {Physical} other {}}"
                    values={{ type: parsedAdditionalDetails?.cardType }}
                  />{' '}
                  {formatLastFour(
                    parsedAdditionalDetails?.cardLastFourDigits,
                    3,
                  )}
                </Link>
              </Details.Value>
            </Details>
            {singleCardQuery.data?.nickname ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.cardNickname"
                    defaultMessage="Card nickname"
                  />
                </Details.Label>

                <Details.Value>{singleCardQuery.data.nickname}</Details.Value>
              </Details>
            ) : null}
            {singleCardQuery.data?.printedUserName ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.cardholder"
                    defaultMessage="Cardholder"
                  />
                </Details.Label>

                <Details.Value>
                  {singleCardQuery.data.printedUserName
                    .toLowerCase()
                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                </Details.Value>
              </Details>
            ) : null}
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.expenseCategory"
                  defaultMessage="Expense category"
                />
              </Details.Label>

              <Details.Value>
                <FormattedMessage
                  id="card.merchantCategory.label"
                  defaultMessage="{category, select, GROCERIES {Groceries} SHOPPING {Shopping} RESTAURANTS {Restaurants} TRAVEL {Travel} ENTERTAINMENT {Entertainment} UTILITIES {Utilities} HEALTH {Health} SERVICES {Services} FINANCE_AND_INVESTMENTS {Finance & Investments} TRANSPORT {Transport} OTHER {Other} other {}}"
                  values={{
                    category: parsedAdditionalDetails?.merchantCategory,
                  }}
                />
              </Details.Value>
            </Details>
          </Card>
        </>
      )
    }

    case TransactionType.FEE:
      return (
        <Card className="flex flex-col gap-4" size="medium">
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>

            <Details.Value>
              <FormattedMessage id="label.feeDebit" defaultMessage="Fee" />
            </Details.Value>
          </Details>

          {transaction.description ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.description"
                  defaultMessage="Description"
                />
              </Details.Label>

              <Details.Value>{transaction.description}</Details.Value>
            </Details>
          ) : null}
        </Card>
      )

    case TransactionType.DEPOSIT:
    case TransactionType.WITHDRAW:
    case TransactionType.INTERNAL_SWAP_DEPOSIT:
    case TransactionType.INTERNAL_SWAP_WITHDRAW:
      return null

    default:
      return null
  }
}
