import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { getFullName } from '@/lib/typography'
import { Ellipsis, X } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Typography,
} from '@/shared/ui'

import { ContractorPaymentCyclePayment } from '../../types'
import { getBankAccountLabel } from '../../utils'

import { DismissDialog } from './DismissDialog'

type Props = {
  contractor: ContractorPaymentCyclePayment
  onDismiss: (id: string) => void
  isLastContractor: boolean
}

export const FixedContractorRow = ({
  contractor,
  onDismiss,
  isLastContractor,
}: Props) => {
  const [showDismiss, setShowDismiss] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-1 py-3">
            <Typography bold>
              {getFullName(contractor.personalDetails)}
            </Typography>

            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractor.runPayment.payTo"
                defaultMessage="Pay to {accountType}"
                values={{
                  accountType: (
                    <FormattedMessage
                      {...getBankAccountLabel(contractor.paymentMethodType)}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="flex flex-col gap-1">
            <Typography text="right" bold>
              {formatAmount({
                amount: contractor.paymentAmount?.amount ?? 0,
                currency: contractor.paymentAmount?.currency ?? Currency.USD,
              })}
            </Typography>
            <Typography text="right" className="text-neutral-gray-600">
              {formatAmount({
                amount: contractor.paymentAmount?.amount ?? 0,
                currency: Currency.USDC,
              })}
            </Typography>
          </div>

          {isLastContractor ? null : (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  leftIcon={<Ellipsis className="size-5" />}
                  loaderPosition="left"
                  size="icon"
                  variant="ghost"
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="p-0" align="end">
                <DropdownMenuItem>
                  <Button
                    onClick={() => setShowDismiss(true)}
                    leftIcon={<X className="size-5" />}
                    size="sm"
                    variant="dropdown"
                    width="full"
                  >
                    <FormattedMessage
                      id="label.dismissPayment"
                      defaultMessage="Dismiss payment"
                    />
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>

      <DismissDialog
        isOpen={showDismiss}
        onOpenChange={setShowDismiss}
        contractor={contractor}
        onDismiss={() => onDismiss(contractor.contractId)}
      />
    </>
  )
}
