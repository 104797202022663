import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { editWallet } from '@/domains/Business/features/Accounts/api'
import { Wallet } from '@/domains/Business/features/Accounts/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  Sheet,
  SheetContent,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

const EDIT_ACCOUNT_FORM = `edit-account-form`

const editAccountSchema = z.object({
  label: z.string().min(1, { message: 'validation.accountName.required' }),
})

type EditAccountSchema = z.infer<typeof editAccountSchema>

type Props = {
  account?: Wallet
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  onDelete: () => void
}

export const EditAccountSidebar = ({
  account,
  isOpen,
  onOpenChange,
}: Props) => {
  const notifyError = useErrorToast()

  const form = useForm<EditAccountSchema>({
    mode: 'onChange',
    resolver: zodResolver(editAccountSchema),
    values: { label: account?.label ?? '' },
  })

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: editWallet,
  })

  const intl = useIntl()

  const onSubmit: SubmitHandler<EditAccountSchema> = async (data) => {
    if (!account?.id) {
      toast.error('Account ID not found')

      return
    }

    try {
      await mutateAsync({
        walletId: account.id,
        ...data,
      })

      await queryClient.refetchQueries({ queryKey: [queryKeys.getAccount] })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getAccountDetails, account.id],
      })

      toast.success(
        intl.formatMessage({
          id: 'account.edit.success',
          defaultMessage: "The account's name was edited successfully",
        }),
      )

      onOpenChange(false)
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent onOpenAutoFocus={(e) => e.preventDefault()}>
        <SlideInLeft className="flex h-full flex-col">
          <Typography variant="h3">
            <FormattedMessage
              id="accounts.sidebar.edit.title"
              defaultMessage="Edit account"
            />
          </Typography>

          <div className="p-2" />

          <Typography>
            <FormattedMessage
              id="accounts.sidebar.edit.description"
              defaultMessage="Change the account settings as many times as you want"
            />
          </Typography>

          <div className="p-4" />

          <Form {...form}>
            <form
              id={EDIT_ACCOUNT_FORM}
              className="h-full"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <div className="flex flex-col gap-4">
                <FormField
                  control={form.control}
                  name="label"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Account name',
                            id: 'label.accountName',
                          })}
                          {...field}
                        />
                      </FormControl>

                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Account name"
                          id="label.accountName"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>

          <div className="p-2" />

          <StickyContainer className="-bottom-16 pb-0">
            <Button
              width="full"
              form={EDIT_ACCOUNT_FORM}
              onClick={form.handleSubmit(onSubmit)}
              loading={isPending || isSuccess}
              disabled={
                !form.formState.isValid ||
                !form.formState.isDirty ||
                isPending ||
                isSuccess
              }
              type="submit"
            >
              <FormattedMessage
                defaultMessage="Save changes"
                id="action.saveChanges"
              />
            </Button>
          </StickyContainer>
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
