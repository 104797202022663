import { FormattedMessage } from 'react-intl'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

type Props = {
  totalContractors: number
  isOpen: boolean
  isPending: boolean
  onOpenChange: (open: boolean) => void
  onDismiss: () => void
}

export const DismissPaymentDialog = ({
  isOpen,
  isPending,
  onOpenChange,
  onDismiss,
  totalContractors,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              id="contractors.dismissPayment.title"
              defaultMessage="Dismiss payment?"
            />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage
              id="contractors.dismissPayment.description"
              defaultMessage="Are you sure you want to dismiss this payment? It will impact {count, plural, one {# fixed-rate contractor} other {# fixed-rate contractors}}"
              values={{ count: totalContractors }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <DialogActions>
          <Button
            width="full"
            variant="secondary"
            onClick={() => onOpenChange(false)}
            disabled={isPending}
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            width="full"
            onClick={onDismiss}
            loading={isPending}
            disabled={isPending}
          >
            <FormattedMessage
              id="action.dismissPayment"
              defaultMessage="Dismiss payment"
            />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
