import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { queryKeys } from '@/constants/queryKeys'
import { getBusinessAddress } from '@/lib/address'
import { getAnimationKey } from '@/lib/utils'
import { CountryCombobox, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MotionDiv,
} from '@/shared/ui'

import { getBusinessIdentity } from '../../api'

const companySettingsSchema = z.object({
  legalName: z.string(),
  countryOfIncorporation: z.string(),
  taxId: z.string(),
  taxIdType: z.string(),
  businessAddress: z.string().optional().nullable(),
})

type CompanySettingsSchema = z.infer<typeof companySettingsSchema>

export const CompanySettings = () => {
  const intl = useIntl()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data?.data,
  })

  const form = useForm<CompanySettingsSchema>({
    mode: 'onChange',
    resolver: zodResolver(companySettingsSchema),
    values: {
      legalName: businessQuery.data?.legalName ?? '',
      countryOfIncorporation: businessQuery.data?.countryOfIncorporation ?? '',
      taxId: businessQuery.data?.taxId ?? '',
      taxIdType: businessQuery.data?.taxIdType ?? '',
      businessAddress: businessQuery.data?.addressCity
        ? getBusinessAddress(businessQuery.data)
        : '',
    },
  })

  return (
    <MotionDiv key={getAnimationKey(businessQuery.isPending)}>
      <Widget
        className="max-w-full"
        variant="form"
        title={
          <FormattedMessage
            id="label.companyDetails"
            defaultMessage="Company details"
          />
        }
      >
        <Form {...form}>
          <div className="flex flex-col gap-3">
            <FormField
              control={form.control}
              name="legalName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.legalName',
                        defaultMessage: 'Legal Name',
                      })}
                      disabled
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.legalName"
                      defaultMessage="Legal Name"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="countryOfIncorporation"
              render={({ field }) => (
                <FormItem>
                  <CountryCombobox
                    placeholder={intl.formatMessage({
                      id: 'label.countryOfIncorporation',
                      defaultMessage: 'Country of incorporation',
                    })}
                    disabled
                    onSelect={() => {}}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="taxId"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      disabled
                      placeholder={intl.formatMessage({
                        id: 'label.taxId',
                        defaultMessage: 'Tax ID',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="auth.taxIdType"
                      defaultMessage="{taxIdType, select, CUIT {CUIT} EIN {EIN} NIT {NIT (include verification digit)} RFC {RFC} RUT_CHL {RUT} RUT_URY {RUT} CNPJ {CNPJ} NIP_ESP {NIP} NIPC {NIPC} other {Tax ID}}"
                      values={{ taxIdType: form.getValues('taxIdType') }}
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="businessAddress"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.businessAddress',
                        defaultMessage: 'Business address',
                      })}
                      disabled
                      {...field}
                      value={field.value ?? ''}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="label.businessAddress"
                      defaultMessage="Business address"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </div>
        </Form>
      </Widget>
    </MotionDiv>
  )
}
