import {
  ContractorPaymentMethod,
  ContractorPaymentMethodType,
} from '@/domains/Contractor/types'
import { intersperse } from '@/lib/typography'

export function getPaymentMethodDetails(method?: ContractorPaymentMethod) {
  if (!method) return undefined

  switch (method.paymentMethodDetails.type) {
    case ContractorPaymentMethodType.US_BANK_ACCOUNT: {
      const info = method.paymentMethodDetails.paymentMethodInformation
      return intersperse([info.accountNumber, info.routingNumber], ' • ')
    }
    case ContractorPaymentMethodType.MX_BANK_ACCOUNT: {
      const info = method.paymentMethodDetails.paymentMethodInformation
      return info.clabe
    }
    case ContractorPaymentMethodType.DOLAR_TAG: {
      const info = method.paymentMethodDetails.paymentMethodInformation
      return info.dolarTag
    }
    default:
      return undefined
  }
}
