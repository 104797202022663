import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { AccountIcon, CountryBankDetails } from '@/domains/Business/components'
import {
  getAccount,
  getAccountDetails,
} from '@/domains/Business/features/Accounts/api'
import {
  Account,
  AccountDetails,
  AccountType,
} from '@/domains/Business/features/Accounts/types'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { GoBackButton } from '@/shared/components'
import { Skeleton, SlideInScreen, Typography } from '@/shared/ui'

type Props = {
  account: string
  onBack: () => void
}

export const AddBalanceDetails = ({ account, onBack }: Props) => {
  const location = useLocation()
  const [to] = useSearchParamsValue(['to'])

  const [detailsQuery, accountQuery, allAccounts] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccountDetails, account],
        queryFn: () => getAccountDetails({ id: account }),
        select: (data: AxiosResponse<AccountDetails>) =>
          data?.data.bankAccounts.sort((a) =>
            a.type === AccountType.ACH ? -1 : 1,
          ),
      },
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) =>
          data.data.wallets.find((wallet) => wallet.id === account),
      },
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data.data,
      },
    ],
  })

  return (
    <>
      {allAccounts.data?.wallets.length === 1 || to ? (
        <GoBackButton to={location.state?.from ?? BusinessRoute.Dashboard} />
      ) : (
        <GoBackButton onClick={onBack} />
      )}

      <SlideInScreen>
        <div className="flex items-center justify-center gap-2">
          {accountQuery.isPending ? (
            <Skeleton className="h-[33px] w-48" />
          ) : (
            <>
              <AccountIcon id={accountQuery.data?.id ?? ''} />

              <Typography variant="h3">{accountQuery.data?.label}</Typography>
            </>
          )}
        </div>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Receive an MXN or USD transfer to your account details below"
            id="addBalance.receiveTransfer"
          />
        </Typography>
        <div className="p-4" />

        {detailsQuery.data ? (
          <CountryBankDetails
            accountBanks={detailsQuery.data}
            walletId={account}
          />
        ) : (
          <>
            <CountryBankDetails.Skeleton rows={6} />
            <div className="p-3" />
            <CountryBankDetails.Skeleton />
          </>
        )}
      </SlideInScreen>
    </>
  )
}
