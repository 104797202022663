import { forwardRef, Ref, type SVGProps } from 'react'

const SvgHome = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="home_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#home_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M6 19h3v-6h6v6h3v-9l-6-4.5L6 10zm-2 2V9l8-6 8 6v12h-7v-6h-2v6z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgHome)
export default ForwardRef
