import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import {
  RecipientAccountTypeStep,
  RecipientDetailsStep,
  RecipientDolarTagStep,
  RecipientEntityStep,
  RecipientReviewStep,
} from './components/AddRecipientSteps'
import { CreatedRecipient, DolarAppSearchRecipient, EntityType } from './types'

enum Step {
  AccountType = 'accountType',
  Entity = 'entity',
  Details = 'Details',
  DolarTag = 'dolarTag',
  Review = 'review',
}

type FormState =
  | { type: Step.AccountType }
  | { type: Step.DolarTag }
  | { type: Step.Entity }
  | { type: Step.Details }
  | { type: Step.Review }

export const AddRecipient = () => {
  const location = useLocation()
  const [entityType, setEntityType] = useState<EntityType>(EntityType.BUSINESS)
  const [country, setCountry] = useState<CountryCode>(CountryCode.MX)
  const [recipient, setRecipient] = useState<CreatedRecipient>()
  const [dolarAppRecipient, setDolarAppRecipient] =
    useState<DolarAppSearchRecipient>()

  const [activeStep, setActiveStep] = useState<FormState>({
    type: Step.AccountType,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.AccountType:
        return 0
      case Step.Entity:
        return 25
      case Step.Details:
        return 50
      case Step.DolarTag:
        return 50
      case Step.Review:
        return 75
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Recipients}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.AccountType ? (
          <RecipientAccountTypeStep
            onContinue={({ type, country }) => {
              if (country) {
                setCountry(country)
              }

              switch (type) {
                case 'dolarapp':
                  setActiveStep({ type: Step.DolarTag })
                  break

                case 'bank':
                  setActiveStep({ type: Step.Entity })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === Step.Entity ? (
          <RecipientEntityStep
            onBack={() => setActiveStep({ type: Step.AccountType })}
            onContinue={(v) => {
              setEntityType(v)

              setActiveStep({ type: Step.Details })
            }}
          />
        ) : null}

        {activeStep.type === Step.DolarTag ? (
          <RecipientDolarTagStep
            dolarAppRecipient={dolarAppRecipient}
            onBack={() => {
              setDolarAppRecipient(undefined)
              setActiveStep({ type: Step.AccountType })
            }}
            onContinue={(recipient) => {
              setDolarAppRecipient(recipient)
              setActiveStep({ type: Step.Review })
            }}
          />
        ) : null}

        {activeStep.type === Step.Details ? (
          <RecipientDetailsStep
            country={country}
            entityType={entityType}
            recipient={recipient}
            onBack={() => {
              setRecipient(undefined)
              setActiveStep({ type: Step.Entity })
            }}
            onContinue={(recipient) => {
              setRecipient(recipient)

              setActiveStep({ type: Step.Review })
            }}
          />
        ) : null}

        {activeStep.type === Step.Review ? (
          <RecipientReviewStep
            dolarAppRecipient={dolarAppRecipient}
            recipient={recipient}
            onBack={() => {
              if (dolarAppRecipient) {
                setActiveStep({ type: Step.DolarTag })
              } else {
                setActiveStep({ type: Step.Details })
              }
            }}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
