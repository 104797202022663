import { useCallback, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  createPhysicalCard,
  createVirtualCard,
} from '@/domains/Business/features/Cards/api'
import { CardType } from '@/domains/Business/features/Cards/types'
import { Role } from '@/domains/Business/types/roles'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { getCountryISO3byISO2 } from '@/lib/country'
import { parseFullPhoneNumber } from '@/lib/phone'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton } from '@/shared/components'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'

import { inviteBusinessUser } from '../../api'
import { MoneyMovementReviewSection } from '../MoneyMovementReviewSection'
import { TeamCardReviewSection } from '../TeamCardReviewSection'
import { TeamDetailsReviewSection } from '../TeamDetailsReviewSection'

import {
  MoneyMovementSchema,
  TeamCardAddressSchema,
  TeamMemberSchema,
  TeamPhysicalCardDetailsSchema,
  TeamVirtualCardDetailsSchema,
} from './schemas'

type Props = {
  cardType?: CardType
  moneyMovement?: MoneyMovementSchema
  onBack: () => void
  role?: Role
  teamCardAddressDetails?: TeamCardAddressSchema
  teamVirtualCardDetails?: TeamVirtualCardDetailsSchema
  teamPhysicalCardDetails?: TeamPhysicalCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamMemberReviewStep = ({
  cardType,
  moneyMovement,
  onBack,
  role,
  teamCardAddressDetails,
  teamVirtualCardDetails,
  teamPhysicalCardDetails,
  teamMemberDetails,
}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const {
    mutateAsync: inviteBusinessUserMutateAsync,
    isPending: inviteBusinessUserIsPending,
    isSuccess: inviteBusinessUserIsSuccess,
  } = useMutation({
    mutationFn: inviteBusinessUser,
  })

  const {
    mutateAsync: createVirtualCardMutateAsync,
    isPending: createVirtualCardIsPending,
  } = useMutation({
    mutationFn: createVirtualCard,
  })

  const {
    mutateAsync: createPhysicalCardMutateAsync,
    isPending: createPhysicalCardIsPending,
  } = useMutation({
    mutationFn: createPhysicalCard,
  })

  const handleInviteUser = useCallback(async () => {
    if (!teamMemberDetails || !role) {
      toast.error(
        intl.formatMessage({
          id: 'team.card.create.user.error',
          defaultMessage:
            'Missing team member details. Please go back and fill them',
        }),
      )
      return
    }

    removeEmptyFormFields(teamMemberDetails)

    try {
      const response = await inviteBusinessUserMutateAsync({
        role,
        firstName: teamMemberDetails.firstName,
        lastName: teamMemberDetails.lastName,
        secondLastName: teamMemberDetails.secondLastName,
        userTitle: teamMemberDetails.title,
        email: teamMemberDetails.email,
        transferPermissions: moneyMovement
          ? {
              type: moneyMovement.movementPermission,
              limitPeriodType: moneyMovement.limitPeriodType,
              periodTransferLimitAmount:
                moneyMovement.periodTransferLimitAmount,
              singleTransferLimitAmount:
                moneyMovement.singleTransferLimitAmount,
            }
          : undefined,
      })

      switch (cardType) {
        case CardType.VIRTUAL:
          if (!teamVirtualCardDetails) {
            toast.error(
              intl.formatMessage({
                id: 'team.card.create.virtual.error',
                defaultMessage:
                  'Missing virtual card details. Please go back and fill them',
              }),
            )
            return
          }

          await createVirtualCardMutateAsync({
            identityId: response.data.identityId,
            requestId: crypto.randomUUID(),
            walletId: teamVirtualCardDetails.walletId,
            nickname: teamVirtualCardDetails.nickname,
            cardLimit: {
              limitAmount: teamVirtualCardDetails.limitAmount,
              limitType: teamVirtualCardDetails.limitType,
            },
          })

          break

        case CardType.PHYSICAL: {
          if (!teamPhysicalCardDetails) {
            toast.error(
              intl.formatMessage({
                id: 'team.card.create.details.physical.error',
                defaultMessage:
                  'Missing physical card details. Please go back and fill them',
              }),
            )
            return
          }

          if (!teamCardAddressDetails) {
            toast.error(
              intl.formatMessage({
                id: 'team.card.create.address.physical.error',
                defaultMessage:
                  'Missing physical card address details. Please go back and fill them',
              }),
            )
            return
          }

          await createPhysicalCardMutateAsync({
            identityId: response.data.identityId,
            requestId: crypto.randomUUID(),
            walletId: teamPhysicalCardDetails.walletId,
            nickname: teamPhysicalCardDetails.nickname,
            localPhoneNumber: parseFullPhoneNumber(teamCardAddressDetails),
            internationalPhonePrefix:
              teamCardAddressDetails.internationalPhonePrefix,
            cardLimit: {
              limitAmount: teamPhysicalCardDetails.limitAmount,
              limitType: teamPhysicalCardDetails.limitType,
            },
            deliveryAddress: {
              street: teamCardAddressDetails.addressStreet,
              streetNumber: teamCardAddressDetails.addressStreetNumber,
              apartment: teamCardAddressDetails.addressApartment,
              references: teamCardAddressDetails.addressReferences,
              neighborhood: teamCardAddressDetails.addressDistrict,
              city: teamCardAddressDetails.addressCity,
              state: teamCardAddressDetails.addressState,
              country: getCountryISO3byISO2(
                teamCardAddressDetails.addressCountry,
              ),
              postcode: teamCardAddressDetails.addressPostCode,
            },
          })
          break
        }
        default:
          break
      }

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      toast.success(
        intl.formatMessage({
          id: 'team.member.invite.success',
          defaultMessage: 'Invitation sent successfully',
        }),
      )

      navigate(BusinessRoute.Team)
    } catch (error) {
      notifyError(error)
    }
  }, [
    cardType,
    createPhysicalCardMutateAsync,
    createVirtualCardMutateAsync,
    intl,
    inviteBusinessUserMutateAsync,
    moneyMovement,
    navigate,
    notifyError,
    role,
    teamCardAddressDetails,
    teamMemberDetails,
    teamPhysicalCardDetails,
    teamVirtualCardDetails,
  ])

  const isPending =
    inviteBusinessUserIsPending ||
    inviteBusinessUserIsSuccess ||
    createVirtualCardIsPending ||
    createPhysicalCardIsPending

  useKeyPress('Enter', handleInviteUser)

  const subtitleByRole = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.admin.subtitle"
            defaultMessage="Review the email address carefully before sending. The Admin role has no restrictions over the account"
          />
        )

      case Role.PAYMENT_OPS:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.paymentOps.subtitle"
            defaultMessage="Review the email address carefully before sending. The Payment Ops role has money movement capabilities"
          />
        )

      case Role.CARD_USER:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.cardUser.subtitle"
            defaultMessage="Review the email address carefully before sending. This role will be able to spend with a card"
          />
        )

      case Role.READ_ONLY:
        return (
          <FormattedMessage
            id="teamMember.create.reviewStep.readOnly.subtitle"
            defaultMessage="Review the email address carefully before sending. This role is able to view and download all the account's information"
          />
        )

      default:
        return
    }
  }, [role])

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="teamMember.create.reviewStep.title"
            defaultMessage="Review and invite {name} to join the team!"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">{subtitleByRole}</Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-6">
          <TeamDetailsReviewSection
            teamMemberDetails={teamMemberDetails}
            role={role}
          />

          <MoneyMovementReviewSection moneyMovement={moneyMovement} />

          <TeamCardReviewSection
            cardType={cardType}
            teamCardAddressDetails={teamCardAddressDetails}
            teamVirtualCardDetails={teamVirtualCardDetails}
            teamPhysicalCardDetails={teamPhysicalCardDetails}
          />
        </div>

        <StickyContainer>
          <Button
            disabled={isPending}
            loading={isPending}
            width="full"
            onClick={() => handleInviteUser()}
          >
            <FormattedMessage
              defaultMessage="Invite {name} as {role}!"
              id="teamMember.action.invite"
              values={{
                name: teamMemberDetails?.firstName,
                role: (
                  <FormattedMessage
                    id="team.role.label"
                    defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                    values={{ role }}
                  />
                ),
              }}
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
