import { useEffect, useRef, useState } from 'react'
import { isValid, parse } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Calendar as CalendarIcon } from '@/shared/icons/outline'
import { Filter } from '@/shared/icons/solid'
import {
  Button,
  DateInput,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  Typography,
} from '@/shared/ui'

import {
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  dateFilterOptions,
  dateMessages,
} from './data'

export const DateFilter = ({ label }: { label?: string }) => {
  const intl = useIntl()
  const fromDateInputRef = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [layout, setLayout] = useState<'custom' | 'preset'>('preset')

  const [fromDate, setFromDate] = useState(
    searchParams.get(DATE_FROM_FILTER_NAME) || '',
  )
  const [toDate, setToDate] = useState(
    searchParams.get(DATE_TO_FILTER_NAME) || '',
  )

  function handleSearchParams({
    fromDate,
    toDate,
  }: {
    fromDate: string
    toDate: string
  }) {
    setSearchParams(
      (params) => {
        if (
          params.get(DATE_FROM_FILTER_NAME) === fromDate &&
          params.get(DATE_TO_FILTER_NAME) === toDate
        ) {
          params.delete(DATE_FROM_FILTER_NAME)
          params.delete(DATE_TO_FILTER_NAME)

          return params
        }

        params.set(DATE_FROM_FILTER_NAME, fromDate)
        params.set(DATE_TO_FILTER_NAME, toDate)

        return params
      },
      { preventScrollReset: true },
    )
  }

  const isValidDate = (date: string) => {
    if (date.length < 6) {
      return false
    }

    if (date.length === 6) {
      const parsedDate = parse(date, 'd/M/yy', new Date())

      return isValid(parsedDate)
    }

    if (date.length === 7) {
      const parsedDayDate = parse(date, 'd/MM/yy', new Date())
      const parsedMonthDate = parse(date, 'dd/M/yy', new Date())

      return isValid(parsedDayDate) || isValid(parsedMonthDate)
    }

    const parsedDate = parse(date, 'dd/MM/yy', new Date())

    return isValid(parsedDate)
  }

  const hasDateFilterParams =
    searchParams.get(DATE_TO_FILTER_NAME) ||
    searchParams.get(DATE_FROM_FILTER_NAME)

  const hasCustomFromRange = dateFilterOptions.every((option) => {
    return (
      !!searchParams.get(DATE_FROM_FILTER_NAME) &&
      searchParams.get(DATE_FROM_FILTER_NAME) !== option.value.fromDate
    )
  })

  const hasCustomToRange = dateFilterOptions.every((option) => {
    return (
      !!searchParams.get(DATE_TO_FILTER_NAME) &&
      searchParams.get(DATE_TO_FILTER_NAME) !== option.value.toDate
    )
  })

  useEffect(() => {
    if (layout === 'custom') {
      fromDateInputRef.current?.focus()
    }
  }, [fromDate.length, layout])

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <CalendarIcon className="size-4" />
              {label ?? (
                <FormattedMessage defaultMessage="Date" id="label.date" />
              )}
            </span>
            {hasDateFilterParams && <Filter className="size-4" />}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            {layout === 'preset' ? (
              <>
                {dateFilterOptions.map((option) => (
                  <DropdownMenuCheckboxItem
                    key={option.key}
                    onSelect={(event) => event.preventDefault()}
                    checked={
                      searchParams
                        .get(DATE_FROM_FILTER_NAME)
                        ?.includes(option.value.fromDate) &&
                      searchParams
                        .get(DATE_TO_FILTER_NAME)
                        ?.includes(option.value.toDate)
                    }
                    onCheckedChange={() => handleSearchParams(option.value)}
                  >
                    {intl.formatMessage(dateMessages[option.key])}
                  </DropdownMenuCheckboxItem>
                ))}

                <DropdownMenuCheckboxItem
                  onSelect={(event) => event.preventDefault()}
                  checked={hasCustomFromRange || hasCustomToRange}
                  onCheckedChange={() => setLayout('custom')}
                >
                  <FormattedMessage
                    defaultMessage="Custom range"
                    id="label.customRange"
                  />
                </DropdownMenuCheckboxItem>
              </>
            ) : layout === 'custom' ? (
              <div className="flex flex-col gap-1">
                <DropdownMenuCheckboxItem
                  tabIndex={-1}
                  onSelect={(event) => event.preventDefault()}
                  checked
                  onCheckedChange={() => setLayout('preset')}
                >
                  <FormattedMessage
                    defaultMessage="Custom range"
                    id="label.customRange"
                  />
                </DropdownMenuCheckboxItem>

                <div className="flex h-10 items-center gap-3 px-2">
                  <Typography className="w-10">
                    <FormattedMessage defaultMessage="From" id="label.from" />
                  </Typography>
                  <DateInput
                    ref={fromDateInputRef}
                    value={fromDate}
                    onClear={() => {
                      setFromDate('')
                      setSearchParams(
                        (params) => {
                          params.delete(DATE_FROM_FILTER_NAME)
                          return params
                        },
                        { preventScrollReset: true },
                      )
                    }}
                    onChange={(value) => {
                      setFromDate(value)
                      if (isValidDate(value)) {
                        setSearchParams(
                          (params) => {
                            params.set(DATE_FROM_FILTER_NAME, value)
                            return params
                          },
                          { preventScrollReset: true },
                        )
                      }
                    }}
                  />
                </div>

                <div className="flex h-10 items-center gap-3 px-2">
                  <Typography className="w-10">
                    <FormattedMessage defaultMessage="To" id="label.to" />
                  </Typography>
                  <DateInput
                    value={toDate}
                    onClear={() => {
                      setToDate('')
                      setSearchParams(
                        (params) => {
                          params.delete(DATE_TO_FILTER_NAME)
                          return params
                        },
                        { preventScrollReset: true },
                      )
                    }}
                    onChange={(value) => {
                      setToDate(value)
                      if (isValidDate(value)) {
                        setSearchParams(
                          (params) => {
                            params.set(DATE_TO_FILTER_NAME, value)
                            return params
                          },
                          { preventScrollReset: true },
                        )
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
