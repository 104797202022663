import { FormattedMessage } from 'react-intl'

import { ContractorPaymentMethod } from '@/domains/Contractor/types'
import { getPaymentMethodDetails } from '@/domains/Contractor/utils'
import { cn } from '@/lib/utils'
import { DolarappWhite } from '@/shared/icons/outline'
import { Badge, Button, Card, Typography } from '@/shared/ui'

import { LinkAction } from './LinkAction'

type Props = {
  onContinue: () => void
  paymentMethod?: ContractorPaymentMethod
}

export const DolarTagCard = ({ onContinue, paymentMethod }: Props) => {
  const inactive = !paymentMethod?.state

  const Component = inactive ? Button : 'div'

  const activeDetails = getPaymentMethodDetails(paymentMethod)

  return (
    <Card
      size="medium"
      className="flex w-full items-center justify-between"
      asChild
      scalable={inactive}
    >
      <Component
        className={cn(!inactive && 'cursor-default')}
        size="inline"
        variant="ghost"
        onClick={inactive ? onContinue : undefined}
      >
        <div className="flex items-center gap-3">
          <div className="flex size-9 items-center justify-center rounded-full bg-primary">
            <DolarappWhite className="size-5 text-white" />
          </div>

          <div className="flex flex-col">
            <div className="flex gap-2">
              <Typography bold>
                <FormattedMessage
                  id="label.dolarTag"
                  defaultMessage="DolarTag"
                />
              </Typography>
              {!inactive ? null : (
                <Badge className="font-bold capitalize" variant="dark-neutral">
                  <FormattedMessage
                    id="label.recommended"
                    defaultMessage="Recommended"
                  />
                </Badge>
              )}
            </div>
            <Typography variant="body-small" className="text-neutral-gray-600">
              {activeDetails ?? (
                <FormattedMessage
                  id="contractors.dolarTag.description"
                  defaultMessage="Instantly and for free"
                />
              )}
            </Typography>
          </div>
        </div>

        <LinkAction
          id={paymentMethod?.paymentMethodDetails.id}
          state={paymentMethod?.state}
        />
      </Component>
    </Card>
  )
}
