import { Currency } from '@/constants/currency'
import { SEARCH_FILTER_NAME } from '@/domains/Business/components'

import {
  ContractorPaymentFrequency,
  HistoricalPayment,
  HistoricalPaymentState,
} from '../types'

type SearchParams = {
  [SEARCH_FILTER_NAME]?: string
}

export const getHistoricalPayments = (params: SearchParams = {}) => {
  const searchParams = new URLSearchParams(params)

  console.log(searchParams.toString())

  return new Promise<HistoricalPayment[]>((resolve) => {
    resolve([
      {
        id: 'asvr-2das-xcvt-asdv',
        localAmount: 1250.5,
        localCurrency: Currency.USDC,
        baseAmount: 1250.5,
        baseCurrency: Currency.USD,
        paymentDate: '2025-01-19',
        state: HistoricalPaymentState.COMPLETED,
        startDate: '2025-03-01',
        endDate: '2025-03-15',
        paidDate: '2025-01-20',
        totalContractors: 3,
        frequency: ContractorPaymentFrequency.MONTHLY,
      },
      {
        id: 'bwer-9plo-mnbv-7yhn',
        localAmount: 52250.75,
        localCurrency: Currency.USDC,
        baseAmount: 2750.75,
        baseCurrency: Currency.USD,
        paymentDate: '2025-01-05',
        state: HistoricalPaymentState.PENDING,
        startDate: '2025-02-15',
        endDate: '2025-02-28',
        paidDate: '2025-01-06',
        totalContractors: 5,
        frequency: ContractorPaymentFrequency.SEMIMONTHLY,
      },
      {
        id: 'kjhg-4fds-qwer-5tgb',
        localAmount: 3100.25,
        localCurrency: Currency.USDC,
        baseAmount: 3100.25,
        baseCurrency: Currency.USD,
        paymentDate: '2024-12-20',
        state: HistoricalPaymentState.INCOMPLETE,
        startDate: '2025-02-01',
        endDate: '2025-02-14',
        paidDate: '2024-12-21',
        totalContractors: 4,
        frequency: ContractorPaymentFrequency.SEMIMONTHLY,
      },
    ])
  })
}
