import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { cn } from '@/lib/utils'
import { Check } from '@/shared/icons/outline'
import { Button, Card, MotionDiv, Typography } from '@/shared/ui'

import { sendContractorReminder } from '../../api'
import {
  ContractorDetails,
  ContractorPaymentMethodState,
  ContractorState,
} from '../../types'

type Props = {
  contractor?: ContractorDetails
}

export const ContractActionsSteps = ({ contractor }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { mutateAsync: sendReminderAsync, isPending: isSendingReminder } =
    useMutation({ mutationFn: sendContractorReminder })

  const onSendReminder = async () => {
    if (!contractor?.id) {
      return
    }

    try {
      await sendReminderAsync({ id: contractor.id })

      toast.success(
        intl.formatMessage({
          id: 'team.member.reminder.success',
          defaultMessage: 'Invitation reminder sent successfully',
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  const firstStepCompleted =
    contractor?.state === ContractorState.WAITING_FOR_CONTRACTOR &&
    contractor.paymentMethod.state === ContractorPaymentMethodState.LINKED

  return (
    <MotionDiv className="flex flex-col gap-4">
      <Card
        size="upload"
        className={cn(
          'flex flex-wrap items-center justify-between',
          firstStepCompleted && 'pointer-events-none opacity-50',
        )}
      >
        <div className="flex flex-wrap">
          <div className="flex flex-col justify-center">
            <Typography bold className="px-1">
              <FormattedMessage
                id="contractors.contract.signReminder.title"
                defaultMessage="1. Remind {name} to sign the contract"
                values={{
                  name: contractor?.personalDetails.firstName,
                }}
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.contract.signReminder.subtitle"
                defaultMessage="Send {name} a friendly reminder via email"
                values={{
                  name: contractor?.personalDetails.firstName,
                }}
              />
            </Typography>
          </div>
        </div>

        {firstStepCompleted ? (
          <Check />
        ) : (
          <Button
            onClick={onSendReminder}
            disabled={isSendingReminder}
            loading={isSendingReminder}
          >
            <FormattedMessage id="actions.remind" defaultMessage="Remind" />
          </Button>
        )}
      </Card>

      <Card
        size="upload"
        className="flex flex-wrap items-center justify-between"
      >
        <div className="flex flex-wrap">
          <div className="flex flex-col justify-center">
            <Typography bold className="px-1">
              <FormattedMessage
                id="contractors.contract.reviewAndSign.title"
                defaultMessage="2. Review and sign {name}'s contract"
                values={{
                  name: contractor?.personalDetails.firstName,
                }}
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.contract.reviewAndSign.subtitle"
                defaultMessage="You need to review and sign the contract"
              />
            </Typography>
          </div>
        </div>

        <Button>
          <FormattedMessage
            id="actions.reviewAndSign"
            defaultMessage="Review & Sign"
          />
        </Button>
      </Card>
    </MotionDiv>
  )
}
