import { api } from '@/lib/api'

type DownloadDocumentParams = {
  fileId: string
  contractId: string
}

export function deleteContractDocument({
  fileId,
  contractId,
}: DownloadDocumentParams) {
  return api.delete<void>(
    `/v1/me/contract-agreements/${fileId}/contract/${contractId}`,
  )
}
