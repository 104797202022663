import { useEffect, useState } from 'react'

import { Sheet, SheetContent } from '@/shared/ui'

import { Wallet } from '../types'

import { CreditCycleScreen } from './CreditCycleScreen'
import { StatementsBaseScreen } from './StatementsBaseScreen'

type ScreenType = 'credit-cycle' | 'statements'

type Props = {
  account?: Wallet
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  type: string | null
}

export const StatementsSidebar = ({
  account,
  isOpen,
  onOpenChange,
  type,
}: Props) => {
  const [screen, setScreen] = useState<ScreenType>('statements')

  useEffect(() => {
    if (!isOpen) {
      setScreen('statements')
    }
  }, [isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {(() => {
          switch (screen) {
            case 'statements':
              return (
                <StatementsBaseScreen
                  account={account}
                  type={type}
                  setScreen={setScreen}
                />
              )

            case 'credit-cycle':
              return (
                <CreditCycleScreen account={account} setScreen={setScreen} />
              )

            default:
              return null
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
