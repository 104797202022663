import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Filter } from '@/shared/icons/solid'
import { Button, MotionDiv } from '@/shared/ui'

import { ALL_FILTERS } from './data'

type Props = {
  children: React.ReactNode
}

export const ActiveFilters = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeFilters = ALL_FILTERS.filter((filter) => searchParams.has(filter))

  const hasMultipleFilters = activeFilters.length >= 2

  return (
    <MotionDiv>
      <div className="flex w-full flex-wrap gap-3">
        {hasMultipleFilters && (
          <Button
            leftIcon={<Filter />}
            onClick={() => {
              setSearchParams(undefined, {
                preventScrollReset: true,
              })
            }}
          >
            <FormattedMessage
              defaultMessage="Clear all filters"
              id="action.clearAllFilters"
            />
          </Button>
        )}
        {children}
      </div>
      {activeFilters.length > 0 ? <div className="p-3" /> : null}
    </MotionDiv>
  )
}
