import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { RecipientAvatar, WithPermissions } from '@/domains/Business/components'
import { useBusinessRole } from '@/domains/Business/hooks'
import { Permission } from '@/domains/Business/types'
import { Widget } from '@/shared/components'
import { Pencil } from '@/shared/icons/outline'
import {
  Button,
  Details,
  MotionDiv,
  Skeleton,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { Recipient } from '../types'
import { getRecipientTitle } from '../utils'

import { PaymentDetails } from './PaymentDetails'
import { RecipientContactDetails } from './RecipientContactDetails'

type Props = {
  recipient?: Recipient
  setScreen: (screen: 'base' | 'edit') => void
}

export const BaseRecipientScreen = ({ setScreen, recipient }: Props) => {
  const location = useLocation()
  const intl = useIntl()
  const { isAdmin } = useBusinessRole()

  return (
    <SlideInLeft className="flex h-full flex-col">
      <MotionDiv key={recipient?.firstName}>
        {recipient ? (
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-3">
              <RecipientAvatar
                recipient={recipient}
                variant="dark-neutral"
                size="xl"
              />
              <div className="flex flex-col">
                <Typography variant="h3">{recipient.nickname}</Typography>
                <Typography className="text-neutral-gray-600">
                  {getRecipientTitle(recipient)}
                </Typography>
              </div>
            </div>

            {isAdmin && (
              <Button
                leftIcon={<Pencil className="size-4" />}
                onClick={() => setScreen('edit')}
                variant="tertiary"
                size="icon"
                aria-label={intl.formatMessage({
                  id: 'recipients.details.edit',
                  defaultMessage: 'Edit recipient',
                })}
              />
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            <Skeleton className="h-[33px] w-36" />
            <Skeleton className="h-[18px] w-36" />
          </div>
        )}
      </MotionDiv>

      <div className="p-4" />

      <MotionDiv key={recipient?.id} className="flex flex-col gap-8">
        <Widget
          title={
            <FormattedMessage
              id="recipient.contactDetails"
              defaultMessage="Contact details"
            />
          }
        >
          <RecipientContactDetails recipient={recipient} />
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="recipient.paymentDetails"
              defaultMessage="Payment details"
            />
          }
        >
          <PaymentDetails recipient={recipient} />

          {recipient?.bankName ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.bank" defaultMessage="Bank" />
              </Details.Label>
              <Details.Value>{recipient.bankName}</Details.Value>
            </Details>
          ) : null}
        </Widget>

        {recipient?.creationDetails ? (
          <Widget
            title={
              <FormattedMessage
                id="recipient.recipientCreation"
                defaultMessage="Recipient creation"
              />
            }
          >
            {recipient.creationDetails.requestedBy ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.requestedBy"
                    defaultMessage="Requested by"
                  />
                </Details.Label>
                <Details.Value>
                  {recipient.creationDetails.requestedBy}
                </Details.Value>
              </Details>
            ) : null}
            {recipient.creationDetails.approvedBy ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.approvedBy"
                    defaultMessage="Approved by"
                  />
                </Details.Label>
                <Details.Value>
                  {recipient.creationDetails.approvedBy}
                </Details.Value>
              </Details>
            ) : null}
          </Widget>
        ) : null}
      </MotionDiv>

      <WithPermissions permissions={[Permission.TRANSFERS]}>
        <StickyContainer className="-bottom-16 pb-0">
          <Button width="full" asChild>
            <Link
              state={{ from: location }}
              to={`${BusinessRoute.Send}?recipient=${recipient?.id}`}
            >
              <FormattedMessage
                id="action.sendPayment"
                defaultMessage="Send a payment"
              />
            </Link>
          </Button>
        </StickyContainer>
      </WithPermissions>
    </SlideInLeft>
  )
}
