import { FormattedMessage } from 'react-intl'

import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { ContractorPaymentFrequency, ContractPaymentDayType } from '../../types'

type Props = {
  disabled?: boolean
  frequency: ContractorPaymentFrequency
  value?: string
  onChange: (v: ContractPaymentDayType) => void
}

export const PaymentDaySelect = ({
  disabled,
  frequency,
  onChange,
  value,
}: Props) => {
  const getOptionsByFrequency = () => {
    switch (frequency) {
      case ContractorPaymentFrequency.WEEKLY:
      case ContractorPaymentFrequency.BIWEEKLY:
        return [
          ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
          ContractPaymentDayType.CUSTOM_DAY_OF_WEEK,
        ]

      case ContractorPaymentFrequency.SEMIMONTHLY:
        return [
          ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
          ContractPaymentDayType.LAST_FRIDAY_OF_PERIOD,
        ]

      case ContractorPaymentFrequency.MONTHLY:
        return [
          ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD,
          ContractPaymentDayType.LAST_FRIDAY_OF_PERIOD,
          ContractPaymentDayType.CUSTOM_DAY_OF_MONTH,
        ]

      default:
        return []
    }
  }

  return (
    <Select
      disabled={disabled || !frequency}
      onValueChange={onChange}
      value={value}
    >
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage id="label.payday" defaultMessage="Payday" />
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="contractor.paymentDay.label"
                  defaultMessage="{paymentDay, select, LAST_BUSINESS_DAY_OF_PERIOD {Last business day of the period} LAST_FRIDAY_OF_PERIOD {Last Friday of the period } CUSTOM_DAY_OF_MONTH {Custom day of the month} CUSTOM_DAY_OF_WEEK {Custom day of the week} other {}}"
                  values={{
                    paymentDay: value,
                  }}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={
              <FormattedMessage id="label.payday" defaultMessage="Payday" />
            }
          >
            <FormattedMessage id="label.payday" defaultMessage="Payday" />
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {getOptionsByFrequency().map((option) => (
          <SelectItem
            disabled={option === value}
            hideChecked
            className="flex h-[54px]"
            key={option}
            value={option}
          >
            <Typography>
              <FormattedMessage
                id="contractor.paymentDay.label"
                defaultMessage="{paymentDay, select, LAST_BUSINESS_DAY_OF_PERIOD {Last business day of the period} LAST_FRIDAY_OF_PERIOD {Last Friday of the period } CUSTOM_DAY_OF_MONTH {Custom day of the month} CUSTOM_DAY_OF_WEEK {Custom day of the week} other {}}"
                values={{
                  paymentDay: option,
                }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
