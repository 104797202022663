import { useMemo } from 'react'
import { Big } from 'big.js'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatMoney, formatPercentage, formatRate } from '@/lib/money'
import { cn } from '@/lib/utils'
import { SortUp } from '@/shared/icons/outline'
import { Skeleton } from '@/shared/ui'
import { ChartFXRate, ChartFXRateWithTimestamp, Period } from '@/types/recharts'

type Props = {
  active: boolean
  chartRate?: ChartFXRate
  data: ChartFXRateWithTimestamp[]
  period: string
  isPending: boolean
}

export const FXRatesValues = ({
  active,
  chartRate,
  data,
  isPending,
  period,
}: Props) => {
  const newestRate = useMemo(() => data[data.length - 1], [data])
  const oldestRate = useMemo(() => data[0], [data])

  const amountDifference = useMemo(() => {
    if (!data) {
      return Big(0)
    }

    if (!chartRate) {
      return Big(newestRate?.bid ?? 0).minus(oldestRate?.bid ?? 0)
    }

    return Big(chartRate?.bid ?? 0).minus(oldestRate?.bid ?? 0)
  }, [chartRate, data, newestRate?.bid, oldestRate?.bid])

  const isPositiveDelta = useMemo(
    () => amountDifference.gte(0),
    [amountDifference],
  )

  const ratePercentageDifference = useMemo(() => {
    if (!data || !oldestRate?.bid) {
      return Big(0)
    }

    return Big(amountDifference).div(oldestRate.bid).times(100)
  }, [amountDifference, data, oldestRate?.bid])

  const positiveResult = useMemo(() => {
    return isPositiveDelta || amountDifference.toFixed(2) === '0.00'
  }, [amountDifference, isPositiveDelta])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <div className="flex flex-col items-start justify-center gap-1 md:min-w-40">
          <p className="text-neutral-gray-600">
            <FormattedMessage
              id="fxRatesWidget.buyOneUsdc"
              defaultMessage="Buy 1 USDc"
            />
          </p>
          {isPending ? (
            <Skeleton className="h-[33px] w-36" />
          ) : (
            <p className="text-2.5xl font-bold">
              {active && chartRate
                ? formatRate(chartRate.ask)
                : formatRate(newestRate?.ask ?? 0)}{' '}
              <span className="text-xl font-semibold">{Currency.MXN}</span>
            </p>
          )}
        </div>

        <div className="flex flex-col items-start justify-center gap-1 md:min-w-40">
          <p className="text-neutral-gray-600">
            <FormattedMessage
              id="fxRatesWidget.sellOneUsdc"
              defaultMessage="Sell 1 USDc"
            />
          </p>
          {isPending ? (
            <Skeleton className="h-[33px] w-36" />
          ) : (
            <p className="text-2.5xl font-bold">
              {active && chartRate
                ? formatRate(chartRate.bid)
                : formatRate(newestRate?.bid ?? 0)}{' '}
              <span className="text-xl font-semibold">{Currency.MXN}</span>
            </p>
          )}
        </div>
      </div>

      <div className="flex">
        <div
          className={cn('flex items-center gap-1 rounded-xl px-1.5 py-1', {
            'bg-primary/20': positiveResult,
            'bg-primary-error/20': !positiveResult,
          })}
        >
          <SortUp
            className={cn(
              'size-4',
              {
                'fill-primary text-primary': positiveResult,
              },
              {
                'rotate-180 fill-primary-error text-primary-error':
                  !positiveResult,
              },
            )}
          />
          <p
            className={cn('font-semibold', {
              'text-primary': positiveResult,
              'text-primary-error': !positiveResult,
            })}
          >
            {formatPercentage(ratePercentageDifference.toNumber())}
          </p>
        </div>

        <div className="p-1" />

        <div className="flex items-center gap-1 font-semibold">
          <p className="text-neutral-gray-600">
            {`${isPositiveDelta ? '+' : ''}${formatMoney(amountDifference.toFixed(2))}`}
          </p>
          {active && period !== Period.Day ? null : (
            <p className="text-neutral-gray-600">
              <FormattedMessage
                id="fxRatesChart.label.period"
                defaultMessage="{period, select, 1D {Today} 1W {Last week} 1M {Last month} 6M {L6 months} 1Y {Last year} 5Y {L5 years} other {}}"
                values={{ period }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
