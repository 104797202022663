import { parseAdditionalDetails } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { EntityType } from '../../features/Recipients/types'
import { SingleTransaction } from '../../features/Transactions/types'
import { TransactionIcon } from '../TransactionIcon'

type Props = {
  transaction: SingleTransaction
}

export const SingleTransactionCell = ({ transaction }: Props) => {
  const beneficiaryEntityType = parseAdditionalDetails<{
    beneficiaryEntityType?: EntityType
  }>(transaction.additionalDetails)?.beneficiaryEntityType

  return (
    <div className="flex items-center gap-3 pr-2 sm:pr-0">
      <TransactionIcon
        additionalDetails={transaction.additionalDetails}
        recipientType={beneficiaryEntityType}
        transactionType={transaction.transactionType}
        transactionStatus={transaction.transactionStatus}
      />

      <div className="flex items-center gap-1">
        <Typography className="line-clamp-1">{transaction.name}</Typography>
      </div>
    </div>
  )
}
