import { FormattedMessage } from 'react-intl'

import { getCardTitle } from '@/lib/card'
import { Widget } from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Details,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { CardScreenType, Card as CardType } from '../types'

type Props = {
  card?: CardType
  setScreen: (screen: CardScreenType) => void
  pin?: string
}

export const CardPIN = ({ card, setScreen, pin }: Props) => {
  return (
    <SlideInLeft>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'base' })}
              variant="ghost"
              size="inline"
            >
              {card ? (
                <Typography>{getCardTitle(card)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage id="label.cardPIN" defaultMessage="Card PIN" />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <Widget
        title={
          <FormattedMessage id="label.cardPIN" defaultMessage="Card PIN" />
        }
      >
        <Details>
          <Details.Label>
            <FormattedMessage
              id="label.4DigitPin"
              defaultMessage="4-digit PIN"
            />
          </Details.Label>

          <Details.Value copyable>{pin}</Details.Value>
        </Details>
      </Widget>

      <div className="p-1" />

      <Typography
        variant="body-small"
        text="center"
        className="text-neutral-gray-600"
      >
        <FormattedMessage
          id="card.sidebar.doNotSharePin"
          defaultMessage="Do not share your PIN with anyone"
        />
      </Typography>
    </SlideInLeft>
  )
}
