import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence } from 'framer-motion'
import { SubmitHandler, useForm } from 'react-hook-form'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { LimitTypeSelect } from '@/domains/Business/components'
import { CurrencyFlag, GoBackButton, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  MoneyInput,
  MotionDiv,
  RadioGroup,
  RadioGroupItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { MovementPermission } from '../../types'

import {
  moneyMovementSchema,
  MoneyMovementSchema,
  TeamMemberSchema,
} from './schemas'

const MOVEMENT_RADIO_OPTIONS = [
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.noApproval',
      defaultMessage: 'No approval required',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.noApproval.subtitle',
      defaultMessage: 'Can complete transfers without approval',
    }),
    value: MovementPermission.NO_APPROVAL_REQUIRED,
  },
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.oneAdmin',
      defaultMessage: 'One admin approval',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.oneAdmin.subtitle',
      defaultMessage:
        'Can request a transfer and needs at least one Admin to approve it',
    }),
    value: MovementPermission.ALWAYS_REQUIRE_APPROVAL,
  },
  {
    title: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.aboveLimit',
      defaultMessage: 'One admin approval above a limit',
    }),
    subtitle: defineMessage({
      id: 'teamMember.moneyMovement.select.approval.aboveLimit.subtitle',
      defaultMessage: `Requires an Admin's approval after reaching the limit`,
    }),
    value: MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT,
  },
]

const MONEY_MOVEMENT_FORM_ID = 'money-movement-form-id'

type Props = {
  moneyMovement?: MoneyMovementSchema
  onBack: () => void
  teamMemberDetails?: TeamMemberSchema
  onContinue: (data: MoneyMovementSchema) => void
}

export const MoneyMovementStep = ({
  moneyMovement,
  onBack,
  onContinue,
  teamMemberDetails,
}: Props) => {
  const intl = useIntl()

  const form = useForm<MoneyMovementSchema>({
    mode: 'onChange',
    resolver: zodResolver(moneyMovementSchema),
    values: {
      movementPermission:
        moneyMovement?.movementPermission ??
        MovementPermission.NO_APPROVAL_REQUIRED,
      limitPeriodType: moneyMovement?.limitPeriodType,
      periodTransferLimitAmount: moneyMovement?.periodTransferLimitAmount ?? '',
      singleTransferLimitAmount: moneyMovement?.singleTransferLimitAmount ?? '',
    },
  })

  const onSubmit: SubmitHandler<MoneyMovementSchema> = async (data) => {
    onContinue(data)
  }

  const showLimitFields =
    form.watch('movementPermission') ===
    MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="teamMember.moneyMovement.title"
            defaultMessage="Money movement permissions for {name}"
            values={{
              name: teamMemberDetails?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="teamMember.moneyMovement.subtitle"
            defaultMessage="Choose permissions for transfers. Card spending limits will be defined separately"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={MONEY_MOVEMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6">
              <Widget
                title={
                  <FormattedMessage
                    id="teamMember.moneyMovement.permissions"
                    defaultMessage="Money movement permissions"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="movementPermission"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <RadioGroup
                          onValueChange={(value) => {
                            if (
                              value !==
                              MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
                            ) {
                              form.setValue('limitPeriodType', undefined)
                              form.setValue(
                                'periodTransferLimitAmount',
                                undefined,
                              )
                              form.setValue(
                                'singleTransferLimitAmount',
                                undefined,
                              )

                              form.clearErrors([
                                'periodTransferLimitAmount',
                                'singleTransferLimitAmount',
                              ])
                            }

                            field.onChange(value)
                          }}
                          defaultValue={field.value}
                          value={field.value}
                          className="flex flex-col gap-2"
                        >
                          {MOVEMENT_RADIO_OPTIONS.map((option) => (
                            <FormItem key={option.value}>
                              <Card
                                scalable
                                size="upload"
                                className="flex w-full items-center justify-between"
                              >
                                <FormLabel className="flex w-full flex-col">
                                  <Typography bold>
                                    <FormattedMessage {...option.title} />
                                  </Typography>
                                  <Typography
                                    variant="body-small"
                                    className="text-neutral-gray-600"
                                  >
                                    <FormattedMessage {...option.subtitle} />
                                  </Typography>
                                </FormLabel>
                                <FormControl>
                                  <RadioGroupItem value={option.value} />
                                </FormControl>
                              </Card>
                            </FormItem>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </Widget>

              <AnimatePresence>
                {showLimitFields ? (
                  <MotionDiv layout>
                    <Widget
                      title={
                        <FormattedMessage
                          id="teamMember.perCycleLimit.permissions"
                          defaultMessage="Per cycle limit"
                        />
                      }
                      variant="form"
                    >
                      <FormField
                        control={form.control}
                        name="limitPeriodType"
                        render={({ field }) => (
                          <FormItem>
                            <LimitTypeSelect
                              value={field.value}
                              onChange={(v) => {
                                field.onChange(v)
                              }}
                              placeholder={intl.formatMessage({
                                id: 'teamMember.moneyMovement.cycleType',
                                defaultMessage: 'Cycle type',
                              })}
                            />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="periodTransferLimitAmount"
                        render={({ field }) => (
                          <FormItem>
                            <Card size="input" className="flex">
                              <CurrencyFlag />
                              <FormControl>
                                <MoneyInput
                                  currency={Currency.USD}
                                  placeholder={intl.formatMessage({
                                    defaultMessage: 'Aggregate amount',
                                    id: 'label.aggregateAmount',
                                  })}
                                  className="text-right"
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              </FormControl>

                              <AnimatedFormLabel align="end">
                                <FormattedMessage
                                  defaultMessage="Aggregate amount"
                                  id="label.aggregateAmount"
                                />
                              </AnimatedFormLabel>
                            </Card>
                          </FormItem>
                        )}
                      />
                    </Widget>
                  </MotionDiv>
                ) : null}
              </AnimatePresence>

              <AnimatePresence>
                {showLimitFields ? (
                  <MotionDiv>
                    <Widget
                      title={
                        <FormattedMessage
                          id="teamMember.moneyMovement.singleTransactionLimit"
                          defaultMessage="Single transaction limit"
                        />
                      }
                      variant="form"
                    >
                      <FormField
                        control={form.control}
                        name="singleTransferLimitAmount"
                        render={({ field }) => (
                          <FormItem>
                            <Card size="input" className="flex">
                              <CurrencyFlag />
                              <FormControl>
                                <MoneyInput
                                  currency={Currency.USD}
                                  placeholder={intl.formatMessage({
                                    defaultMessage: 'Single transaction amount',
                                    id: 'label.singleTransactionAmount',
                                  })}
                                  className="text-right"
                                  value={field.value}
                                  onChange={field.onChange}
                                />
                              </FormControl>

                              <AnimatedFormLabel align="end">
                                <FormattedMessage
                                  defaultMessage="Single transaction amount"
                                  id="label.singleTransactionAmount"
                                />
                              </AnimatedFormLabel>
                            </Card>
                          </FormItem>
                        )}
                      />
                    </Widget>
                  </MotionDiv>
                ) : null}
              </AnimatePresence>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={MONEY_MOVEMENT_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
