import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getFullName } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { Badge, Skeleton, Typography } from '@/shared/ui'

import { getIdentity } from '../../api'
import { Card } from '../../features/Cards/types'
import { useBusinessRole, useBusinessUser } from '../../hooks'
import { Role } from '../../types'

type Props = {
  card: Card
}

export const CardholderCell = ({ card }: Props) => {
  const { role, isPending } = useBusinessRole()
  const businessUser = useBusinessUser()

  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  const isUser = useMemo(
    () => card.identityId === userQuery.data?.id,
    [card.identityId, userQuery.data?.id],
  )

  const cardUserName = card.printedUserName
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase())

  const fullName = useMemo(() => {
    switch (role) {
      case Role.ADMIN:
      case Role.READ_ONLY:
        return cardUserName

      case Role.PAYMENT_OPS:
      case Role.CARD_USER:
        return getFullName(businessUser)

      default:
        return ''
    }
  }, [cardUserName, businessUser, role])

  return (
    <div className="flex min-w-28 items-center pr-4">
      {isPending ? (
        <Skeleton className="h-[21px] w-36" />
      ) : (
        <div className="flex items-center gap-2">
          <Typography className={cn('line-clamp-1')}>{fullName}</Typography>
          {isUser && (
            <Badge className="capitalize" variant="neutral">
              <Typography bold variant="body-small">
                <FormattedMessage id="label.you" defaultMessage="You" />
              </Typography>
            </Badge>
          )}
        </div>
      )}
    </div>
  )
}
