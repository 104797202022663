import { FormattedMessage } from 'react-intl'

import { getImage } from '@/lib/images'
import { Widget } from '@/shared/components'
import { Typography } from '@/shared/ui'

export const ContractorPayments = () => {
  return (
    <Widget
      title={<FormattedMessage id="label.payments" defaultMessage="Payments" />}
    >
      <div className="flex items-center gap-4">
        <img
          src={getImage({
            name: 'empty-contractors',
            category: 'contractors',
          })}
          alt=""
          aria-hidden
          className="relative w-12"
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="contractors.payments.empty.title"
              defaultMessage="No payments found"
            />
          </Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            <FormattedMessage
              id="contractors.payments.empty.title.description"
              defaultMessage="There are no payments yet for this contractor"
            />
          </Typography>
        </div>
      </div>
    </Widget>
  )
}
