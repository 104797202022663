import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { formatCardNumber } from '@/lib/card'
import { cn } from '@/lib/utils'
import { Copy } from '@/shared/icons/outline'
import { Button, Skeleton, Typography } from '@/shared/ui'

import { getUnmaskedCardDetails } from '../../api'
import { Card, CardState } from '../../types'

type Props = {
  isFrozen: boolean
  card?: Card
}

export const CardBack = ({ card, isFrozen }: Props) => {
  const intl = useIntl()
  const copyTextToClipboard = useCopyToClipboard()

  const unmaskedCardQuery = useQuery({
    queryKey: [queryKeys.getUnmaskedCardDetails, card?.id],
    queryFn: () => getUnmaskedCardDetails({ id: card?.id ?? '' }),
    enabled: !!card?.id,
  })

  return (
    <div
      className={cn(
        'absolute h-full w-full rounded-xl bg-flip-card-back bg-cover p-4 text-white shadow-card transition-all backface-hidden rotate-y-180',
        {
          'bg-flip-card-frozen': isFrozen,
          'text-neutral-gray-800': isFrozen,
          'bg-flip-card-terminated': card?.state === CardState.TERMINATED,
        },
      )}
    >
      <div className="flex h-full flex-col justify-end gap-2 font-messina-variable">
        {unmaskedCardQuery.isPending ? (
          <Skeleton className="h-5 w-full bg-neutral-gray-800" />
        ) : (
          <Button
            className={cn('w-fit hover:text-neutral-gray-100', {
              'hover:text-neutral-gray-900': isFrozen,
            })}
            onClick={(e) => {
              e.stopPropagation()
              if (unmaskedCardQuery.data) {
                copyTextToClipboard(unmaskedCardQuery.data.cardNumber)
              }
            }}
            variant="ghost"
            size="inline"
            aria-label={intl.formatMessage({
              defaultMessage: 'Copy value',
              id: 'action.copyValue',
            })}
          >
            <Typography className="flex items-center gap-2 font-semibold tracking-widest hover:underline">
              {formatCardNumber(unmaskedCardQuery.data?.cardNumber)}
            </Typography>
            <Copy className="size-4 shrink-0" />
          </Button>
        )}

        {unmaskedCardQuery.isPending ? (
          <Skeleton className="h-4 w-36 bg-neutral-gray-800" />
        ) : (
          <Button
            className={cn('w-fit hover:text-neutral-gray-100', {
              'hover:text-neutral-gray-900': isFrozen,
            })}
            onClick={(e) => {
              e.stopPropagation()
              if (unmaskedCardQuery.data) {
                copyTextToClipboard(unmaskedCardQuery.data.printedUserName)
              }
            }}
            variant="ghost"
            size="inline"
            aria-label={intl.formatMessage({
              defaultMessage: 'Copy value',
              id: 'action.copyValue',
            })}
          >
            <Typography className="flex items-center gap-2 text-xs font-semibold uppercase tracking-widest hover:underline">
              {unmaskedCardQuery.data?.printedUserName}
            </Typography>
            <Copy className="size-4 shrink-0" />
          </Button>
        )}

        <div className="flex gap-4">
          <Button
            className={cn(
              'group flex w-fit flex-col items-start gap-0 hover:text-neutral-gray-100',
              { ' hover:text-neutral-gray-900': isFrozen },
            )}
            onClick={(e) => {
              e.stopPropagation()
              if (unmaskedCardQuery.data?.validToMMYY) {
                copyTextToClipboard(unmaskedCardQuery.data.validToMMYY)
              }
            }}
            variant="ghost"
            size="inline"
            aria-label={intl.formatMessage({
              defaultMessage: 'Copy value',
              id: 'action.copyValue',
            })}
          >
            <Typography className="text-neutral-gray-300" variant="body-tiny">
              <FormattedMessage id="label.expires" defaultMessage="Exp" />
            </Typography>
            <span className="flex items-center gap-1">
              <Typography
                variant="body-tiny"
                className="flex items-center gap-2 font-semibold uppercase tracking-widest hover:underline group-hover:underline"
              >
                {unmaskedCardQuery.data?.validToMMYY}
              </Typography>
              <Copy className="size-3 shrink-0" />
            </span>
          </Button>

          <Button
            className={cn(
              'group flex w-fit flex-col items-start gap-0 hover:text-neutral-gray-100',
              { ' hover:text-neutral-gray-900': isFrozen },
            )}
            onClick={(e) => {
              e.stopPropagation()
              if (unmaskedCardQuery.data) {
                copyTextToClipboard(unmaskedCardQuery.data.cvv2)
              }
            }}
            variant="ghost"
            size="inline"
            aria-label={intl.formatMessage({
              defaultMessage: 'Copy value',
              id: 'action.copyValue',
            })}
          >
            <Typography className="text-neutral-gray-300" variant="body-tiny">
              <FormattedMessage id="label.CVC" defaultMessage="CVC" />
            </Typography>
            <span className="flex items-center gap-1">
              <Typography
                variant="body-tiny"
                className="flex items-center gap-2 font-semibold uppercase tracking-widest hover:underline group-hover:underline"
              >
                {unmaskedCardQuery.data?.cvv2}
              </Typography>
              <Copy className="size-3 shrink-0" />
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}
