import { Fragment } from 'react'
import { Big } from 'big.js'
import { FormattedMessage } from 'react-intl'
import {
  Area,
  AreaChart,
  Dot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  type TooltipProps,
} from 'recharts'
import type { CategoricalChartState } from 'recharts/types/chart/types'
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import {
  getMobileIntervalByPeriod,
  getTicksByPeriod,
  parseBottomAxisValueByPeriod,
  parseTopAxisValueByPeriod,
} from '@/lib/chart'
import { formatDate } from '@/lib/date'
import { formatRate } from '@/lib/money'
import { Spinner } from '@/shared/ui'
import { AxisProps, ChartFXRateWithTimestamp, Period } from '@/types/recharts'

const X_AXIS_KEY = 'createdAt'
const Y_AXIS_KEY = 'bid'
const BASE_COLOR = '#2C2C2E'

type Props = {
  data: ChartFXRateWithTimestamp[]
  isPending: boolean
  onMouseMove: (data: CategoricalChartState) => void
  onMouseLeave: (data: CategoricalChartState) => void
  period: Period
}

const CustomizedAxisTick = (props: AxisProps & { period: Period }) => {
  const { x, y, payload } = props

  const topValue = parseTopAxisValueByPeriod(payload.value, props.period)
  const bottomValue = parseBottomAxisValueByPeriod(payload.value, props.period)

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16}>
        <tspan className="text-xs" textAnchor="middle" x="0">
          {topValue}
        </tspan>
        <tspan
          className="text-base font-bold"
          textAnchor="middle"
          x="0"
          dy="25"
        >
          {bottomValue}
        </tspan>
      </text>
    </g>
  )
}

const CustomizedYAxisTick = (props: AxisProps) => {
  const { x, y, payload } = props

  const value = Big(payload.value).toFixed(2)

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={0}
        textAnchor="end"
        fill={BASE_COLOR}
        className="text-xs"
      >
        {value}
      </text>
    </g>
  )
}

const CustomTooltip = ({
  active,
  payload,
  period,
}: TooltipProps<ValueType, NameType> & { period: Period }) => {
  const { createdAt, bid, ask } = payload?.[0]?.payload ?? {}

  const dateFormatByPeriod = [Period.Year, Period.FiveYears].includes(period)
    ? 'dd/MM/yyyy'
    : 'MM/dd/yyyy, HH:mm'

  if (active) {
    return (
      <div className="flex flex-col items-start gap-1 rounded-lg bg-white p-4 text-sm shadow-sm">
        <p>
          <FormattedMessage id="label.date" defaultMessage="Date" />:{' '}
          {createdAt ? (
            <span className="font-bold">
              {formatDate(new Date(createdAt), dateFormatByPeriod)}
            </span>
          ) : null}
        </p>
        <p>
          <FormattedMessage id="label.buy" defaultMessage="Buy" />:{' '}
          {ask ? <span className="font-bold">{formatRate(ask)}</span> : null}
        </p>
        <p>
          <FormattedMessage id="label.sell" defaultMessage="Sell" />:{' '}
          {bid ? <span className="font-bold"> {formatRate(bid)}</span> : null}
        </p>
      </div>
    )
  }

  return null
}

export const FXChart = ({
  data,
  isPending,
  onMouseMove,
  onMouseLeave,
  period,
}: Props) => {
  const isMobile = useMediaQuery(isSmallScreen)

  if (isPending) {
    return (
      <div className="flex h-[450px] items-center justify-center">
        <Spinner className="size-16" />
      </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={450}>
      <AreaChart
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        width={500}
        height={320}
        data={data}
        margin={{ bottom: 24 }}
      >
        <XAxis
          axisLine={false}
          tickLine={false}
          tick={(props: AxisProps) => (
            <CustomizedAxisTick {...props} period={period} />
          )}
          dataKey={X_AXIS_KEY}
          type="number"
          scale="time"
          ticks={getTicksByPeriod(period)}
          interval={isMobile ? getMobileIntervalByPeriod(period) : 0}
          domain={['auto', 'auto']}
          padding={{ left: 24, right: 24 }}
        />

        <YAxis
          hide={isMobile}
          domain={['auto', 'auto']}
          axisLine={false}
          tick={(props: AxisProps) => <CustomizedYAxisTick {...props} />}
          tickLine={false}
          padding={{ top: 24, bottom: 24 }}
        />

        <Tooltip
          content={(props) => <CustomTooltip {...props} period={period} />}
        />

        <defs>
          <linearGradient id={Y_AXIS_KEY} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={BASE_COLOR} stopOpacity={0.1} />
            <stop offset="95%" stopColor={BASE_COLOR} stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area
          type="monotone"
          dataKey={Y_AXIS_KEY}
          stroke={BASE_COLOR}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#${Y_AXIS_KEY})`}
          activeDot={({ key, ...props }) => (
            <Dot {...props} r={3} fill={BASE_COLOR} stroke={BASE_COLOR} />
          )}
          dot={(props) => {
            const result = data
            const lastResult = result?.[result.length - 1]

            if (
              period === Period.Day &&
              lastResult?.[X_AXIS_KEY] === props.payload[X_AXIS_KEY]
            ) {
              return (
                <Fragment key={props.cy}>
                  <circle cx={props.cx} r="4" cy={props.cy} fill={BASE_COLOR} />
                  <circle cx={props.cx} r="6" cy={props.cy} fill={BASE_COLOR}>
                    <animate
                      attributeName="r"
                      from="4"
                      to="8"
                      dur="2s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="opacity"
                      values="1;0.75;0.5;0"
                      dur="2s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                  </circle>
                </Fragment>
              )
            }

            return (
              <circle
                key={props.cy + props.cx}
                cx={props.cx}
                r="4"
                cy={props.cy}
                opacity={0}
                fill={BASE_COLOR}
              />
            )
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}
