import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { TwoFactorGuardChecker } from '../TwoFactorGuardChecker'

import { CreateOwner } from './CreateOwner'
import { Layout } from './Layout'
import { Onboarding } from './Onboarding'
import { OnboardingComplete } from './OnboardingComplete'
import { OnboardingStart } from './OnboardingStart'
import { OnboardingSteps } from './OnboardingSteps'
import { ValidateIdentity } from './ValidateIdentity'

export const onboardingRoutes: RouteObject[] = [
  {
    element: (
      <TwoFactorGuardChecker>
        <Layout />
      </TwoFactorGuardChecker>
    ),
    children: [
      {
        path: BusinessRoute.Onboarding,
        element: <Onboarding />,
      },
      {
        path: BusinessRoute.OnboardingStart,
        element: <OnboardingStart />,
      },
      {
        path: BusinessRoute.OnboardingStep,
        element: <OnboardingSteps />,
      },
      {
        path: BusinessRoute.OnboardingCompleted,
        element: <OnboardingComplete />,
      },
    ],
  },
  {
    path: BusinessRoute.OnboardingCreateOwner,
    element: <CreateOwner />,
  },
  {
    path: BusinessRoute.OnboardingValidateIdentity,
    element: <ValidateIdentity />,
  },
]
