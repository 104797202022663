import { formatAmount } from '@/lib/money'
import { Typography } from '@/shared/ui'

import { DisplayableType, Transaction } from '../../features/Transactions/types'

import { SingleAmountCell } from './SingleAmountCell'

type Props = {
  transaction: Transaction
}

export const AmountCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE: {
      return <SingleAmountCell transaction={transaction} />
    }

    case DisplayableType.BULK: {
      return (
        <div className="flex flex-col justify-center">
          <Typography>
            {formatAmount({
              amount: transaction.totalBaseAmount,
              currency: transaction.totalBaseCurrency,
            })}
          </Typography>
        </div>
      )
    }

    default:
      return null
  }
}
