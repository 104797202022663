import { useMemo } from 'react'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { useErrorToast } from '@/hooks/useErrorToast'
import { Envelope, Link } from '@/shared/icons/outline'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { restartIdentityValidation } from '../api/restartIdentityValidation'
import { sendIdentityValidationEmail } from '../api/sendIdentityValidationEmail'
import { startIdentityValidation } from '../api/startIdentityValidation'
import {
  IdentityValidation,
  OnboardingMember,
  OnboardingMemberState,
} from '../types'

type Props = {
  member: OnboardingMember
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const IdentityEmailDialog = ({
  member,
  isOpen,
  onOpenChange,
}: Props) => {
  const copyTextToClipboard = useCopyToClipboard()
  const intl = useIntl()
  const notifyError = useErrorToast()

  const [startValidationQuery, restartValidationQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.startIdentityValidation, member.email],
        queryFn: () => startIdentityValidation({ email: member.email }),
        select: (data: AxiosResponse<IdentityValidation>) => data.data,
        enabled: isOpen && member.state === OnboardingMemberState.NOT_STARTED,
      },
      {
        queryKey: [queryKeys.restartIdentityValidation, member.email],
        queryFn: () => restartIdentityValidation({ email: member.email }),
        enabled: isOpen && member.state === OnboardingMemberState.REJECTED,
        select: (data: AxiosResponse<IdentityValidation>) => data.data,
      },
    ],
  })

  const link = useMemo(() => {
    return (
      member.validationLink ??
      startValidationQuery.data?.validationLink ??
      restartValidationQuery.data?.validationLink ??
      ''
    )
  }, [
    member.validationLink,
    startValidationQuery.data,
    restartValidationQuery.data,
  ])

  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendIdentityValidationEmail,
  })

  const onSendEmail = async () => {
    if (!member?.email) {
      return
    }

    try {
      await mutateAsync({
        email: member?.email,
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Email sent successfully',
          id: 'onboarding.identityValidationScreen.emailSent',
        }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="ID validation link"
              id="onboarding.identityValidationScreen.dialog.title"
            />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage
              defaultMessage="Share the link below with {name} manually or we can send them an email to {email} and copy you in. Remember they will need to have a valid legal ID!"
              id="onboarding.identityValidationScreen.dialog.subtitle"
              values={{
                name: member.firstName,
                email: member.email,
              }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <div className="flex flex-col gap-3">
          <Button
            width="full"
            onClick={() => copyTextToClipboard(link)}
            disabled={
              startValidationQuery.isFetching ||
              restartValidationQuery.isFetching
            }
            leftIcon={<Link className="size-5" />}
            loading={
              startValidationQuery.isFetching ||
              restartValidationQuery.isFetching
            }
          >
            <FormattedMessage id="action.copyLink" defaultMessage="Copy link" />
          </Button>

          <Button
            disabled={
              startValidationQuery.isFetching ||
              restartValidationQuery.isFetching ||
              isPending
            }
            onClick={onSendEmail}
            width="full"
            leftIcon={<Envelope className="size-4" />}
            loading={isPending}
            variant="secondary"
          >
            <FormattedMessage
              id="action.sendEmail"
              defaultMessage="Send email"
            />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
