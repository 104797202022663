import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { getAnimationKey } from '@/lib/utils'
import { ChevronRight } from '@/shared/icons/outline'
import {
  Button,
  Card,
  DataTable,
  DataTableColumnHeader,
  MotionDiv,
} from '@/shared/ui'

import { SingleTransaction } from '../../features/Transactions/types'
import { SingleAmountCell } from '../TransactionsTable'
import { SingleTransactionCell } from '../TransactionsTable/SingleTransactionCell'

type Props = {
  isPending: boolean
  transactionId?: string
  transactions: SingleTransaction[]
}

export const ShortCashbackTransactionsTable = ({
  isPending,
  transactionId,
  transactions,
}: Props) => {
  const intl = useIntl()

  const columns: ColumnDef<SingleTransaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <SingleTransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <SingleAmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
        size: 130,
      },
    ],
    [intl],
  )

  return (
    <MotionDiv key={getAnimationKey(isPending)}>
      <DataTable
        loaderOptions={{ rows: 3 }}
        isLoading={isPending}
        columns={columns}
        data={transactions}
      />
      <Card className="relative -top-5" size="large">
        <Button
          rightIcon={<ChevronRight />}
          size="md"
          variant="secondary"
          asChild
        >
          <Link
            to={generatePath(BusinessRoute.CashbackTransactions, {
              id: transactionId,
            })}
          >
            <FormattedMessage id="action.seeAll" defaultMessage="See all" />
          </Link>
        </Button>
      </Card>
    </MotionDiv>
  )
}
