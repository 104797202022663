import { FormattedMessage } from 'react-intl'

import { DOLAR_TAG, PaymentRails } from '@/domains/Business/constants'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import { Details } from '@/shared/ui'

type Props = {
  recipient?: Recipient
}

export const ReviewPaymentDetails = ({ recipient }: Props) => {
  switch (recipient?.paymentRails) {
    case PaymentRails.MEX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
          </Details.Label>
          {recipient ? (
            <Details.Value masked>
              {recipient.localInformation.clabe}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      )

    case PaymentRails.USA:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="ABA / Routing number"
                id="label.routingNumber"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value>
                {recipient.localInformation.routingNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value masked>
                {recipient.localInformation.accountNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
        </>
      )

    case PaymentRails.DOLAR_APP:
      return (
        <Details>
          <Details.Label>{DOLAR_TAG}</Details.Label>
          <Details.Value>{recipient.localInformation.dolarTag}</Details.Value>
        </Details>
      )

    default:
      return null
  }
}
