import { forwardRef, Ref, type SVGProps } from 'react'

const SvgNavigation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="navigation_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#navigation_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M3 20V4l19 8zm2-3 11.85-5L5 7v3.5l6 1.5-6 1.5z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgNavigation)
export default ForwardRef
