import { cn } from '@/lib/utils'
import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { Wallet } from '../features/Accounts/types'

import { WalletItem } from './WalletItem'

type Props = {
  value: string
  onChange: (v: string) => void
  disabled?: boolean
  accounts?: Wallet[]
  label: string
  showLabel?: boolean
  hasError?: boolean
}

export const AccountSelect = ({
  accounts,
  disabled,
  label,
  onChange,
  value,
  hasError = false,
  showLabel = false,
}: Props) => {
  const account = accounts?.find((a) => a.id === value)

  return (
    <Select
      disabled={accounts?.length === 0 || disabled}
      value={value}
      onValueChange={onChange}
    >
      <SelectTrigger
        className={cn('', {
          'border border-primary-error': hasError,
        })}
      >
        {account ? (
          <MotionDiv className="flex flex-col items-start">
            {showLabel ? (
              <Typography
                className={cn('', { 'text-primary-error': hasError })}
                variant="body-tiny"
              >
                {label}
              </Typography>
            ) : null}
            <MotionDiv key={account.id}>
              <WalletItem wallet={account} />
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue placeholder={label} />
        )}
      </SelectTrigger>

      <SelectContent>
        {accounts?.map((wallet) => (
          <SelectItem
            disabled={wallet.id === value}
            key={wallet.id}
            value={wallet.id}
            className="flex items-center gap-3 px-2 py-3"
          >
            <WalletItem wallet={wallet} />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
