import { defineMessages, FormattedMessage } from 'react-intl'
import { generatePath, Link } from 'react-router'

import { DefineMessages } from '@/constants/messages'
import { ContractorRoute } from '@/constants/paths'
import { cn } from '@/lib/utils'
import { Check } from '@/shared/icons/outline'
import { Typography } from '@/shared/ui'

import {
  ContractorOnboardingStep,
  ContractorOnboardingStepName,
  ContractorOnboardingStepState,
} from '../types'

const stepsMessages: DefineMessages<ContractorOnboardingStepName> =
  defineMessages({
    PERSONAL_INFORMATION: {
      id: 'contractors.onboarding.step.personalInformation',
      defaultMessage: 'Personal information',
    },
    PAYMENT_METHOD: {
      id: 'contractors.onboarding.step.paymentMethod',
      defaultMessage: 'Payment method',
    },
    TAX_FORMS: {
      id: 'contractors.onboarding.step.taxForms',
      defaultMessage: 'Tax forms',
    },
    SIGN_CONTRACT: {
      id: 'contractors.onboarding.step.signContract',
      defaultMessage: 'Sign your contract',
    },
    REVIEW_AND_SUBMIT: {
      id: 'contractors.onboarding.step.reviewAndSubmit',
      defaultMessage: 'Review and submit',
    },
  })

export const ContractorsOnboardingSidebarStep = ({
  active,
  step,
  currentNotStartedStep,
}: {
  active: boolean
  step: ContractorOnboardingStep
  currentNotStartedStep: boolean
}) => {
  if (currentNotStartedStep) {
    return (
      <Link
        to={generatePath(ContractorRoute.OnboardingStep, {
          step: step.name,
        })}
        className="group flex h-10 items-center gap-3"
      >
        <div
          className={cn(
            'h-5 w-5 shrink-0 rounded-full border-2 border-neutral-gray-800 ',
            active && 'border-white',
          )}
        />

        <Typography
          bold={active}
          className={cn(
            'hidden text-neutral-gray-800 group-hover:text-white/80 md:block',
            active && 'text-white',
          )}
        >
          <FormattedMessage {...stepsMessages[step.name]} />
        </Typography>
      </Link>
    )
  }

  if (step.state === ContractorOnboardingStepState.COMPLETED) {
    return (
      <Link
        to={generatePath(ContractorRoute.OnboardingStep, {
          step: step.name,
        })}
        className="group flex h-10 items-center gap-3"
      >
        <span className="flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-neutral-gray-200 transition-colors group-hover:bg-neutral-gray-200/80">
          <Check className="h-4 w-4" />
        </span>
        <Typography
          bold={active}
          className="hidden text-white transition-colors group-hover:text-white/80 md:block"
        >
          <FormattedMessage {...stepsMessages[step.name]} />
        </Typography>
      </Link>
    )
  }

  return (
    <div className="flex h-10 items-center gap-3">
      <div
        className={cn(
          'h-5 w-5 shrink-0 rounded-full border-2 border-neutral-gray-800',
          active && 'border-white',
        )}
      />

      <Typography
        bold={active}
        className={cn(
          'hidden text-neutral-gray-800 md:block',
          active && 'text-white',
        )}
      >
        <FormattedMessage {...stepsMessages[step.name]} />
      </Typography>
    </div>
  )
}
