import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import { ACCOUNT_MOCK_RESPONSE, MOCKED_ACCOUNT_DETAILS } from './accounts.mocks'

export const accountHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/account`, async () => {
    return HttpResponse.json(ACCOUNT_MOCK_RESPONSE)
  }),
  http.post(`${BASE_API_DEV}/v1/me/account/:id/wallets`, async () => {
    return HttpResponse.json(ACCOUNT_MOCK_RESPONSE)
  }),

  http.get(`${BASE_API_DEV}/v1/me/bank-accounts/wallet/:id`, async () => {
    return HttpResponse.json(MOCKED_ACCOUNT_DETAILS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/statements/:id`, async () => {
    return HttpResponse.json([
      {
        id: '2b36c1ca-0ab5-434f-ad30-5903326a98b2',
        businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
        entityId: 'a0033bc7-39ea-4491-9f49-833ce331c1ec',
        type: 'CREDIT',
        startDate: '2024-09-01',
        endDate: '2024-09-30',
      },
    ])
  }),

  http.patch(`${BASE_API_DEV}/v1/me/wallets/:id`, async () => {
    return HttpResponse.json(ACCOUNT_MOCK_RESPONSE)
  }),

  http.get(`${BASE_API_DEV}/v1/me/bank-accounts`, async () => {
    return HttpResponse.json(MOCKED_ACCOUNT_DETAILS)
  }),
]

export * from './accounts.mocks'
