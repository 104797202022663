import { useIntl } from 'react-intl'

import { intersperse } from '@/lib/typography'
import { Bank, Dolarapp, Payment } from '@/shared/icons/outline'

import { ContractorDetails, ContractorPaymentMethodType } from '../types'

export function useActivePaymentMethod(contractor?: ContractorDetails) {
  const intl = useIntl()

  switch (contractor?.paymentMethod.paymentMethodDetails?.type) {
    case ContractorPaymentMethodType.DOLAR_TAG:
      return {
        title: intl.formatMessage({
          id: 'contractor.paymentDetails.dolarappAccount',
          defaultMessage: 'DolarApp account',
        }),
        subtitle:
          contractor?.paymentMethod.paymentMethodDetails
            .paymentMethodInformation.dolarTag,
        Icon: Dolarapp,
      }

    case ContractorPaymentMethodType.US_BANK_ACCOUNT:
      return {
        title: intl.formatMessage({
          id: 'label.bankAccount',
          defaultMessage: 'Bank account',
        }),
        subtitle: intersperse(
          [
            contractor?.paymentMethod.paymentMethodDetails
              .paymentMethodInformation.accountNumber,
            contractor?.paymentMethod.paymentMethodDetails
              .paymentMethodInformation.routingNumber,
          ],
          ' • ',
        ),
        Icon: Bank,
      }

    case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
      return {
        title: intl.formatMessage({
          id: 'label.bankAccount',
          defaultMessage: 'Bank account',
        }),
        subtitle:
          contractor?.paymentMethod.paymentMethodDetails
            .paymentMethodInformation.clabe,
        Icon: Bank,
      }

    default:
      return {
        title: intl.formatMessage({
          id: 'label.paymentMethod',
          defaultMessage: 'Payment method',
        }),
        subtitle: intl.formatMessage({
          id: 'contractor.paymentDetails.signupIncomplete',
          defaultMessage: 'Signup incomplete',
        }),
        Icon: Payment,
      }
  }
}
