import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { isSameDay } from 'date-fns'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { getPriceChartRates } from '@/domains/Business/api'
import { useSidebar } from '@/hooks/useSidebar'
import { Calculator } from '@/shared/icons/outline'
import {
  Button,
  Card,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/shared/ui'
import { ChartFXRate, Period } from '@/types/recharts'

import { CalculateRateSidebar } from './CalculateRateSidebar'
import { FXChart } from './FXChart'
import { FXRatesValues } from './FXRatesValues'

export const FXRatesWidget = () => {
  const [show, setShow] = useSidebar({ key: 'calculator' })
  const [, setSearchParams] = useSearchParams()

  const { ref, inView } = useInView()

  const [period, setPeriod] = useState<Period>(Period.Day)
  const [chartRate, setChartRate] = useState<ChartFXRate>()
  const [isTooltipActive, setIsTooltipActive] = useState(false)

  const priceChartQuery = useQuery({
    queryKey: [queryKeys.getPriceChartRates, period],
    queryFn: () => getPriceChartRates({ currency: Currency.MXN, period }),
    select: (data) => {
      return {
        fxRates: data.data.fxRates.map((rate) => ({
          ...rate,
          createdAt: Date.parse(rate.createdAt),
        })),
      }
    },
    refetchInterval: 60 * 1000,
    enabled: inView,
  })

  const chartData = useMemo(
    () =>
      (priceChartQuery.data?.fxRates ?? []).filter((rate) => {
        const parsedDate = new Date()
        const offsetMinutes = parsedDate.getTimezoneOffset()

        if (period === Period.Day) {
          return isSameDay(
            rate.createdAt - offsetMinutes * 60 * 1000,
            Date.now(),
          )
        }

        return true
      }),
    [period, priceChartQuery.data],
  )

  return (
    <>
      <Card size="widget" ref={ref}>
        <Tabs
          defaultValue={period}
          onValueChange={(value) => setPeriod(value as Period)}
          className="flex flex-col"
        >
          <div className="flex w-full flex-wrap items-center justify-between gap-6">
            <FXRatesValues
              isPending={priceChartQuery.isPending}
              data={chartData}
              active={isTooltipActive}
              period={period}
              chartRate={chartRate}
            />

            <div className="flex flex-wrap gap-6 md:flex-nowrap md:gap-3">
              <TabsList className="w-fit bg-neutral-gray-100">
                {Object.keys(Period).map((key) => (
                  <TabsTrigger
                    key={key}
                    value={Period[key as keyof typeof Period]}
                  >
                    <FormattedMessage
                      id="fxChart.period.label"
                      defaultMessage="{period, select, 1D {1d} 1W {1w} 1M {1m} 6M {6m} 1Y {1y} 5Y {5y} other {}}"
                      values={{ period: Period[key as keyof typeof Period] }}
                    />
                  </TabsTrigger>
                ))}
              </TabsList>

              <Button
                leftIcon={<Calculator className="size-5" />}
                size="xs"
                onClick={() =>
                  setSearchParams(
                    (params) => {
                      params.set('calculator', 'true')
                      return params
                    },
                    { preventScrollReset: true },
                  )
                }
                variant="secondary"
              >
                <FormattedMessage
                  id="label.exchangeCalculator"
                  defaultMessage="Exchange calculator"
                />
              </Button>
            </div>
          </div>

          <div className="p-4" />

          {Object.keys(Period).map((key) => (
            <TabsContent key={key} value={Period[key as keyof typeof Period]}>
              <FXChart
                data={chartData}
                isPending={priceChartQuery.isLoading}
                period={Period[key as keyof typeof Period]}
                onMouseMove={(data) => {
                  setIsTooltipActive(!!data.isTooltipActive)
                  setChartRate(data.activePayload?.[0]?.payload)
                }}
                onMouseLeave={(data) => {
                  setIsTooltipActive(!!data.isTooltipActive)
                  setChartRate(undefined)
                }}
              />
            </TabsContent>
          ))}
        </Tabs>
      </Card>
      <CalculateRateSidebar
        isOpen={show}
        onOpenChange={() => {
          setShow((d) => !d)
          setSearchParams(
            (params) => {
              params.delete('calculator')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </>
  )
}
