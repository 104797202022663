import { useRef } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessUser } from '@/domains/Business/hooks'
import { Permission } from '@/domains/Business/types'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useSidebar } from '@/hooks/useSidebar'
import { getAnimationKey } from '@/lib/utils'
import { Plus } from '@/shared/icons/outline'
import {
  Button,
  DataTable,
  MotionDiv,
  SearchInput,
  Typography,
} from '@/shared/ui'

import {
  ActiveFilters,
  FiltersWidget,
  MoveMoneyWidget,
  STATUS_FILTER_NAME,
  TeamDetailsSidebar,
  TeamRoleBadge,
  TeamRoleFilter,
  TeamStateBadge,
  TeamStateFilter,
  WithPermissions,
} from '../../components'

import { getTeamMember } from './api/getTeamMember'
import { getTeamMembers } from './api/getTeamMembers'
import { useTeamColumns } from './components/TeamTable'
import { TeamMember, TeamState } from './types'

export const Team = () => {
  const columns = useTeamColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showDetails, setShowDetails] = useSidebar({ key: 'id' })
  const showTerminatedFilterOnceRef = useRef(false)
  const businessUser = useBusinessUser()

  const [teamMemberId] = useSearchParamsValue(['id'])

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const selectAndAddTerminatedFilter = (data: AxiosResponse<TeamMember[]>) => {
    const teamMembers = data?.data

    if (!showTerminatedFilterOnceRef.current && teamMembers) {
      const hasTerminatedCards = teamMembers.some(
        (member) => member.state === TeamState.TERMINATED,
      )

      const hasTeamMemberFilterParams = Object.keys(params).length > 0

      if (hasTeamMemberFilterParams) {
        showTerminatedFilterOnceRef.current = true
      } else if (hasTerminatedCards) {
        const defaultStatuses = [
          TeamState.ACTIVE,
          TeamState.INVITED,
          TeamState.BLOCKED,
        ]

        setSearchParams(
          (params) => {
            params.set(STATUS_FILTER_NAME, defaultStatuses.join(','))
            showTerminatedFilterOnceRef.current = true
            return params
          },
          { preventScrollReset: true },
        )
      }
    }

    return teamMembers
  }

  const [teamMembersQuery, memberQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTeamMembers, params, businessUser?.identityId],
        queryFn: () => getTeamMembers(params),
        gcTime: 0,
        staleTime: 0,
        select: (data: AxiosResponse<TeamMember[]>) =>
          selectAndAddTerminatedFilter(data).sort((a) => {
            return a.identityId === businessUser?.identityId ? -1 : 1
          }),
      },
      {
        queryKey: [queryKeys.getTeamMember, teamMemberId],
        queryFn: () => getTeamMember({ id: teamMemberId ?? '' }),
        select: (data: AxiosResponse<TeamMember>) => data.data,
        enabled: !!teamMemberId,
      },
    ],
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage id="team.home.title" defaultMessage="Team" />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="team.home.subtitle"
              defaultMessage="Invite and manage your team members in the account"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap ">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <TeamRoleFilter />
          <TeamStateFilter />
        </FiltersWidget>
        <WithPermissions permissions={[Permission.TEAM_MANAGEMENT]}>
          <Button
            leftIcon={<Plus className="size-5" />}
            asChild
            variant="tertiary"
          >
            <Link to={BusinessRoute.AddTeamMember}>
              <FormattedMessage
                id="action.addNewMember"
                defaultMessage="Add new member"
              />
            </Link>
          </Button>
        </WithPermissions>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <TeamRoleBadge />
        <TeamStateBadge />
      </ActiveFilters>

      <MotionDiv key={getAnimationKey(teamMembersQuery.isPending)}>
        <DataTable
          columns={columns}
          data={teamMembersQuery.data ?? []}
          isLoading={teamMembersQuery.isPending}
          loaderOptions={{ rows: 3 }}
          onRowClick={(card) => {
            setSearchParams(
              (params) => {
                params.set('id', card.id)
                return params
              },
              { preventScrollReset: true },
            )
          }}
        />
      </MotionDiv>

      <TeamDetailsSidebar
        member={memberQuery.data}
        isOpen={showDetails}
        onOpenChange={() => {
          setShowDetails((d) => !d)
          setSearchParams(
            (params) => {
              params.delete('id')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </div>
  )
}
