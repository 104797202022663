import { FormattedMessage, useIntl } from 'react-intl'

import { ContractorPaymentMethod } from '@/domains/Contractor/types'
import { getPaymentMethodDetails } from '@/domains/Contractor/utils'
import { getCountryNameByISO3 } from '@/lib/country'
import { getFlagUrlByCountryCode } from '@/lib/images'
import { cn } from '@/lib/utils'
import { Button, Card, Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { LinkAction } from './LinkAction'

type Props = {
  paymentMethod?: ContractorPaymentMethod
  countryCode: CountryCode
  onContinue: () => void
}

export const BankAccountCard = ({
  countryCode,
  paymentMethod,
  onContinue,
}: Props) => {
  const intl = useIntl()

  const inactive = !paymentMethod?.state

  const Component = inactive ? Button : 'div'

  const activeDetails = getPaymentMethodDetails(paymentMethod)

  return (
    <Card
      size="medium"
      className="flex w-full items-center justify-between"
      asChild
      scalable={inactive}
    >
      <Component
        className={cn(!inactive && 'cursor-default')}
        size="inline"
        variant="ghost"
        onClick={inactive ? onContinue : undefined}
      >
        <div className="flex items-center gap-3">
          <img
            src={getFlagUrlByCountryCode(countryCode)}
            className="size-9 shrink-0"
            alt={intl.formatMessage(
              {
                id: 'country.flag.alt',
                defaultMessage:
                  '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
              },
              {
                country: countryCode,
              },
            )}
          />

          <div className="flex flex-col">
            <Typography bold>
              {getCountryNameByISO3(countryCode, intl.locale)}
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              {activeDetails ??
                (() => {
                  switch (countryCode) {
                    case CountryCode.US:
                      return (
                        <FormattedMessage
                          id="contractors.paymentMethodByCountry.description.US"
                          defaultMessage="3-5 business days and 1% fee"
                        />
                      )
                    case CountryCode.MX:
                      return (
                        <FormattedMessage
                          id="contractors.paymentMethodByCountry.description.MX"
                          defaultMessage="Instantly and 1% fee"
                        />
                      )

                    default:
                      return null
                  }
                })()}
            </Typography>
          </div>
        </div>

        <LinkAction
          state={paymentMethod?.state}
          id={paymentMethod?.paymentMethodDetails.id}
        />
      </Component>
    </Card>
  )
}
