import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { HistoricalPayment, HistoricalPaymentState } from '../../../types'

function getBadgeVariant(state: HistoricalPaymentState) {
  return {
    [HistoricalPaymentState.PENDING]: 'neutral',
    [HistoricalPaymentState.INCOMPLETE]: 'warning',
    [HistoricalPaymentState.COMPLETED]: 'active',
    [HistoricalPaymentState.EXECUTING]: 'neutral',
  }[state] as StatusVariant
}

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaymentStateCell = ({ payment }: Props) => {
  const badgeVariant = getBadgeVariant(payment.state)

  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariant}
        title={
          <FormattedMessage
            id="historicalPayment.state"
            defaultMessage="{state, select, PENDING {In progress} INCOMPLETE {Incomplete} COMPLETED {Completed} EXECUTING {Executing} other {}}"
            values={{ state: payment.state }}
          />
        }
      />
    </div>
  )
}
