import { Contractor } from '../../../types'
import { ContractorStateBadge } from '../../ContractorStateBadge'

type Props = {
  contractor: Contractor
}

export const ContractorStateCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <ContractorStateBadge state={contractor.state} />
    </div>
  )
}
