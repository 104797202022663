import { FormattedMessage } from 'react-intl'

import { RecipientAccountAvatar } from '@/domains/Business/components'
import { DOLAR_TAG, PaymentRails } from '@/domains/Business/constants'
import { Details, Typography } from '@/shared/ui'

import { Recipient } from '../types'

type Props = {
  recipient?: Recipient
}

export const PaymentDetails = ({ recipient }: Props) => {
  switch (recipient?.paymentRails) {
    case PaymentRails.MEX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
          </Details.Label>
          <Details.Value masked>
            {recipient.localInformation.clabe}
          </Details.Value>
        </Details>
      )

    case PaymentRails.USA:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="ABA / Routing number"
              />
            </Details.Label>
            <Details.Value>
              {recipient.localInformation.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </Details.Label>
            <Details.Value masked>
              {recipient.localInformation.accountNumber}
            </Details.Value>
          </Details>
        </>
      )

    case PaymentRails.DOLAR_APP:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>

            <div className="flex items-center gap-1">
              <RecipientAccountAvatar paymentRails={recipient.paymentRails} />
              <Typography>{DOLAR_TAG}</Typography>
            </div>
          </Details>
          <Details>
            <Details.Label>{DOLAR_TAG}</Details.Label>
            <Details.Value>{recipient.localInformation.dolarTag}</Details.Value>
          </Details>
        </>
      )

    default:
      return (
        <>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
        </>
      )
  }
}
