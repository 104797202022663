import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { CompanySettings } from './CompanySettings'
import { Settings } from './Settings'

export const settingsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Settings,
    element: <Settings />,
    children: [
      {
        index: true,
        element: <CompanySettings />,
      },
      {
        path: BusinessRoute.CompanySettings,
        element: <CompanySettings />,
      },
    ],
  },
]
