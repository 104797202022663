import {
  ACH,
  DOLAR_TAG,
  PaymentMethod,
  PaymentRails,
  SPEI,
  WIRE,
} from '@/domains/Business/constants'
import { Recipient } from '@/domains/Business/features/Recipients/types'

import { maskValue } from './typography'

export function getRecipientAccountNumber(recipient?: Recipient) {
  switch (recipient?.paymentRails) {
    case PaymentRails.MEX:
      return maskValue(recipient.localInformation.clabe, 3)

    case PaymentRails.USA:
      return maskValue(recipient.localInformation.accountNumber, 3)

    case PaymentRails.DOLAR_APP:
      return recipient.localInformation.dolarTag

    default:
      return ''
  }
}

export function getRecipientPaymentMethod(recipient?: Recipient) {
  switch (recipient?.paymentRails) {
    case PaymentRails.MEX:
      return SPEI

    case PaymentRails.USA:
      return recipient.localInformation.paymentMethod === PaymentMethod.ACH
        ? ACH
        : WIRE

    case PaymentRails.DOLAR_APP:
      return DOLAR_TAG

    default:
      return ''
  }
}
