import { Fragment, useEffect } from 'react'
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useSearchParams } from 'react-router'

import { PAGE_SIZE } from '@/constants/pagination'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getRecipients } from '@/domains/Business/features/Recipients'
import { useSearchInput } from '@/hooks/useSearchInput'
import { getAnimationKey } from '@/lib/utils'
import { EmptyCard, GoBackButton, Widget } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import {
  Button,
  MotionDiv,
  SearchInput,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { getRecentRecipients } from '../api'

import { RecipientCard } from './RecipientCard'

type Props = {
  onSelect: (recipient: string) => void
}

export const SelectRecipientScreen = ({ onSelect }: Props) => {
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const {
    data,
    isPending,
    isFetching,
    isFetchingNextPage,
    isFetched,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getRecipients, params],
    queryFn: ({ pageParam }) =>
      getRecipients({
        ...params,
        page: String(pageParam),
        limit: String(PAGE_SIZE),
      }),
    select: (data) => data.pages.flatMap((page) => page.data),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.data?.length < PAGE_SIZE) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  useEffect(() => {
    if (inView && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetching, isFetchingNextPage])

  const recentRecipientsQuery = useQuery({
    queryKey: [queryKeys.getRecentRecipients],
    queryFn: getRecentRecipients,
    select: (data) => data?.data,
  })

  return (
    <>
      <GoBackButton to={location.state?.from ?? BusinessRoute.Dashboard} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Who do you want to pay?"
            id="send.selectRecipient.title"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-wrap gap-4 md:flex-nowrap">
          <SearchInput
            value={search}
            onChange={(value) => {
              setSearch(value)
              handleSearchQuery(value)
            }}
          />

          <Button size="md" leftIcon={<Plus className="size-5" />} asChild>
            <Link to={BusinessRoute.AddRecipients} state={{ from: location }}>
              <FormattedMessage
                defaultMessage="New recipient"
                id="action.newRecipient"
              />
            </Link>
          </Button>
        </div>

        <div className="p-4" />

        <div className="flex flex-col gap-8">
          {search.length > 0 ||
          (!recentRecipientsQuery.data?.length &&
            !recentRecipientsQuery.isPending) ? null : (
            <MotionDiv key={getAnimationKey(recentRecipientsQuery.isPending)}>
              <Widget
                className="py-2"
                gap="none"
                title={
                  <FormattedMessage id="label.recent" defaultMessage="Recent" />
                }
              >
                {recentRecipientsQuery.data ? (
                  recentRecipientsQuery.data.map((recipient) => {
                    if (!recipient) return null

                    const lastItem =
                      recentRecipientsQuery.data.indexOf(recipient) ===
                      recentRecipientsQuery.data.length - 1

                    return (
                      <Fragment key={recipient.id}>
                        <RecipientCard
                          recipient={recipient}
                          onSelect={onSelect}
                        />

                        {!lastItem && (
                          <div className="border-b bg-neutral-gray-200" />
                        )}
                      </Fragment>
                    )
                  })
                ) : recentRecipientsQuery.isPending ? (
                  <RecipientCard.Skeleton />
                ) : (
                  <EmptyCard>
                    <EmptyCard.Title>
                      <FormattedMessage
                        defaultMessage="No recent recipients"
                        id="send.selectRecipient.noRecent"
                      />
                    </EmptyCard.Title>
                    <EmptyCard.Description>
                      <FormattedMessage
                        id="recipients.search.notFound.description"
                        defaultMessage="There were no recipients that matched your search criteria"
                      />
                    </EmptyCard.Description>
                  </EmptyCard>
                )}
              </Widget>
            </MotionDiv>
          )}

          <Widget
            gap="none"
            className="py-2"
            title={
              <FormattedMessage
                id="label.allRecipients"
                defaultMessage="All recipients"
              />
            }
          >
            <MotionDiv key={getAnimationKey(isPending)}>
              {data?.length ? (
                data.map((recipient) => {
                  const lastItem = data.indexOf(recipient) === data.length - 1

                  return (
                    <Fragment key={recipient.id}>
                      <RecipientCard
                        recipient={recipient}
                        onSelect={onSelect}
                      />

                      {!lastItem && !isPending && !isFetchingNextPage && (
                        <div className="border-b bg-neutral-gray-200" />
                      )}
                    </Fragment>
                  )
                })
              ) : isPending || (!isFetchingNextPage && isFetching) ? (
                <RecipientCard.Skeleton />
              ) : (
                <EmptyCard>
                  <EmptyCard.Title>
                    <FormattedMessage
                      id="recipients.search.notFound"
                      defaultMessage="No recipients found"
                    />
                  </EmptyCard.Title>
                  <EmptyCard.Description>
                    <FormattedMessage
                      id="recipients.search.notFound.description"
                      defaultMessage="There were no recipients that matched your search criteria"
                    />
                  </EmptyCard.Description>
                </EmptyCard>
              )}
              {isFetched && <div ref={ref} />}
              {isFetchingNextPage && <RecipientCard.Skeleton length={1} />}
            </MotionDiv>
          </Widget>
        </div>

        <div className="p-8" />
      </SlideInScreen>
    </>
  )
}
