import { Outlet } from 'react-router'

import { OnboardingSidebar } from './components/OnboardingSidebar'

export const Layout = () => {
  return (
    <div className="grid grid-cols-[3rem_1fr] gap-4 md:grid-cols-[15rem_1fr] md:gap-0">
      <OnboardingSidebar />

      <main className="col-start-2 mx-auto w-full max-w-4xl px-6 py-8 md:pb-12 md:pt-24">
        <Outlet />
      </main>
    </div>
  )
}
