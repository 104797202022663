import { isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { formatDate } from '@/lib/date'
import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { HistoricalPayment } from '../../../types'

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaymentCycleCell = ({ payment }: Props) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex items-center gap-2">
        <Typography bold>
          {intersperse(
            [
              formatDate(
                payment.startDate,
                isSameYear(payment.startDate, payment.endDate)
                  ? 'dd MMM.'
                  : 'dd MMM. yyyy',
              ),
              formatDate(payment.endDate, 'dd MMM. yyyy'),
            ],
            ' - ',
          )}
        </Typography>
      </div>
      <Typography variant="body-small" className="text-neutral-gray-600">
        <FormattedMessage
          id="contractor.paymentFrequency.label"
          defaultMessage="{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}"
          values={{
            paymentFrequency: payment.frequency,
          }}
        />
      </Typography>
    </div>
  )
}
