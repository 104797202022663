import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { StateCell, TransactionIcon } from '@/domains/Business/components'
import { formatDate, getBrowserTimeZone } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { cn, parseAdditionalDetails } from '@/lib/utils'
import { Card, Details, Skeleton, Typography } from '@/shared/ui'

import { EntityType } from '../../features/Recipients/types'
import { getCashbackTransactions } from '../../features/Transactions/api'
import { failedTransactionStatuses } from '../../features/Transactions/constants'
import {
  SingleTransaction,
  TransactionType,
} from '../../features/Transactions/types'

import { PaymentDetails } from './PaymentDetails'
import { ShortCashbackTransactionsTable } from './ShortCashbackTransactionsTable'
import { TrackingInformation } from './TrackingInformation'
import { TransactionAmountDetails } from './TransactionAmountDetails'
import { TransactionInfo } from './TransactionInfo'

type Props = {
  transaction?: SingleTransaction
}

export const SingleTransactionDetails = ({ transaction }: Props) => {
  const additionalDetails = parseAdditionalDetails<{
    taskOwnerFullName?: string
    completedByFullName?: string
    beneficiaryEntityType?: EntityType
  }>(transaction?.additionalDetails)

  const cashbackTransactionQuery = useQuery({
    queryKey: [queryKeys.getCashbackTransactions, transaction?.id],
    select: (data) => data.data,
    queryFn: () =>
      getCashbackTransactions({
        id: transaction?.id ?? '',
      }),
    enabled: transaction?.transactionType === TransactionType.CASHBACK,
    retry: 1,
  })

  const isFailed = transaction?.transactionStatus
    ? failedTransactionStatuses.includes(transaction?.transactionStatus)
    : false

  const transactionAmount = formatAmount(
    {
      amount: transaction?.baseAmount ?? 0,
      currency: transaction?.baseCurrency ?? Currency.USDC,
    },
    { signDisplay: true },
  )

  return (
    <>
      <div className="flex items-center gap-2">
        <TransactionIcon
          variant="dark-neutral"
          size="xl"
          additionalDetails={transaction?.additionalDetails}
          recipientType={additionalDetails?.beneficiaryEntityType}
          transactionType={transaction?.transactionType}
          transactionStatus={transaction?.transactionStatus}
        />
        <div className="flex flex-col">
          <Typography
            variant="h3"
            className={cn('', isFailed && 'line-through')}
          >
            {transactionAmount}
          </Typography>
          {transaction?.name ? (
            <Typography className="text-neutral-gray-600">
              {transaction.name}
            </Typography>
          ) : (
            <Skeleton className="h-[21px] w-24" />
          )}
        </div>
      </div>

      <div className="p-4" />

      <div className="flex flex-col gap-4">
        <TransactionInfo transaction={transaction} />

        <PaymentDetails transaction={transaction} />

        {transaction ? (
          <TransactionAmountDetails transaction={transaction} />
        ) : (
          <>
            <Details.Loader />
            <div className="p-2" />
            <Details.Loader />
          </>
        )}

        {transaction ? (
          <Card className="flex flex-col gap-4" size="medium">
            <TrackingInformation transaction={transaction} />
            <Details>
              <Details.Label>
                <FormattedMessage id="label.date" defaultMessage="Date" />
              </Details.Label>
              <Details.Value>
                {formatDate(transaction.createdOn, `d MMM. yyyy, HH:mm`)} (
                {getBrowserTimeZone()})
              </Details.Value>
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage id="label.status" defaultMessage="Status" />
              </Details.Label>
              <StateCell transaction={transaction} />
            </Details>
            {additionalDetails?.taskOwnerFullName ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.requestedBy"
                    defaultMessage="Requested by"
                  />
                </Details.Label>
                <Details.Value>
                  {additionalDetails.taskOwnerFullName}
                </Details.Value>
              </Details>
            ) : null}
            {additionalDetails?.completedByFullName ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    id="label.approvedBy"
                    defaultMessage="Approved by"
                  />
                </Details.Label>
                <Details.Value>
                  {additionalDetails.completedByFullName}
                </Details.Value>
              </Details>
            ) : null}
          </Card>
        ) : (
          <Details.Loader rows={2} />
        )}

        {transaction?.transactionType === TransactionType.CASHBACK ? (
          <ShortCashbackTransactionsTable
            isPending={cashbackTransactionQuery.isFetching}
            transactionId={transaction?.id}
            transactions={(
              cashbackTransactionQuery.data?.eligibleTransactions ?? []
            ).slice(0, 3)}
          />
        ) : null}
      </div>
    </>
  )
}
