import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router'

import { handleURLSearchParams } from '@/lib/utils'
import { Playlist } from '@/shared/icons/outline'
import { Filter } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/shared/ui'

import { TaskType } from '../../features/Tasks/types'

import { TYPE_FILTER_NAME } from './data'

export const TasksTypeFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSearchParams(value: string) {
    return (
      setSearchParams((params) =>
        handleURLSearchParams(params, TYPE_FILTER_NAME, value),
      ),
      { preventScrollReset: true }
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <Playlist className="size-4" />
              <FormattedMessage defaultMessage="Type" id="label.type" />
            </span>
            {searchParams.get(TYPE_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(TYPE_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(TYPE_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>

            {Object.values(TaskType).map((type) => (
              <DropdownMenuCheckboxItem
                key={type}
                onSelect={(event) => event.preventDefault()}
                checked={searchParams
                  .get(TYPE_FILTER_NAME)
                  ?.split(',')
                  ?.includes(type)}
                onCheckedChange={() => handleSearchParams(type)}
              >
                <FormattedMessage
                  id="filter.tasks.types.label"
                  defaultMessage="{type, select, ADD_BENEFICIARY {Recipients} EXECUTE_WITHDRAWAL {Withdrawals} EXECUTE_BULK_PAYMENT {Bulk payments} other {}}"
                  values={{ type }}
                />
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
