import { countryCodeToCountryData } from '@/constants/countries'
import { CountryCode } from '@/types/country'

export function getFlagUrl(name: string) {
  return new URL(`../assets/images/flags/${name}.png`, import.meta.url).href
}

export function getFlagUrlByCountryCode(countryCode: CountryCode) {
  return getFlagUrl(countryCodeToCountryData[countryCode].flagCode)
}

export function getImage({
  category = 'shared',
  format = 'webp',
  name,
}: {
  category?: string
  format?: string
  name: string
}) {
  return new URL(
    `../assets/images/${category}/${name}.${format}`,
    import.meta.url,
  ).href
}
