import { useEffect, useState } from 'react'

import { Sheet, SheetContent } from '@/shared/ui'

import { Recipient } from '../types'

import { BaseRecipientScreen } from './BaseRecipientScreen'
import { EditRecipient } from './EditRecipient'

type ScreenType = 'base' | 'edit'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  recipient?: Recipient
}

export const RecipientDetailsSidebar = ({
  isOpen,
  onOpenChange,
  recipient,
}: Props) => {
  const [screen, setScreen] = useState<ScreenType>('base')

  useEffect(() => {
    if (!isOpen) {
      setScreen('base')
    }
  }, [isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {(() => {
          switch (screen) {
            case 'base':
              return (
                <BaseRecipientScreen
                  recipient={recipient}
                  setScreen={setScreen}
                />
              )

            case 'edit':
              return (
                <EditRecipient
                  recipient={recipient}
                  setScreen={setScreen}
                  onClose={() => onOpenChange(false)}
                />
              )

            default:
              return null
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
