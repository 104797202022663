import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBuilding = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="building_svg__a"
      width={20}
      height={20}
      x={1}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M1 1h20v20H1z" />
    </mask>
    <g mask="url(#building_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M3.5 18.501V6.834h3.334V3.501h8.333v6.667H18.5V18.5h-6.666v-3.333h-1.667V18.5zm1.667-1.667h1.667v-1.666H5.167zm0-3.333h1.667v-1.667H5.167zm0-3.333h1.667V8.5H5.167zM8.5 13.5h1.667v-1.667H8.5zm0-3.333h1.667V8.5H8.5zm0-3.334h1.667V5.168H8.5zm3.334 6.667H13.5v-1.667h-1.667zm0-3.333H13.5V8.5h-1.667zm0-3.334H13.5V5.168h-1.667zm3.333 10h1.667v-1.666h-1.667zm0-3.333h1.667v-1.667h-1.667z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgBuilding)
export default ForwardRef
