import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { AccountDetails } from './AccountDetails'
import { Accounts } from './Accounts'
import { AccountTransactions } from './AccountTransactions'
import { CreateAccount } from './CreateAccount'

export const accountsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Accounts,
    element: <Accounts />,
  },
  {
    path: BusinessRoute.SingleAccount,
    element: <AccountDetails />,
  },
  {
    path: BusinessRoute.AccountTransactions,
    element: <AccountTransactions />,
  },
]

export const accountActionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.CreateAccount,
    element: <CreateAccount />,
  },
]
