import { motion } from 'framer-motion'
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'
import { useIntercom } from 'react-use-intercom'

import { ChevronRight } from '@/shared/icons/outline'
import {
  Badge,
  Button,
  Card,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  slideInFromBottomAnimation,
  Typography,
} from '@/shared/ui'
import { TwoFactorMethod } from '@/types/auth'

type AvailableMethod = {
  title: MessageDescriptor
  description: MessageDescriptor
  label?: boolean
  type: TwoFactorMethod
}

const availableMethods: AvailableMethod[] = [
  {
    title: defineMessage({
      id: '2fa.method.app.title',
      defaultMessage: 'Authenticator app',
    }),
    description: defineMessage({
      id: '2fa.method.app.description',
      defaultMessage: 'e.g. Google Authenticator, Duo, Okta, 1Password',
    }),
    label: true,
    type: TwoFactorMethod.AUTHENTICATOR_APP,
  },
  {
    title: defineMessage({
      id: '2fa.method.email.title',
      defaultMessage: 'Email',
    }),
    description: defineMessage({
      id: '2fa.method.email.description',
      defaultMessage: 'Verify with a code sent to your email',
    }),
    label: false,
    type: TwoFactorMethod.EMAIL,
  },
]

type Props = {
  onReset: () => void
  onMethodChange: (method: 'APP' | 'EMAIL' | 'METHOD' | 'RESET') => void
  onSelect: (method: TwoFactorMethod) => void
}

export const MethodDialog = ({ onReset, onMethodChange, onSelect }: Props) => {
  const { boot, showNewMessage } = useIntercom()

  return (
    <motion.div
      variants={slideInFromBottomAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <DialogHeader>
        <DialogTitle>
          <FormattedMessage
            defaultMessage="Verify with another method"
            id="2fa.dialog.verifyWithAnotherMethod.title"
          />
        </DialogTitle>

        <DialogDescription>
          <FormattedMessage
            defaultMessage="Select from the following options"
            id="2fa.dialog.verifyWithAnotherMethod.subtitle"
          />
        </DialogDescription>
      </DialogHeader>

      <div className="p-3" />

      <div className="flex flex-col gap-4">
        {availableMethods.map((method) => {
          return (
            <Card
              key={method.title.id}
              size="upload"
              className="flex w-full items-center justify-between bg-neutral-gray-50"
              asChild
              scalable
            >
              <Button
                size="inline"
                variant="ghost"
                onClick={() => {
                  onSelect(method.type)

                  switch (method.type) {
                    case TwoFactorMethod.AUTHENTICATOR_APP:
                      onMethodChange('APP')
                      break

                    case TwoFactorMethod.EMAIL:
                      onMethodChange('EMAIL')
                      break

                    default:
                      break
                  }
                }}
              >
                <div className="flex flex-col">
                  <div className="flex gap-2">
                    <Typography bold>
                      <FormattedMessage {...method.title} />
                    </Typography>
                    {method.label && (
                      <Badge
                        className="font-bold capitalize"
                        variant="dark-neutral"
                      >
                        <FormattedMessage
                          id="label.recommended"
                          defaultMessage="Recommended"
                        />
                      </Badge>
                    )}
                  </div>
                  <Typography
                    variant="body-small"
                    className="whitespace-break-spaces text-neutral-gray-600"
                  >
                    <FormattedMessage {...method.description} />
                  </Typography>
                </div>

                <ChevronRight className="size-8 shrink-0 text-neutral-gray-600" />
              </Button>
            </Card>
          )
        })}
      </div>

      <div className="p-3" />

      <Typography
        variant="body-small"
        text="center"
        className="text-neutral-gray-600"
      >
        <FormattedMessage
          defaultMessage="Need help? {resetApp} or {chatWithSupport}"
          id="2fa.dialog.needHelp.resetOrChat"
          values={{
            resetApp: (
              <Button
                variant="link"
                size="inline"
                className="text-xs"
                onClick={onReset}
              >
                <FormattedMessage
                  defaultMessage="Reset Authentication App"
                  id="2fa.dialog.action.resetApp"
                />
              </Button>
            ),
            chatWithSupport: (
              <Button
                variant="link"
                size="inline"
                className="text-xs"
                onClick={() => {
                  boot({
                    customAttributes: { user_type: 'BUSINESS' },
                  })
                  showNewMessage()
                }}
              >
                <FormattedMessage
                  defaultMessage="Chat with support"
                  id="2fa.dialog.action.chatWithSupport"
                />
              </Button>
            ),
          }}
        />
      </Typography>
    </motion.div>
  )
}
