import { FormattedMessage, useIntl } from 'react-intl'

import { ContractorPaymentMethodType } from '@/domains/Contractor/types'
import { getOnboardingAddress } from '@/lib/address'
import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { ContractorOnboardingState } from '../types'

type Props = {
  contractorState?: ContractorOnboardingState
}

export const PaymentInformationReview = ({ contractorState }: Props) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="label.paymentMethodInformation"
          defaultMessage="Payment method information"
        />
      }
    >
      {(() => {
        switch (contractorState?.paymentMethodDetails?.type) {
          case ContractorPaymentMethodType.US_BANK_ACCOUNT:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Account number"
                      id="label.accountNumber"
                    />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractorState?.paymentMethodDetails
                        ?.paymentMethodInformation.accountNumber
                    }
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="ABA / Routing number"
                      id="label.routingNumber"
                    />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractorState?.paymentMethodDetails
                        ?.paymentMethodInformation.routingNumber
                    }
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Address"
                      id="label.address"
                    />
                  </Details.Label>
                  <Details.Value className="whitespace-break-spaces text-right">
                    {getOnboardingAddress(
                      contractorState?.paymentMethodDetails
                        .paymentMethodInformation.address,
                      intl,
                    )}
                  </Details.Value>
                </Details>
              </>
            )

          case ContractorPaymentMethodType.DOLAR_TAG:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="DolarTag"
                      id="label.dolarTag"
                    />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractorState?.paymentMethodDetails
                        ?.paymentMethodInformation.dolarTag
                    }
                  </Details.Value>
                </Details>
              </>
            )

          case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractorState?.paymentMethodDetails
                        .paymentMethodInformation.clabe
                    }
                  </Details.Value>
                </Details>
              </>
            )

          default:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Payment method"
                      id="label.paymentMethod"
                    />
                  </Details.Label>
                  <Details.Value>-</Details.Value>
                </Details>
              </>
            )
        }
      })()}
    </Widget>
  )
}
