import { cn } from '@/lib/utils'
import { Avatar, AvatarSize, AvatarVariant } from '@/shared/ui'

import { PaymentRails } from '../constants'
import { Recipient } from '../features/Recipients/types'
import { getIconByEntityType } from '../features/Recipients/utils'

type Props = {
  recipient?: Recipient
  size?: AvatarSize
  variant?: AvatarVariant
}

export const RecipientAvatar = ({ recipient, size, variant }: Props) => {
  const isDolarAppRecipient = recipient?.paymentRails === PaymentRails.DOLAR_APP

  const Icon = getIconByEntityType(recipient?.entityType)

  const avatarVariant = isDolarAppRecipient ? 'primary' : variant

  return (
    <Avatar
      variant={avatarVariant}
      size={size}
      icon={
        <Icon
          className={cn('size-5', {
            'size-8': size === 'xl',
            'size-3': size === 'sm',
            'text-primary': avatarVariant === 'primary',
          })}
        />
      }
    />
  )
}
