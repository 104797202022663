import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { Role } from '@/domains/Business/types/roles'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { CardType } from '../Cards/types'

import {
  MoneyMovementSchema,
  MoneyMovementStep,
  TeamCardAddressSchema,
  TeamCardAddressStep,
  TeamMemberDetailsStep,
  TeamMemberReviewStep,
  TeamMemberSchema,
  TeamMemberTypeStep,
  TeamPhysicalCardDetailsSchema,
  TeamPhysicalCardDetailsStep,
  TeamSelectCardStep,
  TeamVirtualCardDetailsSchema,
  TeamVirtualCardDetailsStep,
} from './components/AddTeamMemberSteps'

enum Step {
  SelectType = 'selectType',
  Details = 'details',
  MoneyMovement = 'moneyMovement',
  CardType = 'cardType',
  VirtualCardDetails = 'virtualCardDetails',
  PhysicalCardDetails = 'physicalCardDetails',
  CardAddress = 'cardAddress',
  CardReview = 'cardReview',
  Review = 'review',
}

type State =
  | { type: Step.SelectType }
  | { type: Step.Details }
  | { type: Step.Review }
  | { type: Step.MoneyMovement }
  | { type: Step.CardType }
  | { type: Step.CardAddress }
  | { type: Step.VirtualCardDetails }
  | { type: Step.PhysicalCardDetails }

export const AddTeamMember = () => {
  const location = useLocation()

  const [role, setRole] = useState<Role>()
  const [cardType, setCardType] = useState<CardType>()
  const [teamVirtualCardDetails, setTeamVirtualCardDetails] =
    useState<TeamVirtualCardDetailsSchema>()
  const [teamPhysicalCardDetails, setTeamPhysicalCardDetails] =
    useState<TeamPhysicalCardDetailsSchema>()
  const [teamMemberDetails, setTeamMemberDetails] = useState<TeamMemberSchema>()
  const [moneyMovement, setMoneyMovementDetails] =
    useState<MoneyMovementSchema>()
  const [teamCardAddressDetails, setTeamCardAddressDetails] =
    useState<TeamCardAddressSchema>()

  const [activeStep, setActiveStep] = useState<State>({
    type: Step.SelectType,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.SelectType:
        return 25
      case Step.Details:
        return role === Role.ADMIN ? 75 : 50
      case Step.MoneyMovement:
        return 75
      case Step.Review:
        return 100
    }
  }, [activeStep.type, role])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Team}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.SelectType ? (
          <TeamMemberTypeStep
            onContinue={(role) => {
              setRole(role)

              setActiveStep({ type: Step.Details })
            }}
          />
        ) : null}

        {activeStep.type === Step.Details ? (
          <TeamMemberDetailsStep
            teamMemberDetails={teamMemberDetails}
            onBack={() => {
              setActiveStep({ type: Step.SelectType })

              setTeamMemberDetails(undefined)
              setMoneyMovementDetails(undefined)
              setCardType(undefined)
              setTeamVirtualCardDetails(undefined)
              setTeamCardAddressDetails(undefined)
            }}
            role={role}
            onContinue={(details) => {
              setTeamMemberDetails(details)

              switch (role) {
                case Role.ADMIN:
                case Role.READ_ONLY:
                  setActiveStep({ type: Step.Review })
                  break

                case Role.PAYMENT_OPS:
                  setActiveStep({ type: Step.MoneyMovement })
                  break

                case Role.CARD_USER:
                  setActiveStep({ type: Step.CardType })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === Step.MoneyMovement ? (
          <MoneyMovementStep
            moneyMovement={moneyMovement}
            onBack={() => {
              setActiveStep({ type: Step.Details })
            }}
            teamMemberDetails={teamMemberDetails}
            onContinue={(data) => {
              setMoneyMovementDetails(data)

              setActiveStep({ type: Step.CardType })
            }}
          />
        ) : null}

        {activeStep.type === Step.CardType ? (
          <TeamSelectCardStep
            role={role}
            onBack={() => {
              switch (role) {
                case Role.ADMIN:
                case Role.READ_ONLY:
                  break

                case Role.PAYMENT_OPS:
                  setActiveStep({ type: Step.MoneyMovement })
                  break

                case Role.CARD_USER:
                  setActiveStep({ type: Step.Details })
                  break

                default:
                  break
              }
            }}
            teamMemberDetails={teamMemberDetails}
            onContinue={(cardType) => {
              setCardType(cardType)
              switch (cardType) {
                case CardType.VIRTUAL:
                  setActiveStep({ type: Step.VirtualCardDetails })
                  break

                case CardType.PHYSICAL:
                  setActiveStep({ type: Step.PhysicalCardDetails })
                  break

                default:
                  break
              }
            }}
            onSkip={
              role !== Role.CARD_USER
                ? () => {
                    setTeamVirtualCardDetails(undefined)
                    setTeamCardAddressDetails(undefined)

                    setActiveStep({ type: Step.Review })
                  }
                : undefined
            }
          />
        ) : null}

        {activeStep.type === Step.VirtualCardDetails ? (
          <TeamVirtualCardDetailsStep
            teamCardDetails={teamVirtualCardDetails}
            teamMemberDetails={teamMemberDetails}
            onBack={() => {
              setTeamVirtualCardDetails(undefined)
              setActiveStep({ type: Step.CardType })
            }}
            onContinue={(data) => {
              setTeamVirtualCardDetails(data)

              setActiveStep({ type: Step.Review })
            }}
          />
        ) : null}

        {activeStep.type === Step.PhysicalCardDetails ? (
          <TeamPhysicalCardDetailsStep
            teamCardDetails={teamPhysicalCardDetails}
            teamMemberDetails={teamMemberDetails}
            onBack={() => {
              setTeamPhysicalCardDetails(undefined)
              setActiveStep({ type: Step.CardType })
            }}
            onContinue={(data) => {
              setTeamPhysicalCardDetails(data)

              setActiveStep({ type: Step.CardAddress })
            }}
          />
        ) : null}

        {activeStep.type === Step.CardAddress ? (
          <TeamCardAddressStep
            teamCardAddressDetails={teamCardAddressDetails}
            onBack={() => {
              setTeamCardAddressDetails(undefined)
              setActiveStep({ type: Step.PhysicalCardDetails })
            }}
            onContinue={(data) => {
              setTeamCardAddressDetails(data)

              setActiveStep({ type: Step.Review })
            }}
          />
        ) : null}

        {activeStep.type === Step.Review ? (
          <TeamMemberReviewStep
            onBack={() => {
              switch (role) {
                case Role.ADMIN:
                  setActiveStep({ type: Step.Details })
                  break

                case Role.PAYMENT_OPS:
                  switch (cardType) {
                    case CardType.VIRTUAL:
                      setActiveStep({ type: Step.VirtualCardDetails })
                      break
                    case CardType.PHYSICAL:
                      setActiveStep({ type: Step.CardAddress })
                      break
                    default:
                      setActiveStep({ type: Step.CardType })
                      break
                  }
                  break

                case Role.CARD_USER:
                  switch (cardType) {
                    case CardType.VIRTUAL:
                      setActiveStep({ type: Step.VirtualCardDetails })
                      break
                    case CardType.PHYSICAL:
                      setActiveStep({ type: Step.CardAddress })
                      break
                    default:
                      setActiveStep({ type: Step.CardType })
                      break
                  }
                  break

                case Role.READ_ONLY:
                  setActiveStep({ type: Step.Details })
                  break

                default:
                  break
              }
            }}
            cardType={cardType}
            role={role}
            teamMemberDetails={teamMemberDetails}
            moneyMovement={moneyMovement}
            teamCardAddressDetails={teamCardAddressDetails}
            teamVirtualCardDetails={teamVirtualCardDetails}
            teamPhysicalCardDetails={teamPhysicalCardDetails}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
