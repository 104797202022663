import { Navigate, RouteObject } from 'react-router'

import { BaseRoute } from '@/constants/paths'

import { BusinessLoader } from './BusinessLoader'
import { dashboardRoutes } from './Dashboard'
import { App2FAInterceptor } from './Dashboard/App2FAInterceptor'
import { RoleChecker } from './Dashboard/RoleChecker'
import { onboardingRoutes } from './Onboarding'

export const businessRoutes: RouteObject[] = [
  {
    element: <App2FAInterceptor />,
    children: [
      {
        path: BaseRoute.Root,
        element: <Navigate to={BaseRoute.SigningIn} />,
      },
      {
        path: BaseRoute.SigningIn,
        element: <RoleChecker />,
      },
      {
        path: BaseRoute.LoggingOut,
        element: <BusinessLoader />,
      },
      ...dashboardRoutes,
      ...onboardingRoutes,
    ],
  },
]
