import { zodResolver } from '@hookform/resolvers/zod'
import { AnimatePresence, LayoutGroup } from 'framer-motion'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { SCOPE_OF_WORK_DESCRIPTION_PER_ROLE } from '@/constants/messages'
import { AmountInput, GoBackButton, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  DatePickerInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MotionFormItem,
  SlideInScreen,
  StickyContainer,
  Textarea,
  Typography,
} from '@/shared/ui'

import {
  ContractorFirstCycleMethod,
  ContractorPaymentFrequency,
  ContractPaymentDayType,
} from '../../../types'
import { CustomDaySelect } from '../CustomDaySelect'
import { FirstCycleMethodSelect } from '../FirstCycleMethodSelect'
import { PaymentDaySelect } from '../PaymentDaySelect'
import { PaymentFrequencySelect } from '../PaymentFrequencySelect'
import { ScopeOfWorkField } from '../ScopeOfWorkField'

import { fixedRateContractSchema, FixedRateContractSchema } from './schema'

const FIXED_RATE_CONTRACT_FORM = 'fixed-rate-contract-form-id'

type Props = {
  fixedRateContract?: FixedRateContractSchema
  onBack: () => void
  onContinue: (data: FixedRateContractSchema) => void
}

export const FixedRateContractStep = ({
  fixedRateContract,
  onBack,
  onContinue,
}: Props) => {
  const intl = useIntl()

  const form = useForm<FixedRateContractSchema>({
    mode: 'onChange',
    resolver: zodResolver(fixedRateContractSchema),
    values: {
      contractorRole: fixedRateContract?.contractorRole ?? '',
      scopeOfWork: fixedRateContract?.scopeOfWork ?? '',
      scopeOfWorkRole: fixedRateContract?.scopeOfWorkRole ?? '',
      startDate: fixedRateContract?.startDate ?? '',
      paymentDetails: {
        amount: fixedRateContract?.paymentDetails?.amount ?? '',
        currency: fixedRateContract?.paymentDetails?.currency ?? Currency.USD,
        frequency:
          fixedRateContract?.paymentDetails?.frequency ??
          ('' as ContractorPaymentFrequency),
        dayType:
          fixedRateContract?.paymentDetails?.dayType ??
          ('' as ContractPaymentDayType),
        day: fixedRateContract?.paymentDetails?.day ?? '',
        firstCycleMethod:
          fixedRateContract?.paymentDetails?.firstCycleMethod ??
          ('' as ContractorFirstCycleMethod),
        firstCycleAmount:
          fixedRateContract?.paymentDetails?.firstCycleAmount ?? '',
      },
    },
  })

  const onSubmit: SubmitHandler<FixedRateContractSchema> = (data) => {
    onContinue(data)
  }

  const customDayTypes = [
    ContractPaymentDayType.CUSTOM_DAY_OF_WEEK,
    ContractPaymentDayType.CUSTOM_DAY_OF_MONTH,
  ]

  const showCustomAmountField =
    form.watch('paymentDetails.firstCycleMethod') ===
    ContractorFirstCycleMethod.CUSTOM_AMOUNT

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.create.contact.title"
            defaultMessage="Contract details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.create.contact.fixedRate.subtitle"
            defaultMessage="Define the job and specify how much and when the contractor gets paid"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={FIXED_RATE_CONTRACT_FORM}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-8">
              <Widget
                title={
                  <FormattedMessage
                    defaultMessage="Job details"
                    id="label.jobDetails"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="contractorRole"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Role',
                            id: 'label.role',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Role"
                          id="label.role"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="scopeOfWorkRole"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <ScopeOfWorkField
                          value={field.value}
                          onSelect={(value) => {
                            field.onChange(value)

                            if (SCOPE_OF_WORK_DESCRIPTION_PER_ROLE[value]) {
                              const scopeOfWork = (
                                SCOPE_OF_WORK_DESCRIPTION_PER_ROLE[value]
                                  .defaultMessage ?? ''
                              ).toString()

                              form.setValue('scopeOfWork', scopeOfWork)
                            }
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="scopeOfWork"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          className="relative"
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Scope of work',
                            id: 'label.scopeOfWork',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel size="textarea">
                        <FormattedMessage
                          defaultMessage="Scope of work"
                          id="label.scopeOfWork"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field }) => (
                    <FormItem>
                      <DatePickerInput
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Start date',
                          id: 'label.startDate',
                        })}
                        {...field}
                      />
                    </FormItem>
                  )}
                />
              </Widget>

              <Widget
                title={
                  <FormattedMessage
                    defaultMessage="Payment details"
                    id="label.paymentDetails"
                  />
                }
                variant="form"
              >
                <FormField
                  control={form.control}
                  name="paymentDetails.amount"
                  render={({ field }) => (
                    <FormItem>
                      <AmountInput
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Payment amount',
                          id: 'label.paymentAmount',
                        })}
                        {...field}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentDetails.frequency"
                  render={({ field }) => (
                    <FormItem>
                      <PaymentFrequencySelect
                        value={field.value}
                        onChange={(v) => {
                          form.resetField('paymentDetails.dayType')
                          form.resetField('paymentDetails.day')

                          field.onChange(v)
                        }}
                      />
                    </FormItem>
                  )}
                />

                <LayoutGroup>
                  <FormField
                    control={form.control}
                    name="paymentDetails.dayType"
                    render={({ field }) => (
                      <MotionFormItem layout>
                        <PaymentDaySelect
                          frequency={form.watch('paymentDetails.frequency')}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </MotionFormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="paymentDetails.day"
                    render={({ field }) =>
                      customDayTypes.includes(
                        form.watch('paymentDetails.dayType'),
                      ) ? (
                        <MotionFormItem layout>
                          <CustomDaySelect
                            paymentDayType={form.watch(
                              'paymentDetails.dayType',
                            )}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </MotionFormItem>
                      ) : (
                        <></>
                      )
                    }
                  />
                </LayoutGroup>

                <LayoutGroup>
                  <FormField
                    control={form.control}
                    name="paymentDetails.firstCycleMethod"
                    render={({ field }) => (
                      <MotionFormItem layout>
                        <FirstCycleMethodSelect
                          formValues={form.watch()}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </MotionFormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="paymentDetails.firstCycleAmount"
                    render={({ field }) => (
                      <AnimatePresence>
                        {showCustomAmountField ? (
                          <MotionFormItem
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                            layout
                          >
                            <AmountInput
                              placeholder={intl.formatMessage({
                                defaultMessage:
                                  'Custom first cycle payment amount',
                                id: 'label.customFirstCyclePaymentAmount',
                              })}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          </MotionFormItem>
                        ) : null}
                      </AnimatePresence>
                    )}
                  />
                </LayoutGroup>
              </Widget>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={FIXED_RATE_CONTRACT_FORM}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
