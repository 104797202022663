import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Outlet, useLocation, useNavigate } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@/shared/ui'

import { MoveMoneyWidget } from '../../components'

enum Tab {
  COMPANY = 'company',
}

export const Settings = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultTab = useMemo(() => {
    if (pathname.includes(BusinessRoute.CompanySettings)) {
      return Tab.COMPANY
    }

    return Tab.COMPANY
  }, [pathname])

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.settings.title"
              defaultMessage="Settings"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.settings.subtitle"
              defaultMessage="Manage your preference and configure your account"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <Tabs
        onValueChange={(tab) => {
          switch (tab) {
            case Tab.COMPANY: {
              navigate(BusinessRoute.CompanySettings)
              break
            }
          }
        }}
        defaultValue={defaultTab}
      >
        <TabsList variant="navigation" className="mb-8 gap-4">
          <TabsTrigger size="inline" variant="navigation" value={Tab.COMPANY}>
            <FormattedMessage
              id="settings.tabs.company"
              defaultMessage="Company"
            />
          </TabsTrigger>
        </TabsList>

        <TabsContent value={Tab.COMPANY}>
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  )
}
