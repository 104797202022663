import { use2FAResetStorage } from '@/store'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { QRCode } from 'react-qrcode-logo'
import { useLocation, useNavigate } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { toast } from 'sonner'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getImage } from '@/lib/images'
import { queryClient } from '@/lib/queryClient'
import { CodeInput, ProgressButton } from '@/shared/components'
import { Copy, DoubleTick, Mobile, Scan } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FullScreen,
  MotionDiv,
  Skeleton,
  SlideInScreen,
  Spinner,
  Typography,
} from '@/shared/ui'

import { getContractor2FASetupInfo, setupContractor2FA } from '../api'

const verificationSchema = z.object({
  otp: z.string().min(1, { message: 'validation.otp.required' }).length(6, {
    message: 'validation.otp.length',
  }),
})

type VerificationSchema = z.infer<typeof verificationSchema>

export const ContractorSetup2FAScreen = () => {
  const intl = useIntl()
  const location = useLocation()
  const copyTextToClipboard = useCopyToClipboard()
  const { showArticle } = useIntercom()
  const navigate = useNavigate()
  const notifyError = useErrorToast()

  const reset2FA = use2FAResetStorage((state) => state.reset2FA)
  const disable2FAReset = use2FAResetStorage((state) => state.disable2FAReset)

  const setup2FAInfoQuery = useQuery({
    queryKey: [queryKeys.getContractor2FASetupInfo, reset2FA],
    queryFn: () => getContractor2FASetupInfo({ reset: reset2FA }),
    select: (data) => data?.data,
  })

  const form = useForm<VerificationSchema>({
    mode: 'onChange',
    resolver: zodResolver(verificationSchema),
    defaultValues: { otp: '' },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: setupContractor2FA,
  })

  const onSubmit: SubmitHandler<VerificationSchema> = async (data) => {
    try {
      await mutateAsync({
        method: 'AUTHENTICATOR_APP',
        code: data.otp,
      })

      disable2FAReset()

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractor2FAMethod],
      })

      toast.success(
        intl.formatMessage({
          id: 'setup2fa.success',
          defaultMessage: 'Two-factor authentication enabled successfully',
        }),
      )

      navigate(location.state?.from ?? ContractorRoute.Overview)
    } catch (error) {
      notifyError(error)
    }
  }

  return (
    <FullScreen>
      <ProgressButton
        progress={100}
        to={location.state?.from ?? ContractorRoute.Overview}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Turn on two-factor authentication"
            id="setup2fa.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="setup2fa.subtitle"
            defaultMessage="Secure access using a two-factor authentication app"
          />
        </Typography>

        <div className="p-6" />

        <Card className="mx-auto flex flex-col gap-4" size="upload">
          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2">
              <Mobile className="size-4" />

              <Typography bold>
                <FormattedMessage
                  id="setup2fa.step.downloadApp.title"
                  defaultMessage="Download an authentication app"
                />
              </Typography>
            </div>

            <Typography className="px-6 text-neutral-gray-600">
              <FormattedMessage
                id="setup2fa.step.scanQRCode.description"
                defaultMessage="Download an authentication app on your mobile phone. {whatAppToUse}"
                values={{
                  whatAppToUse: (
                    <Button
                      onClick={() => showArticle(10205828)}
                      variant="link"
                      className="text-neutral-gray-900"
                      size="inline"
                    >
                      <FormattedMessage
                        id="setup2fa.step.scanQRCode.whatAppToUse"
                        defaultMessage="Not sure what app to use?"
                      />
                    </Button>
                  ),
                }}
              />
            </Typography>
          </div>

          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2">
              <Scan className="size-4" />

              <Typography bold>
                <FormattedMessage
                  id="setup2fa.step.scanQRCode.title"
                  defaultMessage="Scan the QR code"
                />
              </Typography>
            </div>

            <Typography className="px-6 text-neutral-gray-600">
              <FormattedMessage
                id="setup2fa.step.scanTheQRCode.description"
                defaultMessage="Scan the QR code using the authentication app or manually type the secret key"
              />
            </Typography>
          </div>

          <div className="flex flex-col items-center gap-3">
            <div className="mx-auto w-fit rounded-3xl border p-2">
              {setup2FAInfoQuery.data ? (
                <MotionDiv>
                  <QRCode
                    eyeRadius={[
                      [8, 8, 0, 8],
                      [8, 8, 8, 0],
                      [8, 0, 8, 8],
                    ]}
                    logoImage={getImage({ name: 'dolarapp-logo-black' })}
                    logoWidth={48}
                    logoPaddingStyle="square"
                    removeQrCodeBehindLogo
                    qrStyle="dots"
                    quietZone={0}
                    size={180}
                    value={setup2FAInfoQuery.data?.qrCodeUrl}
                  />
                </MotionDiv>
              ) : (
                <div className="flex size-[180px] items-center justify-center">
                  <Spinner className="size-12" />
                </div>
              )}
            </div>

            {setup2FAInfoQuery.data ? (
              <Button
                className="text-neutral-gray-600 hover:underline"
                variant="ghost"
                size="inline"
                rightIcon={<Copy className="size-4 text-neutral-gray-600" />}
                onClick={() =>
                  copyTextToClipboard(setup2FAInfoQuery.data.plainTextSecret)
                }
              >
                {setup2FAInfoQuery.data.plainTextSecret}
              </Button>
            ) : (
              <Skeleton className="h-[21px] w-36" />
            )}
          </div>

          <div className="flex items-center">
            <div className="flex-grow border-t border-neutral-gray-100" />
            <span className="mx-2 text-sm uppercase text-neutral-gray-400">
              <FormattedMessage id="label.then" defaultMessage="Then" />
            </span>
            <div className="flex-grow border-t border-neutral-gray-100" />
          </div>

          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2">
              <DoubleTick className="size-4" />

              <Typography bold>
                <FormattedMessage
                  id="setup2fa.step.enterCode.title"
                  defaultMessage="Enter 6-digit code"
                />
              </Typography>
            </div>

            <Typography className="pl-6 text-neutral-gray-600">
              <FormattedMessage
                id="setup2fa.step.enterCode.description"
                defaultMessage="Enter the 6-digit code generated by your authentication app"
              />
            </Typography>
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex items-center gap-3 px-6">
                <FormField
                  control={form.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <CodeInput autoFocus {...field} />
                      </FormControl>
                      <AnimatedFormLabel size="2fa">
                        <FormattedMessage
                          defaultMessage="6-digit code"
                          id="label.sixDigitCode"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
                <Button
                  loading={isPending}
                  size="xl"
                  disabled={!form.formState.isValid || isPending}
                  type="submit"
                >
                  <FormattedMessage
                    id="action.confirm"
                    defaultMessage="Confirm"
                  />
                </Button>
              </div>
            </form>
          </Form>
        </Card>

        <div className="p-12" />
      </SlideInScreen>
    </FullScreen>
  )
}
