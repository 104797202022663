import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getBusinessUser } from '@/domains/Business/api'
import { getFullName } from '@/lib/typography'
import { Avatar, Badge, Typography } from '@/shared/ui'

import { TeamMember } from '../../types'

type Props = {
  member: TeamMember
}

export const TeamNameCell = ({ member }: Props) => {
  const businessUserQuery = useQuery({
    queryKey: [queryKeys.getBusinessUser],
    queryFn: getBusinessUser,
    select: (data) => data.data,
  })

  const isUser = businessUserQuery.data?.email === member.email

  return (
    <div className="flex">
      <div className="flex items-center gap-3">
        <Avatar name={getFullName(member)} />
        <div className="flex flex-col">
          <div className="flex gap-1">
            <Typography bold>{getFullName(member)}</Typography>
            {isUser && (
              <Badge className="hidden capitalize md:flex" variant="neutral">
                <Typography bold variant="body-small">
                  <FormattedMessage id="label.you" defaultMessage="You" />
                </Typography>
              </Badge>
            )}
          </div>
          <Typography className="text-neutral-gray-600">
            {member.userTitle ?? member.email}
          </Typography>
        </div>
      </div>
    </div>
  )
}
