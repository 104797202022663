import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, To } from 'react-router'

import { getImage } from '@/lib/images'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Skeleton, Typography } from '@/shared/ui'

import { SingleTransaction } from '../../features/Transactions/types'

import { TransactionShortListRow } from './TransactionShortListRow'

type Props = {
  transactions: SingleTransaction[]
  limit?: number
  path: To
  isPending?: boolean
}

export const TransactionsShortList = ({
  transactions,
  limit = 5,
  path,
  isPending,
}: Props) => {
  const slicedTransactions = transactions.slice(0, limit)

  if (isPending) {
    return (
      <>
        <div className="p-3" />
        <div className="flex flex-col">
          <div className="flex justify-between">
            <Skeleton className="h-6 w-36" />
            <Skeleton className="h-6 w-36" />
          </div>

          <div className="p-1" />

          <Card size="large" className="flex flex-col gap-6">
            <div className="flex justify-between">
              <Skeleton className="h-6 w-36" />
              <Skeleton className="h-6 w-36" />
            </div>
            <div className="flex justify-between">
              <Skeleton className="h-6 w-36" />
              <Skeleton className="h-6 w-36" />
            </div>
          </Card>
        </div>
      </>
    )
  }

  if (slicedTransactions.length === 0) {
    return (
      <Card
        className="relative flex w-full items-center justify-between overflow-hidden bg-white"
        size="large"
      >
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="cards.search.noTransactions"
              defaultMessage="You don't have any transactions yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="cards.search.noTransactions.description"
              defaultMessage="Start spending and see your transactions here"
            />
          </Typography>
        </div>
        <img
          className="absolute -bottom-4 right-8 hidden w-40 md:flex"
          src={getImage({
            name: 'empty-transactions',
            category: 'transactions',
          })}
          alt=""
          aria-hidden
        />
      </Card>
    )
  }

  return (
    <>
      <div className="flex justify-between px-4">
        <Typography bold>
          <FormattedMessage
            id="label.transaction"
            defaultMessage="Transaction"
          />
        </Typography>
        <Typography bold>
          <FormattedMessage id="label.amount" defaultMessage="Amount" />
        </Typography>
      </div>

      <div className="p-1" />

      <Card className="flex flex-col gap-3" size="medium">
        {slicedTransactions.map((tx, index) => (
          <Fragment key={tx.id}>
            <TransactionShortListRow transaction={tx} />

            {index !== slicedTransactions.length - 1 && (
              <div className="border-t border-neutral-gray-200" />
            )}
          </Fragment>
        ))}

        <Button
          rightIcon={<ChevronRight className="size-5" />}
          size="md"
          variant="secondary"
          asChild
        >
          <Link to={path}>
            <FormattedMessage id="action.seeAll" defaultMessage="See all" />
          </Link>
        </Button>
      </Card>
    </>
  )
}
