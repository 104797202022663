import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import { DragAndDropContainer } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Label, Typography } from '@/shared/ui'

import { uploadDocumentToExistingContact } from '../../api'
import { ContractDocument } from '../../types'

import { UploadedDocument } from './UploadedDocument'

function typeValidator(file: File) {
  if (
    ['image/jpeg', 'image/png', 'application/pdf', 'image/jpg'].includes(
      file.type,
    )
  ) {
    return null
  }

  return {
    code: 'invalid-type',
    message: 'Invalid file type',
  }
}

type Props = {
  isCustomContract: boolean
  contractId?: string
  files: ContractDocument[]
  isLoadingFiles: boolean
}

export const UploadDocumentToExistingContract = ({
  files,
  isLoadingFiles,
  contractId,
  isCustomContract,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: uploadDocumentToExistingContact,
  })

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      multiple: true,
      validator: typeValidator,
      noClick: files.length > 0,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
        'image/jpg': [],
      },
      disabled: isLoadingFiles,
      onDrop: async (acceptedFiles) => {
        if (!acceptedFiles.length || !contractId) {
          return
        }

        const files = await Promise.allSettled(
          acceptedFiles.map(async (file) => {
            const { data } = await mutateAsync({ file, contractId })

            return data
          }),
        )

        files.map((file) => {
          if (file.status === 'fulfilled') {
            queryClient.setQueryData<AxiosResponse<ContractDocument[]>>(
              [queryKeys.getAllContractDocuments, contractId],
              (prev) => {
                if (!prev) {
                  return prev
                }

                return {
                  ...prev,
                  data: [...prev.data, file.value],
                }
              },
            )
          } else {
            notifyError(file.reason)
          }
        })
      },
    })

  const deleteFile = (fileId: string) => {
    queryClient.setQueryData<AxiosResponse<ContractDocument[]>>(
      [queryKeys.getAllContractDocuments, contractId],
      (prev) => {
        if (!prev) {
          return prev
        }

        return {
          ...prev,
          data: prev.data.filter((file) => file.id !== fileId),
        }
      },
    )
  }

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ file }) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: 'This file format is not supported',
              id: 'validation.file.wrongFormat',
            },
            { name: file.name },
          ),
        )
      })
    }
  }, [fileRejections, intl])

  return (
    <Card size="upload">
      {files.length === 0 ? (
        <>
          <div className="flex flex-col gap-x-2.5 pb-2">
            <Typography bold>
              <FormattedMessage
                id="contractor.documents.agreement.title"
                defaultMessage="Use my own agreement"
              />
            </Typography>

            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractor.documents.agreement.subtitle"
                defaultMessage="Upload my own agreement, previously signed by client and contractor"
              />
            </Typography>
          </div>
        </>
      ) : null}

      <div className={cn('flex w-full gap-6')} {...getRootProps()}>
        <input
          {...getInputProps({
            id: 'upload-custom-contract-files-input',
            name: 'upload-custom-contract-files-input',
          })}
        />

        <Label className="sr-only" htmlFor="upload-custom-contract-files-input">
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Label>

        {files.length ? (
          <div className="flex w-full flex-col justify-center gap-2">
            {files.map((file) => (
              <UploadedDocument
                isCustomContract={isCustomContract}
                key={file.id}
                contractId={contractId}
                file={file}
                onDelete={deleteFile}
              />
            ))}

            <div className="p-1" />

            <Button
              leftIcon={<Plus className="size-5" />}
              loading={isPending}
              disabled={isPending}
              size="md"
              onClick={open}
              type="button"
              variant="secondary"
            >
              <FormattedMessage
                id="uploadFile.field.addAnother"
                defaultMessage="Add another file"
              />
            </Button>
          </div>
        ) : (
          <DragAndDropContainer
            files={files.map((file) => file.fileName)}
            isDragActive={isDragActive}
            isPending={isPending || isLoadingFiles}
          />
        )}
      </div>
    </Card>
  )
}
