import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { AddTeamMember } from './AddTeamMember'
import { Team } from './Team'

export const teamRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Team,
    element: <Team />,
  },
]

export const addTeamMemberRoutes: RouteObject[] = [
  {
    path: BusinessRoute.AddTeamMember,
    element: <AddTeamMember />,
  },
]
