import { format, parse } from 'date-fns'

import {
  ACCOUNTS_FILTER_NAME,
  CURRENCY_FILTER_NAME,
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  STATUS_FILTER_NAME,
  TYPE_FILTER_NAME,
} from '@/domains/Business/components'
import { api } from '@/lib/api'

import { SingleTransaction } from '../../Transactions/types'

type SearchParams = {
  [ACCOUNTS_FILTER_NAME]?: string
  [TYPE_FILTER_NAME]?: string
  [CURRENCY_FILTER_NAME]?: string
  [STATUS_FILTER_NAME]?: string
  [DATE_FROM_FILTER_NAME]?: string
  [DATE_TO_FILTER_NAME]?: string
}

export function getBulkTransactions(id: string, params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)

  if (params[DATE_FROM_FILTER_NAME]) {
    searchParams.set(
      DATE_FROM_FILTER_NAME,
      format(
        parse(
          params[DATE_FROM_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  if (params[DATE_TO_FILTER_NAME]) {
    searchParams.set(
      DATE_TO_FILTER_NAME,
      format(
        parse(
          params[DATE_TO_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<SingleTransaction[]>(
    `/v2/me/transactions/group/${id}/search${searchQuery}`,
  )
}
