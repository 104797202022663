import { zodResolver } from '@hookform/resolvers/zod'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { CardFields } from '@/domains/Business/components'
import { PaymentLimitType } from '@/domains/Business/types'
import { getFullName } from '@/lib/typography'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  TeamMemberSchema,
  teamVirtualCardDetailsSchema,
  TeamVirtualCardDetailsSchema,
} from './schemas'

const CARD_INFO_FORM_ID = 'card-info-form-id'

type Props = {
  onBack: () => void
  onContinue: (data: TeamVirtualCardDetailsSchema) => void
  teamCardDetails?: TeamVirtualCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamVirtualCardDetailsStep = ({
  onBack,
  onContinue,
  teamCardDetails,
  teamMemberDetails,
}: Props) => {
  const form = useForm<TeamVirtualCardDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamVirtualCardDetailsSchema),
    values: {
      nickname: teamCardDetails?.nickname ?? '',
      walletId: teamCardDetails?.walletId ?? '',
      limitAmount: teamCardDetails?.limitAmount
        ? Big(teamCardDetails?.limitAmount).toString()
        : '',
      limitType: teamCardDetails?.limitType ?? ('' as PaymentLimitType),
      requestId: teamCardDetails?.requestId ?? '',
      identityId: teamCardDetails?.identityId ?? '',
    },
  })

  const onSubmit: SubmitHandler<TeamVirtualCardDetailsSchema> = async (
    data,
  ) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.virtual.title"
            defaultMessage="Create a virtual card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.virtual.description"
            defaultMessage="Customize the card to fit your needs. You can have as many as you want"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <CardFields form={form} fullName={getFullName(teamMemberDetails)} />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
