import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getFullName } from '@/lib/typography'
import { DataTableColumnHeader } from '@/shared/ui'

import { Contractor } from '../../../types'

import { CitizenshipCell } from './CitizenshipCell'
import { ContractorCell } from './ContractorCell'
import { ContractorStartDateCell } from './ContractorStartDateCell'
import { ContractorStateCell } from './ContractorStateCell'
import { ContractTypeCell } from './ContractTypeCell'
import { LastPaymentDateCell } from './LastPaymentDateCell'

export const useContractorsColumns = (): ColumnDef<Contractor>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Contractor>[] = useMemo(
    () => [
      {
        id: 'contractorName',
        accessorFn: getFullName,
        cell: ({ row }) => <ContractorCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.cardholder',
              defaultMessage: 'Cardholder',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Contractor>[] = useMemo(
    () => [
      {
        id: 'contractorName',
        accessorFn: getFullName,
        cell: ({ row }) => <ContractorCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.contractor',
              defaultMessage: 'Contractor',
            })}
          />
        ),
      },
      {
        accessorKey: 'countryOfCitizenship',
        cell: ({ row }) => <CitizenshipCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.countryOfCitizenship',
              defaultMessage: 'Country of citizenship',
            })}
          />
        ),
      },
      {
        accessorKey: 'paymentType',
        cell: ({ row }) => <ContractTypeCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.contractType',
              defaultMessage: 'Contract type',
            })}
          />
        ),
      },
      {
        accessorKey: 'lastPaymentDate',
        cell: ({ row }) => <LastPaymentDateCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.lastPayment',
              defaultMessage: 'Last payment',
            })}
          />
        ),
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => <ContractorStateCell contractor={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
      },
      {
        accessorKey: 'startDate',
        cell: ({ row }) => (
          <ContractorStartDateCell contractor={row.original} />
        ),
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.startDate',
              defaultMessage: 'Start date',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
