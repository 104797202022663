import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { SlideInScreen, Spinner } from '@/shared/ui'

import { getContractorOnboardingState } from './api'
import { ContractorsOnboardingSubmitStep } from './components/ContractorsOnboardingSubmitStep'
import { PaymentMethodStep } from './components/PaymentMethodStep'
import { PersonalInformationStep } from './components/PersonalInformationStep'
import { SignContractStep } from './components/SignContractStep'
import { TaxFormsStep } from './components/TaxFormsStep'
import { ContractorOnboardingStepName } from './types'

export const ContractorOnboardingSteps = () => {
  const intl = useIntl()

  const { step } = useParams<{ step?: string }>()

  const onboardingStateQuery = useQuery({
    queryKey: [queryKeys.getContractorOnboardingState],
    queryFn: () => getContractorOnboardingState(),
    select: (data) => data.data,
  })

  if (onboardingStateQuery.isPending)
    return (
      <SlideInScreen className="flex items-center justify-center">
        <Spinner
          aria-label={intl.formatMessage({
            defaultMessage: 'Loading',
            id: 'label.loading',
          })}
          className="size-16"
        />
      </SlideInScreen>
    )

  switch (step) {
    case ContractorOnboardingStepName.PERSONAL_INFORMATION:
      return (
        <PersonalInformationStep
          step={step}
          contractorState={onboardingStateQuery.data}
        />
      )

    case ContractorOnboardingStepName.PAYMENT_METHOD:
      return (
        <PaymentMethodStep
          step={step}
          contractorState={onboardingStateQuery.data}
        />
      )

    case ContractorOnboardingStepName.TAX_FORMS:
      return <TaxFormsStep />

    case ContractorOnboardingStepName.SIGN_CONTRACT:
      return <SignContractStep />

    case ContractorOnboardingStepName.REVIEW_AND_SUBMIT:
      return (
        <ContractorsOnboardingSubmitStep
          step={step}
          contractorState={onboardingStateQuery.data}
        />
      )

    default:
      return null
  }
}
