import { forwardRef, Ref, type SVGProps } from 'react'

const SvgGears = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="gears_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#gears_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="m5.85 12-.3-1.5q-.3-.124-.562-.262A4.4 4.4 0 0 1 4.45 9.9L3 10.35l-1-1.7 1.15-1a4.3 4.3 0 0 1 0-1.3L2 5.35l1-1.7 1.45.45q.275-.2.537-.337.263-.138.563-.263l.3-1.5h2l.3 1.5q.3.124.563.263.263.137.537.337l1.45-.45 1 1.7-1.15 1a4.3 4.3 0 0 1 0 1.3l1.15 1-1 1.7-1.45-.45a4.4 4.4 0 0 1-.537.338q-.263.137-.563.262l-.3 1.5zm1-3q.825 0 1.412-.588Q8.85 7.826 8.85 7q0-.824-.588-1.412A1.93 1.93 0 0 0 6.85 5q-.824 0-1.412.588A1.93 1.93 0 0 0 4.85 7q0 .824.588 1.412Q6.025 9 6.85 9m7.95 14-.45-2.1q-.425-.15-.787-.362a6 6 0 0 1-.713-.488l-2 .65-1.4-2.4 1.6-1.4a8 8 0 0 1 0-1.8l-1.6-1.4 1.4-2.4 2 .65q.349-.275.713-.487.362-.213.787-.363L14.8 9h2.8l.45 2.1q.425.15.787.363.363.212.713.487l2-.65 1.4 2.4-1.6 1.4a8 8 0 0 1 0 1.8l1.6 1.4-1.4 2.4-2-.65q-.349.275-.713.488a4.5 4.5 0 0 1-.787.362L17.6 23zm1.4-4q1.25 0 2.125-.875A2.9 2.9 0 0 0 19.2 16q0-1.25-.875-2.125A2.9 2.9 0 0 0 16.2 13q-1.25 0-2.125.875A2.9 2.9 0 0 0 13.2 16q0 1.25.875 2.125A2.9 2.9 0 0 0 16.2 19"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgGears)
export default ForwardRef
