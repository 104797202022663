import { forwardRef } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { getFirstLetters } from '@/lib/typography'
import { cn } from '@/lib/utils'

import { Typography, TypographyVariant } from './Typography'

const textVariant: Record<NonNullable<AvatarSize>, TypographyVariant> = {
  sm: 'body-tiny',
  md: 'body-tiny',
  lg: 'body',
  xl: 'h4',
}

const avatarVariants = cva(
  'relative flex shrink-0 items-center justify-center rounded-full bg-neutral-gray-100',
  {
    variants: {
      size: {
        xl: 'size-14',
        lg: 'size-9',
        md: 'size-6',
        sm: 'size-5',
      },
      variant: {
        neutral: 'bg-neutral-gray-100',
        'dark-neutral': 'bg-neutral-gray-200',
        primary: 'bg-primary-light',
        dark: 'bg-neutral-gray-800',
      },
    },
    defaultVariants: {
      size: 'lg',
      variant: 'neutral',
    },
  },
)

interface AvatarProps extends VariantProps<typeof avatarVariants> {
  name?: string
  icon?: React.ReactNode
  className?: string
}

export type AvatarVariant = VariantProps<typeof avatarVariants>['variant']
export type AvatarSize = VariantProps<typeof avatarVariants>['size']

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ name, icon, size, variant, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(avatarVariants({ size, variant, className }))}
      >
        {icon ? (
          <div className="flex size-full items-center justify-center">
            {icon}
          </div>
        ) : name ? (
          <Typography
            variant={textVariant[size ?? 'lg']}
            bold
            className={cn('text-neutral-gray-900', {
              'text-white': variant === 'dark',
            })}
          >
            {getFirstLetters(name)}
          </Typography>
        ) : null}
      </div>
    )
  },
)

Avatar.displayName = 'Avatar'
