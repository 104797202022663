import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { CountryCode } from '@/types/country'

import { getContactorBank } from '../api'

type Props = {
  country: CountryCode
  bankReference: string
  enabled: boolean
}

export function useContractorBankName({
  country,
  bankReference,
  enabled,
}: Props) {
  const bankNameQuery = useQuery({
    queryKey: [queryKeys.getContactorBank, country, bankReference],
    queryFn: () => getContactorBank({ country, bankReference }),
    select: (data) => data.data,
    enabled,
    retry: 1,
  })

  return {
    name: bankNameQuery.data?.name,
    isPending: bankNameQuery.isFetching,
  }
}
