import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole, useBusinessUser } from '@/domains/Business/hooks'
import { getFullName } from '@/lib/typography'
import { Spinner } from '@/shared/ui'

import { getTeamMembers } from '../../features/Team/api'

import { IDENTITY_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const CardholderBadge = () => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()

  const { isAdmin } = useBusinessRole()
  const businessUser = useBusinessUser()

  const teamMembersQuery = useQuery({
    queryKey: [queryKeys.getTeamMembers],
    queryFn: () => getTeamMembers(),
    select: (data) => data?.data,
    enabled: isAdmin,
  })

  const members = useMemo(() => {
    if (teamMembersQuery.data) {
      return teamMembersQuery.data
    }

    if (businessUser) {
      return [businessUser]
    }

    return []
  }, [teamMembersQuery.data, businessUser])

  const selectedMembers = useMemo(
    () =>
      searchParams
        .get(IDENTITY_FILTER_NAME)
        ?.split(',')
        .map((value) => {
          const activeMember = members?.find(
            (member) => member.identityId === value,
          )

          return getFullName(activeMember)
        })
        .join(', '),
    [members, searchParams],
  )

  if (!searchParams.get(IDENTITY_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(IDENTITY_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.cardholder',
        defaultMessage: 'Cardholder',
      })}
    >
      {teamMembersQuery.isFetching ? (
        <Spinner className="size-4" />
      ) : (
        selectedMembers
      )}
    </FilterBadge>
  )
}
