import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getAnimationKey } from '@/lib/utils'
import { DataTable, MotionDiv } from '@/shared/ui'

import { getPaymentCycles } from '../api'
import { EmptyOverview } from '../components'
import { usePaymentCyclesColumn } from '../components/ContractorsOverview'

export const ContractorsOverview = () => {
  const intl = useIntl()
  const columns = usePaymentCyclesColumn()

  const paymentCyclesQuery = useQuery({
    queryKey: [queryKeys.getPaymentCycles],
    queryFn: getPaymentCycles,
    select: (data) => data.data,
  })

  return (
    <MotionDiv>
      <MotionDiv key={getAnimationKey(paymentCyclesQuery.isPending)}>
        {paymentCyclesQuery.data?.length === 0 ? (
          <EmptyOverview />
        ) : (
          <DataTable
            emptyState={{
              title: intl.formatMessage({
                id: 'contractors.overview.search.notFound',
                defaultMessage: 'No payments found',
              }),
              description: intl.formatMessage({
                id: 'contractors.overview.search.notFound.description',
                defaultMessage:
                  'There were no payments that matched your search criteria',
              }),
            }}
            loaderOptions={{ rows: 3 }}
            isLoading={paymentCyclesQuery.isPending}
            columns={columns}
            data={paymentCyclesQuery.data ?? []}
          />
        )}
      </MotionDiv>
    </MotionDiv>
  )
}
