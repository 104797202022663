import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { formatAmount } from '@/lib/money'
import { cn, parseAdditionalDetails } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { getSingleRecipient } from '../../features/Recipients/api'
import { failedTransactionStatuses } from '../../features/Transactions/constants'
import { SingleTransaction } from '../../features/Transactions/types'
import { TransactionIcon } from '../TransactionIcon'

export const TransactionShortListRow = ({
  transaction,
}: {
  transaction: SingleTransaction
}) => {
  const recipientId = parseAdditionalDetails<{ beneficiaryId?: string }>(
    transaction.additionalDetails,
  )?.beneficiaryId

  const recipientQuery = useQuery({
    queryKey: [queryKeys.getSingleRecipient, recipientId],
    queryFn: () => getSingleRecipient({ id: recipientId ?? '' }),
    select: (data) => data.data,
    enabled: !!recipientId,
  })

  const isFailed = failedTransactionStatuses.includes(
    transaction.transactionStatus,
  )

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <TransactionIcon
          additionalDetails={transaction.additionalDetails}
          recipientType={recipientQuery.data?.entityType}
          transactionType={transaction.transactionType}
          transactionStatus={transaction.transactionStatus}
        />
        <Typography>{transaction.name}</Typography>
      </div>
      <div className="flex flex-col gap-1">
        <Typography text="right" className={cn('', isFailed && 'line-through')}>
          {formatAmount({
            amount: transaction.baseAmount,
            currency: transaction.baseCurrency,
          })}
        </Typography>
        <Typography
          text="right"
          className={cn('text-neutral-gray-400', isFailed && 'line-through')}
        >
          {formatAmount({
            amount: transaction.localAmount,
            currency: transaction.localCurrency,
          })}
        </Typography>
      </div>
    </div>
  )
}
