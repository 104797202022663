import { FormattedMessage } from 'react-intl'

import { getCardTitle } from '@/lib/card'
import { Widget } from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { CardScreenType, Card as CardType } from '../types'

import { CardBillingAddress } from './CardBillingAddress'

type Props = {
  card?: CardType
  setScreen: (screen: CardScreenType) => void
}

export const CardInformation = ({ card, setScreen }: Props) => {
  return (
    <SlideInLeft>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'base' })}
              variant="ghost"
              size="inline"
            >
              {card ? (
                <Typography>{getCardTitle(card)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="card.sidebar.information"
                defaultMessage="Card information"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <div className="flex flex-col gap-8">
        <CardBillingAddress />

        <Widget
          gap="none"
          title={
            <FormattedMessage
              id="card.sidebar.ATMNetwork"
              defaultMessage="ATM network"
            />
          }
        >
          <Typography>
            <FormattedMessage
              id="card.sidebar.withdrawalFees"
              defaultMessage="Withdrawal fees"
            />
          </Typography>

          <div className="p-1" />

          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="card.sidebar.withdrawalFees.description"
              defaultMessage="You will pay a 1% fee on all ATM withdrawals. You can withdraw as much as you have in the account linked to your card"
            />
          </Typography>
        </Widget>
      </div>
    </SlideInLeft>
  )
}
