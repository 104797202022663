import { z } from "zod"

export const createOwnerAddressSchema = z.object({
  GOOGLE_PLACES_ADDRESS: z.string(),

  ADDRESS_STREET: z.string(),
  ADDRESS_STREET_NUMBER: z.string(),
  ADDRESS_DISTRICT: z.string(),
  ADDRESS_CITY: z.string(),
  ADDRESS_STATE: z.string(),
  ADDRESS_POST_CODE: z.string(),
  ADDRESS_COUNTRY: z.string(),
})

export type CreateOwnerAddressSchema = z.infer<typeof createOwnerAddressSchema>