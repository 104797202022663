import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getContractorPaymentMethods } from '@/domains/Contractor/api'
import {
  ContractorPaymentMethod,
  ContractorPaymentMethodType,
} from '@/domains/Contractor/types'
import { getAnimationKey } from '@/lib/utils'
import { GoBackButton, Widget } from '@/shared/components'
import {
  Card,
  MotionDiv,
  Skeleton,
  SlideInScreen,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { BankAccountCard } from './BankAccountCard'
import { DolarTagCard } from './DolarTagCard'

const AccountCardSkeleton = () => (
  <Card size="upload" className="flex w-full items-center justify-between">
    <div className="flex items-center gap-4">
      <Skeleton className="size-7 shrink-0 rounded-full" />

      <div className="flex flex-col gap-0.5">
        <Skeleton className="h-5 w-24" />
        <Skeleton className="h-5 w-24" />
      </div>
    </div>
    <Skeleton className="size-4" />
  </Card>
)

const BANK_ACCOUNTS = [
  {
    countryCode: CountryCode.US,
    type: ContractorPaymentMethodType.US_BANK_ACCOUNT,
    clickType: 'us-bank',
  },
  {
    countryCode: CountryCode.MX,
    type: ContractorPaymentMethodType.MX_BANK_ACCOUNT,
    clickType: 'mx-bank',
  },
]

function getActivePaymentMethod(
  paymentMethods: ContractorPaymentMethod[] | undefined,
  methodType: ContractorPaymentMethodType,
) {
  return paymentMethods?.find(
    (method) => method.paymentMethodDetails.type === methodType,
  )
}

type Props = {
  onContinue: (screen: string) => void
  onBack: () => void
}

export const SelectPaymentMethodScreen = ({ onBack, onContinue }: Props) => {
  const paymentMethodsQuery = useQuery({
    queryKey: [queryKeys.getContractorPaymentMethods],
    queryFn: getContractorPaymentMethods,
    select: (data) => data.data,
  })

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Payment method"
            id="contractors.onboarding.paymentMethod.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="How do you want to get your salary paid?"
            id="contractors.onboarding.paymentMethod.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-6">
          <Widget
            variant="form"
            title={
              <FormattedMessage id="label.dolarapp" defaultMessage="DolarApp" />
            }
          >
            <MotionDiv
              className="flex flex-col gap-3"
              key={getAnimationKey(paymentMethodsQuery.isPending)}
            >
              {paymentMethodsQuery.isPending ? (
                <AccountCardSkeleton />
              ) : (
                <DolarTagCard
                  paymentMethod={getActivePaymentMethod(
                    paymentMethodsQuery.data,
                    ContractorPaymentMethodType.DOLAR_TAG,
                  )}
                  onContinue={() => onContinue('dolarTag')}
                />
              )}
            </MotionDiv>
          </Widget>

          <Widget
            variant="form"
            title={
              <FormattedMessage
                id="label.bankAccounts"
                defaultMessage="Bank accounts"
              />
            }
          >
            <MotionDiv
              className="flex flex-col gap-3"
              key={getAnimationKey(paymentMethodsQuery.isPending)}
            >
              {paymentMethodsQuery.isPending ? (
                <AccountCardSkeleton />
              ) : (
                BANK_ACCOUNTS.map(({ countryCode, type, clickType }) => (
                  <BankAccountCard
                    key={clickType}
                    paymentMethod={getActivePaymentMethod(
                      paymentMethodsQuery.data,
                      type,
                    )}
                    countryCode={countryCode}
                    onContinue={() => onContinue(clickType)}
                  />
                ))
              )}
            </MotionDiv>
          </Widget>
        </div>
      </SlideInScreen>
    </>
  )
}
