import { RouteObject } from 'react-router'

import { ContractorRoute } from '@/constants/paths'

import { ContractorOnboarding } from './ContractorOnboarding'
import { ContractorOnboardingStart } from './ContractorOnboardingStart'
import { ContractorOnboardingSteps } from './ContractorOnboardingSteps'
import { Layout } from './Layout'

export const contractorOnboardingRoutes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: ContractorRoute.Onboarding,
        element: <ContractorOnboarding />,
      },
      {
        path: ContractorRoute.OnboardingStart,
        element: <ContractorOnboardingStart />,
      },
      {
        path: ContractorRoute.OnboardingStep,
        element: <ContractorOnboardingSteps />,
      },
    ],
  },
]
