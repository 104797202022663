import { useEffect, useRef, useState } from 'react'
import { isValid, parse, sub } from 'date-fns'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { DefineMessages } from '@/constants/messages'
import {
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
} from '@/domains/Business/components'
import { formatDate } from '@/lib/date'
import { SortBy } from '@/shared/icons/outline'
import {
  Button,
  DateInput,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Typography,
} from '@/shared/ui'

type Keys = 'lastThreeMonths' | 'lastSixMonths' | 'lastYear' | 'previousYear'

const messages: DefineMessages<Keys> = defineMessages({
  lastThreeMonths: {
    id: 'dateFilter.lastThreeMonths',
    defaultMessage: 'Last 3 months',
  },
  lastSixMonths: {
    id: 'dateFilter.lastSixMonths',
    defaultMessage: 'Last 6 months',
  },
  lastYear: {
    id: 'dateFilter.lastYear',
    defaultMessage: 'Last year',
  },
  previousYear: {
    id: 'dateFilter.previousYear',
    defaultMessage: 'Previous year',
  },
})

const JANUARY = 0
const DECEMBER = 11
const PREVIOUS_YEAR = sub(new Date(), { years: 1 }).getFullYear()

const dateFilterOptions: {
  value: {
    fromDate: string
    toDate: string
  }
  key: Keys
}[] = [
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 3 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastThreeMonths',
  },
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 6 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastSixMonths',
  },
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 12 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastYear',
  },
  {
    key: 'previousYear',
    value: {
      fromDate: formatDate(new Date(PREVIOUS_YEAR, JANUARY, 1), 'dd/MM/yy'),
      toDate: formatDate(new Date(PREVIOUS_YEAR, DECEMBER, 31), 'dd/MM/yy'),
    },
  },
]

export const StatementsDateFilter = () => {
  const intl = useIntl()
  const fromDateInputRef = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [layout, setLayout] = useState<'custom' | 'preset'>('preset')

  const [fromDate, setFromDate] = useState(
    searchParams.get(DATE_FROM_FILTER_NAME) || '',
  )
  const [toDate, setToDate] = useState(
    searchParams.get(DATE_TO_FILTER_NAME) || '',
  )

  function handleSearchParams({
    fromDate,
    toDate,
  }: {
    fromDate: string
    toDate: string
  }) {
    setSearchParams(
      (params) => {
        if (
          params.get(DATE_FROM_FILTER_NAME) === fromDate &&
          params.get(DATE_TO_FILTER_NAME) === toDate
        ) {
          params.delete(DATE_FROM_FILTER_NAME)
          params.delete(DATE_TO_FILTER_NAME)

          return params
        }

        params.set(DATE_FROM_FILTER_NAME, fromDate)
        params.set(DATE_TO_FILTER_NAME, toDate)

        return params
      },
      { preventScrollReset: true },
    )
  }

  const isValidDate = (date: string) => {
    if (date.length < 6) {
      return false
    }

    if (date.length === 6) {
      const parsedDate = parse(date, 'd/M/yy', new Date())

      return isValid(parsedDate)
    }

    if (date.length === 7) {
      const parsedDayDate = parse(date, 'd/MM/yy', new Date())
      const parsedMonthDate = parse(date, 'dd/M/yy', new Date())

      return isValid(parsedDayDate) || isValid(parsedMonthDate)
    }

    const parsedDate = parse(date, 'dd/MM/yy', new Date())

    return isValid(parsedDate)
  }

  const hasCustomFromRange = dateFilterOptions.every((option) => {
    return (
      !!searchParams.get(DATE_FROM_FILTER_NAME) &&
      searchParams.get(DATE_FROM_FILTER_NAME) !== option.value.fromDate
    )
  })

  const hasCustomToRange = dateFilterOptions.every((option) => {
    return (
      !!searchParams.get(DATE_TO_FILTER_NAME) &&
      searchParams.get(DATE_TO_FILTER_NAME) !== option.value.toDate
    )
  })

  useEffect(() => {
    if (layout === 'custom') {
      fromDateInputRef.current?.focus()
    }
  }, [fromDate.length, layout])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          leftIcon={<SortBy className="size-4" />}
          size="icon"
          variant="tertiary"
          aria-label={intl.formatMessage({
            id: 'action.downloadStatements',
            defaultMessage: 'Download statements',
          })}
        />
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-52" align="end">
        {layout === 'preset' ? (
          <>
            {dateFilterOptions.map((option) => (
              <DropdownMenuCheckboxItem
                key={option.key}
                onSelect={(event) => event.preventDefault()}
                checked={
                  searchParams
                    .get(DATE_FROM_FILTER_NAME)
                    ?.includes(option.value.fromDate) &&
                  searchParams
                    .get(DATE_TO_FILTER_NAME)
                    ?.includes(option.value.toDate)
                }
                onCheckedChange={() => handleSearchParams(option.value)}
              >
                {intl.formatMessage(messages[option.key])}
              </DropdownMenuCheckboxItem>
            ))}

            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={hasCustomFromRange || hasCustomToRange}
              onCheckedChange={() => setLayout('custom')}
            >
              <FormattedMessage
                defaultMessage="Custom range"
                id="label.customRange"
              />
            </DropdownMenuCheckboxItem>
          </>
        ) : layout === 'custom' ? (
          <div className="flex flex-col gap-1">
            <DropdownMenuCheckboxItem
              tabIndex={-1}
              onSelect={(event) => event.preventDefault()}
              checked
              onCheckedChange={() => setLayout('preset')}
            >
              <FormattedMessage
                defaultMessage="Custom range"
                id="label.customRange"
              />
            </DropdownMenuCheckboxItem>

            <div className="flex h-10 items-center gap-3 px-2">
              <Typography className="w-10">
                <FormattedMessage defaultMessage="From" id="label.from" />
              </Typography>
              <DateInput
                ref={fromDateInputRef}
                value={fromDate}
                onClear={() => {
                  setFromDate('')
                  setSearchParams(
                    (params) => {
                      params.delete(DATE_FROM_FILTER_NAME)
                      return params
                    },
                    { preventScrollReset: true },
                  )
                }}
                onChange={(value) => {
                  setFromDate(value)
                  if (isValidDate(value)) {
                    setSearchParams(
                      (params) => {
                        params.set(DATE_FROM_FILTER_NAME, value)
                        return params
                      },
                      { preventScrollReset: true },
                    )
                  }
                }}
              />
            </div>

            <div className="flex h-10 items-center gap-3 px-2">
              <Typography className="w-10">
                <FormattedMessage defaultMessage="To" id="label.to" />
              </Typography>
              <DateInput
                value={toDate}
                onClear={() => {
                  setToDate('')
                  setSearchParams(
                    (params) => {
                      params.delete(DATE_TO_FILTER_NAME)
                      return params
                    },
                    { preventScrollReset: true },
                  )
                }}
                onChange={(value) => {
                  setToDate(value)
                  if (isValidDate(value)) {
                    setSearchParams(
                      (params) => {
                        params.set(DATE_TO_FILTER_NAME, value)
                        return params
                      },
                      { preventScrollReset: true },
                    )
                  }
                }}
              />
            </div>
          </div>
        ) : null}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
