import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { HistoricalPayment } from '../../types'

import { HistoricalPaymentDateCell } from './HistoricalPaymentDateCell'
import {
  HistoricalAmountCell,
  HistoricalPaidDateCell,
  HistoricalPaymentContractorsCell,
  HistoricalPaymentCycleCell,
  HistoricalPaymentStateCell,
} from './HistoricalPaymentsTable'

export const useHistoricalPaymentsColumns = () => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<HistoricalPayment>[] = useMemo(
    () => [
      {
        accessorKey: 'startDate',
        cell: ({ row }) => (
          <HistoricalPaymentCycleCell payment={row.original} />
        ),
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.paymentCycle',
              defaultMessage: 'Payment cycle',
            })}
          />
        ),
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <HistoricalAmountCell payment={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amountPaid',
              defaultMessage: 'Amount paid',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<HistoricalPayment>[] = useMemo(
    () => [
      {
        accessorKey: 'startDate',
        cell: ({ row }) => (
          <HistoricalPaymentCycleCell payment={row.original} />
        ),
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.paymentCycle',
              defaultMessage: 'Payment cycle',
            })}
          />
        ),
      },
      {
        accessorKey: 'totalContractors',
        cell: ({ row }) => (
          <HistoricalPaymentContractorsCell payment={row.original} />
        ),
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.contractors',
              defaultMessage: 'Contractors',
            })}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'paymentDate',
        cell: ({ row }) => <HistoricalPaymentDateCell payment={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.paymentDate',
              defaultMessage: 'Payment date',
            })}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'paidDate',
        cell: ({ row }) => <HistoricalPaidDateCell payment={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.paymentDate',
              defaultMessage: 'Payment date',
            })}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => (
          <HistoricalPaymentStateCell payment={row.original} />
        ),
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.paymentDate',
              defaultMessage: 'Payment date',
            })}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <HistoricalAmountCell payment={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amountPaid',
              defaultMessage: 'Amount paid',
            })}
          />
        ),
        size: 130,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
