import { useQueries } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ActiveFilters,
  ContractTypeBadge,
  ContractTypeFilter,
  DateBadge,
  DateFilter,
  FiltersWidget,
} from '@/domains/Business/components'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { getAnimationKey } from '@/lib/utils'
import { Plus } from '@/shared/icons/outline'
import { Button, DataTable, MotionDiv, SearchInput } from '@/shared/ui'

import { getHistoricalPayment, getHistoricalPayments } from '../api'
import {
  EmptyHistoricalPayments,
  HistoricalPaymentDetailsSidebar,
} from '../components'
import { useHistoricalPaymentsColumns } from '../components/HistoricalPayments/useHistoricalPaymentsColumns'

export const HistoricalPayments = () => {
  const intl = useIntl()
  const columns = useHistoricalPaymentsColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const [searchParams, setSearchParams] = useSearchParams()
  const [paymentId] = useSearchParamsValue(['id'])

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const [historicalPaymentsQuery, historicalPaymentQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getHistoricalPayments, params],
        queryFn: () => getHistoricalPayments(params),
      },
      {
        queryKey: [queryKeys.getHistoricalPayment, paymentId],
        queryFn: () => getHistoricalPayment({ id: paymentId ?? '' }),
        enabled: !!paymentId,
      },
    ],
  })

  return (
    <MotionDiv>
      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <ContractTypeFilter />
          <DateFilter
            label={intl.formatMessage({
              id: 'label.startDate',
              defaultMessage: 'Start date',
            })}
          />
        </FiltersWidget>

        <Button
          leftIcon={<Plus className="size-5" />}
          asChild
          variant="tertiary"
        >
          <Link to={BusinessRoute.ContractorsHistoricalPayments}>
            <FormattedMessage
              id="action.newPayment"
              defaultMessage="New payment"
            />
          </Link>
        </Button>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <ContractTypeBadge />
        <DateBadge />
      </ActiveFilters>

      <MotionDiv
        key={getAnimationKey(historicalPaymentsQuery.isPending, params)}
      >
        {historicalPaymentsQuery.data?.length === 0 &&
        Object.keys(params).length === 0 ? (
          <EmptyHistoricalPayments />
        ) : (
          <DataTable
            emptyState={{
              title: intl.formatMessage({
                id: 'contractors.search.notFound',
                defaultMessage: 'No contractors found',
              }),
              description: intl.formatMessage({
                id: 'contractors.search.notFound.description',
                defaultMessage:
                  'There were no contractors that matched your search criteria',
              }),
            }}
            loaderOptions={{ rows: 1 }}
            isLoading={historicalPaymentsQuery.isPending}
            columns={columns}
            data={historicalPaymentsQuery.data ?? []}
            onRowClick={(payment) => {
              setSearchParams(
                (params) => {
                  params.set('id', payment.id)
                  return params
                },
                { preventScrollReset: true },
              )
            }}
          />
        )}
      </MotionDiv>

      <HistoricalPaymentDetailsSidebar
        isOpen={!!paymentId}
        onOpenChange={() => {
          setSearchParams((params) => {
            params.delete('id')
            return params
          })
        }}
        payment={historicalPaymentQuery.data}
      />
    </MotionDiv>
  )
}
