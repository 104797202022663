import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import {
  AmountCell,
  DateCell,
  StateCell,
  TransactionCell,
  WalletCell,
} from '@/domains/Business/components'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { Transaction } from '../../Transactions/types'

export const useCardTransactionColumns = (): ColumnDef<Transaction>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <TransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.transaction',
              defaultMessage: 'Transaction',
            })}
          />
        ),
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <AmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <TransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.transaction',
              defaultMessage: 'Transaction',
            })}
          />
        ),
      },
      {
        accessorKey: 'walletId',
        cell: ({ row }) => <WalletCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
        size: 170,
      },
      {
        accessorKey: 'createdOn',
        cell: ({ row }) => <DateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.date',
              defaultMessage: 'Date',
            })}
          />
        ),
        size: 140,
      },
      {
        accessorKey: 'transactionStatus',
        cell: ({ row }) => <StateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 120,
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <AmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
        size: 120,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
