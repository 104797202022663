import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { BulkPayments } from './BulkPayments'
import { BulkTransactions } from './BulkTransactions'

export const bulkPaymentsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.BulkPayment,
    element: <BulkPayments />,
  },
]

export const bulkTransactionsRoutes: RouteObject[] = [
  {
    path: BusinessRoute.BulkPaymentTransactions,
    element: <BulkTransactions />,
  },
]
