import { Big } from 'big.js'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

import { PaymentLimitType } from '@/domains/Business/types'

import { LimitPeriodType, MovementPermission } from '../../types'

export const moneyMovementSchema = z
  .object({
    movementPermission: z.nativeEnum(MovementPermission),
    limitPeriodType: z.nativeEnum(LimitPeriodType).optional(),
    periodTransferLimitAmount: z.string().optional(),
    singleTransferLimitAmount: z.string().optional(),
  })
  .refine(
    (data) => {
      const {
        periodTransferLimitAmount = '0',
        movementPermission,
        singleTransferLimitAmount = '0',
      } = data

      if (
        movementPermission !==
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      if (
        singleTransferLimitAmount === '0' &&
        periodTransferLimitAmount === '0'
      ) {
        return false
      }

      if (
        Big(
          singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
        ).gt(periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount)
      ) {
        return false
      }

      return true
    },
    {
      message: 'validation.singleTransactionLimit.greaterThanCycleLimit',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, singleTransferLimitAmount = '0' } = data

      if (
        movementPermission !==
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      return Big(
        singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
      ).gt(0)
    },
    {
      message: 'validation.balance.minAmount',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, periodTransferLimitAmount = '0' } = data

      if (
        movementPermission ===
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return Big(
          periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount,
        ).gt(0)
      }

      return true
    },
    {
      message: 'validation.balance.minAmount',
      path: ['periodTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const { movementPermission, limitPeriodType } = data

      if (
        movementPermission ===
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return !!limitPeriodType
      }

      return true
    },
    {
      message: 'validation.balance.minAmount',
      path: ['limitPeriodType'],
    },
  )

export type MoneyMovementSchema = z.infer<typeof moneyMovementSchema>

export const teamCardAddressSchema = z.object({
  addressStreet: z.string().min(1, {
    message: 'validation.addressStreet.required',
  }),
  addressStreetNumber: z.string().min(1, {
    message: 'validation.addressStreetNumber.required',
  }),
  addressApartment: z.string().optional(),
  addressReferences: z.string().optional(),
  addressDistrict: z.string().min(1, {
    message: 'validation.addressDistrict.required',
  }),
  addressCity: z.string().min(1, {
    message: 'validation.addressCity.required',
  }),
  addressState: z.string().min(1, {
    message: 'validation.addressState.required',
  }),
  addressPostCode: z.string().min(1, {
    message: 'validation.addressZipCode.required',
  }),
  addressCountry: z.string().min(1, {
    message: 'validation.addressCountry.required',
  }),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    }),
})

export type TeamCardAddressSchema = z.infer<typeof teamCardAddressSchema>

export const googleAddressSchema = z.object({
  googlePlacesAddress: z.string(),
})

export type GoogleAddressSchema = z.infer<typeof googleAddressSchema>

export const teamMemberDetailsSchema = z.object({
  firstName: z.string().min(1, {
    message: 'validation.firstName.required',
  }),
  lastName: z.string().min(1, {
    message: 'validation.lastName.required',
  }),
  secondLastName: z.string().optional(),
  title: z.string().optional(),
  email: z.string().email({
    message: 'validation.email.invalid',
  }),
})

export type TeamMemberSchema = z.infer<typeof teamMemberDetailsSchema>

export const teamPhysicalCardDetailsSchema = z.object({
  nickname: z.string().min(1, { message: 'validation.nickname.required' }),
  requestId: z.string(),
  identityId: z.string(),
  walletId: z.string().min(1),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

export type TeamPhysicalCardDetailsSchema = z.infer<
  typeof teamPhysicalCardDetailsSchema
>

export const teamVirtualCardDetailsSchema = z.object({
  nickname: z.string().min(1, {
    message: 'validation.nickname.required',
  }),
  requestId: z.string(),
  identityId: z.string(),
  walletId: z.string().min(1),
  limitAmount: z.string().refine((v) => Big(v === '' ? 0 : v).gt(0), {
    message: 'validation.balance.minAmount',
  }),
  limitType: z.nativeEnum(PaymentLimitType),
})

export type TeamVirtualCardDetailsSchema = z.infer<
  typeof teamVirtualCardDetailsSchema
>
