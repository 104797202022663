import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBank = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="bank_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#bank_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M5 17v-7h2v7zm6 0v-7h2v7zm-9 4v-2h20v2zm15-4v-7h2v7zM2 8V6l10-5 10 5v2zm4.45-2h11.1L12 3.25z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgBank)
export default ForwardRef
