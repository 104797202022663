import { useState } from 'react'

import { ContractorOnboardingState } from '../types'

import { DownloadAppScreen } from './DownloadAppScreen'
import { LinkDolarTagScreen } from './LinkDolarTagScreen'

type Step = 'download-app' | 'connect-app'

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
}

export const DolarTagPaymentDetails = ({ contractorState, onBack }: Props) => {
  const [step, setStep] = useState<Step>('download-app')

  switch (step) {
    case 'download-app':
      return (
        <DownloadAppScreen
          onBack={onBack}
          onContinue={() => setStep('connect-app')}
        />
      )

    case 'connect-app':
      return (
        <LinkDolarTagScreen onBack={onBack} contractorState={contractorState} />
      )
  }
}
