import { PaymentMethod, PaymentRails } from '@/domains/Business/constants'
import { CountryCode } from '@/types/country'

export enum DolarAppEntityType {
  RETAIL = 'RETAIL',
  BUSINESS = 'BUSINESS',
}

type BaseRecipient = {
  nickname: string
  bankName: string | null
  addressStreet: string | null
  addressCity: string | null
  addressState: string | null
  addressZipCode: string | null
  businessId: string
  createdAt: string
  email: string | null
  entityType: EntityType
  firstName: string | null
  paymentRails: PaymentRails
  id: string
  internationalPhonePrefix: string | null
  lastName: string | null
  legalName: string | null
  localPhoneNumber: string | null
  secondLastName: string | null
  state: 'ACTIVE'
  creationDetails?: {
    requestedBy?: string
    approvedBy?: string
    updatedAt?: string
  }
}

export type DolarAppSearchRecipient = {
  id: string
  owner: { type: DolarAppEntityType; id: string; displayName: string }
  alias: { value: string }
}

type MXLocalInformation = {
  clabe: string
}

type USLocalInformation = {
  accountNumber: string
  routingNumber: string
  paymentMethod: PaymentMethod
}

type DolarAppLocalInformation = {
  dolarTag: string
}

type MXRecipient = BaseRecipient & {
  country: CountryCode.MX
  paymentRails: PaymentRails.MEX
  localInformation: MXLocalInformation
}

type DolarAppRecipient = BaseRecipient & {
  country: CountryCode.MX
  paymentRails: PaymentRails.DOLAR_APP
  localInformation: DolarAppLocalInformation
}

type USRecipient = BaseRecipient & {
  country: CountryCode.US
  paymentRails: PaymentRails.USA
  localInformation: USLocalInformation
}

export type Recipient = MXRecipient | USRecipient | DolarAppRecipient

export enum EntityType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

type IndividualFields = {
  nickname: string
  firstName: string
  lastName: string
  secondLastName?: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  email?: string
}

type BusinessFields = {
  nickname: string
  legalName: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
  email?: string
}

export type IndividualMXRecipient = IndividualFields & {
  paymentRails: PaymentRails.MEX
  country: CountryCode.MX
  entityType: EntityType.INDIVIDUAL
  localInformation: {
    clabe: string
  }
}

export type BusinessMXRecipient = BusinessFields & {
  paymentRails: PaymentRails.MEX
  country: CountryCode.MX
  entityType: EntityType.BUSINESS
  localInformation: {
    clabe: string
  }
}

export type IndividualUSRecipient = IndividualFields & {
  paymentRails: PaymentRails.USA
  country: CountryCode.US
  entityType: EntityType.INDIVIDUAL
  addressStreet: string
  addressCity: string
  addressState: string
  addressZipCode: string
  addressCountry: string
  localInformation: {
    accountNumber: string
    routingNumber: string
    paymentMethod: string
  }
}

export type BusinessUSRecipient = BusinessFields & {
  paymentRails: PaymentRails.USA
  country: CountryCode.US
  entityType: EntityType.BUSINESS
  addressStreet: string
  addressCity: string
  addressState: string
  addressZipCode: string
  addressCountry: string
  localInformation: {
    accountNumber: string
    routingNumber: string
    paymentMethod: string
  }
}

export type IndividualCreatedDolarAppRecipient = IndividualFields & {
  paymentRails: PaymentRails.DOLAR_APP
  entityType: EntityType.INDIVIDUAL
  country: CountryCode
  localInformation: {
    dolarTag: string
  }
}

export type BusinessCreatedDolarAppRecipient = BusinessFields & {
  paymentRails: PaymentRails.DOLAR_APP
  entityType: EntityType.BUSINESS
  country: CountryCode
  localInformation: {
    dolarTag: string
  }
}

export type CreatedDolarAppRecipient =
  | IndividualCreatedDolarAppRecipient
  | BusinessCreatedDolarAppRecipient

export type CreatedRecipient =
  | IndividualMXRecipient
  | BusinessMXRecipient
  | IndividualUSRecipient
  | BusinessUSRecipient
  | CreatedDolarAppRecipient

export type CreatedRecipientWithId = Partial<CreatedRecipient> & {
  id: string
}

export type SharedRecipientFormProps = {
  country: CountryCode
  layout?: 'edit' | 'create'
  isPending?: boolean
  onDelete?: () => void
}
