import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import {
  formatAmount,
  formatCurrency,
  formatMoney,
  formatRate,
} from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Card, Details } from '@/shared/ui'

import { depositTransactionTypes } from '../../features/Transactions/constants'
import {
  SingleTransaction,
  TransactionType,
} from '../../features/Transactions/types'

type Props = {
  transaction: SingleTransaction
}

export const TransactionAmountDetails = ({ transaction }: Props) => {
  const isUSDCurrency =
    transaction?.localCurrency === Currency.USD ||
    transaction?.localCurrency === Currency.USDC

  const isDeposit = transaction
    ? depositTransactionTypes.includes(transaction?.transactionType)
    : false

  switch (transaction?.transactionType) {
    case TransactionType.WITHDRAW_REFUND:
    case TransactionType.REWARD:
    case TransactionType.CASHBACK:
      return null
    case TransactionType.FEE:
      return (
        <Card className="flex flex-col gap-4" size="medium">
          <Details>
            <Details.Label>
              <FormattedMessage id="label.youPaid" defaultMessage="You paid" />
            </Details.Label>

            <Details.Value>
              {formatAmount({
                amount: transaction.baseAmount,
                currency: transaction.baseCurrency,
              })}
            </Details.Value>
          </Details>
        </Card>
      )

    default:
      return (
        <Card className="flex flex-col gap-4" size="medium">
          <Details>
            <Details.Label>
              <FormattedMessage
                id="transaction.sendAmount.label"
                defaultMessage="{isDeposit, select, true {You were sent} false {You paid} other {}}"
                values={{ isDeposit }}
              />
            </Details.Label>
            <Details.Value>
              {isDeposit
                ? formatAmount({
                    amount: transaction.localAmount,
                    currency: transaction.localCurrency,
                  })
                : formatAmount({
                    amount: transaction.baseAmount,
                    currency: transaction.baseCurrency,
                  })}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.exchangeRate"
                defaultMessage="Exchange rate"
              />
            </Details.Label>
            <Details.Value>
              {intersperse(
                [
                  <Fragment key="baseCurrency">
                    1 {formatCurrency(transaction.baseCurrency)}{' '}
                  </Fragment>,
                  <Fragment key="localCurrency">
                    {isUSDCurrency
                      ? transaction.fxRate
                      : formatRate(transaction.fxRate)}{' '}
                    {formatCurrency(transaction.localCurrency)}
                  </Fragment>,
                ],
                ' = ',
              )}
            </Details.Value>
          </Details>
          {transaction?.feeAmount && transaction.feeAmount > 0 ? (
            <Details>
              <Details.Label>
                <FormattedMessage id="label.fee" defaultMessage="Fee" />
              </Details.Label>
              <Details.Value>
                {formatAmount({
                  amount: transaction.feeAmount,
                  currency: transaction.feeCurrency,
                })}
              </Details.Value>
            </Details>
          ) : null}
          <Details>
            <Details.Label>
              <FormattedMessage
                id="transaction.getAmount.label"
                defaultMessage="{isDeposit, select, true {You received} false {Recipient gets} other {}}"
                values={{ isDeposit }}
              />
            </Details.Label>

            <Details.Value>
              {formatMoney(
                isDeposit ? transaction.baseAmount : transaction.localAmount,
              ).replace(/-/g, '')}{' '}
              {formatCurrency(
                isDeposit
                  ? transaction.baseCurrency
                  : transaction.localCurrency,
              )}
            </Details.Value>
          </Details>
        </Card>
      )
  }
}
