import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { AddBalance } from './AddBalance'
import { Send } from './Send'
import { TransferMoney } from './TransferMoney'

export const moveMoneyRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Send,
    element: <Send />,
  },
  {
    path: BusinessRoute.AddBalance,
    element: <AddBalance />,
  },
  {
    path: BusinessRoute.Transfer,
    element: <TransferMoney />,
  },
]
