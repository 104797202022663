import { useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { format, isEqual, parse } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'
import { z } from 'zod'

import { taxIdByCountry } from '@/constants/countries'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { parseInputDate } from '@/lib/date'
import { queryClient } from '@/lib/queryClient'
import { CountryCombobox, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode, CountryTaxId } from '@/types/country'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { OnboardingDocumentField } from '../components/OnboardingDocumentField'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  CompanyFormationStep as CompanyFormationStepType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../types'

function parseTaxIdToDate(taxId: string) {
  const RFCExtractedDate = taxId.slice(3, 9)
  const [year, month, day] = RFCExtractedDate.match(/.{2}/g) ?? []

  const utcDate = new Date().toUTCString()

  const RFCDate = parse(`${day}/${month}/${year}`, 'dd/MM/yy', utcDate)

  return RFCDate
}

const companyFormationStepSchema = z
  .object({
    COUNTRY_OF_INCORPORATION: z.string(),
    TAX_ID: z.string(),
    TAX_ID_TYPE: z.string(),
    DATE_OF_INCORPORATION: z.string().refine(
      (value) => {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/

        return regex.test(value)
      },
      { message: 'validation.dateOfIncorporation.invalidDateFormat' },
    ),
    FORMATION_DOCUMENTS: z.any(),
    TAX_CERTIFICATE_OF_GOOD_STANDING: z.any(),
  })
  .refine(
    (data) => {
      const { DATE_OF_INCORPORATION, TAX_ID, TAX_ID_TYPE } = data

      switch (TAX_ID_TYPE) {
        case CountryTaxId.MX: {
          const RFCDate = parseTaxIdToDate(TAX_ID)

          const dateOfIncorporation = parse(
            DATE_OF_INCORPORATION,
            'dd/MM/yyyy',
            new Date(),
          )

          return isEqual(RFCDate, dateOfIncorporation)
        }

        default:
          return true
      }
    },
    {
      message: 'validation.dateOfIncorporation.notMatchingRFC',
      path: ['DATE_OF_INCORPORATION'],
    },
  )
  .refine(
    (data) => {
      const { DATE_OF_INCORPORATION, TAX_ID_TYPE } = data

      switch (TAX_ID_TYPE) {
        case CountryTaxId.MX: {
          const dateOfIncorporation = parse(
            DATE_OF_INCORPORATION,
            'dd/MM/yyyy',
            new Date(),
          )

          const isDateInTheFuture = dateOfIncorporation > new Date()

          return !isDateInTheFuture
        }

        default:
          return true
      }
    },
    {
      message: 'validation.dateOfIncorporation.invalidDate',
      path: ['DATE_OF_INCORPORATION'],
    },
  )

type CompanyFormationStepSchema = z.infer<typeof companyFormationStepSchema>

const COMPANY_FORMATION_FORM_ID = 'company-formation-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const CompanyFormationStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,
    enabled: !!config?.name,
  })

  const stepDetails = useMemo(
    () =>
      stepDetailsQuery.data
        ?.stepDetails as CompanyFormationStepType['stepDetails'],
    [stepDetailsQuery.data],
  )

  const dateOfIncorporation = useMemo(() => {
    if (!stepDetails) {
      return ''
    }

    if (stepDetails.dateOfIncorporation) {
      return format(
        parse(
          stepDetails.dateOfIncorporation,
          'yyyy-MM-dd',
          new Date().toUTCString(),
        ),
        'dd/MM/yyyy',
      )
    }

    if (
      !stepDetails.dateOfIncorporation &&
      stepDetails.taxId &&
      stepDetails.taxIdType === CountryTaxId.MX
    ) {
      const RFCDate = parseTaxIdToDate(stepDetails.taxId)

      return format(RFCDate, 'dd/MM/yyyy')
    }

    return ''
  }, [stepDetails])

  const form = useForm<CompanyFormationStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(companyFormationStepSchema),
    values: {
      COUNTRY_OF_INCORPORATION: stepDetails?.countryOfIncorporation ?? '',
      TAX_ID: stepDetails?.taxId ?? '',
      TAX_ID_TYPE: stepDetails?.taxIdType ?? '',
      DATE_OF_INCORPORATION: dateOfIncorporation,
      FORMATION_DOCUMENTS: [new File([], '')],
      TAX_CERTIFICATE_OF_GOOD_STANDING: [new File([], '')],
    },
  })

  const onSubmit: SubmitHandler<CompanyFormationStepSchema> = async (data) => {
    const documents = stepDetailsQuery.data?.stepDetails.documents ?? []

    if (!config?.name) {
      return
    }

    const {
      COUNTRY_OF_INCORPORATION,
      DATE_OF_INCORPORATION,
      TAX_ID,
      TAX_ID_TYPE,
    } = data

    const timezoneOffset = new Date().getTimezoneOffset()

    const localTime = new Date(
      new Date().getTime() - timezoneOffset * 60 * 1000,
    )

    try {
      await mutateAsync({
        stepName: OnboardingStepName.COMPANY_FORMATION,
        stepDetails: {
          countryOfIncorporation: COUNTRY_OF_INCORPORATION,
          taxId: TAX_ID,
          taxIdType: TAX_ID_TYPE,

          dateOfIncorporation: format(
            parse(DATE_OF_INCORPORATION, 'dd/MM/yyyy', localTime),
            'yyyy-MM-dd',
          ),
          documents,
        },
      })

      const currentStepIndex = steps.findIndex(
        (step) => step.name === config.name,
      )

      const nextStep = steps[currentStepIndex + 1]

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getOnboardingState],
      })

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getOnboardingStepDetails, config.name],
      })

      navigate(
        generatePath(BusinessRoute.OnboardingStep, { step: nextStep.name }),
      )
    } catch (error) {
      notifyError(error)
    }
  }

  const fields = form.watch()

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails.documents],
  )

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredFields = config.fields.filter((field) => field.required)
    const requiredDocuments = config.documents.filter((doc) => doc.required)

    return (
      requiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) && requiredDocuments.every((doc) => getFilesByField(doc.type).length)
    )
  }, [config?.documents, config?.fields, fields, getFilesByField])

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <SlideInScreen>
      <Typography variant="h3" text="center">
        <FormattedMessage
          defaultMessage="Company formation"
          id="onboarding.companyFormation.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Submit the documents and get the company set up!"
          id="onboarding.companyFormation.subtitle"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={COMPANY_FORMATION_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Widget
            title={
              <FormattedMessage
                defaultMessage="Formation details"
                id="onboarding.companyFormation.formationDetails"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="COUNTRY_OF_INCORPORATION"
              render={({ field }) => (
                <FormItem>
                  <CountryCombobox
                    placeholder={intl.formatMessage({
                      id: 'label.countryOfIncorporation',
                      defaultMessage: 'Country of incorporation',
                    })}
                    subset="sign-up"
                    onSelect={(value) => {
                      field.onChange(value.valueAsCode)
                      form.setValue(
                        'TAX_ID_TYPE',
                        taxIdByCountry[value.valueAsCode as CountryCode],
                      )

                      if (form.getValues('DATE_OF_INCORPORATION') !== '') {
                        form.trigger('DATE_OF_INCORPORATION')
                      }
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="TAX_ID"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'label.taxId',
                        defaultMessage: 'Tax ID',
                      })}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value)

                        if (form.getValues('DATE_OF_INCORPORATION') !== '') {
                          form.trigger('DATE_OF_INCORPORATION')
                        }
                      }}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="{taxIdType, select, CUIT {CUIT} EIN {EIN} NIT {NIT (include verification digit)} RFC {RFC} RUT_CHL {RUT} RUT_URY {RUT} CNPJ {CNPJ} NIP_ESP {NIP} NIPC {NIPC} other {Tax ID}}"
                      id="auth.taxIdType"
                      values={{ taxIdType: form.getValues('TAX_ID_TYPE') }}
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="DATE_OF_INCORPORATION"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      accept="[0-9\/]+"
                      value={field.value}
                      placeholder={intl.formatMessage({
                        id: 'onboarding.field.dateOfIncorporation',
                        defaultMessage: 'Date of incorporation (DD/MM/YYYY)',
                      })}
                      onChange={(e) => {
                        field.onChange(parseInputDate(e.target.value))
                      }}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      id="onboarding.field.dateOfIncorporation"
                      defaultMessage="Date of incorporation (DD/MM/YYYY)"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                defaultMessage="Supporting documentation"
                id="onboarding.companyFormation.supportingDocuments"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="FORMATION_DOCUMENTS"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OnboardingDocumentField
                      isLoadingFiles={stepDetailsQuery.isPending}
                      title={intl.formatMessage({
                        id: 'onboarding.companyFormation.formationDocuments',
                        defaultMessage: 'Formation documents',
                      })}
                      description={intl.formatMessage({
                        id: 'onboarding.companyFormation.formationDocuments.description',
                        defaultMessage: `Submit your company's (i) certificate of incorporation, (ii) bylaws and (iii) the certificate of registry with the appropriate authority. You may upload more than one document`,
                      })}
                      step={config?.name ?? ''}
                      files={getFilesByField(field.name)}
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="TAX_CERTIFICATE_OF_GOOD_STANDING"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OnboardingDocumentField
                      isLoadingFiles={stepDetailsQuery.isPending}
                      title={intl.formatMessage({
                        id: 'onboarding.step.companyFormation.taxRegistryCertificate',
                        defaultMessage: 'Tax registration certificate',
                      })}
                      description={intl.formatMessage({
                        id: 'onboarding.companyFormation.taxCertificateOfGoodStanding.description',
                        defaultMessage:
                          'Provide the certificate of issuance of the tax identification number and a certificate of good standing upon the relevant tax authority, no older than 3 months',
                      })}
                      step={config?.name ?? ''}
                      files={getFilesByField(field.name)}
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onDrop={(files) => {
                        form.setValue(field.name, files)
                        form.trigger()
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          disabled={
            !hasAllRequiredFields ||
            isPending ||
            isSuccess ||
            !!form.formState.errors.DATE_OF_INCORPORATION
          }
          loading={isPending || isSuccess}
          form={COMPANY_FORMATION_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
