import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { getCountryISO3byISO2 } from '@/lib/country'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { submitOnboardingStep } from '../../api'
import {
  LegalRepresentativeStep,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../../types'
import { OwnerAddressFields } from '../OwnerAddressFields'

const legalRepAddressSchema = z.object({
  GOOGLE_PLACES_ADDRESS: z.string(),

  ADDRESS_STREET: z.string(),
  ADDRESS_STREET_NUMBER: z.string(),
  ADDRESS_DISTRICT: z.string(),
  ADDRESS_CITY: z.string(),
  ADDRESS_STATE: z.string(),
  ADDRESS_POST_CODE: z.string(),
  ADDRESS_COUNTRY: z.string(),
})

type LegalRepAddressSchema = z.infer<typeof legalRepAddressSchema>

const LEGAL_REPRESENTATIVE_ADDRESS_FORM_ID =
  'legal-representative-address-form-id'

type Props = {
  config?: OnboardingStepConfig
  legalRepresentativeDetails?: LegalRepresentativeStep['stepDetails']
  stepDetails?: LegalRepresentativeStep['stepDetails']
  onBack: () => void
  steps: OnboardingStep[]
}

export const LegalRepresentativeAddress = ({
  config,
  legalRepresentativeDetails,
  onBack,
  stepDetails,
  steps,
}: Props) => {
  const [inputView, setInputView] = useState<'auto' | 'manual'>('auto')

  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const form = useForm<LegalRepAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(legalRepAddressSchema),
    values: {
      GOOGLE_PLACES_ADDRESS: '',

      ADDRESS_STREET: stepDetails?.legalRepresentative?.addressStreet
        ? stepDetails.legalRepresentative?.addressStreet
        : '',
      ADDRESS_STREET_NUMBER: stepDetails?.legalRepresentative
        ?.addressStreetNumber
        ? stepDetails.legalRepresentative?.addressStreetNumber
        : '',
      ADDRESS_DISTRICT: stepDetails?.legalRepresentative?.addressDistrict
        ? stepDetails.legalRepresentative?.addressDistrict
        : '',
      ADDRESS_CITY: stepDetails?.legalRepresentative?.addressCity
        ? stepDetails.legalRepresentative?.addressCity
        : '',
      ADDRESS_STATE: stepDetails?.legalRepresentative?.addressState
        ? stepDetails.legalRepresentative?.addressState
        : '',
      ADDRESS_POST_CODE: stepDetails?.legalRepresentative?.addressPostCode
        ? stepDetails.legalRepresentative?.addressPostCode
        : '',
      ADDRESS_COUNTRY: stepDetails?.legalRepresentative?.addressCountry
        ? stepDetails.legalRepresentative?.addressCountry
        : '',
    },
  })

  useEffect(() => {
    if (stepDetails?.legalRepresentative?.addressStreet) {
      setInputView('manual')
    }
  }, [stepDetails?.legalRepresentative?.addressStreet])

  const onSubmit: SubmitHandler<LegalRepAddressSchema> = useCallback(
    async (formData) => {
      const {
        ADDRESS_STREET,
        ADDRESS_STREET_NUMBER,
        ADDRESS_DISTRICT,
        ADDRESS_CITY,
        ADDRESS_STATE,
        ADDRESS_POST_CODE,
        ADDRESS_COUNTRY,
      } = formData

      if (!legalRepresentativeDetails) {
        return
      }

      const stepDetails = {
        ...legalRepresentativeDetails,
        legalRepresentative: {
          ...legalRepresentativeDetails.legalRepresentative,
          addressStreet: ADDRESS_STREET,
          addressStreetNumber: ADDRESS_STREET_NUMBER,
          addressDistrict: ADDRESS_DISTRICT,
          addressCity: ADDRESS_CITY,
          addressState: ADDRESS_STATE,
          addressPostCode: ADDRESS_POST_CODE,
          addressCountry: getCountryISO3byISO2(ADDRESS_COUNTRY),
        },
      }

      try {
        await mutateAsync({
          stepName: OnboardingStepName.LEGAL_REPRESENTATIVE,
          stepDetails,
        })

        const currentStepIndex = steps.findIndex(
          (step) => step.name === config?.name,
        )

        const nextStep = steps[currentStepIndex + 1]

        await queryClient.refetchQueries({
          queryKey: [queryKeys.getOnboardingState],
        })

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
        })

        navigate(
          generatePath(BusinessRoute.OnboardingStep, { step: nextStep.name }),
        )
      } catch (error) {
        notifyError(error)
      }
    },
    [
      config?.name,
      legalRepresentativeDetails,
      mutateAsync,
      navigate,
      notifyError,
      steps,
    ],
  )

  const fields = form.watch()

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const stepFields = [
      'ADDRESS_STREET',
      'ADDRESS_STREET_NUMBER',
      'ADDRESS_DISTRICT',
      'ADDRESS_CITY',
      'ADDRESS_STATE',
      'ADDRESS_POST_CODE',
      'ADDRESS_COUNTRY',
    ]

    const filteredRequiredFields = config.fields.filter(
      (field) => stepFields.includes(field.name) && field.required,
    )

    return filteredRequiredFields.every(
      (field) => fields[field.name as keyof typeof fields],
    )
  }, [fields, config?.fields])

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="onboarding.ownership.addressStep.title"
            defaultMessage="Provide {name}'s residential address"
            values={{
              name: legalRepresentativeDetails?.legalRepresentative?.firstName,
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="onboarding.legalRep.addressStep.subtitle"
            defaultMessage="Review carefully all fields before continuing"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form id={LEGAL_REPRESENTATIVE_ADDRESS_FORM_ID} className="w-full">
            <OwnerAddressFields
              config={config}
              inputView={inputView}
              onInputViewChange={setInputView}
              form={form}
            />
          </form>
        </Form>

        <StickyContainer>
          <Button
            disabled={!hasAllRequiredFields || isPending || isSuccess}
            loading={isPending || isSuccess}
            width="full"
            form={LEGAL_REPRESENTATIVE_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage defaultMessage="Continue" id="action.continue" />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  form.setValue('GOOGLE_PLACES_ADDRESS', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
