import { contractorApi } from '@/lib/api'

import { DolarTagLinkingState } from '../types'

type LinkDolarTagResponse = {
  id: string
  state: DolarTagLinkingState
}

export function getDolarTagLinkingState() {
  return contractorApi.get<LinkDolarTagResponse>(
    '/v1/contractor-onboarding/link-dolartag',
  )
}
