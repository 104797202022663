import { useIntl } from 'react-intl'
import { Link, To, useNavigate } from 'react-router'

import { useKeyPress } from '@/hooks/useKeyPress'
import { cn } from '@/lib/utils'

import { X } from '../icons/outline'
import { Button } from '../ui'

type ProgressProps = {
  size?: number
  progress?: number
  trackWidth?: number
  indicatorWidth?: number
  indicatorColor?: string
}

const ProgressCircle = (props: ProgressProps) => {
  const {
    size = 36,
    progress = 25,
    trackWidth = 2,
    indicatorWidth = 2,
    indicatorColor = `#1F1F1F`,
  } = props

  const center = size / 2
  const radius =
    center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth)
  const dashArray = 2 * Math.PI * radius
  const dashOffset = dashArray * ((100 - progress) / 100)

  return (
    <svg
      className="absolute -rotate-90 transition-all"
      style={{ width: size, height: size }}
    >
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke="transparent"
        strokeWidth={trackWidth}
      />
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke={indicatorColor}
        strokeWidth={indicatorWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        strokeLinecap="round"
        className="shadow-lg"
      />
    </svg>
  )
}

type Props = {
  className?: string
  to?: To
  progress?: 0 | 25 | 50 | 75 | 100
  onClick?: () => void
}

export const ProgressButton = ({ className, onClick, progress, to }: Props) => {
  const intl = useIntl()

  const navigate = useNavigate()

  useKeyPress('Escape', () => {
    if (document.body.style.pointerEvents === 'none') {
      // means any dialog is open, so we prevent closing everything
      return
    }

    if (onClick) {
      onClick()
    }

    if (to) {
      navigate(to)
    }
  })

  if (onClick) {
    return (
      <div
        className={cn(
          'absolute right-6 top-6 md:right-12 md:top-12',
          className,
        )}
      >
        <Button
          variant="ghost"
          size="inline"
          className="relative h-auto rounded-full bg-white p-2 shadow-gentle"
          aria-label={intl.formatMessage({
            id: 'action.exit',
            defaultMessage: 'Exit',
          })}
          onClick={onClick}
        >
          <X className="scale-125" />

          <ProgressCircle progress={progress} />
        </Button>
      </div>
    )
  }

  if (!to) {
    return null
  }

  return (
    <div
      className={cn('absolute right-6 top-6 md:right-12 md:top-12', className)}
    >
      <Button
        variant="ghost"
        size="inline"
        className="relative h-auto rounded-full bg-white p-2 shadow-gentle"
        aria-label={intl.formatMessage({
          id: 'action.exit',
          defaultMessage: 'Exit',
        })}
        asChild
      >
        <Link to={to}>
          <X className="scale-125" />

          <ProgressCircle progress={progress} />
        </Link>
      </Button>
    </div>
  )
}
