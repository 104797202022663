import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { BusinessRoute, ContractorRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@/shared/ui'

type Props = {
  flow?: 'business' | 'contractor'
  isOpen: boolean
}

export const TwoFactorRequiredDialog = ({
  isOpen,
  flow = 'business',
}: Props) => {
  const { showArticle } = useIntercom()
  const location = useLocation()

  const redirectLink =
    flow === 'business'
      ? BusinessRoute.DashboardSetup2fa
      : ContractorRoute.ContractorSetup2fa

  return (
    <Dialog open={isOpen}>
      <DialogContent
        hideCloseButton
        onInteractOutside={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <img
          className="mx-auto w-36 animate-shake object-contain"
          src={getImage({ name: '2fa' })}
          alt=""
          aria-hidden
        />
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Turn on two-factor authentication"
              id="2fa.dialog.required.title"
            />
          </DialogTitle>

          <DialogDescription>
            <FormattedMessage
              defaultMessage="Add an extra layer of security to your account by enabling two-factor authentication via an authenticator app"
              id="2fa.dialog.required.subtitle"
              values={{
                br: <br />,
              }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-3" />

        <Button asChild size="xl" width="full">
          <Link to={redirectLink} state={{ from: location }}>
            <FormattedMessage id="action.continue" defaultMessage="Continue" />
          </Link>
        </Button>

        <div className="p-3" />

        <Typography
          variant="body-small"
          text="center"
          className="text-neutral-gray-600"
        >
          <FormattedMessage
            defaultMessage="Why am I being asked this? {learnMore}"
            id="2fa.dialog.required.footer"
            values={{
              learnMore: (
                <Button
                  onClick={() => showArticle(10205735)}
                  className="text-xs"
                  variant="link"
                  size="inline"
                >
                  <FormattedMessage
                    defaultMessage="Learn more"
                    id="label.learnMore"
                  />
                </Button>
              ),
            }}
          />
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
