import {
  Bag,
  BusFront,
  Cart,
  ChartTrendUp,
  CreditCard,
  LightbulbOn,
  MapMark,
  MedicalKit,
  Receipt,
  Soda,
  Tv,
} from '@/shared/icons/outline'
import { Icon } from '@/types/global'

import { CardCategory } from '../types'

export const iconByCardCategory: Record<CardCategory, Icon> = {
  [CardCategory.GROCERIES]: Cart,
  [CardCategory.SHOPPING]: Bag,
  [CardCategory.RESTAURANTS]: Soda,
  [CardCategory.TRANSPORT]: BusFront,
  [CardCategory.TRAVEL]: MapMark,
  [CardCategory.ENTERTAINMENT]: Tv,
  [CardCategory.UTILITIES]: LightbulbOn,
  [CardCategory.HEALTH]: MedicalKit,
  [CardCategory.SERVICES]: Receipt,
  [CardCategory.FINANCE_AND_INVESTMENTS]: ChartTrendUp,
  [CardCategory.OTHER]: CreditCard,
}
