import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { AddBalanceDetails } from './components/AddBalanceDetails'
import { SelectAccountScreen } from './components/SelectAccountScreen'

enum Step {
  Account = 'account',
  Details = 'details',
}

type State = { type: Step.Account } | { type: Step.Details; account: string }

export const AddBalance = () => {
  const [to] = useSearchParamsValue(['to'])
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<State>(
    to
      ? {
          type: Step.Details,
          account: to,
        }
      : { type: Step.Account },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Account:
        return 50
      case Step.Details:
        return 75
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Dashboard}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Account && (
          <SelectAccountScreen
            onSelect={(account) =>
              setActiveStep({ type: Step.Details, account })
            }
          />
        )}

        {activeStep.type === Step.Details && (
          <AddBalanceDetails
            account={activeStep.account}
            onBack={() => setActiveStep({ type: Step.Account })}
          />
        )}
      </AnimatePresence>
    </FullScreen>
  )
}
