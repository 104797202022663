import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Role } from '../../types'

import { TEAM_ROLE_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const TeamRoleBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(TEAM_ROLE_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(TEAM_ROLE_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.status',
        defaultMessage: 'Status',
      })}
    >
      {searchParams
        .get(TEAM_ROLE_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(Role).find((o) => o === option)

          return intl.formatMessage(
            {
              id: 'team.role.label',
              defaultMessage:
                '{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}',
            },
            { role: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
