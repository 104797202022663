import { useState } from 'react'
import { useSearchParams } from 'react-router'

import { SEARCH_FILTER_NAME } from '@/domains/Business/components'

import { useDebounce } from './useDebounce'

export function useSearchInput(queryKey: string = SEARCH_FILTER_NAME) {
  const [searchParams, setSearchParams] = useSearchParams()

  const [search, setSearch] = useState(searchParams.get(queryKey) ?? '')

  const handleSearchQuery = (value: string) => {
    setSearchParams(
      (params) => {
        if (value.trim() === '') {
          params.delete(queryKey)
        } else {
          params.set(queryKey, value.trim())
        }

        return params
      },
      {
        preventScrollReset: true,
      },
    )
  }

  const debouncedHandleSearch = useDebounce(handleSearchQuery, 300)

  return [search, setSearch, debouncedHandleSearch] as const
}
