import { FormattedMessage } from 'react-intl'

import { Widget } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Skeleton, Typography } from '@/shared/ui'

import { useActivePaymentMethod } from '../../hooks/useActivePaymentMethod'
import { ContractorDetails } from '../../types'
import { ContractorPaymentBadge } from '../ContractorPaymentBadge'

type Props = {
  onClick: () => void
  contractor?: ContractorDetails
}

export const ContractorPaymentDetails = ({ onClick, contractor }: Props) => {
  const activePaymentMethod = useActivePaymentMethod(contractor)

  const { title, subtitle, Icon } = activePaymentMethod

  if (!contractor) {
    return (
      <Widget
        title={
          <FormattedMessage
            id="label.paymentDetails"
            defaultMessage="Payment details"
          />
        }
      >
        <div className="flex items-center justify-between">
          <Skeleton className="h-[39px] w-36" />
          <Skeleton className="h-[21px] w-36" />
        </div>
      </Widget>
    )
  }

  return (
    <Widget
      className="group"
      onClick={onClick}
      title={
        <FormattedMessage
          id="label.paymentDetails"
          defaultMessage="Payment details"
        />
      }
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex gap-2">
          <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
            <Icon className="size-5 text-neutral-gray-900" />
          </div>

          <div className="flex flex-col">
            <Typography bold>{title}</Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              {subtitle}
            </Typography>
          </div>
        </div>
        <div className="flex items-center">
          <ContractorPaymentBadge state={contractor?.paymentMethod.state} />

          <ChevronRight className="size-8 shrink-0 text-neutral-gray-600 group-hover:translate-x-1 group-hover:transition-all" />
        </div>
      </div>
    </Widget>
  )
}
