import { forwardRef, Ref, type SVGProps } from 'react'

const SvgX = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path d="M18 6 6 18M6 6l12 12" />
  </svg>
)
const ForwardRef = forwardRef(SvgX)
export default ForwardRef
