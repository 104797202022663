import { contractorApi } from '@/lib/api'

type LinkDolarTagResponse = {
  id: string
  deepLink: string
}

export function linkDolarTag() {
  return contractorApi.post<LinkDolarTagResponse>(
    '/v1/contractor-onboarding/link-dolartag',
  )
}
