import { formatLastFour } from '@/lib/card'
import { getImage } from '@/lib/images'
import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { Card, CardState } from '../../features/Cards/types'

function getCardImageUrl(card: Card) {
  const lowerCaseCardType = card.type.toLowerCase()

  switch (card.state) {
    case CardState.BLOCKED:
      return getImage({
        category: 'cards',
        name: `card-${lowerCaseCardType}-frozen`,
      })

    default:
      return getImage({
        category: 'cards',
        name: `card-${lowerCaseCardType}`,
      })
  }
}

type Props = {
  card: Card
}

export const CardCell = ({ card }: Props) => {
  const isTerminated = card.state === CardState.TERMINATED

  return (
    <div className="flex items-center gap-3 pr-1">
      <img
        className="h-6 w-8 object-contain"
        src={getCardImageUrl(card)}
        alt=""
        aria-hidden
      />
      <div className="flex max-w-44 flex-col">
        <Typography
          bold
          className={cn('line-clamp-1', isTerminated && 'line-through')}
        >
          {card.nickname}
        </Typography>
        <Typography
          variant="body-small"
          className={cn(
            'text-neutral-gray-600',
            isTerminated && 'line-through',
          )}
        >
          {formatLastFour(card.lastFourDigits)}
        </Typography>
      </div>
    </div>
  )
}
