import { useEffect } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { generatePath, useNavigate, useParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'

import { getOnboardingConfig, getOnboardingState } from './api'
import { useBusinessStateCheck } from './hooks/useBusinessStateCheck'
import {
  BusinessAddressStep,
  CompanyActivityStep,
  CompanyFormationStep,
  CompanyOwnershipStep,
  IdentityValidationStep,
  LegalRepresentativeStep,
  ReviewAndSubmitStep,
} from './steps'
import {
  OnboardingConfig,
  OnboardingState,
  OnboardingStateEnum,
  OnboardingStepName,
} from './types'

export const OnboardingSteps = () => {
  const { step } = useParams<{ step?: string }>()
  const navigate = useNavigate()

  useBusinessStateCheck()

  const [onboardingConfigQuery, onboardingStateQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getOnboardingConfig, step],
        queryFn: getOnboardingConfig,
        select: (data: AxiosResponse<OnboardingConfig>) =>
          data.data.steps.find((s) => s.name === step),
        enabled: Boolean(step),
        staleTime: 0,
      },
      {
        queryKey: [queryKeys.getOnboardingState],
        queryFn: getOnboardingState,
        select: (data: AxiosResponse<OnboardingState>) => data.data,
      },
    ],
  })

  useEffect(() => {
    const hasAnyStepInVerification = onboardingStateQuery.data?.steps.some(
      (step) => step.state === OnboardingStateEnum.VERIFICATION,
    )

    if (hasAnyStepInVerification) {
      navigate(BusinessRoute.OnboardingCompleted, { replace: true })

      return
    }

    const steps = onboardingStateQuery.data?.steps || []
    const currentStepIndex = steps.findIndex((s) => s.name === step)

    if (currentStepIndex > 0) {
      const previousStep = steps[currentStepIndex - 1]
      const currentStep = steps[currentStepIndex]

      if (
        currentStep?.state === OnboardingStateEnum.NOT_STARTED &&
        previousStep?.state === OnboardingStateEnum.IN_PROGRESS
      ) {
        navigate(
          generatePath(BusinessRoute.OnboardingStep, {
            step: previousStep.name,
          }),
        )
      }
    }
  }, [navigate, onboardingStateQuery.data?.steps, step])

  switch (step) {
    case OnboardingStepName.COMPANY_FORMATION:
      return (
        <CompanyFormationStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.BUSINESS_ADDRESS:
      return (
        <BusinessAddressStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.LEGAL_REPRESENTATIVE:
      return (
        <LegalRepresentativeStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.COMPANY_ACTIVITY:
      return (
        <CompanyActivityStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.COMPANY_OWNERSHIP:
      return (
        <CompanyOwnershipStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.IDENTITY_VALIDATION:
      return (
        <IdentityValidationStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )
    case OnboardingStepName.REVIEW_AND_SUBMIT:
      return (
        <ReviewAndSubmitStep
          config={onboardingConfigQuery.data}
          steps={onboardingStateQuery.data?.steps ?? []}
        />
      )

    default:
      return null
  }
}
