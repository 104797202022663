import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { Button, Typography } from '@/shared/ui'

import { authenticate } from '../../features/Auth/api'

const TIMER_COUNTDOWN = 60

type Props = {
  email?: string
  password?: string
}

export const ResendCode = ({ email, password }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const [countdown, setCountdown] = useState(TIMER_COUNTDOWN)

  const { mutateAsync, isPending } = useMutation({ mutationFn: authenticate })

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (countdown > 0) {
      timeoutId = setTimeout(() => setCountdown(countdown - 1), 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [countdown])

  return (
    <Typography
      variant="body-small"
      className="flex items-center justify-center gap-2"
    >
      <span className="text-neutral-gray-600">
        <FormattedMessage
          defaultMessage="Didn't receive the code?"
          id="auth.noCode"
        />
      </span>{' '}
      <Button
        disabled={countdown > 0}
        variant="link"
        size="inline"
        className="text-xs"
        onClick={() => {
          if (email && password) {
            try {
              mutateAsync({ email, password })
            } catch (error) {
              notifyError(error)
            }
          } else {
            toast.error(
              intl.formatMessage({
                defaultMessage: 'Email or password is missing',
                id: 'auth.error.missingEmailOrPassword',
              }),
            )
          }

          setCountdown(TIMER_COUNTDOWN)
        }}
      >
        {countdown === 0
          ? intl.formatMessage({
              defaultMessage: 'Resend now',
              id: 'auth.resendNow',
            })
          : isPending
            ? intl.formatMessage({
                defaultMessage: 'Sending now...',
                id: 'auth.sendNow',
              })
            : intl.formatMessage(
                {
                  defaultMessage: 'Send again in {countdown}...',
                  id: 'auth.sendAgainIn',
                },
                { countdown },
              )}
      </Button>
    </Typography>
  )
}
