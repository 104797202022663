import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getFullName } from '@/lib/typography'
import { DataTableColumnHeader } from '@/shared/ui'

import { TeamMember } from '../../types'

import { TeamNameCell } from './TeamNameCell'
import { TeamRoleCell } from './TeamRoleCell'
import { TeamStateCell } from './TeamStateCell'

export const useTeamColumns = (): ColumnDef<TeamMember>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<TeamMember>[] = useMemo(
    () => [
      {
        id: 'fullName',
        accessorFn: (row) => getFullName(row),
        cell: ({ row }) => <TeamNameCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.name',
              defaultMessage: 'Name',
            })}
          />
        ),
      },
      {
        accessorKey: 'role',
        cell: ({ row }) => <TeamRoleCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.role',
              defaultMessage: 'Role',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<TeamMember>[] = useMemo(
    () => [
      {
        id: 'fullName',
        accessorFn: (row) => getFullName(row),
        cell: ({ row }) => <TeamNameCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.name',
              defaultMessage: 'Name',
            })}
          />
        ),
      },
      {
        accessorKey: 'role',
        cell: ({ row }) => <TeamRoleCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.role',
              defaultMessage: 'Role',
            })}
          />
        ),
        size: 140,
      },
      {
        accessorKey: 'state',
        cell: ({ row }) => <TeamStateCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 110,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
