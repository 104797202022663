import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getBusinessPaymentLimits, getIdentity } from '@/domains/Business/api'
import { MotionDiv, Skeleton, Typography } from '@/shared/ui'

import { AccountBalance, MoveMoneyWidget } from '../../components'
import { useBusinessRole } from '../../hooks'
import { Identity, PaymentLimit } from '../../types'
import { getAllCards } from '../Cards/api'
import { Card } from '../Cards/types'

import { CardsBanner } from './components/CardsBanner'
import { FXRatesWidget } from './components/FXRatesWidget'
import { LimitBanner } from './components/LimitBanner'
import { TransactionsWidget } from './components/TransactionsWidget'

export const Home = () => {
  const { isAdmin } = useBusinessRole()
  const [userQuery, paymentLimitsQuery, cardsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
      {
        queryKey: [queryKeys.getBusinessPaymentLimits],
        queryFn: getBusinessPaymentLimits,
        select: (data: AxiosResponse<PaymentLimit>) => data.data,
        enabled: isAdmin,
      },
      {
        queryKey: [queryKeys.getAllCards],
        queryFn: () => getAllCards(),
        select: (data: AxiosResponse<Card[]>) => data?.data,
        enabled: isAdmin,
      },
    ],
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage id="dashboard.home.title" defaultMessage="Home" />
          </Typography>
          {userQuery.isPending ? (
            <Skeleton className="h-[21px] w-24" />
          ) : (
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="dashboard.home.subtitle"
                defaultMessage="Welcome, {name}!"
                values={{
                  name: userQuery.data?.firstName,
                }}
              />
            </Typography>
          )}
        </div>

        <MoveMoneyWidget />
      </div>

      <MotionDiv>
        <div className="p-6" />

        <AccountBalance />

        <div className="p-6" />

        {paymentLimitsQuery.data?.periodTransferLimit && cardsQuery.data && (
          <MotionDiv className="grid grid-cols-1 gap-8 pb-12 md:grid-cols-2">
            <LimitBanner
              limits={paymentLimitsQuery.data?.periodTransferLimit}
            />
            <CardsBanner cards={cardsQuery.data} />
          </MotionDiv>
        )}

        <TransactionsWidget />

        <div className="p-4" />

        <FXRatesWidget />

        <div className="p-12" />
      </MotionDiv>
    </div>
  )
}
