import { Big } from 'big.js'
import { z } from 'zod'

import { Currency } from '@/constants/currency'

import {
  ContractorFirstCycleMethod,
  ContractorPaymentFrequency,
  ContractPaymentDayType,
} from '../../../types'

export const fixedRateContractSchema = z
  .object({
    contractorRole: z.string().min(1, {
      message: 'validation.role.required',
    }),
    scopeOfWorkRole: z.string().optional(),
    scopeOfWork: z.string().min(1, {
      message: 'validation.scopeOfWork.required',
    }),
    startDate: z.string().min(1, {
      message: 'validation.startDate.required',
    }),
    paymentDetails: z.object({
      amount: z.string().min(1, {
        message: 'validation.amountOut.required',
      }),
      currency: z.nativeEnum(Currency),
      frequency: z.nativeEnum(ContractorPaymentFrequency),
      dayType: z.nativeEnum(ContractPaymentDayType),
      day: z.string().optional(),
      firstCycleMethod: z.nativeEnum(ContractorFirstCycleMethod),
      firstCycleAmount: z.string().optional(),
    }),
  })
  .refine(
    (data) => {
      const { paymentDetails } = data

      if (
        paymentDetails.dayType === ContractPaymentDayType.CUSTOM_DAY_OF_WEEK ||
        paymentDetails.dayType === ContractPaymentDayType.CUSTOM_DAY_OF_MONTH
      ) {
        return paymentDetails.day !== ''
      }

      return true
    },
    { path: ['paymentDetails', 'day'] },
  )
  .refine(
    (data) => {
      const { firstCycleMethod, firstCycleAmount } = data.paymentDetails

      if (firstCycleMethod === ContractorFirstCycleMethod.CUSTOM_AMOUNT) {
        const amount = firstCycleAmount ? firstCycleAmount : '0'

        return Big(amount).gt(0)
      }

      return true
    },
    {
      path: ['paymentDetails', 'firstCycleAmount'],
      message: 'validation.balance.minAmount',
    },
  )

export type FixedRateContractSchema = z.infer<typeof fixedRateContractSchema>
