import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { PAYMENT_TYPES_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const ContractTypeBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(PAYMENT_TYPES_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(PAYMENT_TYPES_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.contactType',
        defaultMessage: 'Contract type',
      })}
    >
      {searchParams
        .get(PAYMENT_TYPES_FILTER_NAME)
        ?.split(',')
        .map((state) => {
          return intl.formatMessage(
            {
              id: 'contract.type.label',
              defaultMessage:
                '{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}',
            },
            { state },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
