import { FormattedMessage } from 'react-intl'

import { PaymentRails } from '@/domains/Business/constants'
import { useGetBankName } from '@/domains/Business/hooks'
import { Details } from '@/shared/ui'

import { CreatedRecipient } from '../types'

type Props = {
  recipient: CreatedRecipient
}

export const RecipientPaymentDetails = ({ recipient }: Props) => {
  const getBankReference = () => {
    switch (recipient.paymentRails) {
      case PaymentRails.MEX:
        return recipient.localInformation.clabe
      case PaymentRails.USA:
        return recipient.localInformation.routingNumber
      case PaymentRails.DOLAR_APP:
        return recipient.localInformation.dolarTag
    }
  }
  const { name } = useGetBankName({
    country: recipient.country,
    bankReference: getBankReference(),
    enabled: recipient.paymentRails !== PaymentRails.DOLAR_APP,
  })

  switch (recipient.paymentRails) {
    case PaymentRails.MEX:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
            </Details.Label>

            <Details.Value>{recipient.localInformation.clabe}</Details.Value>
          </Details>
          {name ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value>{name}</Details.Value>
            </Details>
          ) : null}
        </>
      )

    case PaymentRails.USA:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="ABA / Routing number"
                id="label.routingNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.localInformation.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>

            <Details.Value>
              {recipient.localInformation.accountNumber}
            </Details.Value>
          </Details>
          {name ? (
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Bank" id="label.bank" />
              </Details.Label>

              <Details.Value>{name}</Details.Value>
            </Details>
          ) : null}
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Recipient address"
                id="label.recipientAddress"
              />
            </Details.Label>
            <Details.Value className="max-w-72 whitespace-break-spaces text-right">
              {[
                recipient.addressStreet,
                recipient.addressCity,
                recipient.addressState,
                recipient.addressZipCode,
              ].join(', ')}
            </Details.Value>
          </Details>
        </>
      )

    case PaymentRails.DOLAR_APP:
      return null

    default:
      return null
  }
}
