import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { cn } from '@/lib/utils'
import { Skeleton, Typography } from '@/shared/ui'

import { getAccount } from '../../features/Accounts/api'
import { Transaction } from '../../features/Transactions/types'
import { AccountIcon } from '../AccountIcon'

type Props = {
  transaction: Transaction
}

export const WalletCell = ({ transaction }: Props) => {
  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) =>
      data?.data.wallets.find((wallet) => wallet.id === transaction.walletId),
  })

  return (
    <div className="flex max-w-36 items-center gap-2">
      {accountQuery.isPending ? (
        <Skeleton className="h-[21px] w-24" />
      ) : (
        <div className="flex max-w-36 items-center gap-2">
          <AccountIcon id={accountQuery.data?.id ?? ''} />
          <Typography
            title={accountQuery.data?.label}
            className={cn('line-clamp-1')}
          >
            {accountQuery.data?.label}
          </Typography>
        </div>
      )}
    </div>
  )
}
