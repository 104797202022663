import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'

import { Permission } from '../types'

import { WithPermissions } from './WithPermissions'

export const EmptyTransactionsCard = () => {
  const location = useLocation()
  const params = useParams<{ id: string }>()

  const addBalanceUrl = params.id
    ? `${BusinessRoute.AddBalance}?to=${params.id}`
    : BusinessRoute.AddBalance

  return (
    <Card
      className="flex h-auto w-full flex-wrap items-center justify-between gap-3 overflow-hidden md:h-28 md:flex-nowrap md:gap-0"
      size="large"
    >
      <div className="flex flex-wrap items-center gap-8">
        <img
          className="relative -bottom-4 hidden w-36 object-contain md:block"
          src={getImage({
            name: 'empty-transactions',
            category: 'transactions',
          })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="transactions.empty"
              defaultMessage="You don't have any transactions yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="transactions.empty.addBalance"
              defaultMessage="Add balance to get started"
            />
          </Typography>
        </div>
      </div>
      <WithPermissions permissions={[Permission.TRANSFERS]}>
        <Button leftIcon={<Plus className="size-5" />} asChild>
          <Link to={addBalanceUrl} state={{ from: location }}>
            <FormattedMessage
              id="action.addBalance"
              defaultMessage="Add balance"
            />
          </Link>
        </Button>
      </WithPermissions>
    </Card>
  )
}
