import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { generatePath, Link, useParams } from 'react-router'

import { PAGE_SIZE } from '@/constants/pagination'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  ACCOUNTS_FILTER_NAME,
  EmptyTransactionsCard,
  TransactionDetailsSidebar,
  TransactionsTable,
} from '@/domains/Business/components'
import { Transaction } from '@/domains/Business/features/Transactions/types'
import { useTransactionUtils } from '@/domains/Business/hooks'
import { getAnimationKey } from '@/lib/utils'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, MotionDiv } from '@/shared/ui'

import { getTransactionByType, getTransactions } from '../../Transactions/api'

import { useAccountsTransactionColumns } from './useAccountTransactionsColumns'

export const AccountTransactionsList = () => {
  const columns = useAccountsTransactionColumns()
  const {
    closeSidebar,
    openSidebar,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const { id: walletId } = useParams<{ id: string }>()

  const { data, isPending, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.getTransactions, walletId],
    queryFn: ({ pageParam }) =>
      getTransactions({
        [ACCOUNTS_FILTER_NAME]: walletId,
        page: String(pageParam),
        limit: String(PAGE_SIZE),
      }),
    select: (data) => data.pages.flatMap((page) => page.data),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.data?.length < PAGE_SIZE) {
        return undefined
      }

      return lastPageParam + 1
    },
  })

  const singleTransaction = useQuery({
    queryKey: [
      queryKeys.getTransaction,
      transactionIdFromQuery,
      transactionTypeFromQuery,
    ],
    queryFn: () =>
      getTransactionByType({
        type: transactionTypeFromQuery,
        id: transactionIdFromQuery,
      }),
    select: (data: AxiosResponse<Transaction>) => data?.data,
    enabled: !!transactionIdFromQuery,
  })

  return (
    <>
      <MotionDiv key={getAnimationKey(isPending)}>
        {data?.length === 0 ? (
          <EmptyTransactionsCard />
        ) : (
          <>
            <TransactionsTable
              isLoading={isPending || (!isFetchingNextPage && isFetching)}
              isLoadingMore={isFetchingNextPage}
              onRowClick={openSidebar}
              columns={columns}
              loaderOptions={{ rows: 4 }}
              data={data?.slice(0, 4) ?? []}
            />

            <Card className="relative -top-5" size="large">
              <Button
                rightIcon={<ChevronRight />}
                size="md"
                variant="secondary"
                asChild
              >
                <Link
                  to={generatePath(BusinessRoute.AccountTransactions, {
                    id: walletId,
                  })}
                >
                  <FormattedMessage
                    id="action.seeAll"
                    defaultMessage="See all"
                  />
                </Link>
              </Button>
            </Card>
          </>
        )}
      </MotionDiv>

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </>
  )
}
