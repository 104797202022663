import { FormattedMessage } from 'react-intl'

import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { Role } from '../../../types'

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
}

export const RoleSelectField = ({ disabled, onChange, value }: Props) => {
  return (
    <Select disabled={disabled} onValueChange={onChange} value={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage id="label.role" defaultMessage="Role" />
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="team.role.label"
                  defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                  values={{ role: value }}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue>
            <FormattedMessage id="label.role" defaultMessage="Role" />
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(Role).map((role) => (
          <SelectItem
            hideChecked
            className="flex h-[54px] flex-col items-start"
            key={role}
            value={role}
          >
            <Typography>
              <FormattedMessage
                id="team.role.label"
                defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                values={{ role }}
              />
            </Typography>
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="team.role.label.description"
                defaultMessage="{role, select, ADMIN {Full access to all of the account features. Ideal for owners and C-Suite} PAYMENT_OPS {Payment capabilities with customizable permissions. Perfect for the finance team} CARD_USER {Assign customizable cards. Great for travel expenses and procurement} READ_ONLY {Read-only access to the whole account. Ideal for bookkeepers} other {}}"
                values={{ role }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
