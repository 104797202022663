import { useQuery } from '@tanstack/react-query'
import { generatePath, useSearchParams } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  CARDS_ID_FILTER_NAME,
  TransactionsShortList,
} from '@/domains/Business/components'
import { getTransactions } from '@/domains/Business/features/Transactions/api'
import { SingleTransaction } from '@/domains/Business/features/Transactions/types'
import { getAnimationKey } from '@/lib/utils'
import { MotionDiv } from '@/shared/ui'

export const CardTransactionShortList = () => {
  const [searchParams] = useSearchParams()
  const cardId = searchParams.get('id')

  const cardTransactionsQuery = useQuery({
    queryKey: [queryKeys.getCardTransactions, cardId],
    queryFn: () =>
      getTransactions({ [CARDS_ID_FILTER_NAME]: cardId ?? undefined }),
    select: (data) => data?.data as SingleTransaction[],
    enabled: !!cardId,
  })

  return (
    <MotionDiv
      className="pt-6"
      key={getAnimationKey(cardTransactionsQuery.isPending)}
    >
      <TransactionsShortList
        isPending={cardTransactionsQuery.isPending}
        transactions={cardTransactionsQuery.data ?? []}
        path={generatePath(BusinessRoute.CardsTransactions, {
          id: cardId,
        })}
        limit={4}
      />
    </MotionDiv>
  )
}
