import { RecipientAccountAvatar } from '@/domains/Business/components'
import { PaymentRails } from '@/domains/Business/constants'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import {
  getRecipientAccountNumber,
  getRecipientPaymentMethod,
} from '@/lib/recipient'
import { intersperse } from '@/lib/typography'
import { CountryWithFlag } from '@/shared/components'
import { MotionDiv, Skeleton, Typography } from '@/shared/ui'

type Props = {
  recipient?: Recipient
}

export const AmountScreenSubtitle = ({ recipient }: Props) => {
  return recipient ? (
    <MotionDiv className="flex items-center justify-center gap-1 text-neutral-gray-600">
      {(() => {
        switch (recipient?.paymentRails) {
          case PaymentRails.DOLAR_APP:
            return intersperse(
              [
                <div key="paymentMethod" className="flex items-center gap-1">
                  <RecipientAccountAvatar
                    paymentRails={recipient.paymentRails}
                  />
                  <Typography>
                    {getRecipientPaymentMethod(recipient)}
                  </Typography>
                </div>,
                <Typography key="accountNumber">
                  {getRecipientAccountNumber(recipient)}
                </Typography>,
              ],
              ' • ',
            )
          case PaymentRails.MEX:
          case PaymentRails.USA:
            return (
              <>
                <CountryWithFlag country={recipient.country} />
                <Typography>{getRecipientAccountNumber(recipient)}</Typography>
              </>
            )

          default:
            return null
        }
      })()}
    </MotionDiv>
  ) : (
    <Skeleton className="mx-auto h-[22px] w-48" />
  )
}
