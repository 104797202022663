import { cn } from '@/lib/utils'
import { DolarappWhite } from '@/shared/icons/outline'

type Props = {
  size?: 'sm' | 'md'
  className?: string
}

export const DolarAppAvatar = ({ size = 'sm', className }: Props) => {
  return (
    <div
      className={cn(
        'flex size-3 items-center shrink-0 justify-center rounded-full bg-primary',
        size === 'sm' && 'size-3',
        className,
      )}
    >
      <DolarappWhite
        className={cn('size-2 text-white', size === 'sm' && 'size-2')}
      />
    </div>
  )
}
