import { iconByRecipientType, PaymentRails } from '@/domains/Business/constants'
import { getFullName } from '@/lib/typography'
import { CountryCode } from '@/types/country'

import {
  BusinessCreatedDolarAppRecipient,
  CreatedRecipient,
  DolarAppEntityType,
  DolarAppSearchRecipient,
  EntityType,
  IndividualCreatedDolarAppRecipient,
  Recipient,
} from '../types'

export function getRecipientName(recipient?: Recipient | CreatedRecipient) {
  if (!recipient) {
    return undefined
  }

  if (
    recipient.entityType === EntityType.INDIVIDUAL &&
    recipient.firstName &&
    recipient.lastName
  ) {
    return getFullName(recipient)
  }

  if (recipient.email) {
    return recipient.email
  }

  return undefined
}

export function getRecipientTitle(recipient?: Recipient | CreatedRecipient) {
  if (!recipient) {
    return undefined
  }

  switch (recipient.entityType) {
    case EntityType.INDIVIDUAL:
      return getRecipientName(recipient)

    case EntityType.BUSINESS:
      return recipient.legalName
        ? recipient.legalName
        : getRecipientName(recipient)

    default:
      return undefined
  }
}

export function getIconByEntityType(
  entityType?: EntityType | DolarAppEntityType,
) {
  if (!entityType) {
    return iconByRecipientType[EntityType.INDIVIDUAL]
  }

  if (entityType === DolarAppEntityType.RETAIL) {
    return iconByRecipientType[EntityType.INDIVIDUAL]
  }

  return iconByRecipientType[entityType]
}

function parseDisplayName(displayName: string) {
  const parts = displayName.split(' ')

  if (parts.length > 2) {
    const [firstName, ...middleParts] = parts
    const lastName = middleParts.pop()
    const secondLastName = middleParts.join(' ')

    return {
      firstName,
      secondLastName: secondLastName === '' ? undefined : secondLastName,
      lastName: lastName ?? '',
    }
  }

  return { firstName: parts[0], lastName: parts[1] }
}
export function parseDolarAppRecipient(
  dolarAppRecipient: DolarAppSearchRecipient,
):
  | IndividualCreatedDolarAppRecipient
  | BusinessCreatedDolarAppRecipient
  | undefined {
  switch (dolarAppRecipient.owner.type) {
    case DolarAppEntityType.RETAIL: {
      const { firstName, secondLastName, lastName } = parseDisplayName(
        dolarAppRecipient.owner.displayName,
      )

      return {
        country: CountryCode.MX,
        paymentRails: PaymentRails.DOLAR_APP,
        localInformation: { dolarTag: dolarAppRecipient.alias.value },
        nickname: dolarAppRecipient.owner.displayName,
        entityType: EntityType.INDIVIDUAL,
        firstName,
        secondLastName,
        lastName,
      }
    }

    case DolarAppEntityType.BUSINESS:
      return {
        country: CountryCode.MX,
        paymentRails: PaymentRails.DOLAR_APP,
        localInformation: { dolarTag: dolarAppRecipient.alias.value },
        nickname: dolarAppRecipient.owner.displayName,
        entityType: EntityType.BUSINESS,
        legalName: dolarAppRecipient.owner.displayName,
      }

    default:
      return undefined
  }
}
