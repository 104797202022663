import { cn, parseAdditionalDetails } from '@/lib/utils'
import { CreditCard } from '@/shared/icons/outline'
import { AvatarSize } from '@/shared/ui'

import { iconByCardCategory } from '../features/Cards/constants'
import { CardCategory } from '../features/Cards/types'

type Props = {
  additionalDetails?: string
  size?: AvatarSize
}

export const CardTransactionIcon = ({ additionalDetails, size }: Props) => {
  const parsedAdditionalDetails = parseAdditionalDetails<{
    merchantCategory?: string
    merchantLogoUrl?: string
  }>(additionalDetails)

  const iconProps = {
    className: cn('size-5', {
      'size-8': size === 'xl',
    }),
  }

  if (parsedAdditionalDetails?.merchantLogoUrl) {
    return (
      <img
        className="size-full rounded-full object-contain"
        src={parsedAdditionalDetails.merchantLogoUrl}
        alt=""
      />
    )
  }

  if (parsedAdditionalDetails?.merchantCategory) {
    const Icon =
      iconByCardCategory[
        parsedAdditionalDetails.merchantCategory as CardCategory
      ]

    return <Icon {...iconProps} />
  }

  return <CreditCard {...iconProps} />
}
