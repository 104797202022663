import { isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'

import { AccountIcon } from '@/domains/Business/components'
import { formatDate } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Document } from '@/shared/icons/outline'
import { Document as DocumentSolid } from '@/shared/icons/solid'
import {
  Avatar,
  Card,
  Details,
  MotionDiv,
  Sheet,
  SheetContent,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { HistoricalPayment } from '../../types'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  payment?: HistoricalPayment
}

export const HistoricalPaymentDetailsSidebar = ({
  isOpen,
  onOpenChange,
  payment,
}: Props) => {
  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <SlideInLeft className="flex flex-col gap-6">
          <>
            <Typography className="text-neutral-gray-400">
              <FormattedMessage
                id="label.contractors"
                defaultMessage="Contractors"
              />
            </Typography>

            {payment ? (
              <MotionDiv className="flex items-center gap-3">
                <Avatar
                  variant="dark-neutral"
                  size="xl"
                  icon={<Document className="size-8" />}
                />
                <div className="flex flex-col">
                  <Typography variant="h3">
                    {formatAmount({
                      amount: payment.baseAmount,
                      currency: payment.baseCurrency,
                    })}
                  </Typography>
                  <Typography className="text-neutral-gray-600">
                    {intersperse(
                      [
                        formatDate(
                          payment.startDate,
                          isSameYear(payment.startDate, payment.endDate)
                            ? 'dd MMM.'
                            : 'dd MMM. yyyy',
                        ),
                        formatDate(payment.endDate, 'dd MMM. yyyy'),
                      ],
                      ' - ',
                    )}
                  </Typography>
                </div>
              </MotionDiv>
            ) : null}

            {payment ? (
              <MotionDiv>
                <Card className="flex flex-col gap-4" size="medium">
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.paymentCycle"
                        defaultMessage="Payment cycle"
                      />
                    </Details.Label>
                    <Details.Value>
                      <FormattedMessage
                        id="contractor.paymentFrequency.label"
                        defaultMessage="{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}"
                        values={{
                          paymentFrequency: payment.frequency,
                        }}
                      />
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.contractors"
                        defaultMessage="Contractors"
                      />
                    </Details.Label>
                    <Details.Value>{payment.totalContractors}</Details.Value>
                  </Details>

                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.account"
                        defaultMessage="Account"
                      />
                    </Details.Label>
                    <div className="flex items-center gap-2">
                      <AccountIcon id={payment.id} />
                      <Typography>Main Account</Typography>
                    </div>
                  </Details>

                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.paymentDueDate"
                        defaultMessage="Payment due date"
                      />
                    </Details.Label>

                    <Details.Value>
                      {formatDate(payment.paymentDate, 'dd MMM. yyyy')}
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.invoice"
                        defaultMessage="Invoice"
                      />
                    </Details.Label>

                    <div className="flex items-center gap-1">
                      <DocumentSolid className="size-4" />
                      <Typography className="underline">
                        <FormattedMessage
                          id="action.download"
                          defaultMessage="Download"
                        />
                      </Typography>
                    </div>
                  </Details>

                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.paidOn"
                        defaultMessage="Paid on"
                      />
                    </Details.Label>

                    <Details.Value>
                      {formatDate(payment.paymentDate, 'dd MMM. yyyy HH:mm:ss')}
                    </Details.Value>
                  </Details>
                </Card>
              </MotionDiv>
            ) : null}

            {payment ? (
              <MotionDiv>
                <Card className="flex flex-col gap-4" size="medium">
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="label.amountPaid"
                        defaultMessage="Amount paid"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatAmount({
                        amount: payment.baseAmount,
                        currency: payment.baseCurrency,
                      })}
                    </Details.Value>
                  </Details>
                  <Details>
                    <Details.Label>
                      <FormattedMessage id="label.fee" defaultMessage="Fee" />
                    </Details.Label>
                    <Typography bold className="uppercase text-primary">
                      <FormattedMessage id="label.free" defaultMessage="Free" />
                    </Typography>
                  </Details>
                </Card>
              </MotionDiv>
            ) : null}

            {/* <MotionDiv>
              <TransactionsShortList
                transactions={allTransactions}
                path={generatePath(BusinessRoute.BulkPaymentTransactions, {
                  id: transaction?.groupId,
                })}
              />
            </MotionDiv> */}
          </>
        </SlideInLeft>
      </SheetContent>
    </Sheet>
  )
}
