import { useCallback, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'
import { z } from 'zod'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import { GoBackButton, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SlideInScreen,
  StickyContainer,
  Textarea,
  Typography,
} from '@/shared/ui'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { ConfirmActivityField } from '../components/ConfirmActivityField'
import { HighRiskActivitiesScreen } from '../components/HighRiskActivitiesScreen'
import { IndustryField } from '../components/IndustryField'
import { OnboardingDocumentField } from '../components/OnboardingDocumentField'
import { OnboardingLoader } from '../components/OnboardingLoader'
import { ProhibitedActivitiesScreen } from '../components/ProhibitedActivitiesScreen'
import { ProhibitedCountriesScreen } from '../components/ProhibitedCountriesScreen'
import { SourceOfFundsDescription } from '../components/SourceOfFundsDescription'
import { SourceOfFundsMultiSelectField } from '../components/SourceOfFundsMultiSelectField'
import {
  CompanyActivityStep as CompanyActivityStepType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../types'

const companyActivityStepSchema = z.object({
  ACTIVITY_TYPE: z.string(),
  SOURCES_OF_FUNDS: z.array(z.string()),
  COMPANY_WEBSITE: z.string().refine(
    (value) => {
      const REGEX =
        /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/g

      return new RegExp(REGEX).test(value)
    },
    { message: 'validation.url.invalid' },
  ),
  EXPECTED_MONTHLY_VOLUME: z.string(),
  BUSINESS_DESCRIPTION: z.string().min(50),
  OPERATES_IN_HIGH_RISK_ACTIVITIES: z.boolean(),
  OPERATES_IN_PROHIBITED_ACTIVITIES: z.boolean(),
  OPERATES_IN_PROHIBITED_COUNTRIES: z.boolean(),

  SOURCE_OF_FUNDS: z.any(),
})

type CompanyActivityStepSchema = z.infer<typeof companyActivityStepSchema>

const COMPANY_ACTIVITY_FORM_ID = 'company-activity-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const CompanyActivityStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const [dialogType, setDialogType] = useState<
    'highRisk' | 'prohibited' | 'prohibitedCountries'
  >()

  const onCloseDialog = () => setDialogType(undefined)

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,
    enabled: !!config?.name,
  })

  const stepDetails = useMemo(
    () =>
      stepDetailsQuery.data
        ?.stepDetails as CompanyActivityStepType['stepDetails'],
    [stepDetailsQuery.data],
  )

  const form = useForm<CompanyActivityStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(companyActivityStepSchema),
    values: {
      ACTIVITY_TYPE: stepDetails?.activityType ?? '',
      SOURCES_OF_FUNDS: stepDetails?.sourcesOfFunds ?? [],
      COMPANY_WEBSITE: stepDetails?.website ?? '',
      BUSINESS_DESCRIPTION: stepDetails?.description ?? '',
      EXPECTED_MONTHLY_VOLUME: stepDetails?.expectedVolume ?? '',
      OPERATES_IN_HIGH_RISK_ACTIVITIES:
        stepDetails?.operatesInHighRiskActivities ?? false,
      OPERATES_IN_PROHIBITED_ACTIVITIES:
        stepDetails?.operatesInProhibitedActivities ?? false,
      OPERATES_IN_PROHIBITED_COUNTRIES:
        stepDetails?.operatesInProhibitedCountries ?? false,

      SOURCE_OF_FUNDS: [new File([], '')],
    },
  })

  const onSubmit: SubmitHandler<CompanyActivityStepSchema> = useCallback(
    async (data) => {
      const documents = stepDetailsQuery.data?.stepDetails.documents ?? []

      if (!config?.name) {
        return
      }

      const {
        ACTIVITY_TYPE,
        COMPANY_WEBSITE,
        EXPECTED_MONTHLY_VOLUME,
        SOURCES_OF_FUNDS,
        BUSINESS_DESCRIPTION,
        OPERATES_IN_HIGH_RISK_ACTIVITIES,
        OPERATES_IN_PROHIBITED_ACTIVITIES,
        OPERATES_IN_PROHIBITED_COUNTRIES,
      } = data

      try {
        await mutateAsync({
          stepName: OnboardingStepName.COMPANY_ACTIVITY,
          stepDetails: {
            activityType: ACTIVITY_TYPE,
            website: COMPANY_WEBSITE,
            expectedVolume: EXPECTED_MONTHLY_VOLUME,
            sourcesOfFunds: SOURCES_OF_FUNDS,
            description: BUSINESS_DESCRIPTION,
            operatesInHighRiskActivities: OPERATES_IN_HIGH_RISK_ACTIVITIES,
            operatesInProhibitedActivities: OPERATES_IN_PROHIBITED_ACTIVITIES,
            operatesInProhibitedCountries: OPERATES_IN_PROHIBITED_COUNTRIES,
            documents,
          },
        })

        const currentStepIndex = steps.findIndex(
          (step) => step.name === config.name,
        )

        const nextStep = steps[currentStepIndex + 1]

        await queryClient.refetchQueries({
          queryKey: [queryKeys.getOnboardingState],
        })

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getOnboardingStepDetails, config.name],
        })

        navigate(
          generatePath(BusinessRoute.OnboardingStep, { step: nextStep.name }),
        )
      } catch (error) {
        notifyError(error)
      }
    },
    [
      config?.name,
      navigate,
      notifyError,
      stepDetailsQuery.data?.stepDetails.documents,
      steps,
      mutateAsync,
    ],
  )

  const fields = form.watch()

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails.documents],
  )

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredFields = config.fields
      .filter((field) => field.required)
      .filter(
        (field) =>
          ![
            'OPERATES_IN_HIGH_RISK_ACTIVITIES',
            'OPERATES_IN_PROHIBITED_ACTIVITIES',
            'OPERATES_IN_PROHIBITED_COUNTRIES',
          ].includes(field.name),
      )

    const requiredDocuments = config.documents.filter((doc) => doc.required)

    return (
      requiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) &&
      fields['SOURCES_OF_FUNDS'].length > 0 &&
      requiredDocuments.every((doc) => getFilesByField(doc.type).length)
    )
  }, [config?.documents, config?.fields, fields, getFilesByField])

  const getPreviousStep = () => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === config?.name,
    )

    return steps[currentStepIndex - 1]
  }

  const allowedValuesByField = useCallback(
    (fieldName: string) => {
      return (
        config?.fields.find((field) => field.name === fieldName)
          ?.allowedValues ?? []
      )
    },
    [config?.fields],
  )

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(BusinessRoute.OnboardingStep, {
          step: getPreviousStep()?.name ?? '',
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Company activity"
            id="onboarding.companyActivity.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="What is the company's industry and size? Help us give you higher limits"
            id="onboarding.companyActivity.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={COMPANY_ACTIVITY_FORM_ID}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Activity details"
                  id="onboarding.companyActivity.activityDetails"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="ACTIVITY_TYPE"
                render={({ field }) => {
                  return (
                    <IndustryField
                      industries={allowedValuesByField(field.name)}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Industry',
                        id: 'label.industry',
                      })}
                      value={field.value}
                      onSelect={field.onChange}
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="SOURCES_OF_FUNDS"
                render={({ field }) => {
                  return (
                    <SourceOfFundsMultiSelectField
                      sources={allowedValuesByField(field.name)}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Source of funds',
                        id: 'label.sourceOfFunds',
                      })}
                      value={field.value}
                      onSelect={field.onChange}
                    />
                  )
                }}
              />

              <FormField
                control={form.control}
                name="EXPECTED_MONTHLY_VOLUME"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          {field.value ? (
                            <div className="flex flex-col items-start">
                              <Typography variant="body-tiny">
                                <FormattedMessage
                                  id="label.monthlyTransactionVolume"
                                  defaultMessage="Monthly transaction volume"
                                />
                              </Typography>
                              <Typography className="text-neutral-gray-900">
                                <FormattedMessage
                                  id="monthlyTransactionVolume.label"
                                  defaultMessage="{method, select, LESS_THAN_10K {Up to $10,000.00 USD} BETWEEN_10K_50K {Between $10,000.00 USD and $50,000.00 USD} BETWEEN_50K_100K {Between $50,000.00 USD and $100,000.00 USD} BETWEEN_100K_250K {Between $100,000.00 USD and $250,000.00 USD} BETWEEN_250K_500K {Between $250,000.00 USD and $500,000.00 USD} MORE_THAN_500K {More than $500,000.00 USD} other {}}"
                                  values={{ method: field.value }}
                                />
                              </Typography>
                            </div>
                          ) : (
                            <SelectValue
                              placeholder={intl.formatMessage({
                                id: 'label.monthlyTransactionVolume',
                                defaultMessage: 'Monthly transaction volume',
                              })}
                            />
                          )}
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {allowedValuesByField(field.name).map((method) => (
                          <SelectItem
                            disabled={method === field.value}
                            key={method}
                            value={method}
                          >
                            <Typography>
                              <FormattedMessage
                                id="monthlyTransactionVolume.label"
                                defaultMessage="{method, select, LESS_THAN_10K {Up to $10,000.00 USD} BETWEEN_10K_50K {Between $10,000.00 USD and $50,000.00 USD} BETWEEN_50K_100K {Between $50,000.00 USD and $100,000.00 USD} BETWEEN_100K_250K {Between $100,000.00 USD and $250,000.00 USD} BETWEEN_250K_500K {Between $250,000.00 USD and $500,000.00 USD} MORE_THAN_500K {More than $500,000.00 USD} other {}}"
                                values={{ method }}
                              />
                            </Typography>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="COMPANY_WEBSITE"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Company website or social network',
                          id: 'label.companyWebsite',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Company website or social network"
                        id="label.companyWebsite"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="BUSINESS_DESCRIPTION"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        className="relative"
                        hasError={!!form.formState.errors.BUSINESS_DESCRIPTION}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Business description',
                          id: 'label.businessDescription',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel size="textarea">
                      <FormattedMessage
                        defaultMessage="Business description"
                        id="label.businessDescription"
                      />
                    </AnimatedFormLabel>

                    {field.value.length > 50 ? null : (
                      <Typography
                        variant="body-small"
                        className={cn(
                          'absolute bottom-3 right-4 text-neutral-gray-600',
                          form.formState.errors.BUSINESS_DESCRIPTION &&
                            'text-primary-error',
                        )}
                      >
                        <FormattedMessage
                          defaultMessage="Min. 50 characters"
                          id="label.min50Characters"
                        />
                      </Typography>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="OPERATES_IN_HIGH_RISK_ACTIVITIES"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ConfirmActivityField
                        title={intl.formatMessage({
                          defaultMessage:
                            'Does your company operate in any high-risk activity?',
                          id: 'companyActivity.highRisk.title',
                        })}
                        description={intl.formatMessage(
                          {
                            defaultMessage:
                              'Check the list of high risk activities {link}',
                            id: 'companyActivity.highRisk.description',
                          },
                          {
                            link: (
                              <Button
                                type="button"
                                variant="ghost"
                                size="inline"
                                className="text-xs underline"
                                onClick={() => setDialogType('highRisk')}
                              >
                                <FormattedMessage
                                  defaultMessage="here"
                                  id="label.lowercase.here"
                                />
                              </Button>
                            ),
                          },
                        )}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="OPERATES_IN_PROHIBITED_ACTIVITIES"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ConfirmActivityField
                        title={intl.formatMessage({
                          defaultMessage:
                            'Does your company operate in any prohibited activity?',
                          id: 'companyActivity.prohibitedActivity.title',
                        })}
                        description={intl.formatMessage(
                          {
                            defaultMessage:
                              'Check the list of prohibited activities {link}',
                            id: 'companyActivity.prohibitedActivity.description',
                          },
                          {
                            link: (
                              <Button
                                type="button"
                                variant="ghost"
                                size="inline"
                                className="text-xs underline"
                                onClick={() => setDialogType('prohibited')}
                              >
                                <FormattedMessage
                                  defaultMessage="here"
                                  id="label.lowercase.here"
                                />
                              </Button>
                            ),
                          },
                        )}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="OPERATES_IN_PROHIBITED_COUNTRIES"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ConfirmActivityField
                        title={intl.formatMessage({
                          defaultMessage:
                            'Does your company operate in any prohibited country?',
                          id: 'companyActivity.prohibitedCountry.title',
                        })}
                        description={intl.formatMessage(
                          {
                            defaultMessage:
                              'Check the list of high risk countries  {link}',
                            id: 'companyActivity.prohibitedCountry.description',
                          },
                          {
                            link: (
                              <Button
                                type="button"
                                variant="ghost"
                                size="inline"
                                className="text-xs underline"
                                onClick={() =>
                                  setDialogType('prohibitedCountries')
                                }
                              >
                                <FormattedMessage
                                  defaultMessage="here"
                                  id="label.lowercase.here"
                                />
                              </Button>
                            ),
                          },
                        )}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Widget>

            <div className="p-4" />

            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Supporting documentation"
                  id="onboarding.companyFormation.supportingDocuments"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="SOURCE_OF_FUNDS"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <OnboardingDocumentField
                        isLoadingFiles={stepDetailsQuery.isPending}
                        title={intl.formatMessage({
                          id: 'onboarding.companyActivity.sourceOfFunds',
                          defaultMessage: 'Source of funds',
                        })}
                        descriptionComponent={<SourceOfFundsDescription />}
                        step={config?.name ?? ''}
                        files={getFilesByField(field.name)}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        onDrop={(files) => {
                          form.setValue(field.name, files)
                          form.trigger()
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Widget>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={
              !hasAllRequiredFields ||
              isPending ||
              isSuccess ||
              Object.keys(form.formState.errors).length !== 0
            }
            loading={isPending || isSuccess}
            form={COMPANY_ACTIVITY_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>

      <HighRiskActivitiesScreen
        isOpen={dialogType === 'highRisk'}
        activities={allowedValuesByField('OPERATES_IN_HIGH_RISK_ACTIVITIES')}
        onOpenChange={onCloseDialog}
      />

      <ProhibitedActivitiesScreen
        isOpen={dialogType === 'prohibited'}
        activities={allowedValuesByField('OPERATES_IN_PROHIBITED_ACTIVITIES')}
        onOpenChange={onCloseDialog}
      />

      <ProhibitedCountriesScreen
        isOpen={dialogType === 'prohibitedCountries'}
        countries={allowedValuesByField('OPERATES_IN_PROHIBITED_COUNTRIES')}
        onOpenChange={onCloseDialog}
      />
    </>
  )
}
