import { FormattedMessage } from 'react-intl'

import { getImage } from '@/lib/images'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

type Props = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const DolarAppLinkingDialog = ({ isOpen, onOpenChange }: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton>
        <img
          className="mx-auto w-36 animate-shake object-contain"
          src={getImage({ name: '2fa' })}
          alt=""
          aria-hidden
        />
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="Waiting for confirmation..."
              id="contractor.connectDolarApp.pending.title"
            />
          </DialogTitle>

          <DialogDescription>
            <FormattedMessage
              defaultMessage="Confirm that you want to link your DolarApp account directly from the app"
              id="contractor.connectDolarApp.pending.subtitle"
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-2" />
      </DialogContent>
    </Dialog>
  )
}
