import { FormattedMessage } from 'react-intl'

import { Typography } from '@/shared/ui'

import { ContractorPayment } from '../../../types'

type Props = {
  payment: ContractorPayment
}

export const ContractorsAmountCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        <FormattedMessage
          id="contractors.overview.contractorsAmount"
          defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
          values={{ count: payment.numberOfPayments }}
        />
      </Typography>
    </div>
  )
}
