import { FormattedMessage } from 'react-intl'
import { Link, To } from 'react-router'

import { cn } from '@/lib/utils'

import { ArrowLeft } from '../icons/outline'
import { Button } from '../ui'

type Props = {
  className?: string
  to?: To
  onClick?: () => void
}

export const GoBackButton = ({ className, onClick, to }: Props) => {
  if (to) {
    return (
      <Button
        leftIcon={<ArrowLeft />}
        asChild
        variant="ghost"
        size="lg"
        className={cn(
          'min-w-auto absolute left-6 top-6 flex items-center justify-start gap-1 px-0 md:left-12 md:top-12',
          className,
        )}
      >
        <Link to={to}>
          <FormattedMessage defaultMessage="Go back" id="action.goBack" />
        </Link>
      </Button>
    )
  }

  if (onClick) {
    return (
      <Button
        leftIcon={<ArrowLeft />}
        variant="ghost"
        size="lg"
        className={cn(
          'min-w-auto absolute left-6 top-6 flex items-center justify-start gap-1 px-0 md:left-12 md:top-12',
          className,
        )}
        onClick={onClick}
      >
        <FormattedMessage defaultMessage="Go back" id="action.goBack" />
      </Button>
    )
  }

  return null
}
