import { Outlet } from 'react-router'

import { getImage } from '@/lib/images'

import { ContractorsOnboardingSidebar } from './components/ContractorsOnboardingSidebar'

export const PreloadedImages = () => {
  return (
    <>
      <link rel="preload" href={getImage({ name: '2fa' })} as="image" />
      <link
        rel="preload"
        href={getImage({
          name: 'mobile-app-screenshot',
          category: 'contractor',
        })}
        as="image"
      />
    </>
  )
}

export const Layout = () => {
  return (
    <div className="grid grid-cols-[3rem_1fr] gap-4 md:grid-cols-[15rem_1fr] md:gap-0">
      <PreloadedImages />

      <ContractorsOnboardingSidebar />

      <main className="col-start-2 mx-auto w-full max-w-4xl px-6 py-8 md:pb-12 md:pt-24">
        <Outlet />
      </main>
    </div>
  )
}
