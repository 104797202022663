import { FormattedMessage } from 'react-intl'

import { parseAdditionalDetails } from '@/lib/utils'
import { Details } from '@/shared/ui'

import {
  SingleTransaction,
  TransactionType,
} from '../../features/Transactions/types'
import { getTransactionPaymentMethod } from '../../features/Transactions/utils'

type Props = {
  transaction?: SingleTransaction
}

export const TrackingInformation = ({ transaction }: Props) => {
  const paymentMethod = getTransactionPaymentMethod(transaction)

  const additionalDetails = parseAdditionalDetails<{
    claveRastreo?: string
    imad?: string
    omad?: string
    traceNumber?: string
  }>(transaction?.additionalDetails)

  switch (transaction?.transactionType) {
    case TransactionType.DEPOSIT:
    case TransactionType.WITHDRAW:
      switch (paymentMethod) {
        case 'SPEI':
          return (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.claveDeRastreo"
                  defaultMessage="Clave de rastreo"
                />
              </Details.Label>

              <Details.Value>
                {additionalDetails?.claveRastreo ?? '-'}
              </Details.Value>
            </Details>
          )

        case 'ACH':
          return (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.traceNumber"
                  defaultMessage="Trace number"
                />
              </Details.Label>

              <Details.Value>
                {additionalDetails?.traceNumber ?? '-'}
              </Details.Value>
            </Details>
          )

        case 'WIRE':
          return (
            <>
              <Details>
                <Details.Label>
                  <FormattedMessage id="label.imad" defaultMessage="IMAD" />
                </Details.Label>

                <Details.Value>{additionalDetails?.imad ?? '-'}</Details.Value>
              </Details>
              <Details>
                <Details.Label>
                  <FormattedMessage id="label.omad" defaultMessage="OMAD" />
                </Details.Label>

                <Details.Value>{additionalDetails?.omad ?? '-'}</Details.Value>
              </Details>
            </>
          )

        default:
          return null
      }

    default:
      return null
  }
}
