import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'

import {
  ContractorFirstCycleMethod,
  ContractorPaymentFrequency,
  ContractPaymentDayType,
} from '../types'

type ProRataResponse = {
  paymentAmount: { amount: number; currency: Currency }
  paymentDate: string
}

type ProRataRequest = {
  startDate: string
  paymentDetails: {
    amount: string
    currency: Currency
    frequency: ContractorPaymentFrequency
    dayType: ContractPaymentDayType
    firstCycleMethod: ContractorFirstCycleMethod
    day?: string | undefined
    firstCycleAmount?: string | undefined
  }
}

export function calculateProRata(data: ProRataRequest) {
  return api.post<ProRataResponse>(
    `/v1/me/contractor-payments/next-payment-quote`,
    data,
  )
}
