import { FormattedMessage } from 'react-intl'

import { Widget } from '@/shared/components'
import { Avatar, Details, Typography } from '@/shared/ui'

import { DolarAppSearchRecipient } from '../types'
import { getIconByEntityType } from '../utils'

type Props = {
  recipient: DolarAppSearchRecipient
}

export const DolarAppRecipientReview = ({ recipient }: Props) => {
  const Icon = getIconByEntityType(recipient.owner.type)

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.recipientDetails"
          defaultMessage="Recipient details"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Recipient name"
            id="label.recipientName"
          />
        </Details.Label>

        <Details.Value>{recipient.owner.displayName}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="DolarTag" id="label.dolarTag" />
        </Details.Label>

        <Details.Value>{recipient.alias.value}</Details.Value>
      </Details>

      <Details>
        <Details.Label>
          <FormattedMessage defaultMessage="Type" id="label.type" />
        </Details.Label>

        <div className="flex items-center gap-2">
          <Avatar size="sm" icon={<Icon className="size-3" />} />

          <Typography>
            <FormattedMessage
              defaultMessage="{entity, select, BUSINESS {Business} RETAIL {Individual} other {}}"
              id="recipient.dolarApp.type"
              values={{ entity: recipient.owner.type }}
            />
          </Typography>
        </div>
      </Details>
    </Widget>
  )
}
