import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { PaymentRails } from '@/domains/Business/constants'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { Recipient } from '../../types'

import { AccountCell } from './AccountCell'
import { RecipientNicknameCell } from './RecipientNicknameCell'
import { SendCell } from './SendCell'

export const useRecipientsColumns = (): ColumnDef<Recipient>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Recipient>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <RecipientNicknameCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.nickname',
              defaultMessage: 'Nickname',
            })}
          />
        ),
      },
      {
        id: 'send',
        cell: ({ row }) => <SendCell recipient={row.original} />,
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Recipient>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <RecipientNicknameCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.nickname',
              defaultMessage: 'Nickname',
            })}
          />
        ),
      },

      {
        id: 'accountNumber',
        accessorFn: (row) => {
          switch (row.paymentRails) {
            case PaymentRails.USA:
              return row.localInformation.accountNumber.slice(-4)

            case PaymentRails.MEX:
              return row.localInformation.clabe.slice(-4)

            case PaymentRails.DOLAR_APP:
              return row.localInformation.dolarTag

            default:
              return undefined
          }
        },
        cell: ({ row }) => <AccountCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
        size: 90,
      },

      {
        id: 'send',
        cell: ({ row }) => <SendCell recipient={row.original} />,
        size: 90,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
