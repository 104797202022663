import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'

export const EmptyOverview = () => {
  return (
    <Card className="flex h-auto w-full flex-wrap items-center justify-between gap-3 overflow-hidden px-6 py-8 md:h-28 md:flex-nowrap md:gap-0">
      <div className="flex flex-wrap gap-8">
        <img
          className="relative -bottom-4 hidden w-32 object-contain md:block"
          src={getImage({
            name: 'empty-payments',
            category: 'contractors',
          })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col justify-center gap-1">
          <Typography bold>
            <FormattedMessage
              id="contractors.paymentCycles.empty"
              defaultMessage="You don't have upcoming Payment cycles!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="contractors.paymentCycles.empty.subtitle"
              defaultMessage="Set up and manage your contractors first and check again"
            />
          </Typography>
        </div>
      </div>

      <Button leftIcon={<Plus className="size-5" />} asChild>
        <Link to={BusinessRoute.ContractorsCreate}>
          <FormattedMessage
            id="action.setUpContractors"
            defaultMessage="Set up contractors"
          />
        </Link>
      </Button>
    </Card>
  )
}
