import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/domains/Business/hooks'
import { CreditCard, Invoice, PencilEdit } from '@/shared/icons/outline'
import { Ellipsis } from '@/shared/icons/solid'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui'

import { getAccount } from '../../api'
import { Wallet } from '../../types'

type Props = {
  wallet: Wallet
}

export const DetailsButtonCell = ({ wallet }: Props) => {
  const [, setSearchParams] = useSearchParams()
  const { isAdmin } = useBusinessRole()

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data.data,
  })

  return (
    <div className="flex items-center">
      <Button
        onClick={(e) => {
          e.stopPropagation()

          setSearchParams(
            (params) => {
              params.set('id', wallet.id)
              return params
            },
            { preventScrollReset: true },
          )
        }}
        size="xs"
        variant="secondary"
      >
        <FormattedMessage id="action.seeDetails" defaultMessage="See details" />
      </Button>

      <div className="hidden p-4 sm:block" />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            size="inline"
            className="hidden size-10 sm:flex"
            variant="ghost"
          >
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={(e) => e.stopPropagation()}>
            <Button
              leftIcon={<Invoice className="size-4" />}
              onClick={() => {
                setSearchParams((params) => {
                  params.set('statements', 'ACCOUNT')
                  params.set('walletId', wallet.id)
                  return params
                })
              }}
              size="sm"
              variant="dropdown"
              width="full"
            >
              <FormattedMessage
                id="label.accountStatements"
                defaultMessage="Account statements"
              />
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={(e) => e.stopPropagation()}>
            <Button
              leftIcon={<CreditCard className="size-4" />}
              width="full"
              size="sm"
              variant="dropdown"
              onClick={() => {
                setSearchParams(
                  (params) => {
                    params.set('statements', 'CREDIT')
                    params.set('walletId', wallet.id)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }}
            >
              <FormattedMessage
                id="label.creditStatements"
                defaultMessage="Credit statements"
              />
            </Button>
          </DropdownMenuItem>
          {isAdmin && accountQuery.data?.defaultWalletId !== wallet.id ? (
            <DropdownMenuItem onClick={(e) => e.stopPropagation()}>
              <Button
                leftIcon={<PencilEdit className="size-4" />}
                onClick={() => {
                  setSearchParams(
                    (params) => {
                      params.set('edit', 'true')
                      params.set('walletId', wallet.id)
                      return params
                    },
                    { preventScrollReset: true },
                  )
                }}
                className="justify-start px-4 font-normal"
                width="full"
                variant="ghost"
              >
                <FormattedMessage id="label.edit" defaultMessage="Edit" />
              </Button>
            </DropdownMenuItem>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
