import { CountryWithFlag } from '@/shared/components'
import { CountryCode } from '@/types/country'

import { PaymentRails } from '../constants'

import { DolarAppAvatar } from './DolarAppAvatar'

type Props = {
  paymentRails: PaymentRails
  className?: string
}

export const RecipientAccountAvatar = ({ paymentRails, className }: Props) => {
  switch (paymentRails) {
    case PaymentRails.MEX:
      return <CountryWithFlag country={CountryCode.MX} hideName />

    case PaymentRails.USA:
      return <CountryWithFlag country={CountryCode.US} hideName />

    case PaymentRails.DOLAR_APP:
      return <DolarAppAvatar className={className} />

    default:
      return null
  }
}
