import { getFullName } from '@/lib/typography'
import { Avatar, Typography } from '@/shared/ui'

import { Task } from '../../types'

type Props = {
  task: Task
}

export const RequestedByCell = ({ task }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar
        size="md"
        variant="neutral"
        name={getFullName({
          firstName: task.createdByFirstName,
          lastName: task.createdByLastName,
          secondLastName: task.createdBySecondLastName,
        })}
      />
      <Typography>{task.createdByFirstName}</Typography>
    </div>
  )
}
