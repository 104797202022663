import { zodResolver } from '@hookform/resolvers/zod'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { CardFields } from '@/domains/Business/components'
import { PaymentLimitType } from '@/domains/Business/types'
import { getFullName } from '@/lib/typography'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import {
  TeamMemberSchema,
  teamPhysicalCardDetailsSchema,
  TeamPhysicalCardDetailsSchema,
} from './schemas'

const CARD_INFO_FORM_ID = 'card-info-form-id'

type Props = {
  onBack: () => void
  onContinue: (data: TeamPhysicalCardDetailsSchema) => void
  teamCardDetails?: TeamPhysicalCardDetailsSchema
  teamMemberDetails?: TeamMemberSchema
}

export const TeamPhysicalCardDetailsStep = ({
  onBack,
  onContinue,
  teamCardDetails,
  teamMemberDetails,
}: Props) => {
  const form = useForm<TeamPhysicalCardDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamPhysicalCardDetailsSchema),
    values: {
      nickname: teamCardDetails?.nickname ?? '',
      walletId: teamCardDetails?.walletId ?? '',
      limitAmount: teamCardDetails?.limitAmount
        ? Big(teamCardDetails?.limitAmount).toString()
        : '',
      limitType: teamCardDetails?.limitType ?? ('' as PaymentLimitType),
      requestId: teamCardDetails?.requestId ?? '',
      identityId: teamCardDetails?.identityId ?? '',
    },
  })

  const onSubmit: SubmitHandler<TeamPhysicalCardDetailsSchema> = async (
    data,
  ) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="cards.create.infoStep.physical.title"
            defaultMessage="Create a physical card"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="cards.create.infoStep.physical.description"
            defaultMessage="Customize the card to fit your needs"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={CARD_INFO_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <CardFields form={form} fullName={getFullName(teamMemberDetails)} />
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={CARD_INFO_FORM_ID}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
