import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router'
import { z } from 'zod'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useContractorBankName } from '@/domains/Contractor/hooks'
import {
  ContractorPaymentMethodType,
  MXBankAccountDetails,
} from '@/domains/Contractor/types'
import { queryClient } from '@/lib/queryClient'
import { isValidClabe } from '@/lib/validations'
import { BankName, GoBackButton } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { submitPaymentDetails } from '../api'
import { ContractorOnboardingState } from '../types'

const MX_BANK_PAYMENT_DETAILS_FORM_ID = 'mx-bank-payment-details-id'

const mxBankPaymentDetailsSchema = z.object({
  clabe: z.string().min(1, { message: 'validation.clabe.required' }),
})

type MXBankPaymentDetailsSchema = z.infer<typeof mxBankPaymentDetailsSchema>

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
}

export const MXBankPaymentDetails = ({ onBack, contractorState }: Props) => {
  const intl = useIntl()
  const { step } = useParams<{ step?: string }>()
  const navigate = useNavigate()

  const paymentMethodDetails =
    contractorState?.paymentMethodDetails as MXBankAccountDetails

  const form = useForm<MXBankPaymentDetailsSchema>({
    mode: 'onChange',
    resolver: zodResolver(mxBankPaymentDetailsSchema),
    values: {
      clabe: paymentMethodDetails?.paymentMethodInformation.clabe ?? '',
    },
  })

  const { name, isPending } = useContractorBankName({
    country: CountryCode.MX,
    bankReference: form.watch('clabe'),
    enabled: isValidClabe(form.watch('clabe')),
  })

  const {
    mutateAsync: submitPaymentDetailsMutate,
    isPending: isSubmitPaymentDetailsPending,
  } = useMutation({
    mutationFn: submitPaymentDetails,
  })

  const onSubmit: SubmitHandler<MXBankPaymentDetailsSchema> = async (data) => {
    if (!contractorState) {
      return
    }

    await submitPaymentDetailsMutate({
      type: ContractorPaymentMethodType.MX_BANK_ACCOUNT,
      paymentMethodDetails: data,
    })

    const currentStepIndex = contractorState.steps.findIndex(
      (s) => s.name === step,
    )

    const nextStep = contractorState?.steps[currentStepIndex + 1]

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorOnboardingState],
    })

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorPaymentMethods],
    })

    navigate(
      generatePath(ContractorRoute.OnboardingStep, { step: nextStep.name }),
    )
  }

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.bankDetails.title"
            defaultMessage="Complete your payment details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.bankDetails.subtitle"
            defaultMessage="Enter you payment methods details. You'll get your payments in this account"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={MX_BANK_PAYMENT_DETAILS_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="clabe"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'label.clabe',
                          defaultMessage: 'CLABE',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        id="label.clabe"
                        defaultMessage="CLABE"
                      />
                    </AnimatedFormLabel>

                    <BankName name={name} isPending={isPending} />
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            form={MX_BANK_PAYMENT_DETAILS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            disabled={!form.formState.isValid || isSubmitPaymentDetailsPending}
            loading={isSubmitPaymentDetailsPending}
            type="submit"
            width="full"
          >
            <FormattedMessage id="action.continue" defaultMessage="Continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
