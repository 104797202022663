import { useIntl } from 'react-intl'

import { getDayOfWeek } from '@/lib/date'

import { ContractPaymentDayType } from '../types'

type Props = {
  dayType?: ContractPaymentDayType
  day?: number | string | null
}

export function usePaymentDate({ dayType, day }: Props) {
  const intl = useIntl()

  if (!dayType) {
    return ''
  }

  switch (dayType) {
    case ContractPaymentDayType.LAST_BUSINESS_DAY_OF_PERIOD:
      return intl.formatMessage({
        id: 'contractors.period.lastBusinessDay',
        defaultMessage: 'on last business day',
      })

    case ContractPaymentDayType.LAST_FRIDAY_OF_PERIOD:
      return intl.formatMessage({
        id: 'contractors.period.lastFriday',
        defaultMessage: 'on last Friday',
      })

    case ContractPaymentDayType.CUSTOM_DAY_OF_WEEK:
      return intl.formatMessage(
        {
          id: 'contractors.period.customDayOfTheWeek',
          defaultMessage: 'on {dayOfTheWeek}',
        },
        {
          dayOfTheWeek: getDayOfWeek(day, intl.locale),
        },
      )

    case ContractPaymentDayType.CUSTOM_DAY_OF_MONTH:
      return intl.formatMessage(
        {
          id: 'contractors.period.customDayOfMonth',
          defaultMessage: 'on {dayOfMonth}',
        },
        {
          dayOfMonth: intl.formatMessage(
            {
              id: 'day.ordinal',
              defaultMessage:
                '{dayOfMonth, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
            },
            { dayOfMonth: day },
          ),
        },
      )

    default:
      return ''
  }
}
