import { FormattedMessage } from 'react-intl'

import { X } from '@/shared/icons/solid'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  onTryAgain: () => void
}

export const DolarAppLinkingFailedDialog = ({
  onTryAgain,
  isOpen,
  onOpenChange,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton className="flex flex-col items-center">
        <span className="flex items-center justify-center rounded-full bg-primary-error p-2">
          <X className="size-16 text-white" />
        </span>

        <div className="p-2" />

        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              id="contractor.onboarding.dolarTag.failed.title"
              defaultMessage="Failed to link DolarApp account"
            />
          </DialogTitle>

          <DialogDescription>
            <FormattedMessage
              id="contractor.onboarding.dolarTag.failed.description"
              defaultMessage="We couldn't link your DolarApp account. Please try again or select another payment method"
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <Button width="full" onClick={onTryAgain}>
          <FormattedMessage id="action.tryAgain" defaultMessage="Try Again" />
        </Button>
      </DialogContent>
    </Dialog>
  )
}
