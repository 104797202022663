import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { DOLAR_TAG, PaymentRails } from '@/domains/Business/constants'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { Task, TaskType } from '../../types'

type Props = {
  task: Task
}

export const TaskDescriptionCell = ({ task }: Props) => {
  const taskByType = useMemo(() => {
    switch (task.type) {
      case TaskType.ADD_BENEFICIARY:
        return (
          <FormattedMessage
            id="task.addRecipient.action"
            defaultMessage="Add {recipient} as recipient"
            values={{
              recipient: task.taskDetails.beneficiaryName,
            }}
          />
        )

      case TaskType.EXECUTE_WITHDRAWAL: {
        return (
          <FormattedMessage
            id="task.executeWithdrawal.action"
            defaultMessage="{amount} transfer to {recipient}"
            values={{
              amount: formatAmount({
                amount: task.taskDetails.localAmount,
                currency: task.taskDetails.localCurrency,
              }),
              recipient: task.taskDetails.beneficiaryName,
            }}
          />
        )
      }

      case TaskType.EXECUTE_BULK_PAYMENT: {
        return (
          <FormattedMessage
            id="task.executeBulkPayment.action"
            defaultMessage="{amount} bulk payment to {total} recipients"
            values={{
              amount: formatAmount({
                amount: task.taskDetails.totalAmount,
                currency: task.taskDetails.currency,
              }),
              total: task.taskDetails.totalNumberOfPayments,
            }}
          />
        )
      }

      default:
        return ''
    }
  }, [task.taskDetails, task.type])

  const fromDescription = useMemo(() => {
    switch (task.type) {
      case TaskType.ADD_BENEFICIARY: {
        const { beneficiaryPaymentRails, beneficiaryBank } = task.taskDetails
        switch (beneficiaryPaymentRails) {
          case PaymentRails.MEX: {
            const { beneficiaryClabe } = task.taskDetails

            return intersperse(
              [
                beneficiaryBank,
                <FormattedMessage
                  key="clabe"
                  id="task.addBeneficiary.subtitle"
                  defaultMessage="CLABE {clabe}"
                  values={{
                    clabe: beneficiaryClabe,
                  }}
                />,
              ],
              ' • ',
            )
          }
          case PaymentRails.USA: {
            const { beneficiaryAccountNumber } = task.taskDetails
            return intersperse(
              [
                <FormattedMessage
                  key="bank"
                  id="task.addBeneficiary.accountNumber"
                  defaultMessage="Account N. {accountNumber}"
                  values={{
                    accountNumber: beneficiaryAccountNumber,
                  }}
                />,
              ],
              ' • ',
            )
          }

          case PaymentRails.DOLAR_APP: {
            return intersperse(
              [DOLAR_TAG, task.taskDetails.beneficiaryDolarTag],
              ' • ',
            )
          }

          default:
            return ''
        }
      }

      case TaskType.EXECUTE_WITHDRAWAL:
      case TaskType.EXECUTE_BULK_PAYMENT:
        return (
          <FormattedMessage
            id="task.executeWithdrawal.subtitle"
            defaultMessage="From {account}"
            values={{ account: task.taskDetails.walletLabel }}
          />
        )

      default:
        return ''
    }
  }, [task.taskDetails, task.type])

  return (
    <div className="flex flex-col justify-center">
      <Typography className="line-clamp-1" bold>
        {taskByType}
      </Typography>
      <Typography className="line-clamp-1 text-neutral-gray-600">
        {fromDescription}
      </Typography>
    </div>
  )
}
