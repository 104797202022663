import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { parseToFormat } from '@/lib/date'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Spinner,
  Typography,
} from '@/shared/ui'

import { calculateProRata } from '../../api'
import { ContractorFirstCycleMethod } from '../../types'

import { FixedRateContractSchema } from './CreateContractorSteps/schema'

type Props = {
  disabled?: boolean
  formValues: FixedRateContractSchema
  value?: string
  onChange: (v: string) => void
}

export const FirstCycleMethodSelect = ({
  disabled,
  formValues,
  onChange,
  value,
}: Props) => {
  const intl = useIntl()

  const proRataQuery = useQuery({
    queryKey: [
      queryKeys.calculateProRata,
      formValues.startDate,
      formValues.paymentDetails,
      formValues.paymentDetails.day,
      formValues.paymentDetails.firstCycleAmount,
    ],
    queryFn: () =>
      calculateProRata({
        startDate: parseToFormat(formValues.startDate),
        paymentDetails: {
          ...formValues.paymentDetails,
        },
      }),
    retry: 1,
    select: (data) => data.data,
    enabled:
      !!formValues.startDate &&
      !!formValues.paymentDetails.amount &&
      !!formValues.paymentDetails.dayType &&
      !!formValues.paymentDetails.firstCycleMethod &&
      formValues.paymentDetails.firstCycleMethod ===
        ContractorFirstCycleMethod.PRO_RATA,
  })

  return (
    <Select disabled={disabled} onValueChange={onChange} value={value}>
      <SelectTrigger className="relative">
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.firstCyclePaymentHanding"
                defaultMessage="First cycle payment handling"
              />
            </Typography>
            <MotionDiv key={value}>
              {value === ContractorFirstCycleMethod.PRO_RATA ? (
                <Typography
                  key="firstCyclePayment"
                  className="text-neutral-gray-900"
                >
                  {intersperse(
                    [
                      <FormattedMessage
                        key="firstCyclePayment"
                        id="contractor.firstCyclePayment.label"
                        defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
                        values={{ firstCyclePayment: value }}
                      />,
                      proRataQuery.isFetching ? (
                        <Spinner
                          key="firstCyclePaymentSpinner"
                          className="inline size-4"
                        />
                      ) : proRataQuery.data ? (
                        formatAmount(proRataQuery.data.paymentAmount)
                      ) : null,
                    ],
                    ' • ',
                  )}
                </Typography>
              ) : (
                <Typography className="text-neutral-gray-900">
                  <FormattedMessage
                    id="contractor.firstCyclePayment.label"
                    defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
                    values={{ firstCyclePayment: value }}
                  />
                </Typography>
              )}
            </MotionDiv>

            <MotionDiv>
              {value === ContractorFirstCycleMethod.PRO_RATA &&
              proRataQuery.data?.paymentDate ? (
                <Typography
                  className="absolute -bottom-5 right-4 text-neutral-gray-900"
                  variant="body-tiny"
                >
                  <FormattedMessage
                    id="label.nextPaymentDate"
                    defaultMessage="Next payment date: {paymentDate}"
                    values={{
                      paymentDate: intl.formatDate(
                        proRataQuery.data.paymentDate,
                        {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                          timeZone: 'UTC',
                        },
                      ),
                    }}
                  />
                </Typography>
              ) : null}
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={
              <FormattedMessage
                id="label.firstCyclePaymentHanding"
                defaultMessage="First cycle payment handling"
              />
            }
          >
            <FormattedMessage
              id="label.firstCyclePaymentHanding"
              defaultMessage="First cycle payment handling"
            />
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(ContractorFirstCycleMethod).map((method) => (
          <SelectItem
            disabled={method === value}
            hideChecked
            className="flex h-[54px]"
            key={method}
            value={method}
          >
            <Typography>
              <FormattedMessage
                id="contractor.firstCyclePayment.label"
                defaultMessage="{firstCyclePayment, select, FULL_AMOUNT {Full amount} CUSTOM_AMOUNT {Custom amount} NO_PAYMENT {No payment} PRO_RATA {Pro-rata} other {}}"
                values={{ firstCyclePayment: method }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
