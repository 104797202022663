import {
  ContractorPaymentMethodDetails,
  ContractorPersonalDetails,
} from '@/domains/Contractor/types'

export enum ContractorOnboardingStepName {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  TAX_FORMS = 'TAX_FORMS',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  REVIEW_AND_SUBMIT = 'REVIEW_AND_SUBMIT',
}

export enum ContractorOnboardingStepState {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
}

export type ContractorOnboardingStep = {
  name: ContractorOnboardingStepName
  state: ContractorOnboardingStepState
}

export type ContractorOnboardingState = {
  businessId: string
  contractorId: string
  personalDetails?: ContractorPersonalDetails | null
  paymentMethodDetails?: ContractorPaymentMethodDetails | null
  steps: ContractorOnboardingStep[]
}

export enum DolarTagLinkingState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
