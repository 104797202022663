import { cva } from 'class-variance-authority'

export const inputVariants = cva(
  'placeholder:text-transparent disabled:cursor-not-allowed peer flex w-full rounded-lg bg-white px-4 py-3 pt-5 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:bg-neutral-gray-200 disabled:text-neutral-gray-600 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
  {
    variants: {
      variant: {
        search:
          'placeholder:text-neutral-gray-900 bg-neutral-gray-200 rounded-lg pt-0',
        '2fa': 'border border-neutral-gray-100 rounded-lg',
      },
      inputSize: {
        default: 'h-10',
        large: 'h-[54px]',
        medium: 'h-12',
      },
    },
    defaultVariants: {
      inputSize: 'large',
      variant: undefined,
    },
  },
)
