import { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { motion } from 'framer-motion'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { getImage } from '@/lib/images'
import {
  CodeInput,
  CodeInputSchema,
  codeInputSchema,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  DialogActions,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  slideInFromBottomAnimation,
  Typography,
} from '@/shared/ui'
import { TwoFactorMethod } from '@/types/auth'

import { Auth2FAResponse, Configuration } from '../../types'

import { ResendCode } from './ResendCode'

type Props = {
  configurations: Configuration[]
  email?: string
  isPending: boolean
  onClose: () => void
  onContinue: (data: Auth2FAResponse) => void
  onMethodChange: () => void
  password?: string
}

export const EmailDialog = ({
  configurations,
  email,
  isPending,
  onClose,
  onContinue,
  onMethodChange,
  password,
}: Props) => {
  const form = useForm<CodeInputSchema>({
    mode: 'onChange',
    resolver: zodResolver(codeInputSchema),
    defaultValues: { otp: '' },
  })

  const onSubmit: SubmitHandler<CodeInputSchema> = useCallback(
    async (data) => {
      onContinue({ otp: data.otp, method: TwoFactorMethod.EMAIL })
    },
    [onContinue],
  )

  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onSubmit)())

    return () => subscription.unsubscribe()
  }, [form, onSubmit])

  return (
    <motion.div
      variants={slideInFromBottomAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <img
        className="mx-auto w-36 object-contain"
        src={getImage({ name: 'email-2fa' })}
        alt=""
        aria-hidden
      />

      <div className="p-3" />

      <DialogHeader>
        <DialogTitle>
          <FormattedMessage
            defaultMessage="Two-factor authentication"
            id="label.twoFactorAuthentication"
          />
        </DialogTitle>

        <DialogDescription>
          <FormattedMessage
            defaultMessage="Enter the verification code sent to your email"
            id="2fa.dialog.email.subtitle"
          />
        </DialogDescription>
      </DialogHeader>

      <div className="p-3" />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CodeInput autoFocus {...field} />
                </FormControl>
                <AnimatedFormLabel size="2fa">
                  <FormattedMessage
                    defaultMessage="6-digit code"
                    id="label.sixDigitCode"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />

          <div className="p-2" />

          <DialogActions>
            <Button
              type="button"
              disabled={isPending}
              width="full"
              variant="secondary"
              onClick={onClose}
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={form.handleSubmit(onSubmit)}
              type="submit"
              disabled={isPending}
              loading={isPending}
              width="full"
            >
              <FormattedMessage
                id="action.continue"
                defaultMessage="Continue"
              />
            </Button>
          </DialogActions>

          <div className="p-3" />
        </form>
      </Form>

      <div className="flex flex-col gap-3">
        {configurations.includes('resend-code') && (
          <ResendCode email={email} password={password} />
        )}
        {configurations.includes('use-another-method') && (
          <Typography
            variant="body-small"
            text="center"
            className="text-neutral-gray-600"
          >
            <FormattedMessage
              defaultMessage="Don't have access to email? {useAnotherMethod}"
              id="2fa.dialog.email.useAnotherMethod"
              values={{
                useAnotherMethod: (
                  <Button
                    variant="link"
                    size="inline"
                    className="text-xs"
                    onClick={() => onMethodChange()}
                  >
                    <FormattedMessage
                      defaultMessage="Use another method"
                      id="2fa.dialog.useAnotherMethod"
                    />
                  </Button>
                ),
              }}
            />
          </Typography>
        )}
      </div>
    </motion.div>
  )
}
