import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'

import { useBusinessRole } from '@/domains/Business/hooks'
import { calculateProgress, formatMoney } from '@/lib/money'
import { cn } from '@/lib/utils'
import { InfoCircle, Pencil } from '@/shared/icons/outline'
import {
  Button,
  Progress,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { Card, CardScreenType, CardState } from '../types'

import { CardTransactionShortList } from './CardTransactionShortList'
import { FlipCard } from './FlipCard/FlipCard'

type Props = {
  card?: Card
  setScreen: (screen: CardScreenType) => void
}

export const BaseCardDetails = ({ card, setScreen }: Props) => {
  const isTerminated = card?.state === CardState.TERMINATED
  const { isAdmin } = useBusinessRole()
  const intl = useIntl()

  const progress = calculateProgress({
    value: card?.cardLimit?.usedAmount,
    total: card?.cardLimit?.limitAmount,
  })

  return (
    <SlideInLeft>
      {card?.nickname ? (
        <div className="flex justify-between">
          <Typography
            className={cn('', isTerminated && 'line-through')}
            variant="h3"
          >
            {card.nickname}
          </Typography>

          <div className="flex gap-2">
            <Button
              onClick={() => setScreen({ type: 'info' })}
              variant="tertiary"
              size="icon"
              leftIcon={<InfoCircle className="size-4" />}
              aria-label={intl.formatMessage({
                id: 'card.details.showDetails',
                defaultMessage: 'Show card details',
              })}
            />
            {isAdmin && !isTerminated && (
              <Button
                onClick={() => setScreen({ type: 'edit' })}
                variant="tertiary"
                size="icon"
                leftIcon={<Pencil className="size-4" />}
                aria-label={intl.formatMessage({
                  id: 'card.details.edit',
                  defaultMessage: 'Edit card details',
                })}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-between">
          <Skeleton className="h-[33px] w-36" />

          <div className="flex gap-3">
            <Skeleton className="size-8" />
            <Skeleton className="size-8" />
            <Skeleton className="size-8" />
          </div>
        </div>
      )}

      {card && !isTerminated ? (
        <>
          <div className="p-4" />

          <div className="flex flex-col justify-center gap-2">
            <div className="flex justify-between">
              <Typography className="font-semibold">
                {formatMoney(card.cardLimit?.usedAmount ?? 0)}
              </Typography>
              <Typography className="font-semibold text-neutral-gray-600">
                {formatMoney(card.cardLimit?.limitAmount ?? 0)} /{' '}
                <FormattedMessage
                  id="card.table.limit.duration"
                  defaultMessage="{limitType, select, DAILY {Day} WEEKLY {Week} MONTHLY {Month} YEARLY {Year} other {}}"
                  values={{
                    limitType: card.cardLimit?.limitType,
                  }}
                />
              </Typography>
            </div>
            <Progress value={progress} />
            {card.cardLimit?.periodResetDate ? (
              <Typography
                className="text-neutral-gray-600"
                variant="body-small"
                text="right"
              >
                <FormattedMessage
                  id="card.limit.resetDate"
                  defaultMessage="Limit resets on {resetDate}"
                  values={{
                    resetDate: (
                      <FormattedDate
                        value={new Date(card.cardLimit.periodResetDate)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                        hour="2-digit"
                        minute="2-digit"
                      />
                    ),
                  }}
                />
              </Typography>
            ) : null}
          </div>
        </>
      ) : isTerminated ? null : (
        <>
          <div className="p-4" />
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <Skeleton className="h-[21px] w-36" />
              <Skeleton className="h-[21px] w-36" />
            </div>

            <Skeleton className="h-1.5 w-full" />
          </div>
        </>
      )}

      <div className="p-4" />

      {card ? (
        <FlipCard
          card={card}
          onShowPIN={(pin) => setScreen({ type: 'pin', pin })}
        />
      ) : (
        <div className="flex flex-col justify-center">
          <Skeleton className="mx-auto h-40 w-64" />

          <div className="p-2" />

          <div className="mx-auto flex gap-3">
            <Skeleton className="h-8 w-32" />
            <Skeleton className="h-8 w-32" />
          </div>
        </div>
      )}

      <div className="p-4" />

      {card ? <CardTransactionShortList /> : null}

      <div className="p-8" />
    </SlideInLeft>
  )
}
