import { FormattedMessage } from 'react-intl'

import { SortBy } from '@/shared/icons/outline'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/shared/ui'

type Props = {
  children: React.ReactNode
}

export const FiltersWidget = ({ children }: Props) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button leftIcon={<SortBy className="size-5" />} variant="tertiary">
          <FormattedMessage
            defaultMessage="Add filters"
            id="action.addFilters"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-52">{children}</DropdownMenuContent>
    </DropdownMenu>
  )
}
