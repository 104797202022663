import { useMutation } from '@tanstack/react-query'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import {
  activatePaymentMethod,
  unlinkPaymentMethod,
} from '@/domains/Contractor/api'
import { ContractorPaymentMethodState } from '@/domains/Contractor/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { getAnimationKey } from '@/lib/utils'
import { StatusBadge } from '@/shared/components'
import {
  Check,
  ChevronRight,
  EllipsisVertical,
  Unlink,
} from '@/shared/icons/outline'
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  MotionDiv,
  MotionSpan,
  Spinner,
  Typography,
} from '@/shared/ui'

type Props = {
  id?: string
  state?: ContractorPaymentMethodState
}

export const LinkAction = ({ id, state }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const {
    mutateAsync: unlinkPaymentMethodMutation,
    isPending: isUnlinking,
    isSuccess: isUnlinked,
    reset: resetUnlink,
  } = useMutation({
    mutationFn: unlinkPaymentMethod,
  })

  const {
    mutateAsync: activatePaymentMethodMutation,
    isPending: isActivating,
    isSuccess: isActivated,
    reset: resetActivate,
  } = useMutation({
    mutationFn: activatePaymentMethod,
  })

  const handleUnlinkPaymentMethod = async () => {
    if (!id) return

    try {
      await unlinkPaymentMethodMutation(id)

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractorOnboardingState],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getContractorPaymentMethods],
      })

      toast.success(
        intl.formatMessage({
          id: 'message.paymentMethodRemovedSuccessfully',
          defaultMessage: 'Payment method removed successfully',
        }),
      )

      resetUnlink()
    } catch (error) {
      notifyError(error)
    }
  }

  const handleActivatePaymentMethod = async () => {
    if (!id) return

    try {
      await activatePaymentMethodMutation(id)

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getContractorOnboardingState],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getContractorPaymentMethods],
      })

      toast.success(
        intl.formatMessage({
          id: 'message.paymentMethodActivatedSuccessfully',
          defaultMessage: 'Payment method activated successfully',
        }),
      )

      resetActivate()
    } catch (error) {
      notifyError(error)
    }
  }

  const isPending = isUnlinking || isActivating || isActivated || isUnlinked

  if (state) {
    return (
      <div className="flex items-center gap-1">
        <AnimatePresence>
          {state === ContractorPaymentMethodState.LINKED && (
            <MotionDiv>
              <StatusBadge
                title={
                  <FormattedMessage id="label.active" defaultMessage="Active" />
                }
                variant="active"
              />
            </MotionDiv>
          )}
        </AnimatePresence>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              aria-label={intl.formatMessage({
                id: 'action.showMethodActions',
                defaultMessage: 'Show method actions',
              })}
              size="inline"
              variant="outline"
              className="p-0.5"
            >
              <MotionSpan key={getAnimationKey(isPending)}>
                {isPending ? (
                  <Spinner className="size-5" />
                ) : (
                  <EllipsisVertical className="size-5" />
                )}
              </MotionSpan>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {state === ContractorPaymentMethodState.INACTIVE && (
              <DropdownMenuItem>
                <Button
                  leftIcon={<Check className="size-4" />}
                  onClick={handleActivatePaymentMethod}
                  size="sm"
                  variant="dropdown"
                  width="full"
                  loading={isActivating}
                  disabled={isActivating}
                >
                  <FormattedMessage
                    id="label.markAsActive"
                    defaultMessage="Mark as active"
                  />
                </Button>
              </DropdownMenuItem>
            )}
            {[
              ContractorPaymentMethodState.LINKED,
              ContractorPaymentMethodState.INACTIVE,
            ].includes(state) && (
              <DropdownMenuItem>
                <Button
                  leftIcon={<Unlink className="size-4 text-primary-error" />}
                  onClick={handleUnlinkPaymentMethod}
                  size="sm"
                  variant="dropdown"
                  width="full"
                  loading={isUnlinking}
                  disabled={isUnlinking}
                  className="text-primary-error"
                >
                  <FormattedMessage
                    id="label.unlinkPaymentMethod"
                    defaultMessage="Unlink payment method"
                  />
                </Button>
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )
  }

  return (
    <MotionDiv className="flex items-center gap-1">
      <Typography className="text-neutral-gray-600">
        <FormattedMessage
          id="action.linkAccount"
          defaultMessage="Link account"
        />
      </Typography>
      <ChevronRight className="size-6 shrink-0 text-neutral-gray-600" />
    </MotionDiv>
  )
}
