import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import { navMessages } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getFirstLetters, getFullName } from '@/lib/typography'
import { SwitchLanguageTooltip } from '@/shared/components'
import { ChevronDown, Logout } from '@/shared/icons/outline'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from '@/shared/ui'

import { getContractorDetails } from '../../api'
import { useContractorLogout } from '../../hooks'

type Props = {
  onOpenChange: (open: boolean) => void
  open: boolean
}

export const ContractorProfileDropdown = ({ onOpenChange, open }: Props) => {
  const { logout } = useContractorLogout()

  const [openTooltip, setOpenTooltip] = useState(false)

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getContractorDetails],
    queryFn: getContractorDetails,
    select: (data) => data?.data,
  })

  useEffect(() => {
    setOpenTooltip(false)
  }, [open])

  const initials = getFirstLetters(
    `${contractorQuery.data?.personalDetails.firstName} ${contractorQuery.data?.personalDetails.lastName}`,
  )

  return (
    <>
      <Popover open={open} onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="group flex h-9 items-center justify-between rounded-xl p-0 font-normal focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 md:w-full md:px-2 md:py-3"
          >
            <div className="flex items-center gap-3">
              <Typography
                bold
                className="flex shrink-0 items-center justify-center text-xl capitalize text-white md:text-2xl"
              >
                {initials}
              </Typography>

              <AnimatePresence mode="wait">
                <motion.span
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                  exit={{ opacity: 0, x: -30 }}
                  className="flex flex-col"
                >
                  <Typography
                    variant="body-small"
                    className="max-w-28 overflow-hidden text-ellipsis whitespace-nowrap text-white"
                  >
                    {getFullName(contractorQuery.data?.personalDetails)}
                  </Typography>
                </motion.span>
              </AnimatePresence>
            </div>

            <AnimatePresence mode="wait">
              <motion.span
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                exit={{ opacity: 0, x: -30 }}
                className="flex size-6 shrink-0"
              >
                <ChevronDown
                  aria-label="Open profile dropdown"
                  className="size-6 shrink-0 text-white transition-all group-hover:-translate-y-0.5"
                />
              </motion.span>
            </AnimatePresence>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-52 p-1">
          <Button
            variant="ghost"
            className="w-full cursor-pointer justify-start gap-2 font-normal focus-visible:ring-transparent"
            onClick={logout}
          >
            <Logout className="size-5" />
            <FormattedMessage {...navMessages.logout} />
          </Button>

          <SwitchLanguageTooltip
            isOpen={openTooltip}
            onOpenChange={setOpenTooltip}
          />
        </PopoverContent>
      </Popover>
    </>
  )
}
