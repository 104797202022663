import { cn } from '@/lib/utils'
import {
  ArrowLeftRight,
  CircleCancel,
  Dolarapp,
  InfoCircle,
} from '@/shared/icons/outline'
import { Avatar, AvatarSize, AvatarVariant } from '@/shared/ui'

import { EntityType, Recipient } from '../features/Recipients/types'
import { getIconByEntityType } from '../features/Recipients/utils'
import { failedTransactionStatuses } from '../features/Transactions/constants'
import {
  SingleTransaction,
  TransactionType,
} from '../features/Transactions/types'

import { CardTransactionIcon } from './CardTransactionIcon'

const IconComponent = ({
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
  size,
}: Props) => {
  const isFailed = transactionStatus
    ? failedTransactionStatuses.includes(transactionStatus)
    : false

  const iconProps = {
    className: cn('text-neutral-gray-900 size-5', {
      'size-8': size === 'xl',
    }),
  }

  if (isFailed) {
    return (
      <>
        <CircleCancel {...iconProps} />
        <InfoCircle
          className={cn(
            'absolute -bottom-1 -right-1 size-3 text-primary-error',
            {
              'size-5': size === 'xl',
            },
          )}
        />
      </>
    )
  }

  switch (transactionType) {
    case TransactionType.INTERNAL_SWAP_DEPOSIT:
    case TransactionType.INTERNAL_SWAP_WITHDRAW:
      return <ArrowLeftRight {...iconProps} />
    case TransactionType.REWARD:
    case TransactionType.FEE:
    case TransactionType.CASHBACK:
      return <Dolarapp {...iconProps} />

    case TransactionType.CARD_PAYMENT: {
      return (
        <CardTransactionIcon
          additionalDetails={additionalDetails}
          size={size}
        />
      )
    }

    default: {
      const Icon = getIconByEntityType(recipientType)

      return <Icon {...iconProps} />
    }
  }
}

type Props = {
  variant?: AvatarVariant
  size?: AvatarSize
  additionalDetails?: SingleTransaction['additionalDetails']
  recipientType?: Recipient['entityType']
  transactionType?: TransactionType
  transactionStatus?: SingleTransaction['transactionStatus']
}

export const TransactionIcon = ({
  variant,
  size,
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
}: Props) => {
  return (
    <Avatar
      variant={variant}
      size={size}
      icon={
        <IconComponent
          additionalDetails={additionalDetails}
          recipientType={recipientType}
          transactionType={transactionType}
          transactionStatus={transactionStatus}
          size={size}
        />
      }
    />
  )
}
