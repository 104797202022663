import { forwardRef, Ref, type SVGProps } from 'react'

const SvgUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 21"
    role="img"
    ref={ref}
    {...props}
  >
    <g clipPath="url(#users_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M0 15.5v-1.312q0-.897.917-1.459.916-.562 2.416-.562.27 0 .521.01.25.01.48.052-.292.438-.438.917t-.146 1V15.5zm5 0v-1.354q0-.667.365-1.219.364-.551 1.03-.969.668-.416 1.595-.625a9.2 9.2 0 0 1 2.01-.208q1.104 0 2.031.208.928.21 1.594.625.667.417 1.02.97.355.55.355 1.218V15.5zm11.25 0v-1.354q0-.542-.135-1.021a3.3 3.3 0 0 0-.407-.896q.23-.042.47-.052.238-.01.489-.01 1.5 0 2.416.552.917.551.917 1.469V15.5zm-9.48-1.667h6.48q-.208-.415-1.156-.729A6.7 6.7 0 0 0 10 12.792q-1.146 0-2.094.312-.948.313-1.135.73m-3.437-2.5q-.687 0-1.177-.49a1.6 1.6 0 0 1-.49-1.176q0-.709.49-1.188Q2.646 8 3.333 8q.709 0 1.188.48Q5 8.957 5 9.666q0 .687-.48 1.177-.478.49-1.187.49m13.334 0q-.688 0-1.177-.49A1.6 1.6 0 0 1 15 9.668q0-.709.49-1.188Q15.979 8 16.667 8q.708 0 1.187.48.48.478.48 1.187 0 .687-.48 1.177t-1.187.49M10 10.5a2.4 2.4 0 0 1-1.77-.73A2.4 2.4 0 0 1 7.5 8q0-1.062.73-1.781A2.43 2.43 0 0 1 10 5.5q1.062 0 1.781.719.72.719.719 1.781 0 1.042-.719 1.77-.719.73-1.781.73m0-1.667q.354 0 .594-.24.24-.239.24-.593a.8.8 0 0 0-.24-.594.8.8 0 0 0-.594-.24.8.8 0 0 0-.594.24.8.8 0 0 0-.24.594q0 .354.24.594t.594.24"
      />
    </g>
    <defs>
      <clipPath id="users_svg__a">
        <path fill="#fff" d="M0 .5h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
const ForwardRef = forwardRef(SvgUsers)
export default ForwardRef
