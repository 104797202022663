import { defineMessage } from 'react-intl'

import { ContractorPaymentMethodType } from '../types'

export const getBankAccountLabel = (
  paymentMethodType: ContractorPaymentMethodType,
) => {
  switch (paymentMethodType) {
    case ContractorPaymentMethodType.DOLAR_TAG:
      return defineMessage({
        id: 'label.dolarTag',
        defaultMessage: 'DolarTag',
      })

    case ContractorPaymentMethodType.US_BANK_ACCOUNT:
      return defineMessage({
        id: 'label.usBankAccount',
        defaultMessage: 'US Bank Account',
      })

    case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
      return defineMessage({
        id: 'label.mxBankAccount',
        defaultMessage: 'MX Bank Account',
      })

    default:
      return defineMessage({
        id: 'label.bankAccount',
        defaultMessage: 'Bank account',
      })
  }
}
