import { FormattedMessage } from 'react-intl'

import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

import { ContractorPaymentFrequency } from '../../types'

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
}

export const PaymentFrequencySelect = ({
  disabled,
  onChange,
  value,
}: Props) => {
  return (
    <Select disabled={disabled} onValueChange={onChange} value={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.paymentFrequency"
                defaultMessage="Payment frequency"
              />
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="contractor.paymentFrequency.label"
                  defaultMessage="{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}"
                  values={{
                    paymentFrequency: value,
                  }}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={
              <FormattedMessage
                id="label.paymentFrequency"
                defaultMessage="Payment frequency"
              />
            }
          >
            <FormattedMessage
              id="label.paymentFrequency"
              defaultMessage="Payment frequency"
            />
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(ContractorPaymentFrequency).map((type) => (
          <SelectItem
            disabled={type === value}
            hideChecked
            className="flex h-[54px]"
            key={type}
            value={type}
          >
            <Typography>
              <FormattedMessage
                id="contractor.paymentFrequency.label"
                defaultMessage="{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}"
                values={{
                  paymentFrequency: type,
                }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
