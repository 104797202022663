import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCheckmark = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 89 90"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={7.2}
      d="M4 44.999c.001-19.32 13.65-35.95 32.6-39.717 18.949-3.768 37.921 6.374 45.314 24.224 7.392 17.85 1.146 38.437-14.919 49.17-16.065 10.732-37.474 8.623-51.135-5.04A40.5 40.5 0 0 1 4 45"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={7.2}
      d="m24.249 44.999 13.497 13.497 27-26.995"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCheckmark)
export default ForwardRef
