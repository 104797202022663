import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCreditCard = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="credit-card_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#credit-card_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M22 6v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 20H4q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 18V6q0-.824.587-1.412A1.93 1.93 0 0 1 4 4h16q.824 0 1.413.588Q22 5.175 22 6M4 8h16V6H4zm0 4v6h16v-6z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgCreditCard)
export default ForwardRef
