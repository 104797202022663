import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { toast } from 'sonner'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/domains/Business/hooks'
import { Role } from '@/domains/Business/types/roles'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import { GoBackButton } from '@/shared/components'
import { Button, SlideInScreen, StickyContainer, Typography } from '@/shared/ui'
import { ApiError } from '@/types/global'

import { createRecipient } from '../../api'
import {
  CreatedRecipient,
  DolarAppSearchRecipient,
  Recipient,
} from '../../types'
import { parseDolarAppRecipient } from '../../utils'
import { DolarAppRecipientReview } from '../DolarAppRecipientReview'

import { RecipientReview } from './RecipientReview'

type Props = {
  dolarAppRecipient?: DolarAppSearchRecipient
  recipient?: CreatedRecipient
  onBack: () => void
}

export const RecipientReviewStep = ({
  dolarAppRecipient,
  recipient,
  onBack,
}: Props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const notifyError = useErrorToast()

  const { role, isPaymentOps } = useBusinessRole()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createRecipient,
  })

  const handleSubmit = async () => {
    let createdRecipient: Recipient | undefined

    try {
      if (recipient) {
        removeEmptyFormFields(recipient)
        const { data } = await mutateAsync(recipient)
        createdRecipient = data
      } else if (dolarAppRecipient) {
        const dolarAppRecipientData = parseDolarAppRecipient(dolarAppRecipient)

        if (!dolarAppRecipientData) {
          return
        }

        const { data } = await mutateAsync(dolarAppRecipientData)

        createdRecipient = data
      }

      if (!createdRecipient) {
        notifyError(
          intl.formatMessage({
            id: 'error.backend.failedToCreateRecipient',
            defaultMessage: 'Failed to create recipient',
          }),
        )
        return
      }

      switch (role) {
        case Role.PAYMENT_OPS:
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getUserTasks],
          })
          toast.success(
            intl.formatMessage({
              id: 'recipient.task.submitted',
              defaultMessage:
                'New recipient request is submitted and pending approval',
            }),
          )

          navigate(BusinessRoute.Tasks)

          break

        default:
          await queryClient.refetchQueries({
            queryKey: [queryKeys.getRecipients],
          })

          toast.success(
            intl.formatMessage({
              id: 'recipient.new.added',
              defaultMessage: 'Recipient added!',
            }),
            {
              description: intl.formatMessage(
                {
                  id: 'recipient.new.added.description',
                  defaultMessage:
                    '{name} has been added to your recipients list',
                },
                {
                  name: createdRecipient.nickname,
                },
              ),
            },
          )
          navigate(`${BusinessRoute.Recipients}?id=${createdRecipient.id}`)
      }
    } catch (error) {
      if (error instanceof Error) {
        const apiError = error as ApiError

        if (apiError.response?.data?.status === 409) {
          const id = apiError.response?.data.details?.beneficiaryId

          if (id) {
            navigate(`${BusinessRoute.Recipients}?id=${id}`)
            toast.info(
              intl.formatMessage({
                defaultMessage: 'Beneficiary already exists',
                id: 'error.backend.beneficiaryAlreadyExists',
              }),
            )

            return
          }
        }

        notifyError(error)
      }
    }
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="recipient.add.review.title"
            defaultMessage="Review the new recipient details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          {isPaymentOps ? (
            <FormattedMessage
              id="recipient.add.review.subtitle.paymentOps"
              defaultMessage="Make sure all the details are correct and send the request for an Admin to review"
            />
          ) : (
            <FormattedMessage
              id="recipient.add.review.subtitle"
              defaultMessage="Make sure all the details are correct before adding your new recipient"
            />
          )}
        </Typography>

        <div className="p-6" />

        {dolarAppRecipient ? (
          <DolarAppRecipientReview recipient={dolarAppRecipient} />
        ) : null}

        <RecipientReview recipient={recipient} />

        <StickyContainer>
          <Button
            loading={isPending}
            disabled={isPending}
            width="full"
            onClick={handleSubmit}
          >
            {isPaymentOps ? (
              <FormattedMessage
                defaultMessage="Request approval to add new recipient"
                id="action.requestApprovalToAddRecipient"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Add {name} as recipient"
                id="action.addAsRecipient"
                values={{
                  name:
                    recipient?.nickname || dolarAppRecipient?.owner.displayName,
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
