import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { QRCode } from 'react-qrcode-logo'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useIntercom } from 'react-use-intercom'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getImage } from '@/lib/images'
import { queryClient } from '@/lib/queryClient'
import { getAnimationKey } from '@/lib/utils'
import { GoBackButton } from '@/shared/components'
import { DoubleTick, Scan } from '@/shared/icons/outline'
import {
  Button,
  Card,
  MotionDiv,
  SlideInScreen,
  Spinner,
  Typography,
} from '@/shared/ui'

import { getDolarTagLinkingState, linkDolarTag } from '../api'
import { ContractorOnboardingState, DolarTagLinkingState } from '../types'

import { DolarAppLinkedDialog } from './DolarAppLinkedDialog'
import { DolarAppLinkingDialog } from './DolarAppLinkingDialog'
import { DolarAppLinkingFailedDialog } from './DolarAppLinkingFailedDialog'

type Props = {
  onBack: () => void
  contractorState?: ContractorOnboardingState
  isLinked?: boolean
}

export const LinkDolarTagScreen = ({ onBack, contractorState }: Props) => {
  const { step } = useParams<{ step: string }>()
  const navigate = useNavigate()
  const { boot, showNewMessage } = useIntercom()

  const linkDolarTagQuery = useQuery({
    queryKey: [queryKeys.linkDolarTag],
    queryFn: linkDolarTag,
    select: (data) => data.data,
    enabled: !!contractorState,
  })

  const dolarTagLinkingStateQuery = useQuery({
    queryKey: [queryKeys.getDolarTagLinkingState],
    queryFn: getDolarTagLinkingState,
    select: (data) => data.data,
    enabled: !!contractorState,
    staleTime: 0,
    refetchInterval: 1000,
  })

  const onContinue = async () => {
    if (!contractorState) {
      return
    }

    const currentStepIndex = contractorState.steps.findIndex(
      (s) => s.name === step,
    )

    const nextStep = contractorState?.steps[currentStepIndex + 1]

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorOnboardingState],
    })

    await queryClient.invalidateQueries({
      queryKey: [queryKeys.getContractorPaymentMethods],
    })

    navigate(
      generatePath(ContractorRoute.OnboardingStep, {
        step: nextStep.name,
      }),
    )
  }

  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.dolarTag.title"
            defaultMessage="Link your DolarApp account"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.dolarTag.subtitle"
            defaultMessage="DolarApp allows you to instantly get paid by your employer at no cost, and to use your salary however you want"
          />
        </Typography>

        <div className="p-6" />

        <Card className="mx-auto flex flex-col gap-4" size="upload">
          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2">
              <Scan className="size-4" />

              <Typography bold>
                <FormattedMessage
                  id="contractor.dolarTag.scanQRCode.title"
                  defaultMessage="Scan the QR code"
                />
              </Typography>
            </div>

            <Typography className="px-6 text-neutral-gray-600">
              <FormattedMessage
                id="contractor.dolarTag.scanQRCode.subtitle"
                defaultMessage="Open your phone's camera, point at the QR code below, and scan it"
              />
            </Typography>
          </div>
          <MotionDiv
            key={getAnimationKey(linkDolarTagQuery.isPending)}
            className="flex flex-col items-center gap-3"
          >
            {linkDolarTagQuery.isPending ? (
              <div className="mx-auto w-fit rounded-3xl border p-2">
                <div className="flex size-[180px] items-center justify-center">
                  <Spinner className="size-12" />
                </div>
              </div>
            ) : (
              <div className="mx-auto w-fit rounded-3xl border p-2">
                <QRCode
                  eyeRadius={[
                    [8, 8, 0, 8],
                    [8, 8, 8, 0],
                    [8, 0, 8, 8],
                  ]}
                  logoImage={getImage({ name: 'dolarapp-logo-black' })}
                  logoWidth={48}
                  logoPaddingStyle="square"
                  removeQrCodeBehindLogo
                  qrStyle="dots"
                  quietZone={0}
                  size={180}
                  value={linkDolarTagQuery.data?.deepLink}
                />
              </div>
            )}
          </MotionDiv>

          <div className="flex items-center">
            <div className="flex-grow border-t border-neutral-gray-100" />
            <span className="mx-2 text-sm uppercase text-neutral-gray-400">
              <FormattedMessage id="label.then" defaultMessage="Then" />
            </span>
            <div className="flex-grow border-t border-neutral-gray-100" />
          </div>

          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2">
              <DoubleTick className="size-4" />

              <Typography bold>
                <FormattedMessage
                  id="contractor.dolarTag.confirmInTheApp.title"
                  defaultMessage="Confirm the linking from the app"
                />
              </Typography>
            </div>

            <Typography className="pl-6 text-neutral-gray-600">
              <FormattedMessage
                id="setup2fa.step.confirmInTheApp.description"
                defaultMessage="After scanning the QR code, confirm the linking process directly from your app"
              />
            </Typography>
          </div>

          <Typography
            variant="body-small"
            text="center"
            className="text-neutral-gray-600"
          >
            <FormattedMessage
              defaultMessage="Need help? {chatWithSupport}"
              id="2fa.dialog.needHelp.chatWithSupport"
              values={{
                chatWithSupport: (
                  <Button
                    variant="link"
                    size="inline"
                    className="text-xs"
                    onClick={() => {
                      boot({
                        customAttributes: { user_type: 'BUSINESS' },
                      })
                      showNewMessage()
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Chat with support"
                      id="2fa.dialog.action.chatWithSupport"
                    />
                  </Button>
                ),
              }}
            />
          </Typography>
        </Card>

        <div className="hidden md:block" />
      </SlideInScreen>

      <DolarAppLinkedDialog
        isOpen={
          !!(
            dolarTagLinkingStateQuery.data?.state ===
            DolarTagLinkingState.COMPLETED
          )
        }
        isPending={false}
        onContinue={onContinue}
        onOpenChange={() => {}}
      />

      <DolarAppLinkingDialog
        isOpen={
          !!(
            dolarTagLinkingStateQuery.data?.state ===
            DolarTagLinkingState.IN_PROGRESS
          )
        }
        onOpenChange={() => {}}
      />
      <DolarAppLinkingFailedDialog
        isOpen={
          !!(
            dolarTagLinkingStateQuery.data?.state ===
            DolarTagLinkingState.FAILED
          )
        }
        onTryAgain={onBack}
        onOpenChange={() => {}}
      />
    </>
  )
}
