import { SEARCH_FILTER_NAME } from '@/domains/Business/components'
import { api } from '@/lib/api'

import { Contractor } from '../types'

type SearchParams = {
  [SEARCH_FILTER_NAME]?: string
}

export function getContractors(params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<Contractor[]>(`/v1/me/contractors/search${searchQuery}`)
}
