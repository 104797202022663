import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { BusinessRoute, NavigationLink } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getTasksCount } from '@/domains/Business/features/Tasks'
import { Feature, useBusinessRole, useFeatures } from '@/domains/Business/hooks'
import {
  Bank,
  CreditCard,
  FileUser,
  Gears,
  Home,
  Navigation,
  SquareCheck,
  ThreeDots,
  Transactions,
} from '@/shared/icons/outline'

import { getPaymentCyclesCount } from '../../Contractors/api'

export function useNavigationLinks() {
  const { isAdmin } = useBusinessRole()

  const [hasContractorsEnabled] = useFeatures([Feature.CONTRACTORS])

  const [tasksCountQuery, paymentCyclesCountQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTasksCount],
        queryFn: getTasksCount,
        select: (data: AxiosResponse<{ pendingTasksCount: number }>) =>
          data.data.pendingTasksCount,
        enabled: isAdmin,
      },
      {
        queryKey: [queryKeys.getPaymentCyclesCount],
        queryFn: getPaymentCyclesCount,
        select: (data: AxiosResponse<{ pendingCyclesCount: number }>) =>
          data.data.pendingCyclesCount,
        enabled: isAdmin && hasContractorsEnabled,
      },
    ],
  })

  const [
    homeEnabled,
    tasksEnabled,
    accountsEnabled,
    cardsEnabled,
    recipientsEnabled,
    transactionsEnabled,
    teamEnabled,
    contractorsEnabled,
    settingsEnabled,
  ] = useFeatures([
    Feature.HOME,
    Feature.TASKS,
    Feature.ACCOUNTS,
    Feature.CARDS,
    Feature.RECIPIENTS,
    Feature.TRANSACTIONS,
    Feature.TEAM,
    Feature.CONTRACTORS,
    Feature.SETTINGS,
  ])

  const topLinks: NavigationLink[] = useMemo(
    () => [
      {
        key: 'home',
        icon: Home,
        path: BusinessRoute.Dashboard,
        enabled: homeEnabled,
      },
      {
        key: 'tasks',
        icon: SquareCheck,
        path: BusinessRoute.Tasks,
        enabled: tasksEnabled,
        count: tasksCountQuery.data,
      },
      {
        key: 'accounts',
        icon: Bank,
        path: BusinessRoute.Accounts,
        enabled: accountsEnabled,
      },
      {
        key: 'cards',
        icon: CreditCard,
        path: BusinessRoute.Cards,
        enabled: cardsEnabled,
      },
      {
        key: 'contractors',
        icon: FileUser,
        path: BusinessRoute.Contractors,
        enabled: contractorsEnabled,
        count: paymentCyclesCountQuery.data,
      },
      {
        key: 'recipients',
        icon: Navigation,
        path: BusinessRoute.Recipients,
        enabled: recipientsEnabled,
      },
      {
        key: 'transactions',
        icon: Transactions,
        path: BusinessRoute.Transactions,
        enabled: transactionsEnabled,
      },
      {
        key: 'team',
        icon: ThreeDots,
        path: BusinessRoute.Team,
        enabled: teamEnabled,
      },
    ],
    [
      accountsEnabled,
      cardsEnabled,
      contractorsEnabled,
      homeEnabled,
      paymentCyclesCountQuery.data,
      recipientsEnabled,
      tasksCountQuery.data,
      tasksEnabled,
      teamEnabled,
      transactionsEnabled,
    ],
  )

  const bottomLinks: NavigationLink[] = useMemo(
    () => [
      {
        key: 'settings',
        icon: Gears,
        path: BusinessRoute.Settings,
        enabled: settingsEnabled,
      },
    ],
    [settingsEnabled],
  )

  return { topLinks, bottomLinks }
}
