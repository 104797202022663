import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router'

import { queryKeys } from '@/constants/queryKeys'
import { isAPIError } from '@/lib/error'

import { getCurrentContractor } from '../api'
import { useContractorLogout } from '../hooks'

export const ContractorActivityChecker = () => {
  const { logout } = useContractorLogout()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getCurrentContractor],
    queryFn: getCurrentContractor,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (
      contractorQuery.error &&
      isAPIError(contractorQuery.error) &&
      contractorQuery.error.response?.status === 401
    ) {
      logout()
    }
  }, [contractorQuery.error, logout])

  return <Outlet />
}
