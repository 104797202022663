import {
  ContractorPaymentMethodType,
  USBankAccountDetails,
} from '@/domains/Contractor/types'
import { CountryCode } from '@/types/country'

import {
  ContractorOnboardingState,
  ContractorOnboardingStepName,
  ContractorOnboardingStepState,
} from '../../types'

export const MOCK_PAYMENT_METHOD_DETAILS: USBankAccountDetails = {
  id: '123',
  type: ContractorPaymentMethodType.US_BANK_ACCOUNT,
  paymentMethodInformation: {
    accountNumber: '1234567',
    routingNumber: '028198017',
    address: {
      addressStreet: 'Main St',
      addressStreetNumber: '123',
      addressCity: 'New York',
      addressState: 'NY',
      addressPostCode: 'AB-1235',
      addressCountry: CountryCode.US,
    },
  },
}

export const MOCK_ONBOARDING_STATE: ContractorOnboardingState = {
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  contractorId: 'a43fd35b-b103-4a6b-b19e-65bca565794b',
  personalDetails: null,
  paymentMethodDetails: null,
  steps: [
    {
      name: ContractorOnboardingStepName.PERSONAL_INFORMATION,
      state: ContractorOnboardingStepState.NOT_STARTED,
    },
    {
      name: ContractorOnboardingStepName.PAYMENT_METHOD,
      state: ContractorOnboardingStepState.NOT_STARTED,
    },
  ],
}

export const MOCK_ONBOARDING_COMPLETED_STATE: ContractorOnboardingState = {
  ...MOCK_ONBOARDING_STATE,
  steps: [
    {
      name: ContractorOnboardingStepName.PERSONAL_INFORMATION,
      state: ContractorOnboardingStepState.COMPLETED,
    },
    {
      name: ContractorOnboardingStepName.PAYMENT_METHOD,
      state: ContractorOnboardingStepState.COMPLETED,
    },
  ],
}

export const MOCK_ONBOARDING_STATE_WITH_PAYMENT_DETAILS: ContractorOnboardingState =
  {
    ...MOCK_ONBOARDING_STATE,
    paymentMethodDetails: MOCK_PAYMENT_METHOD_DETAILS,
  }

export const MOCK_ONBOARDING_IN_PROGRESS_STATE: ContractorOnboardingState = {
  ...MOCK_ONBOARDING_STATE,
  steps: [
    {
      name: ContractorOnboardingStepName.PERSONAL_INFORMATION,
      state: ContractorOnboardingStepState.COMPLETED,
    },
    {
      name: ContractorOnboardingStepName.PAYMENT_METHOD,
      state: ContractorOnboardingStepState.NOT_STARTED,
    },
  ],
}
