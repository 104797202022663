import { FormattedMessage, useIntl } from 'react-intl'

import { getOnboardingAddress } from '@/lib/address'
import { CountryName, Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { ContractorOnboardingState } from '../types'

type Props = {
  contractorState?: ContractorOnboardingState
}

export const PersonalInformationReview = ({ contractorState }: Props) => {
  const intl = useIntl()

  return (
    <>
      <Widget
        title={
          <FormattedMessage
            defaultMessage="Personal information"
            id="label.personalInformation"
          />
        }
      >
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="Birthday" id="label.birthday" />
          </Details.Label>

          <Details.Value>
            {intl.formatDate(
              new Date(contractorState?.personalDetails?.dateOfBirth ?? ''),
              {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
              },
            )}
          </Details.Value>
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Country of citizenship"
              id="label.countryOfCitizenship"
            />
          </Details.Label>

          <CountryName
            countryCode={contractorState?.personalDetails?.countryOfCitizenship}
          />
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage id="label.address" defaultMessage="Address" />
          </Details.Label>

          <Details.Value className="whitespace-break-spaces text-right">
            {contractorState?.personalDetails?.residentialAddress
              ? getOnboardingAddress(
                  contractorState?.personalDetails?.residentialAddress,
                  intl,
                )
              : '-'}
          </Details.Value>
        </Details>
      </Widget>
      <Widget
        title={
          <FormattedMessage
            defaultMessage="Tax information"
            id="label.taxInformation"
          />
        }
      >
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Tax residence"
              id="label.taxResidence"
            />
          </Details.Label>

          <CountryName
            countryCode={
              contractorState?.personalDetails?.countryOfTaxResidence
            }
          />
        </Details>
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Tax identification number"
              id="label.taxIdentificationNumber"
            />
          </Details.Label>

          <Details.Value>
            {contractorState?.personalDetails?.taxId}
          </Details.Value>
        </Details>
      </Widget>
    </>
  )
}
