import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'sonner'

import { BaseRoute } from '@/constants/paths'
import { Auth2FADialog } from '@/domains/Business/components'
import { Auth2FAResponse, Configuration } from '@/domains/Business/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useAuth } from '@/providers/AuthProvider'
import { TwoFactorMethod } from '@/types/auth'

import { authenticate, signIn } from '../api'

type Props = {
  email?: string
  password?: string
  defaultMethod: TwoFactorMethod
  identityId?: string
  initialSetup: boolean
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const Confirm2FADialog = ({
  email,
  password,
  defaultMethod,
  identityId,
  initialSetup,
  isOpen,
  onOpenChange,
}: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { updateRefreshToken } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: signIn,
  })

  const { mutateAsync: authenticateAsync, isPending: authIsPending } =
    useMutation({
      mutationFn: authenticate,
    })

  const onContinue = useCallback(
    async (data: Auth2FAResponse) => {
      if (!identityId) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Identity ID is missing',
            id: 'auth.error.noIdentityId',
          }),
        )

        return
      }

      try {
        const response = await mutateAsync({
          otp: data.otp,
          identityId,
        })

        updateRefreshToken(response.data.refreshToken)

        navigate(BaseRoute.SigningIn, {
          replace: true,
          state: { from: location.state?.from },
        })
      } catch (error) {
        notifyError(error)
      }
    },
    [
      identityId,
      intl,
      location.state?.from,
      mutateAsync,
      navigate,
      notifyError,
      updateRefreshToken,
    ],
  )

  const onMethodChange = useCallback(
    async (method: TwoFactorMethod) => {
      if (!email || !password) {
        return
      }

      try {
        await authenticateAsync({
          password,
          email: email.toLowerCase(),
          otpMethod: method,
        })
      } catch (error) {
        notifyError(error)
      }
    },
    [authenticateAsync, email, notifyError, password],
  )

  return (
    <Auth2FADialog
      email={email}
      password={password}
      isPending={isPending || authIsPending}
      defaultMethod={defaultMethod}
      isOpen={isOpen}
      onContinue={onContinue}
      onMethodChange={onMethodChange}
      onOpenChange={onOpenChange}
      configurations={[
        'resend-code',
        ...(!initialSetup ? (['use-another-method'] as Configuration[]) : []),
      ]}
    />
  )
}
