import { isTabletScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { Transaction } from '../../features/Transactions/types'

type Props = {
  transaction: Transaction
}

export const DateCell = ({ transaction }: Props) => {
  const isSmallDesktop = useMediaQuery(isTabletScreen)

  return (
    <div className="flex items-center">
      <Typography className="whitespace-nowrap">
        {formatDate(
          transaction.createdOn,
          isSmallDesktop ? 'dd MMM. yyyy' : 'd MMM. yyyy, HH:mm',
        )}
      </Typography>
    </div>
  )
}
