import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router'

import { ContractorRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Loader } from '@/shared/components'

import { getCurrentContractor } from '../api'
import { ContractorUserState } from '../types'

export const ContractorStateChecker = () => {
  const navigate = useNavigate()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getCurrentContractor],
    queryFn: getCurrentContractor,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (!contractorQuery.data) return

    switch (contractorQuery.data.state) {
      case ContractorUserState.ACTIVE:
        navigate(ContractorRoute.Overview, {
          replace: true,
        })
        break

      case ContractorUserState.ACCEPTED_INVITE:
      case ContractorUserState.ONBOARDING:
        navigate(ContractorRoute.Onboarding, { replace: true })
        break

      default:
        break
    }
  }, [navigate, contractorQuery.data])

  return <Loader />
}
