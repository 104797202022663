import { FormattedMessage } from 'react-intl'

import { Users } from '@/shared/icons/solid'
import { Avatar, Typography } from '@/shared/ui'

import { BulkTransaction } from '../../features/Transactions/types'

type Props = {
  transaction: BulkTransaction
}

export const BulkTransactionCell = ({ transaction }: Props) => {
  return (
    <div className="flex items-center gap-3">
      <Avatar icon={<Users className="size-5" />} />

      {transaction.bulkTransactionLabel ? (
        <div className="flex max-w-52 flex-col items-start gap-1">
          <Typography
            title={transaction.bulkTransactionLabel}
            className="line-clamp-1"
          >
            {transaction.bulkTransactionLabel}
          </Typography>
          <Typography variant="body-small" className="text-neutral-gray-600">
            <FormattedMessage
              id="label.recipientsCount"
              defaultMessage="{count, plural, one {# recipient} other {# recipients}}"
              values={{
                count:
                  (transaction.transactions.length ?? 0) +
                  (transaction.failedTransactions.length ?? 0),
              }}
            />
          </Typography>
        </div>
      ) : (
        <Typography>
          <FormattedMessage
            id="label.recipientsCount"
            defaultMessage="{count, plural, one {# recipient} other {# recipients}}"
            values={{
              count:
                (transaction.transactions.length ?? 0) +
                (transaction.failedTransactions.length ?? 0),
            }}
          />
        </Typography>
      )}
    </div>
  )
}
