import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPerson = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 20"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M10 9.915A3.38 3.38 0 0 1 7.524 8.89a3.38 3.38 0 0 1-1.027-2.476q0-1.45 1.027-2.474A3.38 3.38 0 0 1 10 2.915q1.45 0 2.476 1.024a3.37 3.37 0 0 1 1.027 2.474q0 1.45-1.027 2.476A3.38 3.38 0 0 1 10 9.915m-6.836 7.006v-2.513q0-.754.39-1.387t1.038-.966q1.307-.65 2.66-.978a11.758 11.758 0 0 1 5.506-.003q1.353.326 2.65.976.647.333 1.038.964.39.631.39 1.394v2.513zm1.896-1.896h9.88v-.592a.76.76 0 0 0-.114-.408.8.8 0 0 0-.302-.285 10.8 10.8 0 0 0-2.226-.819 9.64 9.64 0 0 0-4.595 0q-1.142.276-2.226.819a.8.8 0 0 0-.303.285.76.76 0 0 0-.114.408zM10 8.02q.663 0 1.135-.472t.472-1.135-.472-1.132A1.55 1.55 0 0 0 10 4.81q-.662 0-1.135.47a1.54 1.54 0 0 0-.472 1.132q0 .663.472 1.135.472.471 1.135.472"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPerson)
export default ForwardRef
