import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { Outlet } from 'react-router'

import { OTP_ERROR } from '@/constants/errors'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { api } from '@/lib/api'
import { TwoFactorMethod } from '@/types/auth'

import { getCurrent2FAMethod } from '../../api'
import { Auth2FADialog } from '../../components'

export const App2FAInterceptor = () => {
  const notifyError = useErrorToast()

  const [default2FAMethod, setDefault2FAMethod] = useState<TwoFactorMethod>(
    TwoFactorMethod.EMAIL,
  )

  const [isLoading, setIsLoading] = useState(false)

  const [isOTPOpen, setIsOTPOpen] = useState(false)
  const [lastFailedRequest, setLastFailedRequest] =
    useState<AxiosRequestConfig | null>(null)

  const [resolveFn, setResolveFn] = useState<
    ((value: unknown) => void) | null
  >()

  const [rejectFn, setRejectFn] = useState<((value: unknown) => void) | null>()

  const current2FAQuery = useQuery({
    queryKey: [queryKeys.getCurrent2FAMethod],
    queryFn: getCurrent2FAMethod,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (current2FAQuery.data) {
      const { otpMethod } = current2FAQuery.data

      setDefault2FAMethod(otpMethod)
    }
  }, [current2FAQuery.data])

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status === 403 &&
          error.response.data?.error === OTP_ERROR
        ) {
          return new Promise((resolve, reject) => {
            setLastFailedRequest(error.config)
            setIsOTPOpen(true)

            setResolveFn(() => resolve)
            setRejectFn(() => reject)
          })
        }

        return Promise.reject(error)
      },
    )

    return () => {
      api.interceptors.response.eject(interceptor)
    }
  }, [])

  return (
    <>
      <Outlet />

      <Auth2FADialog
        onMethodChange={() => {}}
        isPending={isLoading}
        defaultMethod={default2FAMethod}
        isOpen={isOTPOpen}
        onOpenChange={(open) => {
          setIsOTPOpen(open)
          rejectFn?.(null)
        }}
        onContinue={async ({ otp }) => {
          if (lastFailedRequest) {
            setIsLoading(true)

            const parsedData = JSON.parse(lastFailedRequest.data)

            const dataWithOTP = JSON.stringify({
              ...parsedData,
              otp,
            })

            try {
              const response = await api.request({
                ...lastFailedRequest,
                data: dataWithOTP,
              })

              if (resolveFn) {
                resolveFn(response)
              }

              setIsLoading(false)
              setIsOTPOpen(false)
            } catch (error) {
              notifyError(error)
              setIsLoading(false)
            }
          }
        }}
      />
    </>
  )
}
