import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { googlePlacesFields } from '@/domains/Business/constants'
import { GooglePlacesParsedAddress } from '@/lib/address'
import { getCountryISO3byISO2 } from '@/lib/country'
import {
  AddressAutocompleteField,
  CountryCombobox,
  OptionalTag,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'

import { onboardingAddressFieldsOrder } from '../constants'
import { OnboardingStepConfig } from '../types'

import { CreateOwnerAddressSchema } from './NewOwnerSteps/schema'

type Props = {
  config?: OnboardingStepConfig
  inputView: 'auto' | 'manual'
  onInputViewChange: (view: 'auto' | 'manual') => void
  form: UseFormReturn<CreateOwnerAddressSchema>
}

export const OwnerAddressFields = ({
  config,
  inputView,
  onInputViewChange,
  form,
}: Props) => {
  const intl = useIntl()

  const optionalFields =
    config?.fields
      .filter((field) => !field.required)
      .map((field) => field.name) ?? []

  return (
    <>
      {inputView === 'auto' ? (
        <FormField
          control={form.control}
          name="GOOGLE_PLACES_ADDRESS"
          render={({ field }) => (
            <AddressAutocompleteField
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              name={field.name}
              disabled={field.disabled}
              placeholder={intl.formatMessage({
                id: 'label.searchAddress',
                defaultMessage: 'Search address',
              })}
              onManualClick={() => onInputViewChange('manual')}
              onGoogleAddressSelected={(address) => {
                onboardingAddressFieldsOrder.forEach((field, index) => {
                  form.setValue(
                    field,
                    address[
                      googlePlacesFields[
                        index
                      ] as keyof GooglePlacesParsedAddress
                    ],
                  )
                })

                onInputViewChange('manual')
              }}
            />
          )}
        />
      ) : (
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2 gap-3">
            <FormField
              control={form.control}
              name="ADDRESS_STREET"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="address-line1"
                      placeholder={intl.formatMessage({
                        id: 'label.street',
                        defaultMessage: 'Street',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Street"
                      id="label.street"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ADDRESS_STREET_NUMBER"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="address-line2"
                      placeholder={intl.formatMessage({
                        id: 'label.streetNumber',
                        defaultMessage: 'Street number',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Street number"
                      id="label.streetNumber"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="ADDRESS_DISTRICT"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'label.neighborhoodOrDistrict',
                      defaultMessage: 'Neighborhood or district',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Neighborhood or district"
                    id="label.neighborhoodOrDistrict"
                  />
                </AnimatedFormLabel>

                {field.value === '' && optionalFields.includes(field.name) && (
                  <OptionalTag />
                )}
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="ADDRESS_CITY"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-level2"
                    placeholder={intl.formatMessage({
                      id: 'label.city',
                      defaultMessage: 'City',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage defaultMessage="City" id="label.city" />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-3">
            <FormField
              control={form.control}
              name="ADDRESS_STATE"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="address-level1"
                      placeholder={intl.formatMessage({
                        id: 'label.stateOrProvince',
                        defaultMessage: 'State or province',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="State or province"
                      id="label.stateOrProvince"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ADDRESS_POST_CODE"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="postal-code"
                      placeholder={intl.formatMessage({
                        id: 'label.postCode',
                        defaultMessage: 'Post code',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Post code"
                      id="label.postCode"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="ADDRESS_COUNTRY"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CountryCombobox
                    placeholder={intl.formatMessage({
                      id: 'label.country',
                      defaultMessage: 'Country',
                    })}
                    onSelect={(value) => {
                      field.onChange(value.valueAsCode)
                    }}
                    value={getCountryISO3byISO2(field.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      )}
    </>
  )
}
