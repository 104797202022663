import { api } from '@/lib/api'

import { Account } from '../types'

export type EditAccountRequest = {
  walletId: string
  label: string
}

export function editWallet(data: EditAccountRequest) {
  const { walletId, ...rest } = data

  return api.patch<Account>(`/v1/me/wallets/${walletId}`, rest)
}
