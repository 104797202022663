import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { ACH_FEE, PaymentMethod, WIRE_FEE } from '@/domains/Business/constants'
import { formatAmount } from '@/lib/money'
import {
  MotionDiv,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

type Props = {
  disabled?: boolean
  value: string
  onChange: (v: string) => void
}

export const PaymentMethodSelect = ({ disabled, onChange, value }: Props) => {
  const intl = useIntl()

  return (
    <Select disabled={disabled} onValueChange={onChange} defaultValue={value}>
      <SelectTrigger>
        {value ? (
          <MotionDiv className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Typography>
            <MotionDiv key={value}>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="paymentMethod.label"
                  defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                  values={{ method: value }}
                />
              </Typography>
            </MotionDiv>
          </MotionDiv>
        ) : (
          <SelectValue
            placeholder={intl.formatMessage({
              id: 'label.paymentMethod',
              defaultMessage: 'Payment method',
            })}
          />
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(PaymentMethod).map((method) => (
          <SelectItem
            className="flex h-[54px] flex-col items-start"
            disabled={method === value}
            key={method}
            value={method}
          >
            <Typography>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method }}
              />
            </Typography>
            <div className="flex gap-0.5">
              <Typography
                variant="body-small"
                className="text-neutral-gray-600"
              >
                <FormattedMessage
                  id="label.transactionFee"
                  defaultMessage="Transaction fee"
                />
              </Typography>
              <Typography
                variant="body-small"
                className="text-neutral-gray-600 line-through"
              >
                {formatAmount({
                  amount: method === PaymentMethod.WIRE ? WIRE_FEE : ACH_FEE,
                  currency: Currency.USDC,
                })}
              </Typography>
              <Typography
                variant="body-small"
                bold
                className="uppercase text-primary"
              >
                <FormattedMessage defaultMessage="Free" id="label.free" />
              </Typography>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
