import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { AmountScreen, SendAmountSchema } from './components/AmountScreen'
import { ReviewScreen } from './components/ReviewScreen'
import { SelectRecipientScreen } from './components/SelectRecipientScreen'

enum Step {
  Recipient = 'recipient',
  Amount = 'amount',
  Review = 'review',
}

export type RequestData = {
  amount: string
  requestId: string
  walletId: string
  currency: Currency
  currencyOut: Currency
  beneficiaryId: string
  paymentMessage?: string
}

type State =
  | { type: Step.Recipient }
  | { type: Step.Amount }
  | { type: Step.Review }

export const Send = () => {
  const [id] = useSearchParamsValue(['recipient'])
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<State>(
    id ? { type: Step.Amount } : { type: Step.Recipient },
  )

  const [recipientId, setRecipientId] = useState<string | null>(id)
  const [sendData, setSendData] = useState<SendAmountSchema | undefined>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Recipient:
        return 25
      case Step.Amount:
        return 50
      case Step.Review:
        return 75
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.Dashboard}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Recipient && (
          <SelectRecipientScreen
            onSelect={(recipientId) => {
              setRecipientId(recipientId)

              setActiveStep({ type: Step.Amount })
            }}
          />
        )}

        {activeStep.type === Step.Amount && (
          <AmountScreen
            sendData={sendData}
            recipientId={recipientId}
            onBack={() => {
              setSendData(undefined)
              setActiveStep({ type: Step.Recipient })
            }}
            onContinue={(data) => {
              setSendData(data)

              setActiveStep({ type: Step.Review })
            }}
          />
        )}

        {activeStep.type === Step.Review && (
          <ReviewScreen
            recipientId={recipientId}
            onBack={() => setActiveStep({ type: Step.Amount })}
            sendData={sendData}
          />
        )}
      </AnimatePresence>
    </FullScreen>
  )
}
