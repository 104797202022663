export enum PaymentMethod {
  WIRE = 'WIRE',
  ACH = 'ACH',
}

export const SPEI = 'SPEI'
export const WIRE = 'Wire'
export const ACH = 'ACH'
export const DOLAR_TAG = 'DolarTag'

export const ACH_FEE = 3
export const WIRE_FEE = 10
export const PHYSICAL_CARD_FEE = 5
