import { FormattedMessage } from 'react-intl'
import { QRCode } from 'react-qrcode-logo'

import { getImage } from '@/lib/images'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Card,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

const DOLAR_APP_DOWNLOAD_LINK = 'https://dolarapp.onelink.me/TKY0/vz9eea8k'

type Props = {
  onBack: () => void
  onContinue: () => void
}

export const DownloadAppScreen = ({ onBack, onContinue }: Props) => {
  return (
    <>
      <GoBackButton className="hidden md:left-80 md:flex" onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="contractor.onboarding.downloadApp.title"
            defaultMessage="Discover a world without borders"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="contractor.onboarding.downloadApp.subtitle"
            defaultMessage="Download DolarApp on your phone, create an account, get your salary paid and enjoy the benefits of a truly global account"
          />
        </Typography>

        <div className="p-6" />

        <Card size="large" className="grid gap-4 md:grid-cols-2">
          <div className="flex flex-col items-center justify-end md:items-start">
            <div className="w-fit rounded-2xl border p-1">
              <QRCode
                eyeRadius={[
                  [8, 8, 0, 8],
                  [8, 8, 8, 0],
                  [8, 0, 8, 8],
                ]}
                logoImage={getImage({ name: 'dolarapp-logo-black' })}
                logoWidth={35}
                logoPaddingStyle="square"
                removeQrCodeBehindLogo
                qrStyle="dots"
                quietZone={0}
                size={150}
                value={DOLAR_APP_DOWNLOAD_LINK}
              />
            </div>

            <div className="p-3" />

            <Typography variant="h4">
              <FormattedMessage
                id="contractor.onboarding.downloadApp.getApp.title"
                defaultMessage="Get the app now"
              />
            </Typography>

            <div className="p-1" />

            <Typography className="text-center text-neutral-gray-600 md:text-left">
              <FormattedMessage
                id="contractor.onboarding.downloadApp.getApp.subtitle"
                defaultMessage="Visit the App Store, Google Play, or scan with your phone to download instantly"
              />
            </Typography>

            <div className="p-2" />

            <div className="flex gap-3">
              <a
                href={DOLAR_APP_DOWNLOAD_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={getImage({ name: 'app-store-badge' })}
                  alt="App Store"
                  className="h-10"
                />
              </a>
              <a
                href={DOLAR_APP_DOWNLOAD_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={getImage({ name: 'google-play-badge' })}
                  alt="Google Play"
                  className="h-10"
                />
              </a>
            </div>
          </div>

          <img
            src={getImage({
              name: 'mobile-app-screenshot',
              category: 'contractor',
            })}
            alt="DolarApp"
            className="hidden h-80 md:block"
            aria-hidden
          />
        </Card>

        <StickyContainer>
          <Button onClick={onContinue} width="full">
            <FormattedMessage
              id="action.alreadyHaveMyDolarAppAccount"
              defaultMessage="I already have my DolarApp account!"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
