import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { getCardTitle } from '@/lib/card'
import { getImage } from '@/lib/images'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Card,
  SearchInput,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { CardScreenType, Card as CardType } from '../types'

type Props = {
  card?: CardType
  setScreen: (screen: CardScreenType) => void
}

export const CardStatements = ({ card, setScreen }: Props) => {
  const [search, setSearch] = useState('')

  return (
    <SlideInLeft>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'base' })}
              variant="ghost"
              size="inline"
            >
              {card ? (
                <Typography>{getCardTitle(card)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen({ type: 'info' })}
              variant="ghost"
              size="inline"
            >
              <FormattedMessage
                id="card.sidebar.information"
                defaultMessage="Card information"
              />
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="card.sidebar.statements"
                defaultMessage="Statements"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <SearchInput value={search} onChange={setSearch} />

      <div className="p-4" />

      <Card size="medium" className="flex items-center gap-6">
        <img
          src={getImage({ name: 'empty-search' })}
          alt=""
          aria-hidden
          className="size-12"
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="cards.statements.empty.search"
              defaultMessage="No statements found!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="transactions.statements.empty.search.description"
              defaultMessage="There are still no statements for this card. You will find them here as they are generated"
            />
          </Typography>
        </div>
      </Card>
    </SlideInLeft>
  )
}
