import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router'

import { COUNTRIES } from '@/constants/countries'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Feature, useFeatures } from '@/domains/Business/hooks/useFeatures'
import { getCountryNameByISO3 } from '@/lib/country'
import { getFlagUrl } from '@/lib/images'
import { getAnimationKey } from '@/lib/utils'
import { GoBackButton, Widget } from '@/shared/components'
import { ChevronRight, DolarappWhite } from '@/shared/icons/outline'
import {
  Button,
  Card,
  MotionDiv,
  Skeleton,
  SlideInScreen,
  Typography,
} from '@/shared/ui'
import { Country, CountryCode } from '@/types/country'

import { getDefaultAccountDetails } from '../../../Accounts/api'
import { AccountType } from '../../../Accounts/types'

type Props = {
  onContinue: (country: {
    type: 'bank' | 'dolarapp'
    country?: Country['code']
  }) => void
}

export const RecipientAccountTypeStep = ({ onContinue }: Props) => {
  const location = useLocation()
  const intl = useIntl()

  const [settingsEnabled] = useFeatures([Feature.SETTINGS])

  const accountDetailsQuery = useQuery({
    queryKey: [queryKeys.getDefaultAccountDetails],
    queryFn: getDefaultAccountDetails,
    select: (data) => data.data,
  })

  const getFilteredCountries = () => {
    const hasUSAccounts = accountDetailsQuery.data?.bankAccounts?.some(
      (account) => [AccountType.ACH, AccountType.WIRE].includes(account.type),
    )

    if (hasUSAccounts) {
      return COUNTRIES
    }

    return COUNTRIES.filter((country) => country.code !== CountryCode.US)
  }

  return (
    <>
      <GoBackButton to={location.state?.from ?? BusinessRoute.Recipients} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Add new recipient"
            id="recipients.add.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="How do you want to add the new recipient?"
            id="recipients.add.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <MotionDiv className="flex flex-col gap-6">
          <Widget
            variant="form"
            title={
              <FormattedMessage
                id="label.bankAccount"
                defaultMessage="Bank account"
              />
            }
          >
            <MotionDiv
              className="flex flex-col gap-3"
              key={getAnimationKey(accountDetailsQuery.isPending)}
            >
              {accountDetailsQuery.isPending ? (
                <Card
                  size="upload"
                  className="flex w-full items-center justify-between"
                >
                  <div className="flex items-center gap-4">
                    <Skeleton className="size-7 shrink-0 rounded-full" />

                    <div className="flex flex-col gap-0.5">
                      <Skeleton className="h-5 w-24" />
                      <Skeleton className="h-5 w-24" />
                    </div>
                  </div>
                  <Skeleton className="size-4" />
                </Card>
              ) : (
                getFilteredCountries().map((country) => {
                  return (
                    <Card
                      size="medium"
                      className="flex w-full items-center justify-between"
                      key={country.code}
                      asChild
                      scalable
                    >
                      <Button
                        size="inline"
                        variant="ghost"
                        onClick={() => {
                          onContinue({
                            type: 'bank',
                            country: country.code,
                          })
                        }}
                      >
                        <div className="flex items-center gap-3">
                          <img
                            src={getFlagUrl(country.flagCode)}
                            className="size-9 shrink-0"
                            alt={intl.formatMessage(
                              {
                                id: 'country.flag.alt',
                                defaultMessage:
                                  '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                              },
                              { country: country.code },
                            )}
                          />

                          <div className="flex flex-col">
                            <Typography bold>
                              {getCountryNameByISO3(country.code, intl.locale)}
                            </Typography>
                            <Typography
                              variant="body-small"
                              className="text-neutral-gray-600"
                            >
                              {(() => {
                                switch (country.code) {
                                  case CountryCode.US:
                                    return (
                                      <FormattedMessage
                                        id="recipients.add.bankAccount.description.US"
                                        defaultMessage="Send USD via Wire or ACH transfer"
                                      />
                                    )
                                  case CountryCode.MX:
                                    return (
                                      <FormattedMessage
                                        id="recipients.add.bankAccount.description.MX"
                                        defaultMessage="Send MXN via SPEI transfer"
                                      />
                                    )

                                  default:
                                    return null
                                }
                              })()}
                            </Typography>
                          </div>
                        </div>

                        <ChevronRight className="size-6 shrink-0 text-neutral-gray-600" />
                      </Button>
                    </Card>
                  )
                })
              )}
            </MotionDiv>
          </Widget>

          {settingsEnabled && (
            <Widget
              variant="form"
              title={
                <FormattedMessage
                  id="label.dolarAppAccount"
                  defaultMessage="DolarApp account"
                />
              }
            >
              <Card
                size="medium"
                className="flex w-full items-center justify-between"
                asChild
                scalable
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => onContinue({ type: 'dolarapp' })}
                >
                  <div className="flex items-center gap-3">
                    <div className="flex size-9 items-center justify-center rounded-full bg-primary">
                      <DolarappWhite className="size-5 text-white" />
                    </div>

                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <Typography bold>
                          <FormattedMessage
                            id="label.inDolarApp"
                            defaultMessage="In DolarApp"
                          />
                        </Typography>
                      </div>
                      <Typography
                        variant="body-small"
                        className="text-neutral-gray-600"
                      >
                        <FormattedMessage
                          id="recipients.add.dolarApp.description"
                          defaultMessage="Send USDc instantly and for free via DolarTag"
                        />
                      </Typography>
                    </div>
                  </div>
                  <ChevronRight className="size-6 shrink-0 text-neutral-gray-600" />
                </Button>
              </Card>
            </Widget>
          )}
        </MotionDiv>
      </SlideInScreen>
    </>
  )
}
