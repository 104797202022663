import { forwardRef, Ref, type SVGProps } from 'react'

const SvgTransactions = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <mask
      id="transactions_svg__a"
      width={24}
      height={24}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill={props.color || `currentColor`} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#transactions_svg__a)">
      <path
        fill={props.color || `currentColor`}
        d="M8 9q.424 0 .713-.287A.97.97 0 0 0 9 8a.97.97 0 0 0-.287-.713A.97.97 0 0 0 8 7a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 8q0 .424.287.713Q7.576 9 8 9m0 4q.424 0 .713-.287A.97.97 0 0 0 9 12a.97.97 0 0 0-.287-.713A.97.97 0 0 0 8 11a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 12q0 .424.287.713Q7.576 13 8 13m0 4q.424 0 .713-.288A.97.97 0 0 0 9 16a.97.97 0 0 0-.287-.713A.97.97 0 0 0 8 15a.97.97 0 0 0-.713.287A.97.97 0 0 0 7 16q0 .424.287.712Q7.576 17 8 17m-3 4q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 19V5q0-.824.587-1.412A1.93 1.93 0 0 1 5 3h11l5 5v11q0 .824-.587 1.413A1.93 1.93 0 0 1 19 21zm0-2h14V9h-4V5H5z"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgTransactions)
export default ForwardRef
