import { Big } from 'big.js'
import { z } from 'zod'

export const payAsYouGoContractorFormSchema = z.object({
  contractors: z.array(
    z.object({
      id: z.string(),
      amount: z
        .string()
        .optional()
        .refine((v) => !v || Big(v).gt(0), {
          message: 'validation.balance.minAmount',
        }),
    }),
  ),
})

export type PayAsYouGoContractorFormSchema = z.infer<
  typeof payAsYouGoContractorFormSchema
>
