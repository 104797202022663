import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { HistoricalPayment } from '../../../types'

type Props = {
  payment: HistoricalPayment
}

export const HistoricalPaidDateCell = ({ payment }: Props) => {
  return (
    <div className="flex items-center">
      <Typography className="whitespace-nowrap">
        {formatDate(payment.paymentDate, 'dd MMM. yyyy')}
      </Typography>
    </div>
  )
}
