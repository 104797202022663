import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { getFullName, intersperse } from '@/lib/typography'
import { StatusBadge, StatusVariant } from '@/shared/components'
import { CircleCancel, Pencil } from '@/shared/icons/outline'
import {
  Avatar,
  Badge,
  Button,
  MotionDiv,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { TeamMember, TeamState } from '../../features/Team/types'
import { useBusinessRole, useBusinessUser } from '../../hooks'

import { BlockTeamMemberDialog } from './BlockTeamMemberDialog'
import { PermissionsList } from './PermissionsList'
import { TeamCardsTable } from './TeamCardsTable'
import { TeamMemberInviteSection } from './TeamMemberInviteSection'
import { TeamMemberUnblockSection } from './TeamMemberUnblockSection'
import { TeamMoneyLimit } from './TeamMoneyLimit'

const sidebarBadgeVariantByTeamStatus: Record<TeamState, StatusVariant> = {
  [TeamState.ACTIVE]: 'active',
  [TeamState.INVITED]: 'neutral-dark',
  [TeamState.BLOCKED]: 'neutral-dark',
  [TeamState.TERMINATED]: 'failed',
}

type Props = {
  member?: TeamMember
  setScreen: (screen: 'base' | 'edit') => void
}

export const BaseTeamDetails = ({ member, setScreen }: Props) => {
  const intl = useIntl()

  const { isAdmin } = useBusinessRole()
  const businessUser = useBusinessUser()

  const [showBlockDialog, setShowBlockDialog] = useState(false)

  const isUser = member?.identityId === businessUser?.identityId

  return (
    <SlideInLeft className="flex flex-col gap-8">
      {member ? (
        <MotionDiv className="flex justify-between">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <Avatar
                name={getFullName(member)}
                variant="dark-neutral"
                size="xl"
              />
              <div className="flex flex-col">
                <Typography variant="h3">{getFullName(member)}</Typography>

                <Typography className="text-neutral-gray-600">
                  {intersperse([member.userTitle, member.email], ' • ')}
                </Typography>
              </div>
            </div>

            <div className="p-1" />

            <div className="flex pl-16 gap-2">
              <Badge
                variant="dark-neutral"
                className="text-xs font-semibold capitalize"
              >
                <FormattedMessage
                  id="team.role.label"
                  defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                  values={{ role: member.role }}
                />
              </Badge>
              <StatusBadge
                variant={sidebarBadgeVariantByTeamStatus[member.state]}
                title={
                  <FormattedMessage
                    id="team.state.label"
                    defaultMessage="{state, select, ACTIVE {Active} INVITED {Invited} BLOCKED {Blocked} TERMINATED {Terminated} other {}}"
                    values={{ state: member.state }}
                  />
                }
              />
            </div>
          </div>

          {isAdmin && member?.state !== TeamState.TERMINATED && (
            <div className="flex items-center gap-2">
              {!isUser && (
                <Button
                  disabled={member.state === TeamState.BLOCKED}
                  leftIcon={<CircleCancel className="size-4" />}
                  onClick={() => setShowBlockDialog(true)}
                  variant="tertiary"
                  size="icon"
                  aria-label={intl.formatMessage({
                    id: 'team.details.blockTeamMember',
                    defaultMessage: 'Block team member',
                  })}
                />
              )}
              <Button
                leftIcon={<Pencil className="size-4" />}
                onClick={() => setScreen('edit')}
                variant="tertiary"
                size="icon"
                aria-label={intl.formatMessage({
                  id: 'team.details.edit',
                  defaultMessage: 'Edit team member',
                })}
              />
            </div>
          )}
        </MotionDiv>
      ) : (
        <div className="flex justify-between">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-[33px] w-36" />
            <Skeleton className="h-[21px] w-24" />

            <div className="flex gap-2">
              <Skeleton className="h-[22px] w-12" />
              <Skeleton className="h-[22px] w-12" />
            </div>
          </div>

          <div className="flex gap-3">
            <Skeleton className="size-8" />
            <Skeleton className="size-8" />
          </div>
        </div>
      )}

      <TeamMemberInviteSection member={member} />

      <TeamMemberUnblockSection member={member} />

      <PermissionsList member={member} />

      <TeamMoneyLimit member={member} />

      <TeamCardsTable member={member} />

      <BlockTeamMemberDialog
        member={member}
        isOpen={showBlockDialog}
        onOpenChange={setShowBlockDialog}
        onBlock={() => {
          setScreen('base')
        }}
      />

      <div className="p-6" />
    </SlideInLeft>
  )
}
