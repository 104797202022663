import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import {
  MOCKED_CONTRACTOR_DETAILS,
  MOCKED_CONTRACTOR_PAYMENTS,
  MOCKED_CONTRACTORS,
} from './contractors.mocks'

export const contractorsHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/contractors/search`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTORS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/contractors/:id`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTOR_DETAILS)
  }),

  http.post(`${BASE_API_DEV}/v1/me/contractors`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTORS[0])
  }),

  http.get(`${BASE_API_DEV}/v1/me/payment-cycles/count`, async () => {
    return HttpResponse.json({ pendingCyclesCount: 1 })
  }),

  http.get(
    `${BASE_API_DEV}/v1/me/contract-agreements/contract/:id`,
    async () => {
      return HttpResponse.json([
        {
          id: '11512fdc-5cb5-456a-9b92-69ce8036bb07',
          uploadedOn: '2024-12-12T16:05:51',
          fileName: 'test.jpg',
        },
      ])
    },
  ),

  http.get(`${BASE_API_DEV}/v1/me/payment-cycles`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTOR_PAYMENTS)
  }),

  http.delete(`${BASE_API_DEV}/v1/me/payment-cycles/:id`, async () => {
    return HttpResponse.json({})
  }),
]

export * from './contractors.mocks'
