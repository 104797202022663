import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'

import { isProduction } from './lib/utils'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.replayCanvasIntegration(),
  ],

  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    /^https:\/\/api\.business\.dolarapp\.com/,
    /^https:\/\/api\.business\.dolarapp\.dev/,
  ],
  beforeSend: (event) => {
    if (!isProduction()) {
      return null
    }

    return event
  },

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
