import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router'

import { Currency } from '@/constants/currency'
import { formatCurrency } from '@/lib/money'

import { CURRENCY_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const CurrencyBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(CURRENCY_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(CURRENCY_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.currency',
        defaultMessage: 'Currency',
      })}
    >
      {searchParams
        .get(CURRENCY_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          return formatCurrency(option as Currency)
        })
        .join(', ')}
    </FilterBadge>
  )
}
