import { RouteObject } from 'react-router'

import { BusinessRoute } from '@/constants/paths'

import { ReviewTask } from './ReviewTask'
import { Tasks } from './Tasks'

export const tasksRoutes: RouteObject[] = [
  {
    path: BusinessRoute.Tasks,
    element: <Tasks />,
  },
]

export const reviewTasksRoutes: RouteObject[] = [
  {
    path: BusinessRoute.ReviewTask,
    element: <ReviewTask />,
  },
]
