import { cn } from '@/lib/utils'

import { MotionDiv, Typography } from '../ui'

type Props = {
  className?: string
  disabled?: boolean
  placeholder?: string
  children: React.ReactNode
}

export const InputLikeCard = ({
  className,
  disabled,
  placeholder,
  children,
}: Props) => {
  return (
    <div
      className={cn(
        'flex h-[54px] w-full rounded-lg bg-white px-4 py-3 text-sm',
        disabled && 'bg-neutral-gray-200 text-neutral-gray-600',
        className,
      )}
    >
      <MotionDiv className="flex flex-col items-start">
        <Typography
          className={cn(
            '-mb-1 -mt-0.5 text-neutral-gray-600',
            disabled && 'text-neutral-gray-400',
          )}
          variant="body-tiny"
        >
          {placeholder}
        </Typography>

        <Typography className={cn(disabled && 'text-neutral-gray-600')}>
          {children}
        </Typography>
      </MotionDiv>
    </div>
  )
}
