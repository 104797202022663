import { getImage } from '@/lib/images'

export const PreloadedImages = () => {
  return (
    <>
      <link
        rel="preload"
        href={getImage({ name: 'business-cards', category: 'cards' })}
        as="image"
      />
      <link
        rel="preload"
        href={getImage({ name: 'frozen-card', category: 'cards' })}
        as="image"
      />
      <link
        rel="preload"
        href={getImage({ name: 'terminated-card', category: 'cards' })}
        as="image"
      />
      <link
        rel="preload"
        href={getImage({ name: 'apple-pay-card' })}
        as="image"
      />
    </>
  )
}
