import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'

import { Card as CardType } from '../../Cards/types'

export const CardsBanner = ({ cards }: { cards: CardType[] }) => {
  const cardsRoute =
    cards.length > 0 ? BusinessRoute.Cards : BusinessRoute.CreateCard

  return (
    <Card size="large" className="relative flex flex-col overflow-hidden">
      <div className="flex items-center gap-8">
        <div className="hidden h-36 w-32 flex-shrink-0 lg:flex">
          <img
            src={getImage({ name: 'apple-pay-card' })}
            alt=""
            aria-hidden
            className="absolute -left-12 bottom-0 size-48 object-contain"
          />
        </div>

        <div className="flex h-full flex-col gap-3">
          <div className="flex flex-col">
            <Typography bold>
              <FormattedMessage
                id="dashboard.card.banner.title"
                defaultMessage="Haven't heard? Your cards have new benefits!"
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              <FormattedMessage
                id="dashboard.card.banner.subtitle"
                defaultMessage="Continue saving up to 5% on FX fees and now earn 1% cashback on local purchases 🔥"
              />
            </Typography>
          </div>

          <Button
            asChild
            rightIcon={<ChevronRight />}
            className="mt-auto self-start"
          >
            <Link to={cardsRoute}>
              {cards.length > 0 ? (
                <FormattedMessage
                  id="action.goToMyCards"
                  defaultMessage="Go to my cards"
                />
              ) : (
                <FormattedMessage
                  id="action.createMyFirstCard"
                  defaultMessage="Create my first card"
                />
              )}
            </Link>
          </Button>
        </div>
      </div>
    </Card>
  )
}
